import { createActions, handleActions } from 'redux-actions';

const initialState = {
  // attr: null,
};

// Actions
const { home: actions } = createActions({
  HOME: {
    // FUNC: attr => ({ attr }),
  },
}) as any;

// Reducer
export default handleActions(
  {
    // [actions.func]: (state, action: any) => ({
    //   ...state,
    //   attr: action.payload.attr,
    // }),
  },
  initialState
);

// Bundle things in a model
export const files = {
  actions,
  selector: {},
};
