import React, { useRef, ElementType, forwardRef } from 'react';
import { mergeProps, useButton, useFocusRing } from 'react-aria';
import { AriaButtonProps } from '@react-types/button';
import styled from 'styled-components';
import MaterialIconButton from '@mui/material/IconButton';

import { focusRing } from '~common/utils/styled.utils';

interface Props<T extends ElementType> extends AriaButtonProps<T> {
  className?: string;
}

interface AriaLabelProps<T extends ElementType> extends Props<T> {
  'aria-label': string;
}

interface AriaLabelledByProps<T extends ElementType> extends Props<T> {
  'aria-labelledby': string;
}

/**
 * As this button expects an icon as the child, you must provide either
 * `aria-label` or `aria-labelledby` for this component for a11y.
 *
 * When wrapping this in a `styled-component`, you should manually typecast
 * it afterwards, as otherwise the aria prop requirements always fail:
 *
 * @example
 * const RedIconButton = styled(IconButton)`
 *   color: red;
 * ` as typeof IconButton;
 */
export const IconButton = forwardRef<
  HTMLButtonElement,
  AriaLabelProps<'button'> | AriaLabelledByProps<'button'>
>((props, ref) => {
  const ownRef = useRef<HTMLButtonElement>(null);
  const domRef = (ref || ownRef) as React.RefObject<HTMLButtonElement>;
  const { buttonProps } = useButton(props, domRef);
  const { isFocusVisible, focusProps } = useFocusRing();

  const { onPress: _, ...mergedProps } = mergeProps(
    buttonProps as {},
    props,
    focusProps
  );

  const { className, children } = props;

  return (
    <Button
      {...mergedProps}
      focused={isFocusVisible}
      className={className}
      ref={domRef}
    >
      {children}
    </Button>
  );
});

IconButton.displayName = 'IconButton';

const Button = styled.button<{ focused: boolean }>`
  background-color: unset;
  border: unset;
  padding: unset;
  display: flex;

  cursor: pointer;

  ${p => focusRing(p.focused)};

  border-radius: 999px;

  transition: background-color ${p => p.theme.custom.transitions.duration} ease;

  &:hover,
  &:focus {
    background-color: ${p => p.theme.palette.custom.fadedTransparent};
  }

  &:active {
    background-color: ${p => p.theme.palette.custom.darkTransparent};
  }

  @media print {
    display: none !important;
  }
`;

const OldIconButton = styled(MaterialIconButton)`
  @media print {
    display: none !important;
  }
`;

export default OldIconButton;
