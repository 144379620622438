import React from 'react';
import styled from 'styled-components';
import { Use as UseType } from './types';
import { getLangValue } from '~common/app.utils';

interface Props {
  use: UseType;
  extended?: boolean;
}

const Use = ({ use, extended }: Props) => {
  const name = getLangValue(use.namesByLang);
  const description = getLangValue(use.descriptionsByLang);
  return (
    <div>
      <Name>{extended ? name && name.toUpperCase() : name}</Name>
      {extended && <Description>{description}</Description>}
    </div>
  );
};

const Name = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Description = styled.div`
  color: #989898;
`;

export default Use;
