import { t } from '@lingui/macro';
import {
  format,
  addDays,
  subDays,
  parseISO,
  differenceInCalendarDays,
  isAfter,
  isPast,
} from 'date-fns';
import {
  Page,
  Commenter,
  UserGroup,
  CommenterUser,
  CommentRequestStatus,
  CommentRequest,
} from './types';
import { isErroneousField } from '~common/utils/form.utils';
import { convertFileIn } from '~common/content.utils';

const countComments = (selectedUsers: string[], page: Page) => {
  return page.commenters
    .filter(commenter =>
      commenter.isGuest
        ? selectedUsers.includes(commenter.email ?? '')
        : selectedUsers.includes(commenter.userId)
    )
    .map(commenter => commenter.commentCount)
    .reduce((a, b) => {
      return a + b;
    }, 0);
};

const countAllComments = (
  selectedUsers: string[],
  pagesById: Record<string, Page>
) => {
  return Object.values(pagesById)
    .map(page => countComments(selectedUsers, page))
    .reduce((a, b) => {
      return a + b;
    }, 0);
};

const countCommentsForCommenter = (selectedUserId: string, page: Page) => {
  const selected = page.commenters.find((commenter: Commenter) =>
    commenter.isGuest
      ? selectedUserId === commenter.email
      : selectedUserId === commenter.userId
  );
  return selected ? selected.commentCount : 0;
};

const countAllCommentsForCommenter = (
  selectedUserId: string,
  pagesById: Record<string, Page>
) => {
  return Object.values(pagesById)
    .map(page => countCommentsForCommenter(selectedUserId, page))
    .reduce((a, b) => {
      return a + b;
    }, 0);
};

const countApproved = (selectedUsers: string[], page: Page) =>
  page.commenters
    .filter((commenter: Commenter) =>
      commenter.isGuest
        ? selectedUsers.includes(commenter.email ?? '')
        : selectedUsers.includes(commenter.userId)
    )
    .filter(
      (commenter: Commenter) => commenter.status === 'PAGE_STATUS_APPROVED'
    ).length;

const countDisapproved = (selectedUsers: string[], page: Page) =>
  page.commenters
    .filter((commenter: Commenter) =>
      commenter.isGuest
        ? selectedUsers.includes(commenter.email ?? '')
        : selectedUsers.includes(commenter.userId)
    )
    .filter(
      (commenter: Commenter) => commenter.status === 'PAGE_STATUS_DISAPPROVED'
    ).length;

const countApprovedForCommenter = (selectedUserId: string, page: Page) => {
  const selected = page.commenters.find((commenter: Commenter) =>
    commenter.isGuest
      ? selectedUserId === commenter.email
      : selectedUserId === commenter.userId
  );
  return selected && selected.status === 'PAGE_STATUS_APPROVED' ? 1 : 0;
};

const countDisapprovedForCommenter = (selectedUserId: string, page: Page) => {
  const selected = page.commenters.find((commenter: Commenter) =>
    commenter.isGuest
      ? selectedUserId === commenter.email
      : selectedUserId === commenter.userId
  );
  return selected && selected.status === 'PAGE_STATUS_DISAPPROVED' ? 1 : 0;
};

const countAllApprovedForCommenter = (
  selectedUserId: string,
  pagesById: Record<string, Page>
) => {
  return (
    Object.values(pagesById)
      .map(page => countApprovedForCommenter(selectedUserId, page))
      .reduce((a, b) => {
        return a + b;
      }, 0) / Object.values(pagesById).length
  );
};

const countAllDisapprovedForCommenter = (
  selectedUserId: string,
  pagesById: Record<string, Page>
) => {
  return (
    Object.values(pagesById)
      .map(page => countDisapprovedForCommenter(selectedUserId, page))
      .reduce((a, b) => {
        return a + b;
      }, 0) / Object.values(pagesById).length
  );
};

const commentingIsApproved = (
  userId: string | undefined,
  commentRequest: CommentRequest | null | undefined
): boolean => {
  if (!commentRequest || !userId) return false;

  const commentingApproved = commentRequest.pages
    ?.map(page =>
      page.commenters.find(commenter =>
        commenter.isGuest
          ? userId === commenter.email
          : userId === commenter.userId
      )
    )
    .some(
      commenter =>
        commenter?.status === 'PAGE_STATUS_DISAPPROVED' ||
        commenter?.status === 'PAGE_STATUS_APPROVED'
    );

  return commentingApproved || false;
};

const requestIsClosed = (expiration: string, status?: CommentRequestStatus) =>
  new Date(expiration).getTime() - Date.now() < 0 ||
  status === 'STATUS_COMMENT_REQUEST_CLOSED' ||
  status === 'STATUS_COMMENT_REQUEST_CLOSED_AND_COMMENT_RECEIVED';

const getCommentRequestErrors = (data, creatingWorkflow) => {
  const erroneousFields = {} as any;
  [
    {
      name: t`Comment request name`,
      id: 'title',
      isMandatory: creatingWorkflow,
      valueType: 'string',
      helperText: t`A name is required.`,
    },
    {
      name: t`Comment request description`,
      id: 'name',
      isMandatory: creatingWorkflow,
      valueType: 'string',
      helperText: t`A description is required.`,
    },
    {
      name: t`Reminder`,
      id: 'reminder',
      isMandatory: true,
      valueType: 'numeric',
      helperText: t`Invalid number of days.`,
    },
    ...(data.expirationMode === 'date'
      ? [
          {
            name: t`Expires on (date)`,
            id: 'expirationDate',
            isMandatory: true,
            valueType: 'date',
            helperText: t`Invalid date.`,
          },
          {
            name: t`Expires on (time)`,
            id: 'expirationTime',
            isMandatory: true,
            valueType: 'date',
            helperText: t`Invalid time.`,
          },
        ]
      : [
          {
            name: t`Expires in`,
            id: 'expirationDays',
            isMandatory: true,
            valueType: 'numeric',
            helperText: t`Invalid number of days.`,
          },
        ]),
  ].forEach(field => {
    const error = isErroneousField(field, data[field.id]);
    if (error) erroneousFields[field.id] = error;
  });
  return erroneousFields;
};

const getExpirationSelectorErrors = (
  errors,
  data,
  expirationFormatted,
  reminderFormatted
) => {
  const expiration =
    expirationFormatted ||
    parseISO(formatExpirationAndReminder(data).expiration);
  const reminder =
    reminderFormatted || parseISO(formatExpirationAndReminder(data).reminder);
  if (data.expirationMode === 'date') {
    if (differenceInCalendarDays(expiration, new Date()) < 0) {
      errors.expirationDate = {
        name: t`Expires on (date)`,
        id: 'expirationDate',
        helperText: t`Chosen date has already passed.`,
      };
    }
    if (isPast(expiration)) {
      errors.expirationTime = {
        name: t`Expires on (time)`,
        id: 'expirationTime',
        helperText: t`Chosen time has already passed.`,
      };
    }
  } else {
    if (isPast(expiration)) {
      errors.expirationDays = {
        name: t`Expires in`,
        id: 'expirationDays',
        helperText: t`Chosen date has already passed.`,
      };
    }
  }
  if (isPast(reminder)) {
    errors.reminder = {
      name: t`Reminder`,
      id: 'reminder',
      helperText: t`Chosen reminder time has already passed.`,
    };
  }
  if (isAfter(reminder, expiration)) {
    errors.reminder = {
      name: t`Reminder`,
      id: 'reminder',
      helperText: t`Chosen reminder time is after closing.`,
    };
  }
  return errors;
};

const validateCommentRequestFields = (
  data,
  expirationFormatted?: Date,
  reminderFormatted?: Date
) => {
  const errors = getCommentRequestErrors(
    data,
    expirationFormatted === undefined
  );
  // Change to more specific error texts if applicable
  if (
    !errors.reminder &&
    !errors.expirationDate &&
    !errors.expirationTime &&
    !errors.expirationDays
  ) {
    getExpirationSelectorErrors(
      errors,
      data,
      expirationFormatted,
      reminderFormatted
    );
  }
  return errors;
};

const formatExpirationAndReminder = data => {
  const {
    expirationMode,
    expirationDays,
    expirationDate,
    expirationTime,
    reminder,
  } = data;
  // Check that data is defined before trying to format
  if (
    (expirationMode === 'days' && !expirationDays) ||
    (expirationMode === 'date' && (!expirationDate || !expirationTime))
  ) {
    return { expiration: '', reminder: '' };
  }
  const expirationDateTime: Date =
    expirationMode === 'days' && expirationDays
      ? addDays(new Date(), expirationDays)
      : new Date(
          `${format(expirationDate, 'yyyy-MM-dd')}T${format(
            expirationTime,
            'HH:mm:ss'
          )}`
        );
  const reminderDateTime: Date = subDays(expirationDateTime, reminder);
  const expirationFormatted = format(
    expirationDateTime,
    "yyyy-MM-dd'T'HH:mm:00.000XX"
  );
  const reminderFormatted = format(
    reminderDateTime,
    "yyyy-MM-dd'T'HH:mm:00.000XX"
  );

  return { expiration: expirationFormatted, reminder: reminderFormatted };
};

const determineIfUserOrGroup = (
  value: CommenterUser | UserGroup
): value is UserGroup => {
  if ((value as UserGroup).isGroup) {
    return true;
  }
  return false;
};

const parseCommentersFromGroups = (
  commenterData: (CommenterUser | UserGroup)[],
  attribute: string
) => {
  const commentersWithoutGroups = commenterData
    .filter(commenter => !determineIfUserOrGroup(commenter))
    .filter(commenter => !commenter.isGuest);

  const questCommenters = commenterData
    .filter(commenter => !determineIfUserOrGroup(commenter))
    .filter(commenter => commenter.isGuest);

  const groupMembers = commenterData
    .filter((commenter: UserGroup) => commenter.isGroup)
    .flatMap((group: UserGroup) =>
      group.members.map((member: CommenterUser) => {
        return {
          ...member,
          isApprover: group.isApprover,
          isCommenter: group.isCommenter,
        };
      })
    );
  const commenters = [...commentersWithoutGroups, ...groupMembers];
  return {
    approverIds: Array.from(
      new Set(
        commenters
          .filter(commenter => commenter.isApprover)
          .map(commenter => commenter[attribute])
      )
    ),
    commenterIds: Array.from(
      new Set(
        commenters
          .filter(commenter => commenter.isCommenter)
          .map(commenter => commenter[attribute])
      )
    ),
    commenterEmails: Array.from(
      new Set(
        questCommenters
          .filter(commenter => commenter.isCommenter)
          .map(commenter => commenter[attribute])
      )
    ),
  };
};

const convertCommentRequestIn = (commentRequest: any): CommentRequest => {
  return {
    ...commentRequest,
    // Convert users in
    users: Object.keys(commentRequest.users || {}).reduce(
      (a, type) => ({
        ...a,
        [type]: commentRequest.users[type].map(convertCommenterIn),
      }),
      {}
    ),
    attachments:
      commentRequest.attachments &&
      Object.entries(commentRequest.attachments).reduce(
        (attachments, [id, file]) => ({
          ...attachments,
          [id]: convertFileIn(file),
        }),
        {}
      ),
  };
};

const convertCommenterIn = <T extends CommenterUser>(user: T) => ({
  ...user,
  fullName: user.isGuest
    ? user.email
    : `${user.firstName || ''} ${user.familyName || ''}`,
});

export {
  countComments,
  countAllComments,
  countAllCommentsForCommenter,
  countApproved,
  countDisapproved,
  countAllApprovedForCommenter,
  countAllDisapprovedForCommenter,
  commentingIsApproved,
  requestIsClosed,
  validateCommentRequestFields,
  formatExpirationAndReminder,
  determineIfUserOrGroup,
  parseCommentersFromGroups,
  convertCommentRequestIn,
  convertCommenterIn,
};
