import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';

import CategoryTree from '../CategoryTree';
import FolderSearchTree from '../FolderSearchTree';
import { search } from './model';
import { Typography } from '~common/misc/Typography';
import Button from '~inputs/Button';
import { CloseIcon } from '~common/misc/icons';
import { useActions } from '~common/utils/hooks.utils';
import { FolderTreeEntry, TreeMetaField } from '~common/content.types';

interface Props {
  onSearch: () => void;
  criteriaId: string;
  title: string;
  treeData: FolderTreeEntry | TreeMetaField;
  useTags?: boolean;
}

export const SearchTreeInput = ({
  onSearch,
  criteriaId,
  title,
  treeData,
  useTags,
}: Props) => {
  const criteria = useSelector(state => state.search.criteria);
  const tags = useSelector(state => state.search.tags);
  const { updateCriteria, updateTags } = useActions(search.actions);

  const setSelectedTreeNodes = (nodes: string[]) => {
    if (useTags) {
      const newTags = [
        ...tags.filter(tag => tag.id !== criteriaId),
        ...nodes.map(node => ({ id: criteriaId, value: node })),
      ];
      updateTags(newTags);
      updateCriteria({ tags: newTags });
    } else {
      updateCriteria({
        ...criteria,
        [criteriaId]: nodes,
      });
    }
    onSearch();
  };

  const selectedNodes = useTags
    ? tags.filter(tag => tag.id === criteriaId).map(tag => tag.value)
    : criteria[criteriaId];

  const treeNodeCount = selectedNodes?.length ?? 0;

  const categoryTree = 'nodesById' in treeData;

  return (
    <div>
      <TreeHeader>
        <Typography variant="h2">{title}</Typography>
        {treeNodeCount > 0 && (
          <ClearButton
            startIcon={<CloseIcon />}
            size={'small'}
            variant="text"
            onClick={() => setSelectedTreeNodes([])}
          >
            <Trans>Clear</Trans>
          </ClearButton>
        )}
      </TreeHeader>
      {categoryTree ? (
        <CategoryTree
          data={treeData}
          selectedNodes={selectedNodes ?? []}
          setSelectedNodes={setSelectedTreeNodes}
        />
      ) : (
        <FolderSearchTree
          data={treeData}
          selectedNodes={selectedNodes ?? []}
          setSelectedNodes={setSelectedTreeNodes}
        />
      )}
    </div>
  );
};

const TreeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
`;

const ClearButton = styled(Button)`
  padding: 4px !important;
  font-size: 0.9rem !important;
  text-transform: none !important;
  font-weight: initial;

  .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
  }
`;
