import React from 'react';
import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';

import { CommonNewsListItem } from './types';
import { useRemoveNewsMutation } from './api';
import MenuButton from '~navigation/MenuButton';
import { DeleteIcon, ActionIcon } from '~misc/icons';
import { useActions } from '~common/utils/hooks.utils';
import { app } from '~common/app.model';

interface Props {
  selectedItems?: string[];
  news?: CommonNewsListItem[];
}

const NewsActionsButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ selectedItems }: Props, ref) => {
    const userRights = useSelector(state => state.app.userRights);
    const [removeNews] = useRemoveNewsMutation();

    const setOpenModal = useActions(app.actions.setOpenModal);

    const handleRemove = () => {
      setOpenModal('COMMON/CONFIRM_MODAL', {
        title: t`Remove News`,
        confirmText: t`Are you sure you want to remove the news?`,
        onConfirm: async () => {
          try {
            await removeNews(selectedItems ?? []).unwrap();
          } catch (e) {
            // failed
          }
        },
      });
    };

    const options = [
      {
        value: 'remove',
        title: t`Remove`,
        icon: <DeleteIcon fontSize="small" />,
        hidden: !userRights?.NEWS_REMOVE,
        onSelect: handleRemove.bind(this, false),
      },
    ];
    return (
      <MenuButton
        color="secondary"
        variant="contained"
        options={options}
        ref={ref}
      >
        <ActionIcon />
      </MenuButton>
    );
  }
);

export default NewsActionsButton;
