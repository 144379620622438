import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

interface Props {
  to: string;
  color: string;
  children: any;
  /** Link to be used on the public spaces */
  publicLink?: string;
  targetBlank: boolean;
}

const LogoLink = ({ publicLink, targetBlank, ...props }: Props) => (
  <Logo>
    {!publicLink ? (
      <StyledLink {...props}>{props.children}</StyledLink>
    ) : (
      // Use anchor tag so that we can exit the public space
      <StyledALink
        href={publicLink}
        target={targetBlank ? '_blank' : undefined}
      >
        {props.children}
      </StyledALink>
    )}
  </Logo>
);

const Logo = styled.div`
  flex-grow: 1;
  margin-top: 1px;
`;

const LinkStyles = css`
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  color: white;
`;

const StyledLink = styled(Link)`
  ${LinkStyles}
`;

const StyledALink = styled.a`
  ${LinkStyles}
`;

export default LogoLink;
