import {
  all,
  put,
  take,
  takeEvery,
  takeLatest,
  fork,
} from 'redux-saga/effects';
import { channel } from 'redux-saga';
import { format } from 'date-fns';
import { t } from '@lingui/macro';

import { invalidateOrder, invalidateOrders } from '../api';
import { OrderWorkflowData } from './types';
import * as api from './api';
import { orderProcessSteps, defaultOrderAmount } from './constants';
import { orders } from './model';
import { app } from '~common/app.model';
import { history } from '~common/config';
import { select, call, putResolve } from '~utils/saga.utils';
import {
  getInitialDataByWorkflowId,
  skipContentsView,
} from '~common/workflows/utils';
import { WorkflowProcessStep } from '~common/workflows/constants';
import { updateBrowseCriteria } from '~common/content.utils';
import { UploadFile } from '~common/content.types';
import { getWorkflow } from '~common/workflows/api';

const { actions } = orders;
const { actions: appActions } = app;

// Browse

function* updateCriteria(action: ReturnType<typeof actions.updateCriteria>) {
  const { tags: _, ...criteria } = action.payload.criteria;
  updateBrowseCriteria(
    '/orders',
    action.payload.enterMode ?? null,
    action.payload.historyUpdateMode ?? '',
    criteria
  );
}

function* watchUpdateCriteria() {
  yield takeEvery(actions.updateCriteria.type, updateCriteria);
}

// Workflow

function* readCategories() {
  const categories = yield* call(api.readCategories);
  yield put(
    actions.afterReadCategories(
      categories.reduce(
        (a, category) => ({ ...a, [category.id]: category }),
        {}
      )
    )
  );
}

function* watchReadCategories() {
  yield takeLatest(actions.readCategories.type, readCategories);
}

const help = channel();

function* watchDownloadFileChannel() {
  while (true) {
    const action = yield take(help);
    yield put(action);
  }
}

function* startOrder(action: ReturnType<typeof appActions.startOrder>) {
  if (!action.payload.fromShoppingCart) {
    yield put(actions.addToOrder([...action.payload.productIds]));
    // reset the order progress
    yield put(actions.resetOrder());
  } else {
    yield put(actions.startShoppingCartOrder([...action.payload.productIds]));
  }
}

function* watchStartOrder() {
  yield takeEvery('APP/START_ORDER', startOrder);
}

// Order Progress
function* startOrderProcess() {
  const ordersByFile = yield* select(state => state.orders.ordersByFile);
  const userLanguage = yield* select(
    state => state.app.settings?.language ?? state.app.customer?.defaultLanguage
  );

  const dataByWorkflowId = yield* getInitialDataByWorkflowId<
    OrderWorkflowData & { title: string }
  >(ordersByFile, userLanguage ?? '');
  if (!dataByWorkflowId) return;

  yield put(actions.initWorkflowData(dataByWorkflowId));
  yield* call(advanceOrderProcess);
}

function* advanceOrderProcess() {
  let process = yield* select(state => state.orders.orderProcess);
  let sentWorkflowId: string | null | undefined = null;
  if (
    process.step !== null &&
    orderProcessSteps.indexOf(process.step) < orderProcessSteps.length - 3
  ) {
    process = {
      ...process,
      step: orderProcessSteps[orderProcessSteps.indexOf(process.step) + 1],
    };
  } else {
    const ordersByFile = yield* select(state => state.orders.ordersByFile);
    const workflowIds = [
      ...new Set(Object.values(ordersByFile).map(x => (x as any).workflowId)),
    ].filter(x => !!x && x !== '0' && x !== process.workflowId);
    if (process.step === WorkflowProcessStep.SENT) {
      sentWorkflowId = process.workflowId;
    }
    if (workflowIds.length > 0) {
      const { data: currWf } = yield* putResolve(
        getWorkflow.initiate({ id: workflowIds[0] })
      );
      const skipContents = currWf && skipContentsView(currWf, process);
      process = {
        workflowId: workflowIds[0],
        step: skipContents
          ? WorkflowProcessStep.DETAILS
          : WorkflowProcessStep.CONTENTS,
      };

      // Set default amounts if those are not asked
      if (!currWf?.askProductAmount) {
        Object.keys(ordersByFile)
          .filter(x => ordersByFile[x].workflowId === process.workflowId)
          .forEach(x =>
            help.put(
              actions.setOrderLineData(x, { amount: defaultOrderAmount })
            )
          );
      }
    } else {
      // No more workflows to go through
      return history.goBack();
    }
  }
  yield put(actions.updateOrderProcess(process));
  if (sentWorkflowId) {
    yield put(actions.removeWorkflowData(sentWorkflowId));
  }
}

function* retreatOrderProcess() {
  let process = yield* select(state => state.orders.orderProcess);
  if (!process.workflowId || process.step === null) return;
  const { data: currentWorkflow } = yield* putResolve(
    getWorkflow.initiate({ id: process.workflowId })
  );
  const skipContents =
    currentWorkflow && skipContentsView(currentWorkflow, process);
  // clear order progress if we are at the first step or we would be coming to the first step which should be skipped
  if (
    orderProcessSteps.indexOf(process.step) > 0 &&
    !(
      skipContents &&
      orderProcessSteps[orderProcessSteps.indexOf(process.step) - 1] ===
        WorkflowProcessStep.CONTENTS
    )
  ) {
    process = {
      ...process,
      step: orderProcessSteps[orderProcessSteps.indexOf(process.step) - 1],
    };
  } else {
    process = {
      workflowId: null,
      step: null,
    };
  }
  yield put(actions.updateOrderProcess(process));
}

function* watchOrderProcess() {
  yield takeEvery(actions.startOrderProcess.type, startOrderProcess);
  yield takeEvery(actions.advanceOrderProcess.type, advanceOrderProcess);
  yield takeEvery(actions.retreatOrderProcess.type, retreatOrderProcess);
}

function* sendOrder() {
  try {
    const workflowId = yield* select(
      state => state.orders.orderProcess.workflowId
    );
    if (!workflowId) return;
    const dataByWorkflowId = yield* select(
      state => state.orders.dataByWorkflowId
    );
    const ordersByFile = yield* select(state => state.orders.ordersByFile);
    const workflowData = dataByWorkflowId[workflowId];
    yield* call(
      api.sendOrder,
      {
        workflowId,
        name: workflowData.name,
        orderLinesByNodeId: Object.keys(ordersByFile)
          .filter(x => ordersByFile[x].workflowId === workflowId)
          .reduce((a, fileId) => {
            a[fileId] = {
              amount: parseInt(
                ordersByFile[fileId].amount?.toLocaleString() ?? '1',
                10
              ),
              variants: ordersByFile[fileId].variants,
              categoryId: ordersByFile[fileId].categoryId,
            };
            return a;
          }, {}),
        fieldValues: Object.keys(workflowData)
          .filter(x => parseInt(x))
          .reduce((a, c) => {
            a[c] =
              workflowData[c] instanceof Date
                ? format(workflowData[c], 'dd-MM-yyyy')
                : workflowData[c] || '';
            return a;
          }, {}),
        recipients: workflowData.recipients,
        emailRecipients: workflowData.emailRecipients,
        messageReply: workflowData.messageReply,
        fyiRecipients: workflowData.fyiRecipients,
        language: workflowData.language,
        approvers: workflowData.approvers,
        attachmentUuids: workflowData.attachments
          ?.filter(x => 'node' in x)
          .map(x => ('node' in x ? x.node.id : '')),
        originalOrderId: workflowData?.originalOrderId,
      },
      workflowData.attachments?.filter(x => 'blob' in x) as UploadFile[]
    );
    yield put(invalidateOrders());
    yield put(actions.afterSendOrder(''));
  } catch (error) {
    yield put({
      type: 'APP/SET_OPEN_SNACKBAR',
      payload: {
        openSnackbarId: 'ORDERS/SEND_FAIL',
        openSnackbarProps: {
          type: 'error',
          message: t`Sending order failed`,
        },
      },
    });
    const orderProcess = yield* select(state => state.orders.orderProcess);
    yield put({
      type: 'ORDERS/UPDATE_ORDER_PROCESS',
      payload: {
        process: { ...orderProcess, step: WorkflowProcessStep.SUMMARY },
      },
    });
    console.log(error);
  }
}

function* watchSendOrder() {
  yield takeEvery(actions.sendOrder.type, sendOrder);
}

function* reviewOrder(action: ReturnType<typeof actions.reviewOrder>) {
  const { approve, orderId } = action.payload;
  try {
    yield* call(api.reviewOrder, action.payload);
    yield put({
      type: 'APP/SET_OPEN_SNACKBAR',
      payload: {
        openSnackbarId: 'ORDERS/REVIEW_ORDER',
        openSnackbarProps: {
          type: 'success',
          message: approve ? t`Order approved` : t`Order rejected`,
        },
      },
    });
    yield put(invalidateOrder(orderId));
  } catch (error) {
    yield put({
      type: 'APP/SET_OPEN_SNACKBAR',
      payload: {
        openSnackbarId: 'ORDERS/REVIEW_ORDER',
        openSnackbarProps: {
          type: 'error',
          message: t`Order review failed`,
        },
      },
    });
  }
}

function* watchReviewOrder() {
  yield takeEvery(actions.reviewOrder.type, reviewOrder);
}

export default function* sagas(): any {
  yield all([
    // Browse
    fork(watchUpdateCriteria),
    // Workflow
    fork(watchReadCategories),
    fork(watchStartOrder),
    fork(watchDownloadFileChannel),
    fork(watchOrderProcess),
    fork(watchSendOrder),
    fork(watchReviewOrder),
  ]);
}
