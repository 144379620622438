import React from 'react';
import TreeItem from '@mui/lab/TreeItem';
import styled from 'styled-components';
import { t } from '@lingui/macro';

import { DefaultTheme } from '@mui/styles';
import { BaseFile, FolderTreeEntry } from '~common/content.types';
import {
  CheckIcon,
  CollapsedFolderIcon,
  CollapsedFolderIconOutlined,
  ExpandedFolderIcon,
  ExpandedFolderIconOutlined,
} from '~common/misc/icons';
import { getLangValue } from '~common/app.utils';
import { TreeNode } from '~common/content.utils';

interface CategoryTreeItemProps {
  node: FolderTreeEntry | TreeNode;
  parents: FolderTreeEntry[] | TreeNode[];
  setExpanded: React.Dispatch<React.SetStateAction<Set<string>>>;
  language: string;
  selectedNodes: string[];
  multiple: boolean;
  setSelectedNodes: (nodes: string[]) => void;
  disabledNodes?: string[];
  theme: DefaultTheme;
  readOnly?: boolean;
  baseFile: BaseFile | TreeNode;
  selectedChildren: FolderTreeEntry[] | TreeNode[];
  isExpanded: boolean;
}

export const CategoryTreeItem: React.FC<
  React.PropsWithChildren<CategoryTreeItemProps>
> = ({
  node,
  parents = [],
  setExpanded,
  language,
  selectedNodes,
  multiple,
  setSelectedNodes,
  disabledNodes,
  theme,
  readOnly,
  baseFile,
  selectedChildren,
  isExpanded,
  children,
}) => {
  const selected = selectedNodes.includes(baseFile.id);
  const isLeaf = node.children.length === 0;

  const isChildSelected = selectedChildren.length > 0;

  let icon: JSX.Element | null =
    !isLeaf && isExpanded ? (
      <ExpandedFolderIconOutlined />
    ) : !isLeaf ? (
      <CollapsedFolderIconOutlined />
    ) : null;
  if (!isLeaf && (selected || isChildSelected)) {
    icon = isExpanded ? <ExpandedFolderIcon /> : <CollapsedFolderIcon />;
  }

  const select = (target: boolean) => {
    if (
      readOnly ||
      disabledNodes?.includes(baseFile.id) ||
      parents.some(parent => disabledNodes?.includes(baseFile.id))
    )
      return;
    if (!multiple) {
      setSelectedNodes(target ? [baseFile.id] : []);
      return;
    }
    let newSet = [...selectedNodes];
    if (target) {
      newSet.push(baseFile.id);
    } else {
      newSet = newSet.filter(selectedId => selectedId !== baseFile.id);
    }
    setSelectedNodes(newSet);
  };

  const toggleExpanded = (target: boolean) => {
    if (isLeaf) {
      return;
    }

    setExpanded(expanded => {
      const set = new Set(expanded);
      if (!target) {
        set.delete(baseFile.id);
      } else {
        set.add(baseFile.id);
      }
      return set;
    });
  };

  const onClick = () => {
    select(!selected);
  };

  return (
    <StyledTreeItem
      key={baseFile.id}
      className={selected ? 'Mui-selected-item' : undefined}
      nodeId={baseFile.id}
      onClick={() => {
        toggleExpanded(!isExpanded);
      }}
      aria-selected={selected}
      icon={icon}
      iconColor={icon ? theme.palette.primary.main : undefined}
      onKeyDown={(e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
          e.stopPropagation();
          onClick();
        }
      }}
      disabled={disabledNodes?.includes(baseFile.id)}
      label={
        <NodeLabelContainer
          onClick={e => {
            e.stopPropagation();
            onClick();
          }}
          selected={selected}
        >
          {getLangValue(baseFile.namesByLang, language) ?? (
            <MissingNameLabel>{t`New category`}</MissingNameLabel>
          )}
          {multiple && selectedChildren.length > 0
            ? ` (${selectedChildren.length})`
            : ''}
          <NodeCheckedIcon />
        </NodeLabelContainer>
      }
    >
      {children}
    </StyledTreeItem>
  );
};

const StyledTreeItem = styled(({ iconColor, ...rest }) => (
  <TreeItem {...rest} />
))<{ iconColor?: string }>`
  .MuiTreeItem-iconContainer {
    ${props => props.iconColor && `color: ${props.iconColor};`}
  }
`;

const NodeLabelContainer = styled.div<{ selected?: boolean }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  ${props =>
    props.selected
      ? ''
      : `
  :hover {
    --icon-opacity: 0.2;
  }`}

  --icon-opacity: ${props => (props.selected ? 1 : 0)};
`;

const NodeCheckedIcon = styled(CheckIcon)`
  width: 16px !important;
  height: 16px !important;
  margin-right: ${props => props.theme.spacing(1)};
  opacity: var(--icon-opacity);
`;

const MissingNameLabel = styled.span`
  font-style: italic;
`;

export default CategoryTreeItem;
