import { FilesById } from '~common/content.types';
import { filterUndefined } from '~common/utils/fn.utils';

/** Filters a list of file IDs into a list where no ID is a folder */
export function getNonFolderIds(ids: string[], filesById: FilesById) {
  return ids
    .map(id => filesById[id]?.file)
    .filter(filterUndefined)
    .filter(item => !item.isFolder)
    .map(item => item.node.id);
}
