import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { t } from '@lingui/macro';
import { Item } from 'react-stately';

import Thumbnail from './Thumbnail';

import Box from '~layout/Box';
import Row from '~layout/Row';
import TextField from '~inputs/TextField';
import ProgressBar from '~sections/ProgressBar';
import { getFileName } from '~common/content.utils';
import { DragSource, File } from '~common/content.types';
import { getLangValue } from '~common/app.utils';
import { GridList } from '~common/layout/GridList';

interface Props {
  // Select file from a list of files
  files: Array<File>;
  fileCount: number;
  selectedFileId?: string;
  onSelectFile: (file: any, index: number) => void;
  showFilter: boolean;
  showCheckboxes?: boolean;
  centered: boolean;
  horizontal?: boolean;
  checkedContent?: Set<string>;
  setCheckedContent?: (items: Set<string>) => void;
  thumbnailType?: string;
  language: string;
  defaultLanguage: string;
  dragSource?: DragSource;
  contentDescription: string;
}

const FileSelector = ({
  files,
  selectedFileId,
  onSelectFile,
  fileCount,
  showFilter,
  showCheckboxes,
  centered,
  horizontal,
  checkedContent,
  setCheckedContent,
  thumbnailType,
  language,
  defaultLanguage,
  dragSource,
  contentDescription,
}: Props) => {
  const [filter, setFilter] = useState('');

  const items = files
    ? files
        .filter(x =>
          getFileName(x, language, defaultLanguage)
            .toLowerCase()
            .includes(filter.toLowerCase())
        )
        .map((item, i) => ({
          ...item,
          index: i,
          key: item.node.id,
          selectedItem: undefined,
        }))
    : [];

  const wrapperRef = useRef<HTMLUListElement>(null);

  // Show progress bar until contents are available on state
  if ((!files || !files.length) && fileCount > 0) return <ProgressBar />;

  return (
    <>
      {showFilter && (
        <Box>
          <Row>
            <TextField
              id={`${t`Filter`}-FileSelector`}
              labelText={t`Filter`}
              fullWidth={centered}
              value={filter}
              onChange={e => setFilter(e.target.value)}
            />
          </Row>
        </Box>
      )}
      <ThumbnailList
        selectionMode={setCheckedContent ? 'multiple' : 'none'}
        selectionBehavior="toggle"
        items={items}
        wrapperRef={wrapperRef}
        selectedKeys={checkedContent}
        onSelectionChange={setCheckedContent}
        onAction={key => {
          const item = items.find(x => x.key === key);
          if (!item) return;
          onSelectFile(item, item.index);
        }}
        nestedFocusScope
        horizontal={horizontal}
        aria-label={contentDescription}
      >
        {file => (
          <Item textValue={getLangValue(file.namesByLang)}>
            <Thumbnail
              id={`file-selector-item-${file.node.id}`}
              key={file.node.id}
              item={file}
              itemId={file.node.id}
              showCheckbox={showCheckboxes}
              showActions={false}
              selected={selectedFileId === file.node.id}
              onSelect={() => onSelectFile(file, file.index)}
              onUnselect={() => onSelectFile(file, file.index)}
              prevItem={null}
              index={null}
              thumbnailType={thumbnailType}
              dragSource={dragSource}
            />
          </Item>
        )}
      </ThumbnailList>
    </>
  );
};

const ThumbnailList = styled(GridList)<{ $horizontal?: boolean }>`
  && {
    --spacing: ${p => p.theme.spacing(1)};
    --min-column-width: 100%;

    width: 100%;

    margin-top: 0;
    padding: var(--spacing);
    padding-bottom: 0;
  }
` as typeof GridList;

export default FileSelector;
