import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  isImageProductElement,
  isTextProductElement,
  ProductElement,
} from '../common/types';
import { useSelectedElement } from '../common/utils';
import { products } from '../common/model';
import Picture from './Picture';
import Text from './Text';

import { useActions } from '~common/utils/hooks.utils';

export function showElementSwitcher(element?: ProductElement) {
  return (
    element &&
    (element.element.type === 'IMAGE' ||
      (element.element.type.indexOf('TEXT') !== -1 &&
        !element.masterElement.lock.textSelect))
  );
}

interface Props {
  mobileLayout?: boolean;
}

export function ElementSwitcher({ mobileLayout }: Props) {
  const rootFolderId = useSelector(
    state => state.app.settings?.contentFolderId
  );
  const product = useSelector(state => state.products.product);

  const updateElement = useActions(products.actions.updateElement);
  const readElementAvailableOptions = useActions(
    products.actions.readElementAvailableOptions
  );
  const selectElement = useActions(products.actions.selectElement);

  const element = useSelectedElement();

  if (!product || !showElementSwitcher(element)) return null;

  return (
    <Wrapper>
      {isImageProductElement(element) && (
        <Picture
          element={element}
          product={product}
          updateElement={updateElement}
          selectElement={selectElement}
          readElementOptions={readElementAvailableOptions}
          productNodeId={product.nodeId}
          rootFolderId={rootFolderId}
          mobileLayout={mobileLayout}
        />
      )}
      {isTextProductElement(element) && (
        <Text
          element={element}
          updateElement={updateElement}
          readElementOptions={readElementAvailableOptions}
          mobileLayout={mobileLayout}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  /** Width of the items on result lists */
  ul[role='grid'] {
    --min-column-width: 9rem;
  }
`;
