import React from 'react';
import styled from 'styled-components';

const DataTableWrapper = ({ children }) => {
  return <TableWrapper>{children}</TableWrapper>;
};

export default DataTableWrapper;

const TableWrapper = styled.section`
  background-color: ${p => p.theme.palette.common.white};
  border-radius: ${p => p.theme.dimensions.border.radius};
  border: ${p => p.theme.dimensions.border.width} solid
    ${p => p.theme.palette.grey[300]};

  padding: ${p => p.theme.spacing(2)};
  padding-top: ${p => p.theme.spacing(0.5)};

  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(2)};
`;
