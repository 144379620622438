import React from 'react';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro';

import {
  useAddToShoppingCart,
  useShoppingCart,
} from '../workspaces/shopping/hooks';

import { useSelection } from '~common/utils/hooks.utils';
import { BasicOption } from '~common/navigation/MenuButton';
import { AllowLevel, allowLevel } from '~common/content.utils';
import { ShoppingCartIcon } from '~common/misc/icons';

export function useAddToShoppingCartOption(fileIds: string[]): BasicOption {
  const filesById = useSelector(state => state.commonContent.filesById);
  const userRights = useSelector(state => state.app.userRights);

  const { addToShoppingCart } = useAddToShoppingCart();
  const { data: cartData } = useShoppingCart();
  const cart = !cartData?.removed ? cartData : undefined;

  const addToWorkspaceAllowed = allowLevel(
    'addToWorkspace',
    fileIds,
    filesById
  );

  const { selection } = useSelection(fileIds, files => ({
    hasMasterProducts: files.some(file => file?.isMasterProduct),
  }));

  return {
    value: 'addToShopping',
    title: t`Add to shopping cart`,
    icon: <ShoppingCartIcon fontSize="small" />,
    disabled:
      selection.hasFolders ||
      selection.hasMasterProducts ||
      addToWorkspaceAllowed === AllowLevel.ForSome,
    hidden:
      !(userRights?.ORDERS_MAKE || userRights?.MAGENTO_ORDER) ||
      !cart ||
      !cart.userRights ||
      !cart.userRights.addFiles ||
      addToWorkspaceAllowed === AllowLevel.ForNone,
    onSelect: () => {
      addToShoppingCart(fileIds);
    },
  };
}
