import React from 'react';
import { useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { t } from '@lingui/macro';

import { itemCanBeRemovedFromWorkspace } from '../common/utils';
import { useRemoveFromWorkspace } from '../workspaces/hooks';

import { useGetFolderQuery } from '~common/content.api';
import { useSelection } from '~common/utils/hooks.utils';
import { DeleteIcon } from '~common/misc/icons';

interface Options {
  fromShoppingCart?: boolean;
}

export function useRemoveFromWorkspaceOption(
  workspaceId: string | undefined,
  fileIds: string[],
  options?: Options
) {
  const { removeFromWorkspace } = useRemoveFromWorkspace();
  const password = useSelector(state => state.commonContent.password);
  const { data: workspace } = useGetFolderQuery(
    workspaceId ? { id: workspaceId, password } : skipToken
  );

  const { selection } = useSelection(fileIds, items => ({
    hasRemovableItems: items.some(item => itemCanBeRemovedFromWorkspace(item)),
    hasUnremovableItems: items.some(
      item => !itemCanBeRemovedFromWorkspace(item)
    ),
  }));

  return {
    value: 'delete',
    hidden: workspace?.removed || !workspace?.userRights?.deleteFiles,
    disabled: !selection.hasRemovableItems,
    title: options?.fromShoppingCart
      ? t`Delete from shopping cart`
      : t`Delete from workspace`,
    icon: <DeleteIcon fontSize="small" />,
    onSelect: () => removeFromWorkspace(fileIds),
  };
}
