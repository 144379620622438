import hash from 'object-hash';

import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  createActions,
  handleActions,
  overwriteWithPayload,
} from './utils/ducks.utils';
import { Criteria, EnterMode } from './common.types';
import { WorkflowSettings } from './workflows/types';
import {
  File,
  FilesById,
  FolderContainer,
  FoldersById,
  MetaField,
  RemovedFile,
  RemovedItemsById,
  WorkflowSettingContainer,
  WorkflowSettingsById,
} from './content.types';
import { mergeFileIntoState, useUrlParams } from './content.utils';
import { isMobile } from './utils/styled.utils';
import { useThrottledWindowSize } from './utils/layout.utils';
import { useActions } from './utils/hooks.utils';
import { defaultCriteria } from '~common/content.constants';

export const criteriaHash = (id: string | null | undefined, criteria?: any) => {
  // selectedIndex/Id is ignored in criteria hash, as it doesn't affect results
  const { selectedIndex, selectedId, ...rest } = criteria || defaultCriteria;
  Object.keys(rest).forEach(key => rest[key] === undefined && delete rest[key]);
  return hash.sha1({ id: id || null, ...rest });
};

export interface ContentState {
  contentsByCriteriaHash: Record<
    string,
    {
      status: null | string;
      timestamp: Date;
      id: string;
      criteria: Criteria;
      items: File[];
      totalCount: string;
      error?: any;
    }
  >;
  /** User's selected view mode.
   * undefined means that no mode is selected and default should be used.
   *
   * NOTE: don't use this directly, prefer useContentViewMode hook */
  viewMode?: 'grid' | 'list';
  foldersById: FoldersById;
  /** Holds references to the concrete nodes i.e. an id of a linked file points to the concrete file  */
  filesById: FilesById;
  /** Holds refereces to the nodes that can be either linked or concrete i.e. an id of a linked file points to the link.
   * If the node is concrete, it essentially contains the same data as if it were included in filesById,
   * but if the node is a linked file, it contains properties of the link instead. */
  itemsById: FilesById;
  removedItemsById: RemovedItemsById;
  contentById: Record<string, any>;
  workflowsById: WorkflowSettingsById;
  /** When sharing content, we might need a password to interact with it */
  password?: string;
  customerMetaFields: MetaField[];
}

type ReadFolderParams = {
  /** include inheritedMetaById */
  inheritmeta?: boolean;
  /** include inheritCustomUploadLayout */
  inheritlayout?: boolean;
};

// TODO: typing
const initialState: ContentState = {
  contentsByCriteriaHash: {},
  viewMode: undefined,
  foldersById: {}, // TODO: remove this? always use filesById?
  filesById: {},
  itemsById: {},
  removedItemsById: {},
  contentById: {},
  workflowsById: {},
  customerMetaFields: [],
};

const actions = createActions('CONTENT', {
  readFile: (id: string, password?: string) => ({ id, password }),
  afterReadFile: (
    id: string,
    file: File | RemovedFile,
    overwriteStateMeta?: boolean
  ) => ({
    id,
    file,
    overwriteStateMeta,
  }),
  readFileContent: (id: string) => ({ id }),
  readMultipleFiles: (ids: string[], include?: string) => ({ ids, include }),
  afterReadFileContent: (id: string, content: any) => ({ id, content }),
  readFiles: (ids: string[], fetchContents?: boolean, include?: string) => ({
    ids,
    fetchContents,
    include,
  }),
  afterReadFiles: (
    filesById: Record<string, File | RemovedFile>,
    overwriteStateMeta?: boolean
  ) => ({
    filesById,
    overwriteStateMeta,
  }),
  readFolder: (id: string, include?: string, params?: ReadFolderParams) => ({
    id,
    include,
    params,
  }),
  afterReadFolder: (id: string, folder: File) => ({ id, folder }),
  showFileInFolder: (
    folderId: string | undefined,
    criteria: any,
    fileId: string,
    historyUpdateMode: string,
    enterMode: EnterMode
  ) => ({
    folderId,
    criteria,
    fileId,
    historyUpdateMode,
    enterMode,
  }),
  readContent: (id: string | undefined, criteria: any, include?: string) => ({
    id,
    criteria,
    include,
  }),
  fetchLatest: (limit: number) => ({ limit }),
  afterFetchLatest: (latestIds: string[]) => ({ latestIds }),
  afterReadContent: (
    id: string | undefined,
    criteria: any,
    items: File[],
    totalCount: number
  ) => ({ id, criteria, items, totalCount }),
  afterReadContentError: (
    id: string | undefined,
    criteria: any,
    error: any
  ) => ({
    id,
    criteria,
    error,
  }),
  fetchWorkflow: (id: string) => ({ id }),
  afterWorkflowFetch: (id: string, workflow: WorkflowSettings) => ({
    id,
    workflow,
  }),
  setViewMode: (viewMode: ContentState['viewMode']) => ({ viewMode }),
  setPassword: (password?: string) => ({ password }),
  afterReadCustomerMetaFields: (customerMetaFields: MetaField[]) => ({
    customerMetaFields,
  }),
});

export default handleActions(initialState)
  .handle(actions.readFile, (state, action) => {
    const file = state.filesById[action.payload.id];
    return {
      ...state,
      filesById: {
        ...state.filesById,
        [action.payload.id]: file
          ? {
              ...file,
              status: 'loading',
            }
          : undefined,
      },
    };
  })
  .handle(actions.readFiles, (state, action) => {
    const changes: FilesById = {};
    action.payload.ids.forEach(id => {
      const file = state.filesById[id];
      if (!file) {
        return;
      }

      changes[id] = {
        ...file,
        status: 'loading',
      };
    });
    return {
      ...state,
      filesById: {
        ...state.filesById,
        ...changes,
      } as FilesById,
    } as ContentState;
  })
  .handle(actions.readMultipleFiles, (state, action) => ({
    ...state,
    filesById: {
      ...state.filesById,
      ...action.payload.ids.reduce(
        (obj, id) => ({
          ...obj,
          [id]: {
            ...state.filesById[id],
            status: 'loading',
          },
        }),
        {}
      ),
    },
  }))
  .handle(actions.afterReadFile, (state, action) => {
    const common = {
      status: null,
      timestamp: new Date(),
    };
    return mergeFileIntoState(
      action.payload.file,
      state,
      common,
      action.payload.overwriteStateMeta
    );
  })
  .handle(actions.afterReadFiles, (state, action) => {
    const common = {
      status: null,
      timestamp: new Date(),
    };
    return Object.values(action.payload.filesById).reduce(
      (acc, file) =>
        mergeFileIntoState(
          file,
          acc,
          common,
          action.payload.overwriteStateMeta
        ),
      state
    );
  })
  .handle(actions.readFileContent, (state, action) => ({
    ...state,
    contentById: {
      ...state.contentById,
      [action.payload.id]: {
        ...state.contentById[action.payload.id],
        status: 'loading',
      },
    },
  }))
  .handle(actions.afterReadFileContent, (state, action) => ({
    ...state,
    contentById: {
      ...state.contentById,
      [action.payload.id]: {
        status: null,
        timestamp: new Date(),
        content: action.payload.content,
      },
    },
  }))
  .handle(actions.readFolder, (state, action) => ({
    ...state,
    foldersById: {
      ...state.foldersById,
      [action.payload.id]: {
        ...(state.foldersById[action.payload.id] as FolderContainer),
        status: 'loading',
      },
    },
  }))
  .handle(actions.afterReadFolder, (state, action) => {
    const existingFolderLink = state.foldersById[action.payload.id]?.folder;
    // const existingFolder = state.foldersById[action.payload.folder.id]?.folder;

    const mergeFolders = (prev, next) => ({
      ...prev,
      ...next,
      metaById: {
        ...prev?.metaById,
        ...next.metaById,
      },
      inheritedMetaById: {
        ...prev?.inheritedMetaById,
        ...next.inheritedMetaById,
      },
      inheritedPropertiesById: {
        ...prev?.inheritedPropertiesById,
        ...next.inheritedPropertiesById,
      },
    });

    return {
      ...state,
      foldersById: {
        ...state.foldersById,
        // This may be a link
        [action.payload.id]: {
          status: null,
          timestamp: new Date(),
          folder: mergeFolders(existingFolderLink, action.payload.folder),
        },
        // Concrete folder
        // If the concrete folder is updated after the link is fetched, path and such will be those of the link
        // TODO: there's a similar problem with afterReadFile
        /* [action.payload.folder.id]: {
          status: null,
          timestamp: new Date(),
          folder: mergeFolders(existingFolder, action.payload.folder),
        }, */
      },
    };
  })
  .handle(actions.readContent, (state, action) => {
    const hash = criteriaHash(action.payload.id, action.payload.criteria);
    return {
      ...state,
      contentsByCriteriaHash: {
        ...state.contentsByCriteriaHash,
        [hash]: {
          ...state.contentsByCriteriaHash[hash],
          status: 'loading',
        },
      },
    };
  })
  .handle(actions.afterReadContent, (state, action) => {
    const hash = criteriaHash(action.payload.id, action.payload.criteria);
    const itemsById = {};
    action.payload.items.forEach(item => {
      itemsById[item.node.id] = {
        status: null,
        timestamp2: new Date(),
        file: item,
      };
    });
    return {
      ...state,
      itemsById: {
        ...state.itemsById,
        ...itemsById,
      },
      contentsByCriteriaHash: {
        ...state.contentsByCriteriaHash,
        [hash]: {
          status: null,
          timestamp: new Date(),
          id: action.payload.id,
          criteria: action.payload.criteria,
          // TODO: save only content ids and get the items from itemsById?
          items: action.payload.items,
          totalCount: action.payload.totalCount,
        },
      },
    };
  })
  .handle(actions.afterReadContentError, (state, action) => {
    const hash = criteriaHash(action.payload.id, action.payload.criteria);
    return {
      ...state,
      contentsByCriteriaHash: {
        ...state.contentsByCriteriaHash,
        [hash]: {
          status: 'error',
          timestamp: new Date(),
          id: action.payload.id,
          criteria: action.payload.criteria,
          items: [],
          totalCount: 0,
          error: action.payload.error,
        },
      },
    };
  })
  .handle(actions.fetchWorkflow, (state, action) => ({
    ...state,
    workflowsById: {
      ...state.workflowsById,
      [action.payload.id]: {
        ...(state.workflowsById[action.payload.id] as WorkflowSettingContainer),
        status: 'loading',
      },
    },
  }))
  .handle(actions.afterWorkflowFetch, (state, action) => ({
    ...state,
    workflowsById: {
      ...state.workflowsById,
      [action.payload.id]: {
        status: null,
        timestamp: new Date(),
        workflow: action.payload.workflow,
      },
    },
  }))
  .handle(actions.setViewMode, overwriteWithPayload)
  .handle(actions.setPassword, overwriteWithPayload)
  .handle(actions.afterReadCustomerMetaFields, overwriteWithPayload);

/** Which view mode should be used when browsing content: grid or list.
 * Accounts for user's selection as well as customer's settings
 */
export function useContentViewMode() {
  const windowSize = useThrottledWindowSize();

  const customerConfig = useSelector(state => state.app.customer?.configById);
  const userViewMode = useSelector(state => state.commonContent.viewMode);
  const mode = useSelector(state => state.content.mode);

  // Only grid is supported for consent request
  if (mode === 'consentRequest') {
    return 'grid';
  }

  const { setViewMode } = useActions(commonContent.actions);
  const { view: urlViewMode } = useUrlParams();

  useEffect(() => {
    if (urlViewMode) setViewMode(urlViewMode);
  }, [urlViewMode]);

  // On small screens the only available view mode is grid
  return isMobile(windowSize.innerWidth)
    ? 'grid'
    : userViewMode ??
        customerConfig?.['content.browse.settings.viewmode.default'] ??
        'grid';
}

// Bundle things in a model
export const commonContent = {
  actions,
  selector: {},
};
