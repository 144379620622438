import React from 'react';
import { t } from '@lingui/macro';
import { History } from '@mui/icons-material';

import { File, Fileish, fileExists } from '~common/content.types';
import { ModalOption } from '~common/navigation/MenuButton';

export function useShareHistoryOption(
  workspace: Fileish | undefined
): ModalOption {
  return {
    value: 'history',
    hidden: !fileExists(workspace) || !workspace.isCart,
    disabled: !fileExists(workspace) || !workspace.emailCount,
    title: t`View sharing history`,
    icon: <History fontSize="small" />,
    modalType: 'WORKSPACE/SHARE_HISTORY',
    modalProps: { workspaceId: (workspace as File)?.node.id },
  };
}
