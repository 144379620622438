import React from 'react';
import styled from 'styled-components';
import { useDrop } from 'react-dnd';
import DeleteIcon from '@mui/icons-material/Delete';

import { useRemoveFromWorkspace } from './hooks';

import { dndTypes } from '~common/misc/drag/constants';
import { DndItem, DragSource } from '~common/content.types';

const acceptedTypes = [
  dndTypes.WORKSPACE_FILE,
  dndTypes.WORKSPACE_FOLDER,
  dndTypes.WORKSPACE_FOLDER_LINK,
  dndTypes.FILE,
];

interface Props {
  dragSource: DragSource;
}

export function DeleteDropzone({ dragSource }: Props) {
  const { removeFromWorkspace } = useRemoveFromWorkspace();

  const [{ isOver, canDrop, isDragging }, drop] = useDrop({
    accept: acceptedTypes,
    drop: (droppedItem: DndItem) => removeFromWorkspace(droppedItem.itemIds),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      isDragging:
        monitor.getItem() &&
        monitor.getItem().dragSource === dragSource &&
        acceptedTypes.includes(
          monitor.getItem().type as typeof acceptedTypes[number]
        ),
    }),
  });

  return (
    <Zone
      ref={drop}
      show={isDragging}
      canDrop={canDrop}
      hover={isOver}
      $source={dragSource}
    >
      <StyledDeleteIcon />
    </Zone>
  );
}

interface ZoneProps {
  show: boolean;
  hover: boolean;
  canDrop: boolean;
  $source: DragSource;
}

const Zone = styled.div<ZoneProps>`
  --left-sidebar-width: 19vw;
  --right-sidebar-width: 282px;
  --navbar-height: 56px;
  --bottom-bar-height: 57px;
  --margin: ${p => p.theme.spacing(1)};

  display: ${p => (p.show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  width: auto !important;

  position: fixed;
  width: unset;
  max-width: unset;
  top: calc(var(--navbar-height) + var(--margin));
  bottom: calc(var(--bottom-bar-height) + var(--margin));
  right: calc(var(--right-sidebar-width) + var(--margin));
  left: calc(var(--left-sidebar-width) + var(--margin));

  background-color: ${p =>
    p.hover
      ? 'rgba(255, 20, 20, 0.7)'
      : p.canDrop
      ? 'rgba(255, 20, 20, 0.5)'
      : 'rgba(255, 20, 20, 0.2)'};
  border-radius: 5px;
  border: 3px red solid;

  /* Delete zone for shopping cart should be under the popover */
  z-index: ${p => (p.$source === 'shopping' ? -1 : 99)};
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  font-size: 72pt !important;
`;
