import { all, call, put, takeEvery, fork } from 'redux-saga/effects';

import config, { history } from './config';
import * as customerApi from './customer.api';
import * as userApi from './user.api';
import * as ShoppingApi from '../content/workspaces/shopping/api';
import { app } from './app.model';
import { select } from './utils/saga.utils';
import { getUrlParamValue } from './app.utils';

// General

function* showInfoMessage(action) {
  yield put(
    app.actions.setOpenSnackbar('INFO_MESSAGE', {
      type: action.payload.type || 'success',
      message: action.payload.message,
      screenReaderOnly: action.payload.screenReaderOnly || false,
    })
  );
}

function* watchShowInfoMessage() {
  yield takeEvery('APP/SHOW_INFO_MESSAGE', showInfoMessage);
}

function* showErrorMessage(action) {
  yield put(
    app.actions.setOpenSnackbar('ERROR_MESSAGE', {
      type: action.payload.type || 'error',
      message: action.payload.message,
      screenReaderOnly: action.payload.screenReaderOnly || false,
    })
  );
}

function* watchShowErrorMessage() {
  yield takeEvery('APP/SHOW_ERROR_MESSAGE', showErrorMessage);
}

function* showError(action) {
  // TODO
  console.log(action.payload.error);
}

function* watchShowError() {
  yield takeEvery('APP/AFTER_ERROR', showError);
}

function* goBack() {
  history.goBack();
}

function* watchGoBack() {
  yield takeEvery('APP/GO_BACK', goBack);
}

// Customer

function* init() {
  try {
    const { customer, sessionLanguage } = yield call(customerApi.readCustomer, {
      customerName: `${config.customer}`,
    });

    const language = getUrlParamValue('lang') ?? sessionLanguage; // ?? call(userApi.getSessionLanguage);

    yield put({ type: 'APP/AFTER_READ_CUSTOMER', payload: { customer } });

    // Populate settings with customer defaults in case user is not logged in
    const share = yield select(state => {
      return state.app.share;
    });
    const settings = yield select(state => {
      return state.app.settings;
    });
    if (!settings) {
      yield put({
        type: 'APP/AFTER_READ_USER_SETTINGS',
        payload: {
          settings: {
            theme: customer.theme,
            language:
              share && share.language
                ? share.language
                : language && customer.languages.includes(language)
                ? language
                : customer.defaultLanguage,
          },
        },
      });
    }
  } catch (error) {
    yield put({ type: 'APP/AFTER_ERROR', payload: { error } });
  }
}

function* watchReadCustomer() {
  yield takeEvery('APP/INIT', init);
}

function* readCustomerFunctions() {
  try {
    const customerId = yield select(state => state.app.customer?.id);
    const functions = yield call(customerApi.readCustomerFunctions, {
      customerId,
    });
    yield put(
      app.actions.afterReadCustomerFunctions(
        Object.keys(functions).reduce(
          (a, c) => ({
            ...a,
            [c]: functions[c] === -1 ? false : functions[c],
          }),
          {}
        )
      )
    );
  } catch (error) {
    yield put({ type: 'APP/AFTER_ERROR', payload: { error } });
  }
}

function* watchReadCustomerFunctions() {
  yield takeEvery('APP/AFTER_READ_CUSTOMER', readCustomerFunctions);
}

// Login and logout

function* login() {
  // NOTE: Always try to clear session before login
  try {
    yield call(
      userApi.logout,
      {
        customer: config.customer,
        session: {},
      },
      true
    );
  } catch (error) {}

  const { search, origin, pathname } = window.location;
  const loginKeys = ['j_username', 'j_password', 'directLogin'];
  const searchParams = search
    .substring(1)
    .split('&')
    .filter(x => !!x)
    .map(x => x.split('='));
  const loginParams: Record<string, string> = Object.fromEntries(
    searchParams.filter(param => loginKeys.includes(param[0]))
  );
  const redirectParams: Record<string, string> = Object.fromEntries(
    searchParams.filter(param => !loginKeys.includes(param[0]))
  );
  const searchString =
    Object.keys(redirectParams).length > 0
      ? `?${Object.keys(redirectParams)
          .map(key => `${key}=${redirectParams[key]}`)
          .join('&')}`
      : '';
  // Login
  yield call(userApi.login, {
    customer: config.customer,
    redirectUrl: redirectParams.redirectUrl
      ? decodeURIComponent(redirectParams.redirectUrl)
      : `${origin}${pathname}${searchString}`,
    loginParams,
  });
}

function* watchLogin() {
  yield takeEvery('APP/LOGIN', login);
}

function* logout(action) {
  try {
    const session = yield select(state => {
      return state.app.session;
    });

    const redirected = yield call(
      userApi.logout,
      {
        customer: config.customer,
        session,
      },
      action.payload.restricted
    );
    if (!redirected) {
      yield put({ type: 'APP/AFTER_LOGOUT', payload: {} });
    }
  } catch (error) {
    yield put({ type: 'APP/AFTER_ERROR', payload: { error } });
  }
}

function* watchLogout() {
  yield takeEvery('APP/LOGOUT', logout);
}

function* afterLogout() {
  yield call(userApi.login, {
    customer: config.customer,
    redirectUrl: null,
    loginParams: null,
  });
}

function* watchAfterLogout() {
  yield takeEvery('APP/AFTER_LOGOUT', afterLogout);
}

function* readSession() {
  try {
    const session: any = yield call(userApi.readLogoutRedirectUrl, {
      customerStaticName: config.customer,
    });
    yield put({
      type: 'APP/AFTER_READ_SESSION',
      payload: { session },
    });
  } catch (error) {
    yield put({ type: 'APP/AFTER_ERROR', payload: { error } });
  }
}

// User rights

function* readUserRights() {
  try {
    const customerId = yield select(state => state.app.customer?.id);

    const userRights = yield call(userApi.readUserRights, {
      customerId,
    });
    yield put({ type: 'APP/AFTER_READ_USER_RIGHTS', payload: { userRights } });
  } catch (error) {
    yield put({ type: 'APP/AFTER_ERROR', payload: { error } });
  }
}

function* watchReadUserRights() {
  yield takeEvery('APP/AFTER_READ_USER', readSession);
  yield takeEvery('APP/AFTER_READ_USER', readUserRights);
}

// User settings

function* readUserSettings() {
  try {
    const { data: user } = yield select(state =>
      userApi.userCacheSelector(state)
    );
    const customer = yield select(state => {
      return state.app.customer;
    });
    const settings = yield call(userApi.readUserSettings, customer.id);
    let lang = getUrlParamValue('lang');
    if (lang) {
      localStorage.setItem(user.id + '-lang', lang);
    } else {
      lang = localStorage.getItem(user.id + '-lang');
    }

    if (lang && customer.languages.includes(lang)) {
      settings.language = lang;
      // remove lang parameter from url
      history.replace({
        ...history.location,
        search: history.location.search
          .replace(/&?lang=.+?(?=&|$)/, '')
          .replace(/\?&/, '?'),
      });
    } else {
      settings.language = user.sessionLanguage ?? user.language;
    }

    yield put({
      type: 'APP/AFTER_READ_USER_SETTINGS',
      payload: { settings },
    });
  } catch (error) {
    yield put({ type: 'APP/AFTER_ERROR', payload: { error } });
  }
}

function* watchReadUserSettings() {
  yield takeEvery('APP/READ_USER_SETTINGS', readUserSettings);
  yield takeEvery('APP/AFTER_READ_USER', readUserSettings);
}

// Combine all

export default function* sagas(): any {
  yield all([
    fork(watchShowInfoMessage),
    fork(watchShowErrorMessage),
    fork(watchShowError),
    fork(watchGoBack),
    fork(watchReadCustomer),
    fork(watchReadCustomerFunctions),
    fork(watchLogin),
    fork(watchLogout),
    fork(watchAfterLogout),
    fork(watchReadUserRights),
    fork(watchReadUserSettings),
  ]);
}
