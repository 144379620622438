import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import AppBar from './AppBar';
import LeftDrawer from './LeftDrawer';
import LeftDock from './LeftDock';
import RightDock, { expandableDocks } from './RightDock';
import Router from './Router';
import ModalHandler from './ModalHandler';
import SnackbarHandler from './Snackbar';
import { NewsPiece } from './news/types';
import { useFetchUnreadNewsQuery } from './news/api';
import Tracking from './Tracking';
import { Router as JCDecauxRouter } from './integration/jcdecaux/Router';
import { LeftDrawerContent as JCDecauxLeftDrawerContent } from './integration/jcdecaux/LeftDrawerContent';

import useFullStory from './FullStory';
import { useGetShoppingCartIdQuery } from './content/workspaces/shopping/api';
import { app } from '~common/app.model';
import CustomDragLayer from '~common/misc/drag/CustomDragLayer';
import { getLangValue } from '~common/app.utils';
import { useActions } from '~common/utils/hooks.utils';
import {
  useGetCurrentUserQuery,
  useUpdateSessionLanguageMutation,
} from '~common/user.api';
import {
  EMBED_LEVEL,
  isEmbeded,
  useCustomerMetaFields,
  useUrlParams,
} from '~common/content.utils';

// TODO: figure out how to fix typings when using styled + mui together
// https://material-ui.com/guides/typescript/
function App() {
  const { pathname } = useLocation();
  const share = useSelector(state => state.app.share);
  const customer = useSelector(state => state.app.customer);
  const settings = useSelector(state => state.app.settings);
  const { data: user } = useGetCurrentUserQuery();
  const [updateSessionLanguage] = useUpdateSessionLanguageMutation();
  const leftDockVisible = useSelector(state => state.app.leftDock.visible);
  const leftDockOpen = useSelector(state => state.app.leftDock.open);
  const rightDockContent = useSelector(state => state.app.rightDock.content);
  const rightDockVisible = useSelector(state => state.app.rightDock.visible);
  const rightDockOpen = useSelector(state => state.app.rightDock.open);
  const rightDockExtraWidth = useSelector(
    state => state.app.rightDock.extraWidth
  );
  const { embed } = useUrlParams();

  const { data: unreadNews } = useFetchUnreadNewsQuery(
    user?.hasUnreadNews ? user.id : skipToken
  );

  // Loading data into Redux storage because it is needed everywhere
  useCustomerMetaFields(share ? { shareKey: share.key.join('/') } : undefined);
  useGetShoppingCartIdQuery(user ? { username: user.username } : skipToken);

  const setOpenModal = useActions(app.actions.setOpenModal);

  const invisibleIframeStyle = {
    position: 'absolute' as const,
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    border: 'none' as const,
  };

  useEffect(() => {
    if (unreadNews) {
      // Only open unread news that have either textual or image content
      const filtered = unreadNews.filter(
        news =>
          getLangValue(news.content) ||
          news.htmlContent ||
          news.imageUuid !== 'null'
      );
      if (filtered.length !== 0) {
        const uniqueNewsIds = [...new Set(filtered.map(news => news.id))];
        const uniqueNews = uniqueNewsIds
          .map(id => filtered.find(news => news.id === id))
          .filter(newsPiece => newsPiece !== undefined) as NewsPiece[];
        setOpenModal('NEWS/UNREAD_NEWS', {
          newsCollection: uniqueNews,
          unreadNews: true,
        });
      }
    }
  }, [unreadNews]);

  // Ask to accept terms of use
  useEffect(() => {
    if (
      customer?.acceptTermsOfUse?.login &&
      settings?.termsOfUseAccepted === false
    ) {
      const terms = getLangValue(customer.termsOfUse);
      if (!terms) return;
      setOpenModal('SETTINGS/TERMS_OF_USE', {
        terms,
      });
    }
  }, [customer, settings]);

  // update session language to api session data
  useEffect(() => {
    if (settings?.language) {
      updateSessionLanguage(settings.language);
      localStorage.setItem(user?.id + '-lang', settings.language);
    }
  }, [settings?.language]);

  const [customerFavicon, setCustomerFavicon] = useState<string>();
  // Check if there exists a customer specific favicon and use that if found
  useEffect(() => {
    if (!customer?.staticName) return;
    const faviconUrl = `/NiboWEB/img/customer/${customer.staticName.toLowerCase()}.ico`;
    fetch(faviconUrl).then(resp => {
      if (!resp.ok) return;
      setCustomerFavicon(faviconUrl);
      // Remove Gredi-branded icons
      ['icon', 'apple-touch-icon', 'apple-touch-startup-image'].forEach(type =>
        document.head
          .querySelectorAll(`link[rel~='${type}']`)
          .forEach(link => link.remove())
      );
    });
  }, [customer?.staticName]);

  const isUploadForm = pathname.startsWith('/invite');
  const hideToolbar =
    isUploadForm || isEmbeded(EMBED_LEVEL.CONTENT_WITHOUT_FOLDER_BAR, embed);

  // Check if JCD service
  const isJcdService = customer?.configById['jcd.isJCDservice']
    ? customer?.configById['jcd.isJCDservice'] === true
    : customer?.staticName === 'jcdecaux';
  const isJCDecauxUI =
    isJcdService &&
    user?.groups.some(
      x =>
        x.name === 'Toimittajat' ||
        (customer?.staticName === 'jcdecaux' && x.id === '21')
    );

  useFullStory();
  return customer?.id &&
    settings?.theme &&
    (settings.home ||
      share ||
      pathname.endsWith('registration') ||
      pathname.endsWith('set_password/') ||
      isUploadForm) ? (
    <>
      <Helmet
        defaultTitle={getLangValue(customer.namesByLang) || 'Gredi Content HUB'}
        titleTemplate={`%s - ${
          getLangValue(customer.namesByLang) || 'Gredi Content HUB'
        }`}
      >
        {/* Backend uses 'se' for Swedish but the correct code is 'sv' */}
        <html lang={settings.language === 'se' ? 'sv' : settings.language} />
        {customerFavicon && <link rel="icon" href={customerFavicon} />}
      </Helmet>
      {/* Sign on for Brand Manual */}
      {settings.signOnLink && (
        <iframe style={invisibleIframeStyle} src={settings.signOnLink} />
      )}
      {settings.signOnAdminLink && (
        <iframe style={invisibleIframeStyle} src={settings.signOnAdminLink} />
      )}

      {!hideToolbar && (
        <>
          <AppBar isJCDecauxUI={isJCDecauxUI} />
          <LeftDrawer
            Content={isJCDecauxUI ? JCDecauxLeftDrawerContent : undefined}
          />
        </>
      )}

      <ContentGrid
        leftDockVisible={leftDockVisible}
        leftDockOpen={leftDockOpen}
        rightDockVisible={rightDockVisible}
        rightDockOpen={rightDockOpen}
        rightDockExtraWidth={
          rightDockContent && expandableDocks.includes(rightDockContent)
            ? rightDockExtraWidth
            : 0
        }
        topBorder={settings.theme.appBar?.border}
        id="content-grid"
      >
        <LeftDock />

        <Main id="maincontent" tabIndex={-1}>
          <ModalHandler />
          <SnackbarHandler />
          <CustomDragLayer />
          {isJCDecauxUI ? <JCDecauxRouter /> : <Router />}
        </Main>

        <RightDock />
      </ContentGrid>

      <Tracking
        grediTrackingId="UA-24120257-2"
        customerTrackingId={customer.gaTrackingId}
        lang={user?.language ?? customer.defaultLanguage}
      />
    </>
  ) : null;
}

const ContentGrid = styled.div<{
  leftDockVisible?: boolean;
  leftDockOpen: boolean;
  rightDockVisible?: boolean;
  rightDockOpen: boolean;
  rightDockExtraWidth?: number;
  topBorder?: boolean;
}>`
  display: grid;
  // magic numbers from initial implementation
  grid-template-columns:
    ${p =>
      p.leftDockVisible && p.leftDockOpen ? 'minmax(15rem, 19vw)' : 'auto'}
    1fr
    ${p =>
      p.rightDockVisible && p.rightDockOpen
        ? `calc(17.5rem + ${p.rightDockExtraWidth ?? 0}px)`
        : 'auto'};

  grid-template-areas: 'leftdock main rightdock';
  /* We need to reset some grid child defaults to prevent layout from
  getting blown up (https://css-tricks.com/preventing-a-grid-blowout/) */
  & > * {
    min-height: 0;
    min-width: 0;
  }

  ${p =>
    p.topBorder && `border-top: 3px solid ${p.theme.palette.border.section};`}

  @media print {
    grid-template-columns: min-content 1fr min-content;
  }
`;

const Main = styled.main`
  grid-area: main;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: auto;
  &:focus {
    outline: none;
  }
`;

export default App;
