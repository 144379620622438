import { TranslatedData } from '~common/common.types';

export * from '~common/product.types';

export type ProductElement<E = Element> = {
  id: string;
  created: string;
  modified: string;
  element: E;
  masterElement: MasterElement<E>;
  isMassImport: boolean;
  isMultipleChosen: boolean;
  updatedFrom?: 'sidebar';
};

type BaseElement = {
  id: string;
  created: string;
  modified: string;
  location: ElementLocation;
  sizeLocked: boolean;
};
export type ImageElement = BaseElement & {
  type: 'IMAGE';
  crop: ElementCrop;
  isCrop: boolean;
  imageUuid?: string | -1;
  metaLang?: string;
  pdfLinks: string;
  scalingMode: number;
  convertMode: number;
};
export type TextElement = BaseElement & {
  type: 'TEXT';
  text: ElementText;
  textUuid?: string | -1;
  lineWidth: number;
  pdfData?: string;
  pdfLinks: string;
  fillColorId: number;
  lineColorId: number;
  fontUuid: string;
  isPercentTextLeading: boolean;
  isPercentLineMode: boolean;
  priceDecimals: number;
  priceDecimalsSup: number;
  indexSize: number;
  indexPosition: number;
  bulletTextIntend: number;
  bulletLeftIntend: number;
  hyphenation: string;
  wrapPolygon: string;
};
export type VectorElement = BaseElement & {
  type: 'VECTOR';
  lineWidth: number;
  fillColorId: number;
  lineColorId: number;
};

export type Element = TextElement | ImageElement | VectorElement;

export type MasterElement<E = Element> = E & {
  elementId: number;
  namesByLang: TranslatedData;
  descriptionsByLang: TranslatedData;
  info?: {
    id: number;
    imageUuid: number;
    contents: TranslatedData;
  };
  lineNr: number;
  lock: ElementLock;
  availableFillColors: AvailableColor[];
  availableLineColors: AvailableColor[];
  availableImageUuids: AvailableNode[];
  availableTextUuids: AvailableNode[];
  availableFontUuids: AvailableFont[];
  metaMasterElementId: number;
  isMetaUpdater: boolean;
  isMetaAutofill: boolean;
  metaId: number;
  canHeightIncrease: boolean;
  groupName: string;
};

export type ProductUnit =
  | {
      metric: 'PIXEL';
      resolution: number;
    }
  | { metric: 'MM' | 'INCH' | 'POINT' };

export type ElementLocation = {
  offsetH: number;
  offSetY: number;
  w: number;
  x: number;
  z: number;
  h: number;
  y: number;
  originalH: number;
  angle: number;
  origoNumber: number;
  origo:
    | 'ORIGO_TOP_LEFT'
    | 'ORIGO_TOP_CENTER'
    | 'ORIGO_TOP_RIGHT'
    | 'ORIGO_LEFT'
    | 'ORIGO_CENTER'
    | 'ORIGO_RIGHT'
    | 'ORIGO_BOTTOM_LEFT'
    | 'ORIGO_BOTTOM_CENTER'
    | 'ORIGO_BOTTOM_RIGHT';
};

export type ElementCrop = {
  w: number;
  h: number;
  y1: number;
  y2: number;
  x1: number;
  x2: number;
  zoom: number;
};

export type ElementText = {
  uuid?: string | -1;
  type:
    | 'TEXT_AREA'
    | 'TEXT_FIELD'
    | 'TEXT_FIELD_CURVING'
    | 'TEXT_FLOW'
    | 'TEXT_PRICE';
  size: number;
  spacing: number;
  align:
    | 'TEXT_ALIGN_LEFT'
    | 'TEXT_ALIGN_CENTER'
    | 'TEXT_ALIGN_RIGHT'
    | 'TEXT_ALIGN_JUSTIFY';
  charset: number;
  leading: number;
  valign: 'TEXT_VALIGN_TOP' | 'TEXT_VALIGN_MIDDLE' | 'TEXT_VALIGN_BOTTOM';
  radius: number;
  deltaY: number;
  xDegree: number;
  renderingMode: number;
  direction: 'TEXT_DIRECTION_CLOCKWISE' | 'TEXT_DIRECTION_ANTICLOCKWISE';
  transformation:
    | 'TEXT_TRANSFORMARMATION_NONE'
    | 'TEXT_TRANSFORMARMATION_GEMENA'
    | 'TEXT_TRANSFORMARMATION_VERSAALI';
  sizes: string;
  data: string;
};

export type ElementLock = {
  pdfLinks: boolean;
  imageUpload: boolean;
  fillColor: boolean;
  imageSelect: boolean;
  textValign: boolean;
  clearFillColor: boolean;
  IndexSize: boolean;
  H: boolean;
  textRenderingMode: boolean;
  hyphenation: boolean;
  W: boolean;
  textSelect: boolean;
  lineColor: boolean;
  search: boolean;
  priceDecimalsSup: boolean;
  clearLineColor: boolean;
  textAlign: boolean;
  imagePosition: boolean;
  Y: boolean;
  textData: boolean;
  textRadius: boolean;
  X: boolean;
  textXDegree: boolean;
  Z: boolean;
  textLeading: boolean;
  clearFont: boolean;
  font: boolean;
  clearSelection: boolean;
  indexPosition: boolean;
  lineWidth: boolean;
  angle: boolean;
  textDirection: boolean;
  imageCrop: boolean;
  textCharset: boolean;
  textDeltaY: boolean;
  textSize: boolean;
  priceDecimals: boolean;
  textSpacing: boolean;
  origo: boolean;
};

export type ProductPageThumbnail = {
  id: number;
  pageNumber: number;
  thumbnailUrl: string;
};

export type AvailableColor = {
  id: number;
  type?: 'COLOR_TYPE_FILL' | 'COLOR_TYPE_STROKE';
  value1: number;
  value2: number;
  value3: number;
  value4: number;
  hexValue: string;
  spotValue?: string;
  isOverprint: boolean;
  isDisabled: boolean;
  removeStatus: 0 | 1;
  isInheritable: boolean;
  customerId: number;
  name: TranslatedData;
};

export type AvailableNode = {
  id: number;
  uuid: string;
  primaryKey: number;
};

export type AvailableFont = {
  uuid: string;
  name: string;
  fileName: string;
  familyName: string;
  style: number;
  styleName: string;
  styleFonts?: string;
  type: number;
  ttfPath?: string;
  t1MetricsPath?: string;
  t1OutlinePath?: string;
  familyUUID?: string;
  htmlFontFamily?: string;
  htmlFontFamily2?: string;
};

export function isImageElement(element?: Element): element is ImageElement {
  return element?.type === 'IMAGE';
}

export function isImageProductElement(
  productElement?: ProductElement
): productElement is ProductElement<ImageElement> {
  return isImageElement(productElement?.element);
}

export function isTextElement(element?: Element): element is TextElement {
  return !!element && element.type.indexOf('TEXT') !== -1;
}

export function isTextProductElement(
  productElement?: ProductElement
): productElement is ProductElement<TextElement> {
  return isTextElement(productElement?.element);
}

export function isVectorElement(element?: Element): element is VectorElement {
  return element?.type === 'VECTOR';
}

export function isVectorProductElement(
  productElement?: ProductElement
): productElement is ProductElement<VectorElement> {
  return isVectorElement(productElement?.element);
}
