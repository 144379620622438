import React from 'react';
import styled from 'styled-components';

import { muiFocusRing } from '~common/utils/styled.utils';
import { ArrowDownIcon, ArrowUpIcon, SortableIcon } from '~common/misc/icons';

interface Pros {
  sortBy?: string;
  ascSortValue: string;
  descSortValue: string;
  onChange: (value: string) => void;
  defaultSortDir?: 'desc' | 'asc';
}

export const SortableHeaderElement: React.FC<Pros> = ({
  sortBy,
  ascSortValue,
  descSortValue,
  onChange,
  children,
  defaultSortDir,
}) => {
  const icon =
    sortBy === ascSortValue ? (
      <ArrowDownIcon />
    ) : sortBy === descSortValue ? (
      <ArrowUpIcon />
    ) : (
      <SortableIcon />
    );

  const onClick = () => {
    if (defaultSortDir !== undefined && defaultSortDir === 'desc') {
      onChange(sortBy === descSortValue ? ascSortValue : descSortValue);
    } else {
      onChange(sortBy === ascSortValue ? descSortValue : ascSortValue);
    }
  };

  return (
    <SortButtonWrapper onClick={onClick}>
      <SortButtonText>{children}</SortButtonText>
      {icon}
    </SortButtonWrapper>
  );
};

/** style resets */
const StyledButton = styled.button`
  border: none;
  padding: 0;
  background-color: inherit;
  cursor: pointer;
  font-family: unset;
  text-align: left;
`;

const SortButtonWrapper = styled(StyledButton)`
  display: flex;
  align-items: center;
  padding: ${p => p.theme.spacing(1)};
  // looks better with sort icon when padding a bit narrower
  padding-right: ${p => p.theme.spacing(0.5)};

  &:focus-visible {
    ${muiFocusRing}
  }
`;

const SortButtonText = styled.span`
  font-weight: ${p => p.theme.typography.fontWeightMedium};
  font-size: 0.9rem;
  margin-right: 0.5em;
`;
