import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { t } from '@lingui/macro';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { search } from './model';
import { useGetSuggestionCategoryTitlesQuery } from './api';
import { ABSOLUTE_FOLDER_TAG_ID } from './constants';
import { FilesById, SearchTag } from '~common/content.types';
import { Chip, ChipList } from '~common/items/Chips';
import { useActions } from '~common/utils/hooks.utils';
import { useGetFileQuery } from '~common/content.api';
import { getLangValue } from '~common/app.utils';

interface Props {
  onSearch: (beforeSearch: () => void) => void;
}

/** The ids of tags we don't want to display here.
 * Those might be required to pass as tags to search but are displayed somewhere else. */
const HIDDEN_TAG_IDS = [ABSOLUTE_FOLDER_TAG_ID];

const Tags = ({ onSearch }: Props) => {
  const tags = useSelector(state => state.search.tags);
  const criteria = useSelector(state => state.search.criteria);
  const customerId = useSelector(state => state.app.customer?.id);
  const lang = useSelector(state => state.app.settings?.language);
  const { updateCriteria, updateTags } = useActions(search.actions);
  const filesById = useSelector(
    state => state.commonContent.filesById
  ) as FilesById;

  /* These two (categoryTitles and translations) are sort of dublicates.
  However I'm not confident enough to delete categoryTitles,
  since there might still be some tiltes the useGetSuggestionCategoryTitlesQuery won't return.
  categoryTitles are updated whenever suggestions are fetched and if there's some explicitely defined category ids,
  titles of those would be missing from translations */
  const categoryTitles = useSelector(state => state.search.categoryTitles);
  const { data: translations } = useGetSuggestionCategoryTitlesQuery(
    customerId && lang ? { customerId, lang } : skipToken
  );

  const { data: linkedData } = useGetFileQuery(
    criteria.linkedId ? { id: criteria.linkedId } : skipToken
  );
  const { data: expandData } = useGetFileQuery(
    criteria.expandId ? { id: criteria.expandId } : skipToken
  );

  const allTags = [...tags] as (SearchTag & {
    onDelete?: () => void;
  })[];
  // NOTE: for now the user can only remove the entry type filter
  // TODO: add dropdown input to SearchForm to properly add/remove entry type filter
  if (criteria.entryType)
    allTags.push({
      id: 'entryType',
      title: t`Entry type`,
      value:
        criteria.entryType === 'concrete'
          ? t`Original`
          : criteria.entryType === 'link'
          ? t`Link`
          : criteria.entryType,
      onDelete: () => {
        onSearch(() => updateCriteria({ entryType: undefined }));
      },
    });
  if (criteria.expandId)
    allTags.push({
      id: 'expandId',
      title: t`Locations`,
      value:
        expandData && !expandData.removed
          ? getLangValue(expandData.namesByLang)
          : criteria.expandId,
      onDelete: () => {
        onSearch(() => updateCriteria({ expandId: undefined }));
      },
    });
  if (criteria.showOnFrontpage === 'true')
    allTags.push({
      id: 'showOnFrontpage',
      title: t`Show on frontpage`,
      value: '',
      onDelete: () => {
        onSearch(() => updateCriteria({ showOnFrontpage: undefined }));
      },
    });
  if (criteria.linkedId)
    allTags.push({
      id: 'linkedId',
      title: t`Links`,
      value:
        linkedData && !linkedData.removed
          ? getLangValue(linkedData.namesByLang)
          : criteria.linkedId,
      onDelete: () => {
        onSearch(() => updateCriteria({ linkedId: undefined }));
      },
    });

  const visibleTags = allTags.filter(tag => !HIDDEN_TAG_IDS.includes(tag.id));

  if (!visibleTags.length) return null;

  return (
    <StyledChipList>
      {visibleTags.map(tag => {
        const title =
          translations?.[tag.id] ??
          categoryTitles?.[tag.id] ??
          tag.title ??
          tag.id;

        const label = `${title}${tag.value ? ': ' + tag.value : ''}`;

        return (
          <StyledChip
            key={`${tag.id}: ${tag.value}`}
            label={`${label}`}
            aria-label={`Delete ${title} ${tag.value}`}
            selected
            onDelete={() => {
              // Don't worry about this.
              // This should be only temporary until we properly implement the entry type filter
              if (tag.onDelete) return tag.onDelete();
              else {
                const newTags = tags.filter(
                  prevTag =>
                    prevTag.id !== tag.id || prevTag.value !== tag.value
                );
                onSearch(() => {
                  updateTags(newTags);
                  updateCriteria({
                    tags: newTags,
                  });
                });
              }
            }}
          />
        );
      })}
    </StyledChipList>
  );
};

const StyledChipList = styled(ChipList)`
  position: relative;
  margin-top: ${p => -p.theme.spacing(2)};
`;

const StyledChip = styled(Chip)`
  max-width: 100%;
`;

export default Tags;
