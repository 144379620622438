import { DefaultRootState } from 'react-redux';
import { t } from '@lingui/macro';
import qs from 'qs';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '@reduxjs/toolkit/dist/query/core/apiState';

import { content } from '../model';
import { ScheduledArchiveFile } from './types';

import { apiBase } from '~common/api.base';
import { app } from '~common/app.model';
import { getFileTag } from '~common/content.api';
import { afterQueryMessages } from '~common/utils/api.utils';
import { Lang } from '~common/utils/i18n';

type GetArchiveQueueParams = {
  lang: Lang;
  customerId?: string;
};

type ArchiveNodesParams = {
  destinationFolderUuid: string;
  selectedNodes: string[];
  scheduleArchive: boolean;
  time?: string;
};

type UnscheduleArchiveParams = {
  selectedNodes: string[];
};

type RestoreFromArchiveParams = {
  selectedNodes: string[];
  destinationFolderUuid: string;
};

const extendedApi = apiBase.injectEndpoints({
  endpoints: builder => ({
    getArchiveQueue: builder.query<
      Array<ScheduledArchiveFile>,
      GetArchiveQueueParams
    >({
      query: params => ({
        url: `/archive/scheduled?${qs.stringify(params)}`,
        method: 'get',
      }),
      transformResponse: (data: any[]) =>
        data.map(item => ({
          ...item,
          archived: new Date(item.archived),
          id: `${item.id}`,
        })),
      providesTags: ['ArchiveSchedule'],
    }),

    archiveNodes: builder.mutation<void, ArchiveNodesParams[]>({
      queryFn: async (params, api, __, fetch) => {
        const { dispatch } = api;
        const results = await Promise.all(
          params.map(data =>
            fetch({
              url: '/archive/add/',
              method: 'post',
              data,
            })
          )
        );
        if (results.some(x => x.error)) {
          dispatch(app.actions.showErrorMessage(t`Archiving materials failed`));
          return { error: results.find(x => x.error) };
        } else {
          dispatch(
            app.actions.showInfoMessage(
              params.some(x => x.scheduleArchive)
                ? t`Archiving scheduled`
                : t`Materials archived`
            )
          );
          if (params.some(x => !x.scheduleArchive)) {
            const selectedNodes = params.flatMap(x => x.selectedNodes);
            filterCheckedContent(selectedNodes, api);
          }
          return { status: 200, data: undefined };
        }
      },
      invalidatesTags: (_, __, params) => [
        'ArchiveSchedule',
        ...params.map(x => getFileTag(x.destinationFolderUuid)),
        ...params.flatMap(x => x.selectedNodes).map(getFileTag),
      ],
    }),

    unscheduleArchive: builder.mutation<void, UnscheduleArchiveParams>({
      query: ({ selectedNodes }) => ({
        url: '/archive/unschedule',
        method: 'post',
        data: {
          selectedNodes,
          destinationFolderUuid: '',
          scheduleArchive: false,
        },
      }),
      onQueryStarted: (_, args) =>
        afterQueryMessages(
          t`Material unscheduled`,
          t`Unscheduling failed`,
          args
        ),
      invalidatesTags: (_, __, { selectedNodes }) => [
        'ArchiveSchedule',
        ...selectedNodes.map(getFileTag),
      ],
    }),

    restoreFromArchive: builder.mutation<void, RestoreFromArchiveParams>({
      query: data => ({
        url: '/archive/restore',
        method: 'post',
        data: { ...data, scheduleArchive: false },
      }),
      onQueryStarted: ({ selectedNodes }, args) =>
        afterQueryMessages(
          t`Material restored`,
          t`Restoring material failed`,
          args,
          {
            onSuccess: () => {
              filterCheckedContent(selectedNodes, args);
            },
          }
        ),
      invalidatesTags: (_, __, { destinationFolderUuid, selectedNodes }) => [
        getFileTag(destinationFolderUuid),
        ...selectedNodes.map(getFileTag),
      ],
    }),
  }),
});

function filterCheckedContent(
  selectedNodes: string[],
  {
    dispatch,
    getState,
  }: {
    dispatch: ThunkDispatch<any, any, AnyAction>;
    getState: () => RootState<any, any, string> | unknown;
  }
) {
  const checkedContent =
    (getState() as unknown as DefaultRootState).content.checkedContent?.items ??
    new Set<string>();
  dispatch(
    content.actions.setCheckedContent(
      new Set([...checkedContent].filter(id => !selectedNodes.includes(id)))
    )
  );
}

export const {
  useGetArchiveQueueQuery,
  useArchiveNodesMutation,
  useUnscheduleArchiveMutation,
  useRestoreFromArchiveMutation,
} = extendedApi;
