import React from 'react';
import styled from 'styled-components';
import { t } from '@lingui/macro';

import Typography from '~misc/Typography';

interface Props {
  prevItem: any;
  nextItem: any;
}

const ThumbnailDivider = ({ prevItem, nextItem }: Props) => {
  const prevIsFolder = prevItem && (prevItem.isFolder || prevItem.isCart);
  const nextIsFolder = nextItem && (nextItem.isFolder || nextItem.isCart);

  let title = '';
  if (nextIsFolder && !prevItem) {
    title = t`Folders`;
  } else if (!nextIsFolder && (!prevItem || prevIsFolder)) {
    title = t`Files`;
  }
  return !title ? null : (
    <Wrapper>
      <Typography variant="h2">{title}</Typography>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  h2 {
    display: none;
  }

  @media print {
    display: none !important;
  }
`;

export default ThumbnailDivider;
