import React from 'react';
import { Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { useLingui } from '@lingui/react';
import StepRedirect from './home/StepRedirect';
import ScrollToTop from './components/ScrollToTop';
import ErrorBoundary from '~common/utils/ErrorBoundary';
import ProgressBar from '~sections/ProgressBar';

const Home = React.lazy(() => import('./home'));
const PrintUpload = React.lazy(() => import('./printUpload'));
const PreparationUpload = React.lazy(() => import('./preparationUpload'));
const Materials = React.lazy(() => import('./materials'));
const Settings = React.lazy(() => import('../../settings'));

export function Router() {
  const { i18n } = useLingui();
  return (
    <ErrorBoundary>
      <React.Suspense fallback={<ProgressBar />}>
        <ScrollToTop />
        <Switch>
          <Route
            path={['/', '/home']}
            exact
            render={() => <Home />}
            key={i18n.locale}
          />
          <Route
            path="/success"
            exact
            render={() => <StepRedirect />}
            key={i18n.locale}
          />
          <Route path="/upload-print" render={() => <PrintUpload />} />
          <Route
            path="/upload-preparation"
            render={() => <PreparationUpload />}
          />
          <Route path="/materials" render={() => <Materials />} />
          <Route
            exact
            path="/settings"
            render={navProps => <Settings {...navProps.match.params} />}
            key={i18n.locale}
          />
        </Switch>
      </React.Suspense>
    </ErrorBoundary>
  );
}
