import React from 'react';
import styled from 'styled-components';
import LoadingButton from '@mui/lab/LoadingButton';

type Props = React.ComponentProps<typeof LoadingButton> & {
  xsmall?: boolean;
};

/** Custom wrapper for MUI LoadingButton */
const Button = styled(
  // eslint-disable-next-line react/display-name
  React.forwardRef<HTMLButtonElement, Props>(({ xsmall, ...rest }, ref) => (
    <LoadingButton {...rest} ref={ref} />
  ))
)<{
  xsmall?: boolean;
}>`
  ${props =>
    props.xsmall &&
    'padding-left: 0px !important; padding-right: 0px !important;'}
  ${props => props.xsmall && 'min-width: 30px !important;'}

  @media print {
    display: none !important;
  }
`;

export default Button;
