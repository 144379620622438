import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { t } from '@lingui/macro';

import Lock from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpen';
import Close from '@mui/icons-material/Close';
import { Tooltip } from '@mui/material';
import { TextField } from '~inputs/TextField';
import { IconButton } from '~common/inputs/IconButton';

interface Props {
  x: number | null;
  y: number | null;
  onAdjust: (params: { x: number; y: number }) => void; // NOTE This function can be anything
  ratio?: number;
  scale?: number;
  forcedSize?: boolean;
  maximumCropSize?: { x: number; y: number };
  onToggleLock?: (locked: boolean) => void;
  toggleableLock?: boolean;
}

export default function Dimensions({
  scale = 1.0,
  maximumCropSize,
  toggleableLock,
  forcedSize,
  onAdjust,
  onToggleLock,
  ...props
}: Props) {
  const x = props.x && Math.round(props.x * scale);
  const y = props.y && Math.round(props.y * scale);
  const lock = !!props.ratio;

  const handleChange = (event, axis) => {
    if (x === null || y === null) return;
    const val = event.target.value;
    const ratio = lock ? props.ratio : undefined;
    let newX;
    let newY;
    if (axis === 'x') {
      newX = maximumCropSize ? Math.min(val, maximumCropSize.x) : val;
      // Adjust y according to aspect ratio
      newY = ratio ? Math.round(newX / ratio) : y;
    } else {
      newY = maximumCropSize ? Math.min(val, maximumCropSize.y) : val;
      newX = ratio ? Math.round(newY * ratio) : x;
    }
    onAdjust({
      x: newX / scale,
      y: newY / scale,
    });
  };

  const toggleLock = (lock: boolean) => {
    onToggleLock?.(lock);
  };

  return (
    <DimensionsWrapper>
      <Dimension>
        <DimensionInput
          label={t`Width`}
          disabled={forcedSize}
          value={x || ''}
          type="number"
          onChange={event => handleChange(event, 'x')}
        />
      </Dimension>
      <Multiplier>
        <Tooltip title={t`Lock aspect ratio`}>
          <IconButton
            aria-label={lock ? t`Open lock` : t`Lock`}
            isDisabled={!toggleableLock}
            onPress={() => toggleLock(!lock)}
          >
            {lock ? <LockIcon /> : <LockOpenIcon />}
          </IconButton>
        </Tooltip>
        <CrossIcon />
      </Multiplier>
      <Dimension>
        <DimensionInput
          label={t`Height`}
          disabled={forcedSize}
          value={y || ''}
          type="number"
          onChange={event => handleChange(event, 'y')}
        />
      </Dimension>
      <Pixels>
        <FormattedMessage id="file.cropPixels" />
      </Pixels>
    </DimensionsWrapper>
  );
}

const DimensionsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Dimension = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 8px;
`;

const DimensionInput = styled(TextField)`
  max-width: 80px;
`;

const Multiplier = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 8px;
  padding-bottom: 3px;
  padding-top: 1px;
  height: fit-content;
  gap: ${p => p.theme.spacing(1)};
`;

const iconSize = '14px';
const LockIcon = styled(Lock)`
  && {
    // cursor: pointer;
    font-size: ${iconSize};
  }
`;

const LockOpenIcon = styled(LockOpen)`
  && {
    // cursor: pointer;
    font-size: ${iconSize};
  }
`;

const CrossIcon = styled(Close)`
  && {
    font-size: ${iconSize};
  }
`;

const Pixels = styled.span`
  margin-top: auto;
`;
