import axios, {
  AxiosInstance,
  AxiosResponse,
  AxiosError,
  AxiosRequestConfig,
} from 'axios';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { MutationLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

import config from '../config';

import { app } from '~common/app.model';

// TODO: Infer store type from actual store
interface Store {
  dispatch: (action: any) => any;
  getState: () => any;
}

interface Api {
  store: null | Store;
  http: AxiosInstance;
}

const httpClient = axios.create({
  baseURL: config.API_URL,
  withCredentials: true,
}) as AxiosInstance;

const api: Api = {
  store: null,
  http: httpClient,
};

/**
 * A BaseQuery generator to be used with Redux Toolkit Query, based on an
 * example from the official docs:
 * https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#axios-basequery
 *
 * Wraps the same Axios instance used elsewhere in the app, so that we can rely
 * on it handling the authentication of requests for us.
 */
export const axiosBaseQuery =
  <Method extends AxiosRequestConfig['method']>(
    { baseURL }: { baseURL: string } = { baseURL: config.API_URL }
  ): BaseQueryFn<
    {
      url: string;
      method: Method;
      data?: AxiosRequestConfig['data'];
      params?: object | URLSearchParams;
      headers?: AxiosRequestConfig['headers'];
      onUploadProgress?: AxiosRequestConfig['onUploadProgress'];
      baseURL?: string;
      /** Adds the customerId on url so that the Api can determine the active customer.
       * Assumes that the customer is already available */
      includeCustomerId?: boolean;
    },
    unknown,
    {
      status?: number;
      data?: any;
    },
    {},
    { headers: AxiosResponse<Method>['headers'] }
  > =>
  async ({ url, includeCustomerId, ...rest }, { signal, getState }) => {
    try {
      if (includeCustomerId && !url.includes('customerId=')) {
        const customerId = (getState() as any).app.customer?.id;
        url = `${url}${url.includes('?') ? '&' : '?'}customerId=${customerId}`;
      }
      const result = await httpClient({ baseURL, url, signal, ...rest });
      return { data: result.data, meta: { headers: result.headers } };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data,
        },
        meta: { headers: err.response?.headers ?? {} },
      };
    }
  };

/** Generic helper function for displaying a success and an error message
 * after a query has ran.
 *
 * @example
 *
 * ...
 * // We have to inline this function, as otherwise
 * // the Lingui `t` will get stale current language
 * // due to a closure
 * onQueryStarted: (_, args) =>
 *   afterQueryMessages(
 *     t`File saved`,
 *     t`Save failed`,
 *     args
 *   ),
 * ...
 */
export async function afterQueryMessages(
  successMessage: string,
  errorMessage: string,
  { dispatch, queryFulfilled }: MutationLifecycleApi<any, any, any>,
  options?: {
    /** A callback to be run after a successful query */
    onSuccess?: () => void;
  }
) {
  try {
    await queryFulfilled;
    dispatch(app.actions.showInfoMessage(successMessage));
    options?.onSuccess?.();
  } catch {
    dispatch(app.actions.showErrorMessage(errorMessage));
  }
}

export const connectApiToStore = (store: Store) => {
  api.store = store;
};

// Interceptors ==============================================================
api.http.interceptors.response.use(
  (response: AxiosResponse) => {
    console.log('> API response', response);
    return response;
  },
  (error: AxiosError) => {
    const { response } = error;

    if (!response) {
      console.log('> Network error', response);
      return Promise.reject(error);
    }

    console.log('> API response error', response);

    if (
      [401].includes(response.status) &&
      api.store &&
      !window.location.pathname.includes('/registration') &&
      !window.location.pathname.includes('/set_password') &&
      !window.location.pathname.includes('/invite') &&
      // When browsing a public share, we should login only
      // once a product is opened
      (!config.shareKey ||
        !response.config ||
        !response.config.url ||
        response.config.url.indexOf('/templates') !== -1 ||
        response.config.url.indexOf('/products') !== -1)
    ) {
      api.store.dispatch({ type: 'APP/LOGIN' });
    }

    // Axios wraps errors returned by the api inside `response.data`
    return Promise.reject(error);
  }
);

// URL manipulation

export const toAbsoluteUrl = (url: string) => {
  return `${config.BASE_URL}${url}`;
};

export const addProtocol = (value: string) =>
  value.match(/^https?:\/\//) ? value : `http://${value}`;

export const getLocalizedErrorMessage = (
  e: Record<string, string> | undefined
): string | null => {
  if (!e) return null;
  const reduxState = api.store?.getState();
  const language = reduxState.app.settings.language;
  const defaultLanguage = reduxState.app.customer.defaultLanguage;

  let localizedKey;
  localizedKey = Object.keys(e).find(k => k === `message_${language}`);

  if (!localizedKey) {
    localizedKey = Object.keys(e).find(k => k === `message_${defaultLanguage}`);
  }
  return localizedKey ? e[localizedKey] : null;
};

export default api;
