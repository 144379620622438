import styled from 'styled-components';

const ActionBar = styled.div`
  flex-grow: 1;
  text-align: right;
  white-space: nowrap;

  button {
    margin-right: ${props => props.theme.spacing(1)};
  }

  & > button:last-child {
    margin-right: 0;
  }

  @media print {
    display: none !important;
  }
`;

export default ActionBar;
