import React from 'react';
import { t } from '@lingui/macro';

import { content } from '../model';
import { useContentMode } from '../common/utils';
import { getIsProductPublished } from '~common/utils/product.utils';
import { File } from '~common/content.types';
import { useActions } from '~common/utils/hooks.utils';
import { useRedirectTo } from '~common/navigation/useRedirectTo';
import { ProductIcon } from '~common/misc/icons';
import { BasicActionOption } from '~common/navigation/ActionOptionButton';

export function useCustomizeProductOption(
  file: File
): BasicActionOption<string> | null {
  const { mode } = useContentMode();

  const createProduct = useActions(content.actions.createProduct);
  const redirectTo = useRedirectTo();

  if (!file.isMasterProduct && !file.isUserProduct) return null;

  return {
    value: file.isMasterProduct
      ? 'navigate-to-new-product'
      : 'navigate-to-product',
    title: t`Customize`,
    icon: <ProductIcon />,
    disabled: !getIsProductPublished(file),
    hidden: !file?.userRights?.customize,
    onSelect: file.isMasterProduct
      ? () => {
          createProduct(file.propertiesById['nibo:master-product-id']);
        }
      : () => {
          if (mode === 'workspaceShared') {
            createProduct(file.propertiesById['nibo:user-product-id'], true);
          } else {
            redirectTo(
              `/products/${file.propertiesById['nibo:user-product-id']}`
            );
          }
        },
  };
}
