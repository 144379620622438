import { t, Trans } from '@lingui/macro';
import { debounce, isSet } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { TablePagination } from '@mui/material';
import { useSelector } from 'react-redux';
import NewsActionsButton from './NewsActionsButton';

import { GetNewsParams, NewsOrderField, useGetAllNewsQuery } from './api';
import { CommonNewsListItem } from './types';
import Button from '~common/inputs/Button';
import { TextField } from '~common/inputs/TextField';
import Row from '~common/layout/Row';
import { AddIcon, EditIcon } from '~common/misc/icons';
import ModalButton from '~common/navigation/ModalButton';
import { ColumnDescriptor, DataTable } from '~common/tables/DataTable';
import { SortableHeaderElement } from '~common/tables/SortableHeaderElement';
import { useSearchParams } from '~common/utils/browser.utils';
import { getDateTimeString } from '~common/utils/date.utils';
import Page from '~sections/Page';
import { getLangValue } from '~common/app.utils';

function useQueryFilters() {
  const [searchParams, setSearchParams] = useSearchParams<SearchQueryParams>();

  const queryFilters = useMemo(() => {
    const { page, status, order, groupId, filter } = searchParams;
    return {
      page: parseInt(page ?? '0') ?? 0,
      pageSize: 200,
      order: order ?? 'created_desc',
      filter,
      groupId,
      status,
      all: status === 'management' ? ('true' as const) : undefined,
    };
  }, [searchParams]);

  return [queryFilters, setSearchParams] as const;
}

type SearchQueryParams = {
  [key in keyof GetNewsParams]:
    | (GetNewsParams[key] extends string | undefined
        ? GetNewsParams[key]
        : string)
    | undefined;
};

const NewsManagementPage: React.FC = () => {
  const [checkedNewsRows, setCheckedNewsRows] = useState<Set<string> | 'all'>(
    new Set()
  );
  const [queryFilters, setSearchParams] = useQueryFilters();

  const {
    data: newsData,
    isFetching: newsFetching,
    error: newsError,
  } = useGetAllNewsQuery(queryFilters);
  const news = newsData ?? [];
  const totalCount = news.length;

  const userRights = useSelector(state => state.app.userRights);

  const [isFilterEnabled, setFilterEnabled] = useState(false);
  const [filterText, setFilterText] = useState(queryFilters.filter ?? '');

  const setFilters = useCallback(
    debounce((filters: Partial<SearchQueryParams>) => {
      setSearchParams(params => ({ ...params, ...filters, page: undefined }));
    }, 500),
    [setSearchParams]
  );
  useEffect(() => {
    const filters: Partial<SearchQueryParams> = {
      filter: filterText || undefined,
    };
    setFilters(filters);
  }, [filterText]);

  const handlePageChange = (event, page: number) => {
    setSearchParams(params => ({ ...params, page: `${page}` }));
  };

  useEffect(() => {
    setCheckedNewsRows(new Set());
  }, [news.length]);

  const columns: ColumnDescriptor<CommonNewsListItem>[] = [
    {
      name: t`Title`,
      dataAccessor: x => getLangValue(x.object?.title) ?? '',
      headerElement: <SortHeader orderField="title">{t`Title`}</SortHeader>,
      width: '25%',
    },
    {
      name: t`Description`,
      dataAccessor: x => getLangValue(x.object?.description) ?? '',
      width: '25%',
    },
    {
      name: t`Groups`,
      dataAccessor: x => {
        return x.object?.groups?.map(g => g.name).join(', ') ?? '';
      },
      width: '20%',
    },
    {
      name: t`Stay on top`,
      dataAccessor: x => (x.object?.stayOnTop ? 'X' : ''),
      width: '10%',
    },
    {
      name: t`Show in popup`,
      dataAccessor: x => (x.object?.showInPopUp ? 'X' : ''),
      width: '10%',
    },
    {
      name: t`Public from`,
      dataAccessor: x => (x.publicFrom ? getDateTimeString(x.publicFrom) : ''),
      headerElement: (
        <SortHeader orderField="public_from">{t`Public from`}</SortHeader>
      ),
    },
    {
      name: t`Public until`,
      dataAccessor: x =>
        x.publicUntil ? getDateTimeString(x.publicUntil) : '',
      headerElement: (
        <SortHeader orderField="public_until">{t`Public until`}</SortHeader>
      ),
    },
    {
      name: t`Created`,
      dataAccessor: x => (x.created ? getDateTimeString(x.created) : ''),
      headerElement: <SortHeader orderField="created">{t`Created`}</SortHeader>,
    },
    {
      name: t`Actions`,
      renderer: userRights?.NEWS_MODIFY ? ActionButton : undefined,
      stopRendererPropagation: true,
      align: 'right',
      headerElement: !(isSet(checkedNewsRows) && checkedNewsRows.size === 0) ? (
        <NewsActionsButton
          news={news}
          selectedItems={
            checkedNewsRows === 'all'
              ? (news ?? []).map(news => news.id)
              : [...checkedNewsRows]
          }
        />
      ) : undefined,
    },
  ];

  return (
    <Page>
      <Helmet>
        <title>{t`News management`}</title>
      </Helmet>
      <ContentWrapper>
        <ButtonWrapper>
          <Button
            variant="outlined"
            onClick={() => {
              setFilterEnabled(!isFilterEnabled);
              setFilterText('');
            }}
          >
            {isFilterEnabled ? (
              <Trans>Hide filter</Trans>
            ) : (
              <Trans>Show filter</Trans>
            )}
          </Button>
          <ModalButton
            variant="contained"
            modalType="NEWS/EDIT"
            startIcon={<AddIcon />}
          >
            <Trans>Create new news</Trans>
          </ModalButton>
        </ButtonWrapper>
        {isFilterEnabled && (
          <FilterRow>
            <FilterTextField
              id="filter-user"
              label={t`Filter`}
              value={filterText}
              onChange={e => setFilterText(e.target.value)}
            />
          </FilterRow>
        )}
        {newsError ? (
          'error'
        ) : (
          <DataTableWrapper>
            <StyledDataTable
              label={t`News`}
              columns={columns}
              rows={news ?? []}
              selectionMode="multiple"
              selectedKeys={checkedNewsRows}
              onSelectionChange={setCheckedNewsRows}
              outlined={false}
              loading={newsFetching}
            />
            {/* TODO: Move pagination to DataTable.tsx */}
            {totalCount !== undefined && (
              <StyledTablePagination
                count={totalCount}
                onPageChange={handlePageChange}
                page={queryFilters.page}
                rowsPerPage={queryFilters.pageSize}
                rowsPerPageOptions={[]}
              />
            )}
          </DataTableWrapper>
        )}
      </ContentWrapper>
    </Page>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${p => p.theme.spacing(3)};
  gap: ${p => p.theme.spacing(4)};

  @media (max-width: 640px) {
    padding-left: ${({ theme }) => theme.spacing(1)};
    padding-right: ${({ theme }) => theme.spacing(1)};
    gap: ${p => p.theme.spacing(2)};
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: ${p => p.theme.spacing(2)};

  @media screen and (max-width: 640px) {
    justify-content: center;
    flex-wrap: wrap-reverse;
  }
`;

const FilterRow = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: ${p => p.theme.spacing(2)};
  flex-wrap: wrap;
`;

const FilterTextField = styled(TextField)`
  min-width: 250px;
`;

const DataTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${p => p.theme.palette.common.white};
  padding: ${p => p.theme.spacing(2)};
  padding-top: ${p => p.theme.spacing(0.5)};
  overflow-x: auto;
  max-width: 100%;
`;

const StyledTablePagination = styled(TablePagination)`
  border-bottom: none;
  align-self: flex-end;
`;

const StyledDataTable = styled(DataTable)`
  height: auto;
` as typeof DataTable;

interface SortHeaderProps {
  orderField: NewsOrderField;
}

const SortHeader: React.FC<SortHeaderProps> = ({ children, orderField }) => {
  /**
   * TODO refactor SortHeader to src common tables SortHeader
   * UserManagementPage and NewsManagementPage contain similar functionality
   */
  const [{ order }, setSearchParams] = useQueryFilters();

  const onChange = (value: NewsOrderField) => {
    setSearchParams(params => ({ ...params, order: value }));
  };

  return (
    <SortableHeaderElement
      sortBy={order}
      ascSortValue={orderField}
      descSortValue={`${orderField}_desc`}
      onChange={onChange}
    >
      {children}
    </SortableHeaderElement>
  );
};

const ActionButton = ({ item }: { item: CommonNewsListItem }) => {
  return (
    <ModalButton
      variant="outlined"
      modalType="NEWS/EDIT"
      aria-label={t`News edit`}
      isIconButton
      modalProps={{
        id: item.id,
      }}
    >
      <EditIcon />
    </ModalButton>
  );
};

export default NewsManagementPage;
