import { PayloadAction } from '@reduxjs/toolkit';
import { findIndex, remove } from 'lodash';

import {
  Card,
  CardItem,
  HazardFrontPageSettings,
  Link,
  LinkGroupMode,
  LinkItem,
} from './types';
import { convertToTranslatedObject, getGroupChangesKey } from './utils';
import { createSlice } from '~utils/ducks.utils';

export type FrontpageSettingsState = {
  cards: Card[];
  links: Link[];
  /** List of selected group IDs */
  previewGroups: number[];
  /**
   * Arrays of group rights IDs which the user has modified.
   * We store them in a separate object, because we want to delay showing
   * group changes to user; they might've strict filters for the current
   * list of groups, and if they were to change an item's rights so that
   * it wouldn't be visible anymore we don't want to just suddenly make
   * the item disappear from the list, instead we require them to confirm
   * the changes with an additional button press.
   */
  unappliedGroupChanges: { [itemId: string]: number[] | undefined };
  linkGroupMode?: LinkGroupMode;
  /** Mapping of input elements to error messages.
   * The keys are of form `<panel_type>.<panel_id>.<input_key>`
   * if the error is in a link or card input element,
   * or `<panel_type>.<panel_id>.<item_id>.<input_key>`
   * if the error is in card/link item.
   * Some examples for keys: `link.2.image`, `link.2.1.text`, `card.1.1.url`.
   * Use the `utils.ts getErrorMessageKey` to get the keys. */
  errorMessages: Record<string, string>;
};

const initialState: FrontpageSettingsState = {
  links: [],
  cards: [],
  previewGroups: [],
  unappliedGroupChanges: {},
  errorMessages: {},
};

type ItemPayload<T> = { parentId: number; newItem: T };
type ChildIdPayload = { parentId: number; id: number };
type ChildIndexPayload = { parentId: number; index: number };

type GroupChangePayload = {
  itemType: 'card' | 'link';
  itemId: number;
  parentId?: number;
  groupId: number;
};
type GroupSetPayload = {
  itemType: 'card' | 'link';
  itemId: number;
  parentId?: number;
  groupIds: number[];
};

/**
 * Moves item targeted by `id` for `delta` steps, returning a new array
 */
function moveItem<T>(arr: T[], index: number, delta: -1 | 1) {
  const newIndex = index + delta;
  if (newIndex < 0 || newIndex === arr.length) return arr;
  const element = arr[index];
  arr.splice(index, 1);
  arr.splice(newIndex, 0, element);
  return [...arr];
}

const slice = createSlice({
  name: 'frontPageSettings',
  initialState,
  reducers: {
    setData: (
      state,
      {
        payload: { data, languages },
      }: PayloadAction<{
        data: Omit<FrontpageSettingsState, 'cards' | 'items'> & {
          cards: HazardFrontPageSettings['cards'];
          links: HazardFrontPageSettings['links'];
        };
        languages: string[];
      }>
    ) => {
      const newState = {
        ...data,
        // Cards nor links have no ID fields whatsoever so we just use their
        // relative indices in the incoming data
        // also, convert the input values to translated objects
        cards: data.cards?.map(({ items, ...card }, i) => {
          const cardBasics = {
            name: convertToTranslatedObject(card.name ?? '', languages),
            id: i,
          };
          const type = card.type.toLocaleUpperCase() as Uppercase<
            typeof card.type
          >;
          if (type === 'STATIC')
            return {
              ...card,
              ...cardBasics,
              type,
              url: convertToTranslatedObject(card.url ?? '', languages),
              items: items?.map((cardItem, idx) => ({
                ...cardItem,
                url: convertToTranslatedObject(cardItem.url, languages),
                name: convertToTranslatedObject(cardItem.name, languages),
                id: idx,
              })),
            };
          if (type === 'LATEST')
            return {
              ...card,
              ...cardBasics,
              type,
            };
          return {
            ...card,
            ...cardBasics,
            type,
          };
        }),
        links: data.links?.map((link, i) => ({
          ...link,
          items: link.items?.map((linkItem, idx) => ({
            ...linkItem,
            url: convertToTranslatedObject(linkItem.url, languages),
            name: convertToTranslatedObject(linkItem.name, languages),
            id: idx,
          })),
          url: convertToTranslatedObject(link.url, languages),
          name: convertToTranslatedObject(link.name, languages),
          image: convertToTranslatedObject(link.image ?? '', languages),
          id: i,
        })),
      };
      return {
        ...state,
        ...newState,
      };
    },

    // Links
    addLink: (state, action: PayloadAction<Link>) => {
      state.links.push(action.payload);
    },
    updateLink: (state, action: PayloadAction<Link>) => {
      const i = findIndex(state.links, { id: action.payload.id });
      state.links[i] = action.payload;
    },
    deleteLink: (state, action: PayloadAction<number>) => {
      remove(state.links, { id: action.payload });
    },
    moveLinkUp: (state, action: PayloadAction<number>) => {
      moveItem(state.links, action.payload, -1);
    },
    moveLinkDown: (state, action: PayloadAction<number>) => {
      moveItem(state.links, action.payload, 1);
    },

    // Link items
    addLinkItem: (state, action: PayloadAction<ItemPayload<LinkItem>>) => {
      const link = state.links.find(l => l.id === action.payload.parentId);
      if (link) {
        if (link.items) link.items.push(action.payload.newItem);
        else link.items = [action.payload.newItem];
      }
    },
    updateLinkItem: (state, action: PayloadAction<ItemPayload<LinkItem>>) => {
      const link = state.links.find(l => l.id === action.payload.parentId);
      const childLink = link?.items?.find(
        l => l.id === action.payload.newItem.id
      );
      if (childLink) Object.assign(childLink, action.payload.newItem);
    },
    deleteLinkItem: (state, action: PayloadAction<ChildIdPayload>) => {
      const link = state.links.find(l => l.id === action.payload.parentId);
      if (link?.items) remove(link.items, { id: action.payload.id });
    },
    moveLinkItemUp: (state, action: PayloadAction<ChildIndexPayload>) => {
      const link = state.links.find(l => l.id === action.payload.parentId);
      if (link?.items) moveItem(link.items, action.payload.index, -1);
    },
    moveLinkItemDown: (state, action: PayloadAction<ChildIndexPayload>) => {
      const link = state.links.find(l => l.id === action.payload.parentId);
      if (link?.items) moveItem(link.items, action.payload.index, 1);
    },

    // Cards
    addCard: (state, action: PayloadAction<Card>) => {
      state.cards.push(action.payload);
    },
    updateCard: (state, action: PayloadAction<Card>) => {
      const i = findIndex(state.cards, { id: action.payload.id });
      state.cards[i] = action.payload;
    },
    deleteCard: (state, action: PayloadAction<number>) => {
      remove(state.cards, { id: action.payload });
    },
    moveCardUp: (state, action: PayloadAction<number>) => {
      moveItem(state.cards, action.payload, -1);
    },
    moveCardDown: (state, action: PayloadAction<number>) => {
      moveItem(state.cards, action.payload, 1);
    },

    // Card items
    addCardItem: (state, action: PayloadAction<ItemPayload<CardItem>>) => {
      const card = state.cards.find(c => c.id === action.payload.parentId);
      if (card && card.type === 'STATIC') {
        if (card.items) card.items.push(action.payload.newItem);
        else card.items = [action.payload.newItem];
      }
    },
    updateCardItem: (state, action: PayloadAction<ItemPayload<CardItem>>) => {
      const card = state.cards.find(c => c.id === action.payload.parentId);
      if (card && card.type === 'STATIC') {
        const childCard = card.items?.find(
          c => c.id === action.payload.newItem.id
        );
        if (childCard) Object.assign(childCard, action.payload.newItem);
      }
    },
    deleteCardItem: (state, action: PayloadAction<ChildIdPayload>) => {
      const card = state.cards.find(c => c.id === action.payload.parentId);
      if (card && card.type === 'STATIC' && card.items)
        remove(card.items, { id: action.payload.id });
    },
    moveCardItemUp: (state, action: PayloadAction<ChildIndexPayload>) => {
      const card = state.cards.find(c => c.id === action.payload.parentId);
      if (card && card.type === 'STATIC' && card.items)
        moveItem(card.items, action.payload.index, -1);
    },
    moveCardItemDown: (state, action: PayloadAction<ChildIndexPayload>) => {
      const card = state.cards.find(c => c.id === action.payload.parentId);
      if (card && card.type === 'STATIC' && card.items)
        moveItem(card.items, action.payload.index, 1);
    },

    // Preview groups
    addPreviewGroup: (state, action: PayloadAction<number>) => {
      if (Number.isInteger(action.payload))
        state.previewGroups.push(action.payload);
    },
    removePreviewGroup: (state, action: PayloadAction<number>) => {
      state.previewGroups = state.previewGroups.filter(
        groupId => groupId !== action.payload
      );
    },
    resetPreviewGroups: state => {
      state.previewGroups = [];
    },

    // Group changes
    setChangedGroups: (state, action: PayloadAction<GroupSetPayload>) => {
      const { itemType, itemId, parentId, groupIds } = action.payload;
      const key = getGroupChangesKey(itemType, itemId, parentId);
      state.unappliedGroupChanges[key] = groupIds;
    },
    addGroupToItem: (state, action: PayloadAction<GroupChangePayload>) => {
      const { itemType, itemId, parentId, groupId } = action.payload;
      const key = getGroupChangesKey(itemType, itemId, parentId);

      if (Array.isArray(state.unappliedGroupChanges[key])) {
        (state.unappliedGroupChanges[key] as number[]).push(groupId);
      } else {
        state.unappliedGroupChanges[key] = [groupId];
      }
    },
    removeGroupFromItem: (state, action: PayloadAction<GroupChangePayload>) => {
      const { itemType, itemId, parentId, groupId } = action.payload;
      const key = getGroupChangesKey(itemType, itemId, parentId);

      state.unappliedGroupChanges[key] = state.unappliedGroupChanges[
        key
      ]?.filter(group => group !== groupId);

      if (state.unappliedGroupChanges[key]?.length === 0) {
        // We don't want to save empty arrays, as backend doesn't handle
        // them properly
        delete state.unappliedGroupChanges[key];
      }
    },
    resetGroupChangesForItem: (
      state,
      action: PayloadAction<Omit<GroupChangePayload, 'groupId'>>
    ) => {
      const { itemType, itemId, parentId } = action.payload;
      const key = getGroupChangesKey(itemType, itemId, parentId);
      delete state.unappliedGroupChanges[key];
    },

    // Errors
    setErrorMessages: (
      state,
      action: PayloadAction<FrontpageSettingsState['errorMessages']>
    ) => ({
      ...state,
      errorMessages: action.payload,
    }),
  },
});

export default slice.reducer;

// Bundle things in a model
export const frontpageSettings = {
  actions: slice.actions,
  selector: {},
};
