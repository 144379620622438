import React from 'react';
import styled from 'styled-components';

interface Props extends Omit<React.HTMLProps<HTMLDivElement>, 'wrap'> {
  children: any;
  position?: 'default' | 'bottom' | 'bottomFixed';
  variant?: 'default' | 'toolbar' | 'borderless';
  padding?: number;
  paddingHoriz?: number;
  align?: string;
  wrap?: boolean;
  theme?: any;
}

const firstDefined = (...values) => {
  return values.find(value => value !== undefined);
};

const Bar = styled(
  // eslint-disable-next-line react/display-name
  React.forwardRef<HTMLDivElement, Props>(
    (
      { position, variant, padding, paddingHoriz, align, wrap, ...props },
      ref
    ) => <div {...props} ref={ref} />
  )
)`
  display: flex;
  flex-grow: 0;
  gap: ${props => props.theme.spacing(1)};
  align-items: center;
  white-space: ${props => (props.wrap ? 'normal' : 'nowrap')};

  min-height: calc(
    41px + ${({ theme, padding }) => theme.spacing(firstDefined(padding, 2))}*2
  );
  justify-content: ${props => props.align || 'left'};
  padding: ${({ theme, padding }) => theme.spacing(firstDefined(padding, 2))}
    ${({ theme, padding, paddingHoriz }) =>
      theme.spacing(firstDefined(paddingHoriz, padding, 3))};

  border-top: ${({ position, variant, theme }: Props) =>
    (position === 'bottom' || position === 'bottomFixed') &&
    variant !== 'borderless'
      ? `1px solid ${theme.palette.border.section}`
      : 'none'};
  border-bottom: ${({ position, variant, theme }: Props) =>
    position !== 'bottom' &&
    position !== 'bottomFixed' &&
    variant !== 'borderless'
      ? `1px solid ${theme.palette.border.section}`
      : 'none'};

  ${({ position, theme, padding }) =>
    position === 'bottomFixed' &&
    `
      /* Fixed positioning to bottom of the screen */
      position: fixed; left: 0; bottom: 0; width: 100%;

      /* Add additional bottom padding on mobile */
      @media screen and (max-width: 1366px) {
        padding-bottom:
          calc(${theme.spacing(firstDefined(padding, 2))} + 6px);
      }
    `}

  @media screen and (max-width: 1199px) {
    padding-left: ${({ theme, padding, paddingHoriz }) =>
      theme.spacing(Math.min(firstDefined(paddingHoriz, padding, 2), 2))};
    padding-right: ${({ theme, padding, paddingHoriz }) =>
      theme.spacing(Math.min(firstDefined(paddingHoriz, padding, 2), 2))};
  }

  ${({ variant, theme }: Props) =>
    variant === 'toolbar'
      ? `background-color: ${theme.palette.common.white};`
      : ''}
  ${({ variant }: Props) =>
    variant === 'borderless'
      ? `background-color: transparent; border-color: transparent;`
      : ''}

  @media print {
    display: none !important;
  }
`;

export default Bar;
