import { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { t } from '@lingui/macro';
import { skipToken } from '@reduxjs/toolkit/query/react';

import { commentWorkflowType, orderWorkflowType } from './constants';
import {
  WorkflowData,
  WorkflowProcess,
  WorkflowSettings,
  WorkflowSettingsBase,
} from './types';
import { getWorkflow, useGetWorkflowQuery } from './api';
import { getLangValue } from '~common/app.utils';
import { commonContent } from '~common/content.model';
import { File, fileExists, WorkflowSettingsById } from '~common/content.types';
import { useGetFileQueries } from '~common/content.api';
import { putResolve } from '~common/utils/saga.utils';

/** @deprecated use useWorkflowSelectionEffect instead */
export const usePrepareContent = (
  selectionsById: { [key: string]: { workflowId?: string | null } },
  selectWorkflow: (fileId: string, workflowId: string) => void,
  workflowFilter: (workflow: WorkflowSettings) => boolean,
  onSkipWorkflowSelection: () => void
) => {
  const [isLoading, setLoading] = useState(
    Object.keys(selectionsById).length > 0
  );

  // control dispatching readFile with this state
  // fixes bug where usePrepareContent would trigger 200+ identical loads on page load
  // TODO: better fix
  const [loadingFiles, setLoadingFiles] = useState(false);

  const dispatch = useDispatch();

  const filesById = useSelector(state => state.commonContent.filesById);
  const workflowsById = useSelector(state => state.commonContent.workflowsById);

  // make sure everything is loaded
  useEffect(() => {
    if (!isLoading) return;
    let allLoaded = true;
    // Make sure all of the files are fetched with sufficient data before we continue
    for (const fileId of Object.keys(selectionsById)) {
      if (
        !filesById ||
        !(fileId in filesById) ||
        !filesById[fileId]?.file?.workflows
      ) {
        if (filesById[fileId]?.status !== 'loading' && !loadingFiles) {
          dispatch(commonContent.actions.readFile(fileId));
          setLoadingFiles(true);
        }
        allLoaded = false;
      }
    }
    // all files are loaded, start fetching workflows
    let workflowIdsToFetch = [] as string[];
    if (allLoaded) {
      workflowIdsToFetch = Object.values(selectionsById)
        .map(selection => selection.workflowId)
        .filter(id => !!id) as string[];
      Object.keys(selectionsById).forEach(fileId => {
        const file = filesById[fileId]?.file;
        (file?.workflows || []).forEach(workflow => {
          if (!selectionsById[workflow.id]) {
            workflowIdsToFetch.push(workflow.id.toString());
          }
        });
      });
      // remove duplicates
      [...new Set(workflowIdsToFetch)]
        .filter(id => !workflowsById[id])
        .forEach(id => dispatch(commonContent.actions.fetchWorkflow(id)));
    }
    // all files are loaded, select the default workflows
    if (allLoaded) {
      Object.keys(selectionsById).forEach(fileId => {
        const item = selectionsById[fileId];
        if (item.workflowId) return;
        const file = filesById[fileId]?.file;
        const filteredWorkflows = (file?.workflows || [])
          .filter(workflowFilter)
          .sort((a, b) =>
            (getLangValue(a.names) || '').localeCompare(getLangValue(b.names))
          );
        // preselect the first workflow
        if (filteredWorkflows.length > 0) {
          allLoaded = false;
          selectWorkflow(fileId, `${filteredWorkflows[0].id}`);
        }
      });
    }
    // Wait for workflows to load
    if (allLoaded) {
      if (
        !workflowIdsToFetch.every(
          id => workflowsById[id] && workflowsById[id]?.workflow
        )
      )
        allLoaded = false;
    }
    // all files loaded and all default workflows selected,
    // advance progress if all the items have the same default workflow and only one available option
    if (allLoaded) {
      let workflowId = 0 as string | number;
      const skipWorkflowSelection = Object.keys(selectionsById).every(
        fileId => {
          const item = selectionsById[fileId];
          if (!workflowId) workflowId = item.workflowId as string;
          const workflows = (filesById[fileId]?.file?.workflows || []).filter(
            workflowFilter
          );
          return (
            !!item.workflowId &&
            workflowId === item.workflowId &&
            workflows.length === 1
          );
        }
      );
      if (!!workflowId && skipWorkflowSelection) {
        if (workflowsById[workflowId] && workflowsById[workflowId]?.workflow) {
          onSkipWorkflowSelection();
        } else allLoaded = false;
      }
    }
    setLoading(!allLoaded);
  }, [filesById, selectionsById, workflowsById, isLoading]);

  return isLoading;
};

/** Selects the default workflows for all of the selected items
 * and advances to the next step if all of the items have only one workflow
 * available and it's the same for all of the items.
 */
export function useWorkflowSelectionEffect(
  selectionsById: { [key: string]: { workflowId?: string | null } },
  selectWorkflow: (fileId: string, workflowId: string | null) => void,
  workflowFilter: (workflow: WorkflowSettings) => boolean,
  workflowProcess: WorkflowProcess,
  startWorkflowProcess: () => void
) {
  const fileArgs = useMemo(
    () => Object.keys(selectionsById).map(id => ({ id })),
    [Object.keys(selectionsById).join('-')]
  );
  const { data: filesData, isLoading: filesLoading } =
    useGetFileQueries(fileArgs);
  const files = useMemo(() => filesData?.filter(fileExists), [filesData]);

  const skippingWorkflowSelection = useRef(false);

  useEffect(() => {
    // Wait until all files are loaded
    if (!files || filesLoading) return;

    const workflowsByFile = files.reduce(
      (acc, file) => ({
        ...acc,
        [file.node.id]: (file.workflows || [])
          .filter(workflowFilter)
          .sort((a, b) =>
            (getLangValue(a.names) || '').localeCompare(getLangValue(b.names))
          ),
      }),
      {} as Record<string, WorkflowSettingsBase[]>
    );

    // Select default workflows
    Object.entries(workflowsByFile).forEach(([fileId, workflows]) => {
      selectWorkflow(
        fileId,
        workflows.length > 0 ? workflows[0].id.toString() : null
      );
    });

    // Skip workflow selection
    skippingWorkflowSelection.current = skipWorkflowSelection(
      files,
      workflowFilter
    );

    if (skippingWorkflowSelection.current) {
      !workflowProcess.workflowId &&
        workflowProcess.step === null &&
        startWorkflowProcess();
    }
  }, [files, filesLoading]);

  const isLoading =
    filesLoading ||
    // Some default workflows are not yet selected
    (files &&
      files.some(
        file => selectionsById[file.node.id]?.workflowId === undefined
      )) ||
    // We are skipping selection but redux is not yet up to date
    (skippingWorkflowSelection.current && workflowProcess.step === null);

  return { isLoading };
}

/** @deprecated use getInitialDataByWorkflowId instead */
export const initializeWorkflowData = (
  selectionsById: {
    [id: string]: { workflowId?: string | null };
  },
  workflowsById: WorkflowSettingsById,
  userLanguage: string
) => {
  const workflowIds = [
    ...new Set(
      Object.values(selectionsById)
        .map((x: any) => x && x.workflowId)
        .filter(x => !!x && x !== '0')
    ),
  ];
  if (workflowIds.length === 0) return null;
  return workflowIds.reduce((a, c) => {
    const workflow = workflowsById[c]?.workflow;
    if (!workflow) return a;
    a[c] = getInitialWorkflowData(workflow, userLanguage);
    return a;
  }, {});
};

/** Constructs the dataByWorkflowId object for all of the selected workflows
 * initialized with the default values.
 */
export function* getInitialDataByWorkflowId<Data extends WorkflowData>(
  selectionsById: {
    [id: string]: { workflowId?: string | null };
  },
  userLanguage: string
) {
  const selectedWorkflows = new Set(
    Object.values(selectionsById)
      .map(x => x?.workflowId)
      .filter((x): x is string => !!x && x !== '0')
  );
  if (!selectedWorkflows.size) return null;

  const dataByWorkflowId: Record<string, Data> = {};
  for (const workflowId of selectedWorkflows) {
    const { data: workflow } = yield* putResolve(
      getWorkflow.initiate({ id: workflowId })
    );
    // This should be always available here so this is here just for type safety
    if (workflow) {
      dataByWorkflowId[workflowId] = getInitialWorkflowData(
        workflow,
        userLanguage
      ) as unknown as Data;
    }
  }

  return dataByWorkflowId;
}

function getInitialWorkflowData(
  workflow: WorkflowSettings,
  userLanguage: string
) {
  return workflow.fields
    .filter(field => field.valueType !== 'VALUE_TYPE_SEPARATOR')
    .reduce(
      (a2, c2) => {
        a2[c2.id] =
          c2.valueType !== 'VALUE_TYPE_DROPDOWN'
            ? getLangValue(
                c2.localizedTranslatedDefaultValues || c2.localizedDefaultValues
              ) || null
            : '';
        return a2;
      },
      {
        name: getLangValue(workflow.defaultOrderNames) || '',
        title: getLangValue(workflow.defaultOrderTitles) || '',
        language: userLanguage,
        emailRecipients: workflow.additionalRecipients,
        messageReply: workflow.messageReplyTo,
        recipients: workflow.lockRecipients
          ? workflow.recipients
              ?.filter(recipient => recipient.parentId !== 'null')
              .map(x => x.id)
          : undefined,
        fyiRecipients: workflow.fyiRecipients,
        approvers: !workflow.allowApproverSelection
          ? workflow.approverGroup?.members?.map(x => x.id)
          : undefined,
      }
    );
}

/** Fetches the files that are selected to be included in a workflow.
 * If the optional process is defined and some workflow is in progress,
 * will only return files that are selected to be included in that workflow
 */
export function useWorkflowFiles(
  selectionById: Record<string, { workflowId?: string | null }>,
  process?: WorkflowProcess
) {
  const fileArgs = useMemo(
    () =>
      Object.entries(selectionById)
        .filter(
          ([_, data]) =>
            !process?.workflowId || data.workflowId === process.workflowId
        )
        .map(([id]) => ({ id })),
    [selectionById, process]
  );

  const { data: fileData } = useGetFileQueries(fileArgs);
  return { data: fileData?.filter(fileExists) };
}

export const skipContentsView = (
  workflow: WorkflowSettings,
  workflowProcess: WorkflowProcess
) => {
  return (
    (workflow.type === orderWorkflowType &&
      !workflow.askProductAmount &&
      !workflow.askProductOrderCategory) ||
    (workflow.type === commentWorkflowType && !workflowProcess.originalOrderId)
  );
};

export const skipWorkflowSelection = (
  files: File[],
  workflowFilter: (workflow: WorkflowSettings) => boolean
) => {
  const singleWorkflowIds = new Set(
    files?.map(file => {
      const workflows = file?.workflows?.filter(workflowFilter) || [];
      return workflows.length === 1 ? workflows[0].id : false;
    })
  );
  return singleWorkflowIds.size === 1 && !singleWorkflowIds.has(false);
};

export const parseOrderStatus = (status: string, isCommentRequest = false) => {
  switch (status) {
    case 'STATUS_WAITING_APPROVAL':
      return t`Waiting approval`;
    case 'STATUS_WAITING_SEND':
      return t`Waiting to be send`;
    case 'STATUS_SENT_BUT_SOME_FAILED':
      return t`Sent but something failed`;
    case 'STATUS_SENT':
      return isCommentRequest ? t`Ready for comments` : t`Sent`;
    case 'STATUS_OFFER_RECEIVED':
      return t`Offer received`;
    case 'STATUS_REJECTED':
      return t`Rejected`;
    case 'STATUS_FAILED_AND_CANCELLED':
      return t`Failed and cancelled`;
    case 'STATUS_COMMENT_RECEIVED':
      return t`Comment received`;
    case 'STATUS_COMMENT_REQUEST_CLOSED':
      return t`Comment request closed`;
    case 'STATUS_COMMENT_REQUEST_ABOUT_TO_CLOSE':
      return t`Comment request about to close`;
    case 'STATUS_COMMENT_REQUEST_CLOSED_AND_COMMENT_RECEIVED':
      return t`Comment request closed and comment received`;
    case 'STATUS_COMMENT_REQUEST_ABOUT_TO_CLOSE_AND_COMMENT_RECEIVED':
      return t`Comment request about to close and comment received`;
    case 'STATUS_APPROVED':
      return t`Approved`;
    case 'STATUS_SENT_CANCELED':
      return t`Sent canceled`;
    case 'STATUS_FINALIZED':
      return t`Finalized`;
  }
  return status;
};

export function useCurrentWorkflow(workflowProcess: WorkflowProcess) {
  return useGetWorkflowQuery(
    workflowProcess.workflowId ? { id: workflowProcess.workflowId } : skipToken
  );
}
