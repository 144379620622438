import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { CircularProgress } from '@mui/material';

import { t } from '@lingui/macro';
import { DragHandle } from '@mui/icons-material';
import ItemThumbnailImage from '~common/items/ItemThumbnailImage';
import { IconButton } from '~common/inputs/IconButton';

interface Props {
  pageNumber: string;
  pageId: number;
  thumbnailUrl: string;
  selected: boolean;
  allowPageOps: boolean;
  onclick: () => void;
  ondelete: () => void;
}

const PageItem: React.FC<Props & React.HTMLProps<HTMLButtonElement>> = ({
  pageNumber,
  pageId,
  thumbnailUrl,
  selected,
  onclick,
  ondelete,
  allowPageOps,
}) => {
  const imageKey = `${pageId}-${pageNumber}`;
  const [imageLoaded, setImageLoaded] = useState(false);
  useEffect(() => {
    setImageLoaded(false);
  }, [imageKey]);

  return (
    <PageItemWrapper onClick={onclick} selected={selected}>
      <PageID>{pageNumber}</PageID>
      <PageThumbnail
        key={imageKey}
        name={`${pageId}`}
        thumbnailUrl={thumbnailUrl}
        onLoad={() => setImageLoaded(true)}
        isPageThumbnail
      />
      {/* pageId !== pageNumber if page's index is about to change */}
      {(!imageLoaded || `${pageId}` !== pageNumber) && (
        <LoadingWrapper>
          <CircularProgress color="primary" />
        </LoadingWrapper>
      )}
      {allowPageOps && (
        <>
          <DeleteIconButton
            onPress={() => {
              ondelete();
            }}
            aria-label={t`Delete page ${pageNumber}`}
          >
            <DeleteOutline color="primary" />
          </DeleteIconButton>
          <DragIconButton tabIndex={0} aria-label={t`Rearrange`}>
            <DragHandle color="primary" />
          </DragIconButton>
        </>
      )}
    </PageItemWrapper>
  );
};

/* TODO: Use predefined color, if present */
const PageItemWrapper = styled.button<any>`
  display: block;
  position: relative;
  height: 7rem;
  width: 100%;
  background-color: ${props =>
    props.selected
      ? props.theme.palette.primary.light
      : props.theme.palette.common.white};
  border: none;

  &:hover {
    background-color: ${props =>
      !props.selected && props.theme.palette.primary.lightFade};
    cursor: pointer;
  }
`;

const PageID = styled.div`
  position: absolute;
  top: 0.8rem;
  left: 1.5rem;
`;
const PageThumbnail = styled(ItemThumbnailImage)`
  && {
    position: absolute;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const DeleteIconButton = styled(IconButton)`
  position: absolute;
  right: 0.8rem;
  top: 0.8rem;
`;

const DragIconButton = styled.div`
  position: absolute;
  right: 0.8rem;
  bottom: 0.8rem;
  cursor: ns-resize;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export default PageItem;
