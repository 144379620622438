import React from 'react';
import styled from 'styled-components';
import { FormGroup, FormControlLabel } from '@mui/material';

import Checkbox from './Checkbox';

interface Option<T> {
  name: string;
  value: T;
  disabled?: boolean;
}

interface Props<T> {
  options: Option<T>[];
  value: T[];
  onChange: (e: { target: { value: T[] } }) => void;
}

export default function CheckBoxes<T extends string>({
  options,
  value,
  onChange,
}: Props<T>) {
  return (
    <FormGroup>
      {options &&
        options.map(option => {
          return (
            <StyledFormControl
              control={
                <Checkbox
                  checked={value ? value.includes(option.value) : false}
                  disabled={!!option.disabled}
                />
              }
              key={option.value}
              value={option.value}
              label={option.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChange({
                  target: {
                    value: e.target.checked
                      ? [...new Set([...value, option.value])]
                      : value.filter(x => x !== option.value),
                  },
                })
              }
            />
          );
        })}
    </FormGroup>
  );
}

const StyledFormControl = styled(FormControlLabel)`
  padding-top: 10px;
`;
