import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSwipeable } from 'react-swipeable';
import config from '~common/config';
import { useIntersectionObserver } from '~common/utils/hooks.utils';
import { FolderContentIcon } from '~common/misc/icons';
import { File } from '~common/content.types';

type Props = Omit<React.HTMLAttributes<HTMLImageElement>, 'as'> & {
  item?: File;
  onSwipe?: (dir: -1 | 1) => void;
  src?: string;
  /** An image to display while the actual image is loading */
  loadPreviewSrc?: string;
  /** An image to display if loading the src fails */
  fallbackSrc?: string;
  alt?: string;
};

enum SrcState {
  DEFAULT,
  FALLBACK,
  ERROR,
}

const PreviewImage = React.forwardRef<HTMLImageElement, Props>(
  (
    { item, src, fallbackSrc, loadPreviewSrc, onError, onSwipe, ...props },
    ref
  ) => {
    const [srcState, setSrcState] = useState(SrcState.DEFAULT);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const isIntersecting = useIntersectionObserver(wrapperRef);

    useEffect(() => {
      setSrcState(SrcState.DEFAULT);
    }, [src]);

    const handlers = useSwipeable({
      onSwiped: e => onSwipe?.(e.deltaX < 0 ? -1 : 1),
    });

    const errorSrc = `${config.assetsPath}notfound_dark.svg`;

    const displaySrc =
      (!loadPreviewSrc && !isIntersecting) || srcState === SrcState.ERROR
        ? errorSrc
        : srcState === SrcState.FALLBACK && fallbackSrc
        ? fallbackSrc
        : src;

    return (
      <Wrapper ref={wrapperRef}>
        {item?.isFolder && srcState === SrcState.ERROR ? (
          <FolderContentIcon
            width="100%"
            height="100%"
            backgroundColor="gray"
          />
        ) : (
          <Img
            {...props}
            {...handlers}
            ref={ref}
            src={displaySrc}
            onError={e => {
              onError?.(e);
              // Try the fallbackSrc first if not yet tried, and after that the error image
              setSrcState(srcState =>
                srcState < SrcState.FALLBACK && fallbackSrc
                  ? SrcState.FALLBACK
                  : SrcState.ERROR
              );
            }}
            bgImgSrc={loadPreviewSrc}
            aria-hidden
          />
        )}
      </Wrapper>
    );
  }
);

const Wrapper = styled.div`
  // position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img<{
  onClick?: React.MouseEventHandler;
  bgImgSrc?: string;
}>`
  && {
    width: unset;
    height: unset;
    max-width: 100%;
    max-height: 100%;
  }
  // position: absolute;
  object-fit: contain;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

  background: ${p => (p.bgImgSrc ? `url(${p.bgImgSrc}) no-repeat` : 'none')};
  background-size: contain;
  background-position: center;
`;

export default PreviewImage;
