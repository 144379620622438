import React from 'react';
import { useDrop } from 'react-dnd';
import { useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query/react';
import styled from 'styled-components';
import { t } from '@lingui/macro';

import { findInTree } from '../../settings/rights/utils';
import FileTree from '../common/FileTree';
import { useGetDefaultWorkspaceId, useGetWorkspaceTreeQuery } from './api';
import { useOpenWorkspace } from './hooks';

import ProgressBar from '~sections/ProgressBar';
import { app } from '~common/app.model';
import { useActions } from '~common/utils/hooks.utils';
import { isDesktop } from '~common/utils/styled.utils';
import { useThrottledWindowSize } from '~common/utils/layout.utils';
import { FolderTreeEntry } from '~common/content.types';
import { getAcceptedDndTypes, useOnNodeDrop } from '~common/misc/drag/utils';

interface Props {
  isSaved: boolean;
  openInLocation?: 'sidebar' | 'center';
  onSelect: () => void;
}

export default function WorkspaceTree({
  onSelect,
  isSaved,
  openInLocation,
}: Props) {
  const language = useSelector(state => state.app.settings?.language);
  const workspacesFolderId = useSelector(
    state => state.app.settings?.workspacesFolderId
  );

  const setOpenModal = useActions(app.actions.setOpenModal);
  const { openWorkspace } = useOpenWorkspace();

  const { data: defaultWorkspaceData } = useGetDefaultWorkspaceId();
  const { data: workspaceTree } = useGetWorkspaceTreeQuery(
    workspacesFolderId && language
      ? { workspacesFolderId, language }
      : skipToken
  );

  const windowSize = useThrottledWindowSize();
  const desktop = isDesktop(windowSize.innerWidth);

  const promptOpen = async (id: string) => {
    if (!rootNode) return;
    const item = findInTree(rootNode, id, node => node.node.id);
    if (!item) return;
    if (item.node.fileType !== 'nt:cart') return;
    const location = openInLocation || (desktop ? 'sidebar' : 'center');
    if (!isSaved) {
      setOpenModal('WORKSPACE/CONFIRM_OPEN', {
        workspaceId: item.node.id,
        confirmCallback: onSelect,
        openInLocation: location,
      });
    } else {
      onSelect();
      openWorkspace(item.node.id, location, { saveAsCurrentId: !desktop });
    }
  };

  const onDrop = useOnNodeDrop(workspaceTree?.node, { parents: [] });
  const accept = workspaceTree?.node
    ? getAcceptedDndTypes(workspaceTree.node, [])
    : [];

  const [{ isOver, canDrop }, drop] = useDrop(
    {
      accept: accept,
      drop: onDrop,
      // We'd really like to collect hover and canDrop here, but due to some
      // hacky `FileTree` rerendering stuff `react-dnd` throws errors whenever
      // we drag any tree items while collecting stuff here.
      // Somewhat related:
      // https://github.com/react-dnd/react-dnd/issues/236
      collect: monitor => ({
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
      }),
    },
    [accept, onDrop]
  );

  const isDropHover = isOver && canDrop;

  // The 'default workspace' is not an actual workspace unless a user saves
  // it to the api, so we manually inject it as an option in the listing
  const rootNode =
    workspaceTree && defaultWorkspaceData
      ? ({
          ...workspaceTree,
          children: [
            {
              children: [],
              depth: 0,
              node: {
                id: defaultWorkspaceData.id,
                name: t`Default workspace`,
                namesByLang: {},
                fileType: 'nt:cart',
              },
            },
            ...workspaceTree.children,
          ],
        } as FolderTreeEntry)
      : undefined;

  return (
    <Wrapper dropHover={isDropHover}>
      {!rootNode && <ProgressBar />}
      {rootNode && (
        <>
          <FileTree
            onSelectItem={promptOpen}
            treeContent={rootNode}
            getNodeStyles={node => ({ bold: node.fileType === 'nt:cart' })}
          />
          <RootDropZone ref={drop} />
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ dropHover: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  ${p => (p.dropHover ? `background-color: ${p.theme.palette.grey[100]}` : '')}
`;

const RootDropZone = styled.div`
  flex: 1;
  width: 100%;
  min-height: 100px;
`;
