import { t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Redirect } from 'react-router-dom';

export default function StepRedirect() {
  return (
    <Box
      sx={{
        textAlign: 'center',
        height: 1,
        width: 1,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h4">
        {t`Upload complete. Redirecting to the home page...`}
      </Typography>
      <Redirect to="/" />
    </Box>
  );
}
