import React from 'react';
import { DatePicker as Picker } from '@mui/x-date-pickers/DatePicker';

import withStyles from '@mui/styles/withStyles';
import { IconButton } from '@mui/material';
import TextField from '@mui/material/TextField';
import { t } from '@lingui/macro';

import FormLabel, { FormLabelProps } from './FormLabel';
import { CloseIcon } from '~misc/icons';
import { LocalizedText, determineIfObjectOrString } from '~common/app.utils';
import {
  isValidDateObject,
  parseUnknownFormatDateString,
} from '~common/utils/date.utils';

interface Props
  extends Omit<React.ComponentProps<typeof Picker>, 'renderInput'> {
  id: string;
  onClear: any;
  variant?: any;
  labelText?: string | LocalizedText;
  ariaLabel?: string;
  required?: boolean;
  reverseAdornments?: boolean;
  labelProps?: Partial<FormLabelProps>;
  error?: boolean;
  helperText?: string;
  inputProps?: Record<string, unknown>;
}

const StyledButt = withStyles({
  root: {
    padding: '6px 6px 7px',
  },
})(IconButton);

const DatePicker = ({
  id,
  value,
  onChange,
  onClear,
  variant,
  labelText,
  required,
  error,
  helperText,
  ariaLabel,
  reverseAdornments,
  labelProps,
  ...pickerprops
}: Props) => {
  return (
    <Picker
      {...pickerprops}
      inputFormat="dd.MM.yyyy"
      label={
        labelText && (
          <FormLabel
            label={
              determineIfObjectOrString(labelText) ? labelText.value : labelText
            }
            disabled={pickerprops.disabled}
            required={required}
            {...labelProps}
          />
        )
      }
      value={value}
      onChange={(value, kbdValue) => {
        // date selected from picker ui
        if (!kbdValue && isValidDateObject(value)) {
          onChange(value);
        }
        // only apply full length dd.MM.yyyy when typing
        if (kbdValue?.length === 10) {
          onChange(value, kbdValue);
        }
      }}
      renderInput={params => (
        <TextField
          helperText={!!error && helperText}
          inputMode="numeric"
          variant="standard"
          aria-label={!labelText ? ariaLabel || t`Select date` : undefined}
          aria-required={required}
          InputLabelProps={{
            style: { transform: 'scale(1) translate(0px, -4px)' },
          }}
          inputProps={{
            readOnly: pickerprops.readOnly,
          }}
          onPasteCapture={e => {
            // handle pasting differently formatted dates
            try {
              e.preventDefault();
              const pasteData = e.clipboardData.getData('text/plain');
              onChange(parseUnknownFormatDateString(pasteData));
            } catch {}
          }}
          className="date-picker"
          {...(pickerprops.inputProps || {})}
          {...params}
          error={Boolean(error)}
        />
      )}
      InputProps={{
        [reverseAdornments ? 'startAdornment' : 'endAdornment']:
          !pickerprops.readOnly ? (
            <StyledButt
              aria-label={t`Clear`}
              onClick={e => {
                e.stopPropagation();
                onClear();
              }}
            >
              <CloseIcon fontSize="small" />
            </StyledButt>
          ) : undefined,
        'aria-label': ariaLabel || t`Select date`,
      }}
      InputAdornmentProps={{
        position: reverseAdornments ? 'end' : 'start',
        style: {
          marginRight: '-2px',
          display: pickerprops.readOnly ? 'none' : undefined,
        },
      }}
    />
  );
};

export default DatePicker;
