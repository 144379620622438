import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { t } from '@lingui/macro';
import { Item } from 'react-stately';

import { useSelector } from 'react-redux';
import { useShoppingCartContents } from './hooks';
import Thumbnail from '../../common/Thumbnail';
import { DeleteDropzone } from '../DeleteDropzone';
import { useAddToOrderOption } from '../../actionOptions/useAddToOrderOption';
import { useMagentoOption } from '../../actionOptions/useMagentoOption';

import { getLangValue } from '~common/app.utils';
import { Typography } from '~common/misc/Typography';
import { GridList } from '~common/layout/GridList';
import Button from '~common/inputs/Button';
import ProgressBar from '~common/sections/ProgressBar';
import MenuButton from '~common/navigation/MenuButton';
import { BaseModal } from '~common/sections/Modal';

interface Props {
  onClose: () => void;
}

function ShoppingCartPreview({ onClose }: Props) {
  const history = useHistory();
  const id = useSelector(state => state.app.shoppingCart?.id) || undefined;
  const { contents } = useShoppingCartContents();

  const fileIds = contents?.items.map(item => item.node.id) ?? [];
  const startShoppingCartOrderOption = useAddToOrderOption(fileIds, {
    skipFolders: true,
    shoppingCartOrder: true,
  });
  const magentoOptions = useMagentoOption(fileIds, { skipFolders: true });

  const orderOptions = [
    { ...startShoppingCartOrderOption, title: t`With workflow` },
    { ...magentoOptions, title: t`As print products` },
  ];

  const visibleOptions = orderOptions.filter(o => !o.hidden);

  return (
    <>
      <BaseModal
        title={t`Shopping cart`}
        onClose={onClose}
        confirmButtonProps={null}
        cancelButtonProps={null}
        extraButtons={{
          left: (
            <Button
              onClick={() => {
                if (id) history.push(`/shopping/${id}`);
                onClose();
              }}
              variant="outlined"
            >{t`Open`}</Button>
          ),
          center:
            visibleOptions.length > 1 ? (
              <MenuButton
                options={orderOptions}
                disabled={!contents?.items.length}
                variant="contained"
                onSelect={() => onClose()}
              >{t`Order(verb)`}</MenuButton>
            ) : visibleOptions.length > 0 ? (
              <Button
                onClick={() => {
                  visibleOptions[0]?.onSelect?.();
                  onClose();
                }}
                disabled={!contents?.items.length}
                variant="contained"
              >{t`Order(verb)`}</Button>
            ) : null,
        }}
        minWidth="0"
      >
        <ShoppingCartContent />
      </BaseModal>

      <DeleteDropzone dragSource="shopping" />
    </>
  );
}

function ShoppingCartContent() {
  const { contents, isLoading } = useShoppingCartContents();

  if (isLoading) return <ProgressBar />;

  if (!contents || !contents.items.length)
    return (
      <Typography variant="body1">{t`The shopping cart is empty`}</Typography>
    );

  const items = contents.items.map((item, index) => ({
    ...item,
    index,
    key: item.node.id,
  }));

  return (
    <StyledGridList
      data-intersection-root
      aria-label={t`Shopping cart content`}
      items={items}
    >
      {item => (
        <Item textValue={getLangValue(item.namesByLang)}>
          <Thumbnail
            id={item.node.id}
            itemId={item.node.id}
            item={item}
            index={item.index}
            prevItem={null}
            dragSource="shopping"
          />
        </Item>
      )}
    </StyledGridList>
  );
}

const StyledGridList = styled(GridList)`
  --gap: ${p => p.theme.spacing(1)};

  align-self: center;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
  width: 100%;
  max-width: calc(2 * var(--min-column-width) + var(--gap));
  overflow: hidden;

  & > li {
    width: var(--min-column-width);

    @media screen and (max-width: 425px) {
      width: 100%;
    }
  }
` as typeof GridList;

export default ShoppingCartPreview;
