import { OrderCategory } from './types';
import api from '~utils/api.utils';
import { UploadFile } from '~common/content.types';

export const sendOrder = async (data: object, attachments?: UploadFile[]) => {
  const formData = new FormData();
  formData.append(
    'json',
    new Blob([JSON.stringify(data)], { type: 'application/json;charset=utf-8' })
  );
  for (const attachment of attachments ?? [])
    formData.append('attachment', attachment.blob);
  const res = await api.http.post('orders', formData, {
    headers: {
      'Content-Type': `multipart/form-data; boundary=${
        (formData as any)._boundary
      }`,
    },
  });
  return res.data;
};

export const reviewOrder = async ({ approve, orderId, data }) => {
  const res = await api.http.post(
    `orders/${orderId}/actions/${approve ? 'approve' : 'disapprove'}`,
    data
  );
  return res.data;
};

export const readCategories = async () => {
  const res = await api.http.get<OrderCategory[]>('orders/categories');
  return res.data;
};
