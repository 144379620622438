import {
  OrderCategory,
  OrderLineWorkflowData,
  OrderWorkflowData,
} from './types';
import { Criteria, EnterMode, HistoryUpdateMode } from '~common/common.types';
import { WorkflowProcessStep } from '~common/workflows/constants';
import { WorkflowProcess } from '~common/workflows/types';
import {
  createActions,
  handleActions,
  overwriteWithPayload,
} from '~common/utils/ducks.utils';

export interface OrderState {
  // browsing
  criteria: Criteria | null;
  // workflow
  ordersByFile: Record<
    string,
    OrderLineWorkflowData & { workflowId?: string | null }
  >;
  dataByWorkflowId: Record<string, OrderWorkflowData>;
  orderProcess: WorkflowProcess;
  categoriesById: Record<string, OrderCategory> | null;
}

const initialState: OrderState = {
  // Browsing
  criteria: null,
  // Workflow
  ordersByFile: {},
  dataByWorkflowId: {},
  orderProcess: { workflowId: null, step: null },
  categoriesById: null,
};

// Actions
const actions = createActions('ORDERS', {
  // Browsing
  updateCriteria: (
    criteria: Criteria,
    historyUpdateMode?: HistoryUpdateMode,
    enterMode?: EnterMode
  ) => ({
    criteria,
    historyUpdateMode,
    enterMode,
  }),
  // Order workflow
  addToOrder: (productIds: string[]) => ({ productIds }),
  resetOrder: () => ({}),
  startShoppingCartOrder: (productIds: string[]) => ({ productIds }),
  selectWorkflow: (fileId: string, workflowId: string | null) => ({
    fileId,
    workflowId,
  }),
  setOrderLineData: (fileId: string, data: OrderLineWorkflowData) => ({
    fileId,
    data,
  }),
  startOrderProcess: () => ({}),
  advanceOrderProcess: () => ({}),
  retreatOrderProcess: () => ({}),
  updateOrderProcess: (process: WorkflowProcess) => ({ orderProcess: process }),
  initWorkflowData: (
    dataByWorkflowId: OrderState['dataByWorkflowId'],
    clear?: boolean
  ) => ({
    dataByWorkflowId,
    clear,
  }),
  setWorkflowData: <T extends keyof OrderWorkflowData>(
    workflowId: string,
    fieldId: T,
    data: OrderWorkflowData[T]
  ) => ({
    workflowId,
    fieldId,
    data,
  }),
  removeWorkflowData: (workflowId: string) => ({ workflowId }),
  sendOrder: (workflowId: string) => ({ workflowId }),
  afterSendOrder: (workflowId: string) => ({ workflowId }),
  reviewOrder: (
    approve: boolean,
    orderId: string,
    data: { cause: string }
  ) => ({ approve, orderId, data }),
  readCategories: () => ({}),
  afterReadCategories: (categoriesById: OrderState['categoriesById']) => ({
    categoriesById,
  }),
});

// Reducer
export default handleActions(initialState)
  .handle(actions.updateCriteria, (state, action) => ({
    ...state,
    criteria: action.payload.criteria,
  }))
  .handle(actions.addToOrder, (state, action) => ({
    ...state,
    ordersByFile: {
      ...[...action.payload.productIds].reduce((a, c) => {
        a[c] = { workflowId: undefined, amount: undefined };
        return a;
      }, {}),
    },
  }))
  .handle(actions.startShoppingCartOrder, (state, action) => ({
    ...state,
    ordersByFile: {
      ...[...action.payload.productIds].reduce((a, id) => {
        a[id] = state.ordersByFile[id] ?? {
          workflowId: undefined,
          amount: undefined,
        };
        return a;
      }, {}),
    },
    orderProcess: initialState.orderProcess,
  }))
  .handle(actions.resetOrder, state => ({
    ...state,
    orderProcess: initialState.orderProcess,
    dataByWorkflowId: initialState.dataByWorkflowId,
  }))
  .handle(actions.selectWorkflow, (state, action) => ({
    ...state,
    ordersByFile: {
      ...state.ordersByFile,
      [action.payload.fileId]: {
        ...state.ordersByFile[action.payload.fileId],
        workflowId: action.payload.workflowId,
      },
    },
  }))
  .handle(actions.setOrderLineData, (state, action) => ({
    ...state,
    ordersByFile: {
      ...state.ordersByFile,
      [action.payload.fileId]: {
        ...state.ordersByFile[action.payload.fileId],
        ...action.payload.data,
      },
    },
  }))
  .handle(actions.updateOrderProcess, overwriteWithPayload)
  .handle(actions.initWorkflowData, (state, action) => ({
    ...state,
    dataByWorkflowId: {
      ...action.payload.dataByWorkflowId,
      ...(!action.payload.clear ? state.dataByWorkflowId : {}),
    },
  }))
  .handle(actions.setWorkflowData, (state, action) => ({
    ...state,
    dataByWorkflowId: {
      ...state.dataByWorkflowId,
      [action.payload.workflowId]: {
        ...(state.dataByWorkflowId[action.payload.workflowId] ||
          ({} as OrderWorkflowData)),
        [action.payload.fieldId]: action.payload.data,
      },
    },
  }))
  .handle(actions.removeWorkflowData, (state, action) => ({
    ...state,
    dataByWorkflowId: Object.keys(state.dataByWorkflowId || {})
      .filter(x => x !== action.payload.workflowId)
      .reduce((a, c) => {
        a[c] = state.dataByWorkflowId[c];
        return a;
      }, {}),
    ordersByFile: Object.keys(state.ordersByFile)
      .filter(
        x => state.ordersByFile[x].workflowId !== action.payload.workflowId
      )
      .reduce((a, c) => {
        a[c] = state.ordersByFile[c];
        return a;
      }, {}),
  }))
  .handle(actions.sendOrder, state => ({
    ...state,
    orderProcess: {
      ...state.orderProcess,
      step: WorkflowProcessStep.SENDING,
    },
  }))
  .handle(actions.afterSendOrder, state => ({
    ...state,
    orderProcess: {
      ...state.orderProcess,
      step: WorkflowProcessStep.SENT,
    },
  }))
  .handle(actions.afterReadCategories, (state, action) => ({
    ...state,
    categoriesById: action.payload.categoriesById,
  }));

// Bundle things in a model
export const orders = {
  actions,
  selector: {},
};
