import React from 'react';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro';

import { ModalOption } from '~common/navigation/MenuButton';
import {
  AllowLevel,
  allowLevel,
  useCurrentFolderId,
} from '~common/content.utils';
import { useGetFolderDownloadConfigQuery } from '~common/content.api';
import { DownloadIcon } from '~common/misc/icons';

type Options = {
  mode?: 'search' | string;
  isWorkspace?: boolean;
  isFolderAction?: boolean;
  contentSpecific?: boolean;
  /** defaults to `false` */
  skipFolders?: boolean;
};

function useGetDownloadAllowedLevel(
  fileIds: string[],
  options?: Options
): number {
  const filesById = useSelector(state => state.commonContent.filesById);
  return options?.contentSpecific === false
    ? AllowLevel.ForNone
    : allowLevel('download', fileIds, filesById, options?.skipFolders ?? false);
}

/** Returns an `option` to be used for downloading content */
export function useDownloadOption(
  fileIds: string[],
  options?: Options
): ModalOption {
  const downloadAllowed = useGetDownloadAllowedLevel(fileIds, options);

  return {
    value: 'download',
    title: t`Download`,
    icon: <DownloadIcon />,
    disabled: options?.isWorkspace
      ? downloadAllowed !== AllowLevel.ForAll
      : downloadAllowed === AllowLevel.ForSome,
    hidden: downloadAllowed === AllowLevel.ForNone,
    modalType: 'CONTENT/DOWNLOAD',
    modalProps: {
      fileIds,
      isSearch: options?.mode === 'search',
      isWorkspace: options?.isWorkspace,
      isFolderAction: options?.isFolderAction,
    },
  };
}

export function useDeepDownloadOption(
  fileIds: string[],
  options?: Options
): ModalOption {
  const currentFolderId = useCurrentFolderId();

  const { data: downloadConfig } = useGetFolderDownloadConfigQuery(
    currentFolderId ? { id: currentFolderId } : skipToken
  );

  const downloadAllowed = useGetDownloadAllowedLevel(fileIds, options);

  return {
    value: 'deepDownload',
    title: t`Download with sublevels`,
    icon: <DownloadIcon />,
    disabled: downloadAllowed === AllowLevel.ForSome,
    hidden:
      options?.mode !== 'folder' ||
      !downloadConfig?.enabledDeepDownload ||
      downloadAllowed === AllowLevel.ForNone,
    modalType: 'CONTENT/DEEP_DOWNLOAD',
    modalProps: {
      fileIds,
      isFolderAction: options?.isFolderAction,
      downloadConfig,
    },
  };
}
