import React from 'react';
import styled from 'styled-components';

import { Tooltip } from '@mui/material';
import Row from '~layout/Row';
import Typography from '~misc/Typography';
import { LocalizedText } from '~common/app.utils';

export interface FormLabelProps {
  label: string | LocalizedText;
  required?: boolean;
  disabled?: boolean;
  compact?: boolean;
  tooltip?: boolean;
  noWrap?: boolean;
  endAdornment?: JSX.Element;
  'aria-hidden'?: boolean;
  as?: React.ElementType;
}

const FormLabel = ({
  label,
  required = false,
  disabled = false,
  compact = false,
  tooltip = false,
  noWrap = false,
  endAdornment,
  as,
  ...rest
}: FormLabelProps) => {
  const text = label instanceof Object ? label.value : label;
  return (
    <>
      {!compact && <Row padding={2} />}
      <Row padding={0} {...rest}>
        {/* Mui tooltips with '' as title are not rendered */}
        <Tooltip title={tooltip ? text : ''} arrow placement="top">
          <Label
            variant="subtitle1"
            disabled={disabled}
            noWrap={noWrap}
            as={as}
            lang={label instanceof Object ? label.lang : undefined}
          >
            {text}
          </Label>
        </Tooltip>
        {required && (
          <Typography color="error" aria-hidden>
            &nbsp;*
          </Typography>
        )}
        {endAdornment}
      </Row>
    </>
  );
};

const Label = styled(Typography)<{ disabled: boolean; lang?: string }>`
  opacity: ${props => (props.disabled ? 0.6 : 1)};
`;

export default FormLabel;
