import styled from 'styled-components';

import ActionBar from '~sections/ActionBar';

export const WorkspaceActionBar = styled(ActionBar)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 0;

  button {
    margin-right: ${props => props.theme.spacing(1)} !important;
  }

  & > button:last-child {
    margin-right: 0;
  }
`;
