import sanitizeHTML from 'sanitize-html';
import { useSelector } from 'react-redux';

import { LocalizedText } from '~common/app.utils';

export function snapToGrid(x: number, y: number) {
  const snappedX = Math.round(x / 32) * 32;
  const snappedY = Math.round(y / 32) * 32;
  return [snappedX, snappedY];
}

export const sanitizeHtml = (html: string, allowedTags?: string[]) =>
  html &&
  sanitizeHTML(html, {
    allowedTags: sanitizeHTML.defaults.allowedTags
      .concat(['img', 'del', 'ins'])
      .concat(allowedTags ?? []),
    allowedAttributes: {
      ...sanitizeHTML.defaults.allowedAttributes,
      '*': ['style'],
    },
  });

export const getText = (value: string | LocalizedText) =>
  typeof value === 'string' ? value : value.value;

export const getLang = (value: string | LocalizedText) =>
  typeof value === 'string' ? undefined : value.lang;

/** Returns a list of possible email addresses that can be set as a sender
 * for mailing something out of the application, for example workspace or
 * upload form shares */
export function useEmailOptions() {
  const user = useSelector(state => state.app.user);

  const sendingEmails = useSelector(
    state => state.app.customer?.sendCartAddressCollection
  );
  const userAsSender = useSelector(
    state => state.app.customer?.configById['send.cart.personal.address']
  );

  // Parse emails from API into options for <select>
  const emailOptions =
    sendingEmails?.map(email => ({
      id: email.id,
      name: email.email,
    })) ?? [];
  if (user && userAsSender) emailOptions.unshift({ id: -1, name: user.email });

  return emailOptions;
}

export const isAtStartOfContent = criteria =>
  criteria?.page === 0 && criteria?.selectedIndex === 0;

export const isAtEndOfContent = (criteria, totalCount) =>
  criteria?.page !== undefined &&
  criteria?.pageSize !== undefined &&
  criteria?.selectedIndex !== undefined &&
  totalCount !== undefined &&
  criteria.page * criteria.pageSize + criteria.selectedIndex === totalCount - 1;
