// Hard-coded home page data for dev and demo purposes

const home = {
  layout: 'modern',
  background: '',
  logo: {
    image: '',
    link: '',
    width: 260,
  },
  search: true,
  itemLimit: 10,
  cardHeight: 350,
  links: [
    {
      url: '/goto/content',
      image:
        'https://nibo.materialbank.net/NiboWEB/brandwind/getPublicFile.do?uuid=16262136&inline=false&ticket=7ce32dfd26e9b95f57cc2cf202601b25&type=original',
      name: {
        en: 'Demo: Images and documents',
        fi: 'Demo: Kuvat ja dokumentit',
      },
    },
    {
      url: '/goto/content',
      image:
        'https://nibo.materialbank.net/NiboWEB/brandwind/getPublicFile.do?uuid=16262346&inline=false&ticket=00e04898aafbc250e079d50930b20f09&type=original',
      name: {
        en: 'Demo: Videos and sounds',
        fi: 'Demo: Videot ja äänet',
      },
    },
    {
      url: '/goto/content',
      image:
        'https://nibo.materialbank.net/NiboWEB/brandwind/getPublicFile.do?uuid=16262528&inline=false&ticket=3ab061d6ece413ce0fecc1ee63858a2f&type=original',
      name: {
        en: 'Demo: Logos and instructions',
        fi: 'Demo: Logot ja ohjeet',
      },
    },
    {
      url: '/goto/content',
      image:
        'https://nibo.materialbank.net/NiboWEB/brandwind/getPublicFile.do?uuid=16262556&inline=false&ticket=73a6c1e78dadf2ad291fde73aef0be1b&type=original',
      name: {
        en: 'Demo: Company presentations',
        fi: 'Demo: Yritysesitykset',
      },
    },
    {
      url: '/goto/content',
      image:
        'https://nibo.materialbank.net/NiboWEB/brandwind/getPublicFile.do?uuid=16262575&inline=false&ticket=a263d9efcd821962a0c35243362e4808&type=original',
      name: {
        en: 'Demo: Create brochure',
        fi: 'Demo: Muokkaa esite',
      },
    },
    {
      url: '/goto/content',
      image:
        'https://nibo.materialbank.net/NiboWEB/brandwind/getPublicFile.do?uuid=16262605&inline=false&ticket=7e47169514989c501ecaa58b2c16092a&type=original',
      name: {
        en: 'Demo: Create newsletter',
        fi: 'Demo: Luo uutiskirje',
      },
    },
    {
      url: '/goto/content',
      image:
        'https://nibo.materialbank.net/NiboWEB/brandwind/getPublicFile.do?uuid=16263661&inline=false&ticket=0cfd0fb0b393fded8c47352ac37d16d6&type=original',
      name: {
        en: 'Demo: Order translation',
        fi: 'Demo: Tilaa käännöstyö',
      },
    },
    {
      url: '/goto/content',
      image:
        'https://nibo.materialbank.net/NiboWEB/brandwind/getPublicFile.do?uuid=16263737&inline=false&ticket=baf780d672a5bdef3f136d00d3b7fe40&type=original',
      name: {
        en: 'Demo: Make bizcard',
        fi: 'Demo: Tee käyntikortti',
      },
    },
  ],
  cards: [
    {
      type: 'STATIC',
      name: {
        en: 'Demo: Folders',
        fi: 'Demo: Kansiot',
      },
      items: [
        {
          name: {
            en: 'intia_en',
            fi: 'intia_fi',
          },
          url: '/goto/content',
        },
        {
          name: {
            en: 'testikuvat_en',
            fi: 'testikuvat_fi',
          },
          url: '/goto/content',
        },
      ],
    },
    {
      type: 'WORKSPACES',
    },
    {
      type: 'LATEST',
    },
  ],
};

export default home;
