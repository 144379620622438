import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import MaterialTabs from '@mui/material/Tabs';
import { useTabState } from '~common/utils/form.utils';
import Tab from '~common/navigation/Tab';
import TabPanel from '~common/navigation/TabPanel';

interface Props extends ComponentProps<typeof MaterialTabs> {
  withBorder?: boolean;
}

// We need to destructure the 'withBorder' prop as MUI otherwise passes it down
// to html causing errors
const Tabs = styled(({ withBorder, ...rest }: Props) => (
  <MaterialTabs {...rest} />
))<Props>`
  overflow: initial !important; /* TODO: remove? */
  min-height: initial !important; /* TODO: remove? */
  border-bottom: ${({ theme, withBorder }) =>
    withBorder ? '1px solid ' + theme.palette.border.section : 0};
`;

export default Tabs;

type TabPanelProps = {
  label: string;
  children: React.ReactNode;
};

type BasicTabsProps = {
  children: (React.ReactElement<TabPanelProps> | null)[];
  tabValue?: number;
  onTabChange?: (_: unknown, newValue: number) => void;
  variant?: 'scrollable' | 'fullWidth' | 'standard';
};

/** Component for creating a tabbed view, integrating tab switching buttons
 * and tab panels with a single child definition when used with `BasicTabPanel`
 *
 * Tab state can be controlled from parent by passing in `tabValue` and
 * `onTabChange` -callbacks (returned from `useTabState`)
 *
 * @example
 *
 *  <BasicTabs>
 *    <BasicTabPanel label={t`First tab`}>
 *      {firstTabContents}
 *    </BasicTabPanel>
 *    <BasicTabPanel label={t`Second tab`}>
 *      {secondTabContents}
 *    </BasicTabPanel>
 *  </BasicTabs>
 */
export function BasicTabs({
  children,
  tabValue,
  onTabChange,
  variant = 'standard',
  ...rest
}: BasicTabsProps) {
  const [ownTabValue, ownOnTabChange] = useTabState();

  const currentTabValue = tabValue ?? ownTabValue;
  const currentOnTabChange = onTabChange ?? ownOnTabChange;

  return (
    <BasicTabsWrapper {...rest}>
      <Tabs
        value={currentTabValue}
        onChange={currentOnTabChange}
        variant={variant}
      >
        {React.Children.map(children, (child, index) =>
          child !== null ? (
            <StyledTab
              id={`tab-${index}`}
              aria-controls={`tabpanel-${index}`}
              label={child.props.label}
            />
          ) : null
        )}
      </Tabs>

      {React.Children.map(children, (child, index) =>
        child !== null ? (
          <TabPanel
            id={`tabpanel-${index}`}
            tabId={`tab-${index}`}
            open={currentTabValue === index}
          >
            {child}
          </TabPanel>
        ) : null
      )}
    </BasicTabsWrapper>
  );
}

export function BasicTabPanel({ children }: TabPanelProps) {
  return <>{children}</>;
}

const BasicTabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(2)};
`;

const StyledTab = styled(Tab)`
  text-transform: uppercase;
`;
