import {
  AppSettings,
  Customer,
  CustomerTheme,
  User,
  UserRights,
} from './app.types';
import {
  createActions,
  deepExtendWithPayload,
  extendWithPayload,
  handleActions,
  overwriteWithPayload,
} from './utils/ducks.utils';
import { Lang } from './utils/i18n';

export type RightDockContent =
  | '/workspaces'
  | '/productElement'
  | '/commentList';
export type LeftDockContent =
  | '/contentTabs'
  | '/productPages'
  | '/commentPages';

export interface AppState {
  share: {
    type: string;
    id: string;
    ticket: string;
    language: string;
    key: string[];
  } | null;
  customer: Customer | null;
  customerFunctions: Record<string, unknown> | null;
  user: User | null;
  userRights: UserRights | null;
  shoppingCart: Record<string, string> | null;
  settings: AppSettings | null;
  leftDrawer: {
    open: boolean;
    enabled: boolean;
  };
  leftDock: {
    open: boolean;
    visible?: boolean;
    content: null | LeftDockContent;
    activeTab: null | string;
  };
  rightDock: {
    open: boolean;
    visible?: boolean;
    content: null | RightDockContent;
    extraWidth?: number;
  };
  // TODO: figure out something here
  openModals: { type: string; props: unknown }[];
  openSnackbarId: null | string;
  openSnackbarProps: {
    message: string;
    type: 'success' | 'error' | 'warning' | 'info';
    screenReaderOnly?: boolean;
    duration?: number;
  } | null;
  currentPathname: null | string;
  prevPathname: null | string;
  session?: {
    redirectURL: string;
  } | null;
  userFolderSort?: string;
  userSearchSort?: string;
}

const initialState: AppState = {
  share: null,
  customer: null,
  customerFunctions: null,
  user: null,
  userRights: null,
  shoppingCart: null,
  settings: null,
  leftDrawer: {
    open: false,
    enabled: true,
  },
  leftDock: {
    open: true,
    visible: false,
    content: '/contentTabs',
    activeTab: null,
  },
  rightDock: {
    open: true,
    visible: false,
    content: null,
  },
  openModals: [],
  openSnackbarId: null,
  openSnackbarProps: null,
  currentPathname: null,
  prevPathname: null,
};

const actions = createActions('APP', {
  init: (shareKey?: string[]) => ({ shareKey }),
  showInfoMessage: (
    message: string,
    type?: string,
    screenReaderOnly?: boolean
  ) => ({
    message,
    type,
    screenReaderOnly,
  }),
  showErrorMessage: (
    message: string,
    type?: string,
    screenReaderOnly?: boolean
  ) => ({
    message,
    type,
    screenReaderOnly,
  }),
  afterError: (error: Error, message?: string) => ({ error, message }),
  afterReadCustomer: (customer: Customer) => ({ customer }),
  afterReadCustomerFunctions: (functions: Record<string, unknown>) => ({
    customerFunctions: functions,
  }),
  readUser: () => {},
  readUserSettings: () => {},
  afterReadUser: (user: User) => ({ user }),
  afterReadUserRights: (userRights: UserRights) => ({ userRights }),
  afterReadUserSettings: (settings: AppSettings) => ({ settings }),
  updateTheme: (theme: CustomerTheme) => theme,
  setLanguage: (language: Lang) => ({ settings: { language } }),
  setUserFolderSort: (userFolderSort: string) => ({ userFolderSort }),
  setUserSearchSort: (userSearchSort: string) => ({ userSearchSort }),

  login: () => {},
  logout: (restricted?: boolean) => ({ restricted }),
  afterLogout: () => {},
  afterReadSession: (session: AppState['session']) => ({ session }),
  goBack: () => {},

  setOpenModal: (openModalType: string, openModalProps = undefined) => ({
    openModalProps,
    openModalType,
  }),
  closeModal: (goBack?: boolean) => ({ goBack }),
  setOpenSnackbar: (openSnackbarId: string, openSnackbarProps) => ({
    openSnackbarId,
    openSnackbarProps,
  }),
  closeSnackbar: () => {},

  enableDrawer: (enabled: boolean) => ({ leftDrawer: { enabled } }),
  toggleDrawer: (open: boolean) => ({ open }),
  toggleLeft: (dock: Partial<AppState['leftDock']>) => ({
    ...dock,
  }),
  toggleRight: (dock: Partial<AppState['rightDock']>) => ({ ...dock }),
  toggleRightDock: (open: boolean, visible: boolean, content: string) => ({
    open,
    visible,
    content,
  }),
  resizeRightDock: (diff: number) => ({ diff }),

  startOrder: (productIds: Set<string>, fromShoppingCart?: boolean) => ({
    productIds,
    fromShoppingCart,
  }),
  startCommentRequest: (contentIds: Set<string>) => ({ contentIds }),
  afterReadShoppingCart: (shoppingCart: Record<string, string>) => ({
    shoppingCart,
  }),
});

export default handleActions(initialState)
  .handle(actions.init, (state, action) => {
    const key = action.payload.shareKey;
    return {
      ...state,
      share: key
        ? {
            type: key[0].substring(0, 1),
            id: key[0].substring(1),
            ticket: key[1],
            language: key[2],
            key,
          }
        : null,
    };
  })
  .handle(actions.afterReadCustomer, overwriteWithPayload)
  .handle(actions.afterReadCustomerFunctions, overwriteWithPayload)
  .handle(actions.afterReadUserRights, overwriteWithPayload)
  .handle(actions.afterReadUserSettings, overwriteWithPayload)
  .handle(actions.afterReadUser, overwriteWithPayload)
  .handle(actions.afterReadShoppingCart, overwriteWithPayload)
  .handle(actions.updateTheme, deepExtendWithPayload('settings.theme'))
  .handle(actions.setLanguage, extendWithPayload)
  .handle(actions.setUserFolderSort, overwriteWithPayload)
  .handle(actions.setUserSearchSort, overwriteWithPayload)
  .handle(actions.afterLogout, state => ({
    ...state,
    userRights: initialState.userRights,
  }))
  .handle(actions.afterReadSession, overwriteWithPayload)
  .handle(actions.enableDrawer, extendWithPayload)
  .handle(actions.toggleDrawer, (state, action) => ({
    ...state,
    leftDrawer: {
      ...state.leftDrawer,
      open:
        action.payload.open !== undefined
          ? action.payload.open
          : state.leftDrawer.open,
    },
  }))
  .handle(actions.toggleLeft, (state, action) => ({
    ...state,
    leftDock: {
      open:
        action.payload.open !== undefined
          ? action.payload.open
          : !state.leftDock.open,
      visible:
        action.payload.visible !== undefined
          ? action.payload.visible
          : state.leftDock.visible,
      content:
        action.payload.content !== undefined
          ? action.payload.content
          : state.leftDock.content,
      activeTab: action.payload.activeTab || null,
    },
  }))
  .handle(actions.toggleRight, (state, action) => ({
    ...state,
    rightDock: {
      ...state.rightDock,
      open:
        action.payload.open !== undefined
          ? action.payload.open
          : !state.rightDock.open,
      visible:
        action.payload.visible !== undefined
          ? action.payload.visible
          : state.rightDock.visible,
      content:
        action.payload.content !== undefined
          ? action.payload.content
          : state.rightDock.content,
    },
  }))
  .handle(actions.resizeRightDock, (state, action) => ({
    ...state,
    rightDock: {
      ...state.rightDock,
      extraWidth: (state.rightDock.extraWidth ?? 0) + action.payload.diff,
    },
  }))
  .handle(
    actions.setOpenModal,
    (state, { payload: { openModalProps, openModalType } }) => ({
      ...state,
      openModals: [
        ...state.openModals,
        { type: openModalType, props: openModalProps },
      ],
    })
  )
  .handle(actions.closeModal, (state, { payload }) => ({
    ...state,
    openModals: payload?.goBack === true ? state.openModals.slice(0, -1) : [],
  }))
  .handle(actions.setOpenSnackbar, overwriteWithPayload)
  .handle(actions.closeSnackbar, state => ({
    ...state,
    openSnackbarId: null,
    openSnackbarProps: null,
  }))
  .handle('@@router/LOCATION_CHANGE', (state, action: any) => ({
    ...state,
    prevPathname: state.currentPathname,
    currentPathname: action.payload.location.pathname,
  }));

// Bundle things in a model
export const app = {
  actions,
  selector: {},
};
