import { setupI18n, i18n, MessageDescriptor } from '@lingui/core';
import { t } from '@lingui/macro';
import { ALL_CUSTOMER_LANGUAGES } from '../../../lingui.config';
export { ALL_CUSTOMER_LANGUAGES };

export type Lang = typeof ALL_CUSTOMER_LANGUAGES[number];

function withLang(language: Lang) {
  return setupI18n({
    locale: language,
    locales: i18n.locales,
    // Just to ignore private property type error
    messages: (i18n as any)._messages,
  });
}

export const getTranslatedLanguage = (langCode: string) => {
  switch (langCode) {
    case 'en':
      return t`English`;
    case 'fi':
      return t`Finnish`;
    case 'se':
      return t`Swedish`;
    case 'de':
      return t`German`;
    case 'fr':
      return t`French`;
    default:
      return langCode || null;
  }
};

/** Converts lingui translated values to an object with language codes as keys
 *
 * @param msg Lingui MessageDescription of the message to be translated.
 * You should use the `defineMessage` function of `@lingui/macro` to make the message translatable.
 *
 * @example
 * const namesByLang = getStringByLang(defineMessage`Description`);
 */
export const getStringByLang = (msg: MessageDescriptor) =>
  ALL_CUSTOMER_LANGUAGES.reduce(
    (a, lang) => ({ ...a, [lang]: withLang(lang)._(msg) }),
    {} as Record<Lang, string>
  );
