import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import throttle from 'lodash/throttle';

function getWindowSize() {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
  };
}

/**
 * Returns current window dimensions
 */
export function useWindowSize() {
  const [size, setSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => setSize(getWindowSize());

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return size;
}

/**
 * Returns current window dimensions, throttling the result for performance
 */
export function useThrottledWindowSize(delay = 500) {
  const [size, setSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = () => setSize(getWindowSize());
    const throttledHandleResize = throttle(handleResize, delay);

    window.addEventListener('resize', throttledHandleResize);
    return () => {
      window.removeEventListener('resize', throttledHandleResize);
    };
  }, [delay]);

  return size;
}

/**
 * @description Tries to fit as much buttons without their contents overflowing nad shrinks the rest
 * @param buttons The buttons that should be fitted as well as their backup options if not enough space
 * @param container Ref to the container in which the buttons will be placed
 * @return {object} visibleButtons: button elements as whole or the short versions that can be fitted on the space available
 *
 * excessOptions: the backup options of buttons that can't be fitted (should be passed to a MenuButton)
 */
export const useOverflowButtonArrangement = (
  buttons: {
    element: React.ReactElement;
    shortElement?: React.ReactElement;
    option: any;
  }[],
  container?: React.RefObject<HTMLElement>
) => {
  const innerWidth = useWindowSize().innerWidth;
  /** Reference to the automatically detected container element. Can be overwritten by defining container parameter */
  const detectedContainer = useRef<HTMLElement>();
  /** References to the visible buttons */
  const buttonElements = useRef<HTMLElement[]>([]);
  const [visibleButtonsCount, setVisibleButtonsCount] = useState(
    buttons.length
  );
  /** Arbitary value used to re-trigger button fitting */
  const [change, setChange] = useState(0);
  /** Holds the container's minimum required widths to show certain amounts of buttons  */
  const requiredWidths = useRef({});

  // use the container from parameters if that's defined
  const getContainer = () =>
    container ? container.current : detectedContainer.current;

  const setButtonElementRef = index => el => {
    buttonElements.current = [
      ...buttonElements.current.slice(0, index),
      el,
      ...buttonElements.current.slice(index + 1),
    ];
    // we are assuming that the container element is the parent of one of the buttons
    if (el && !detectedContainer.current)
      detectedContainer.current = el.parentElement as HTMLElement;
  };

  /** Hold the previous values so that we can detect changes in them */
  const prev = useRef({
    containerWidth: getContainer()?.getBoundingClientRect().width || 0,
    buttonsLength: buttons.length,
  });
  /* Trigger the button fitting process if changes detected in dependencies */
  useEffect(() => {
    const newWidth = getContainer()?.getBoundingClientRect().width || 0;

    // Redo the whole process if the number of buttons has changed
    if (prev.current.buttonsLength !== buttons.length) {
      requiredWidths.current = {};
      setVisibleButtonsCount(buttons.length);
      setChange(new Date().getTime()); // trigger button fitting
    }
    // The container width has changed -> check again if we can fit more buttons
    if (prev.current.containerWidth !== newWidth)
      setChange(new Date().getTime()); // trigger button fitting

    prev.current = { containerWidth: newWidth, buttonsLength: buttons.length };
  }, [
    getContainer()?.getBoundingClientRect().width || undefined,
    innerWidth,
    buttons.length,
  ]);

  /* Button fitting process:
     Measure if the current visible buttons overflow, and reduce the number of visible buttons
     or check if we can fit more visible buttons if they don't overflow. */
  useLayoutEffect(() => {
    const overflow = buttonElements.current
      .filter(ref => !!ref)
      .map(ref => ref.scrollWidth - ref.clientWidth)
      .reduce((a, c) => a + c, 0);
    const containerWidth = getContainer()?.scrollWidth || 0;
    if (overflow > 0 && visibleButtonsCount > 0) {
      // current amount of buttons results in overflow
      setVisibleButtonsCount(x => Math.max(0, x - 1));
      // Save the required width to display the current amount of buttons
      // so that we can possibly show more buttons if the container will widen
      requiredWidths.current = {
        ...requiredWidths.current,
        [visibleButtonsCount]:
          // add some extra padding so that we don't push the limits in expense of the visual congestion
          containerWidth + overflow + visibleButtonsCount * 10,
      };
    } else if (
      overflow <= 0 &&
      containerWidth >= requiredWidths.current[visibleButtonsCount + 1]
    ) {
      // the container is wide enough to include more buttons
      setVisibleButtonsCount(x => Math.max(0, x + 1));
    }
  }, [change, visibleButtonsCount]);

  const visibleButtons = buttons.map((button, index) =>
    index >= visibleButtonsCount
      ? button.shortElement
        ? React.cloneElement(button.shortElement, {
            ref: setButtonElementRef(index),
            key: index,
          })
        : null
      : React.cloneElement(button.element, {
          ref: setButtonElementRef(index),
          key: index,
        })
  );
  const excessOptions = buttons
    .slice(
      visibleButtonsCount +
        buttons.slice(visibleButtonsCount).filter(x => x.shortElement).length
    )
    .map(x => x.option);
  return { visibleButtons, excessOptions };
};

/** Run an effect after window size changes, throttled by default for 500ms */
export function useWindowSizeChangeEffect(
  effect: () => void,
  deps: unknown[] = [],
  throttle = 500
) {
  const size = useThrottledWindowSize(throttle);

  useLayoutEffect(effect, [size, ...deps]);
}

/** Observes and returns the size of the element */
export function useObservedSize(ref: React.RefObject<HTMLElement>) {
  const [size, setSize] = useState<
    { width: number; height: number } | undefined
  >(undefined);

  useEffect(() => {
    if (!ref.current || !('ResizeObserver' in window)) {
      setSize(undefined);
      return;
    }
    const resizeObserver = new ResizeObserver(
      (entries: ResizeObserverEntry[]) => {
        entries.forEach(entry => {
          const contentBoxSize: ResizeObserverSize = Array.isArray(
            entry.contentBoxSize
          )
            ? entry.contentBoxSize[0]
            : entry.contentBoxSize;
          setSize({
            width: contentBoxSize.inlineSize,
            height: contentBoxSize.blockSize,
          });
        });
      }
    );
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect();
  }, [ref.current]);

  return size;
}
