import React from 'react';
import styled from 'styled-components';

interface Props extends React.ComponentProps<'div'> {
  padding?: number;
}

// eslint-disable-next-line react/display-name
const Column = React.forwardRef<HTMLDivElement, Props>((props, ref) => (
  <Styled {...props} ref={ref}>
    {props.children}
  </Styled>
));

const Styled = styled.div<Props>`
  display: flex;
  flex-direction: column;
  margin: 0 0
    ${({ theme, padding }) =>
      theme.spacing(padding !== undefined ? padding : 2)}
    0;
`;

export default Column;
