import React from 'react';
import { t } from '@lingui/macro';

import { useOpenWorkspace } from '../hooks';

import { useWindowSize } from '~common/utils/layout.utils';
import { File, Fileish, fileExists } from '~common/content.types';
import { isDesktop } from '~common/utils/styled.utils';
import { BasicOption } from '~common/navigation/MenuButton';
import { WorkspaceIcon } from '~common/misc/icons';

type Options = {
  isInSidebar?: boolean;
};

export function useOpenWorkspaceOption(
  workspace: Fileish | undefined,
  options?: Options
): BasicOption {
  const { openWorkspace } = useOpenWorkspace();

  const windowSize = useWindowSize();
  const desktop = isDesktop(windowSize.innerWidth);

  return {
    value: 'open',
    title: options?.isInSidebar
      ? t`Open workspace in center`
      : t`Open workspace on dock`,
    icon: <WorkspaceIcon fontSize="small" />,
    hidden: !fileExists(workspace) || !openWorkspace || !desktop,
    disabled: !fileExists(workspace) || !workspace.isCart,
    onSelect: () => {
      openWorkspace(
        (workspace as File)?.node.id,
        options?.isInSidebar ? 'center' : 'sidebar'
      );
    },
  };
}
