import { createAction, handleActions } from 'redux-actions';
import update from 'immutability-helper';

/**
 * ACTIONS
 */
export const setData = createAction('SET_DATA');
export const setLoading = createAction('SET_LOADING');

/**
 * REDUCERS
 */
const initialState = {
  materials: {
    data: {},
    options: {},
    loading: false,
  },
  diskusage: {
    data: {},
    options: {},
    loading: false,
  },
  loginsUsers: {
    data: {},
    options: {},
    loading: false,
  },
  materialUsage: {
    data: {},
    options: {},
    loading: false,
  },
  personification: {
    data: {},
    options: {},
    loading: false,
  },
};

export default handleActions(
  {
    SET_DATA: (state, { payload: { key, data } }) =>
      update(state, {
        [key]: {
          data: { $set: data.data },
          options: { $set: data.options },
        },
      }),
    SET_LOADING: (state, { payload: { key, data } }) =>
      update(state, {
        [key]: {
          loading: { $set: data },
        },
      }),
  },
  initialState
);

/**
 * SELECTORS
 */
export const getData = state => state.dashboard;
