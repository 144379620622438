import styled from 'styled-components';

interface Props {
  padding?: number;
  paddingHoriz?: number;
  maxHeight?: string;
  onClick?: (event: any) => void;
}

const Content = styled.div<Props>`
  width: 100%;
  flex-grow: 1;
  position: relative;
  overflow-y: auto;
  outline: none;

  max-height: ${({ maxHeight }) => maxHeight || 'initial'};

  padding: ${({ theme, padding }) => theme.spacing(padding ?? 2)}
    ${({ theme, padding, paddingHoriz }) =>
      theme.spacing(paddingHoriz ?? padding ?? 3)};

  @media screen and (max-width: 1199px) {
    overflow-y: initial;
    padding-left: ${({ theme, padding }) => theme.spacing(padding ?? 2)};
    padding-right: ${({ theme, padding }) => theme.spacing(padding ?? 2)};
  }
`;

export default Content;
