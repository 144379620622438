import React, { useMemo } from 'react';
import { InputAdornment } from '@mui/material';
import { connect } from 'react-redux';
import styled from 'styled-components';
import TextField from './TextField';
import { FoldersIcon } from '~common/misc/icons';
import ModalButton from '~common/navigation/ModalButton';
import { isTreeMetaField } from '~common/content.types';
import {
  getNodePath,
  parseMetaFieldTree,
  useCustomerMetaFields,
} from '~common/content.utils';
import { app } from '~common/app.model';
import { Lang } from '~common/utils/i18n';

interface Props {
  value: string[];
  onChange: (newValue: string[]) => void;
  setOpenModal: (modalType: string, modalProps: any) => void;
  metaFieldId: string;
  language: Lang;
  label?: any;
  labelText?: any;
  disabled?: boolean;
  readOnly?: boolean;
}

/** TODO: this is basically copypaste from `FormTreeField.tsx`, when we no
 * longer have underlined inputs lets remove this and only use the other one
 *
 * @deprecated */
const TreeField = ({
  value,
  onChange,
  metaFieldId,
  language,
  setOpenModal,
  label,
  labelText,
  readOnly,
  ...rest
}: Props) => {
  const metaFields = useCustomerMetaFields();
  const metaField = metaFields.find(field => field.id === metaFieldId);

  const treeData = useMemo(() => {
    if (!metaField || !isTreeMetaField(metaField) || !metaField.valueTree) {
      return undefined;
    }
    return parseMetaFieldTree(metaField.valueTree);
  }, [metaFields]);

  const nodes = useMemo(() => (Array.isArray(value) ? value : []), [value]);

  const modalProps = {
    initialNodes: nodes,
    onConfirm: nodes => onChange(nodes.filter(Boolean)),
    metaField,
    readOnly,
  };

  const paths = useMemo(
    () =>
      nodes
        .map(id => getNodePath(id, language, treeData?.nodesById).fullPath)
        .filter(Boolean)
        .join(', '),
    [nodes, treeData]
  );

  return (
    <TextField
      {...rest}
      label={label}
      labelText={labelText}
      onClick={() => metaField && setOpenModal('SELECT/CATEGORY', modalProps)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <StyledButton
              modalType="SELECT/CATEGORY"
              modalProps={modalProps}
              isIconButton
              disabled={rest.disabled}
            >
              <FoldersIcon />
            </StyledButton>
          </InputAdornment>
        ),
        readOnly: true,
      }}
      value={
        // as to why an empty string does not reset the value I no has answers
        paths || ' '
      }
    />
  );
};

const StyledButton = styled(ModalButton)`
  padding: ${props => props.theme.spacing(1)} !important;
  margin-bottom: ${props => props.theme.spacing(1)} !important;
`;

export default connect(
  state => ({
    language: state.app.settings?.language,
  }),
  {
    setOpenModal: app.actions.setOpenModal,
  }
)(TreeField);
