import qs from 'qs';
import { t } from '@lingui/macro';
import {
  CommonNews,
  CommonNewsListItem,
  NewsListItem,
  NewsObject,
  NewsPiece,
} from './types';
import { apiBase } from '~common/api.base';
import { app } from '~common/app.model';
import { afterQueryMessages } from '~common/utils/api.utils';
import { TranslatedData } from '~common/common.types';

export type NewsOrderField =
  | 'title'
  | 'description'
  | 'public_from'
  | 'stay_on_top'
  | 'show_in_popup'
  | 'family_name'
  | 'modified'
  | 'public_from'
  | 'public_until'
  | 'created';

export type GetNewsParams = {
  order?: NewsOrderField | `${NewsOrderField}_desc`;
  page: number;
  pageSize: number;
  filter?: string;
  groupId?: `${number}`;
  status?: 'management' | 'customer';
  all?: 'true';
};

interface NewsChange {
  title: TranslatedData;
  description: TranslatedData;
  content?: TranslatedData;
  showPopup: boolean;
  stickyBit: boolean;
  publishTime?: string;
  endTime?: string;
  groupIds: string[];
  sendByEmail: boolean;
}

interface UpdateNewsChange extends NewsChange {
  id: string;
}

const getNewsTag = (newsId: string) => ({
  type: 'News' as const,
  id: newsId,
});

const getFrontpageNewsTag = (lang: string) => ({
  type: 'FrontpageNews' as const,
  id: lang,
});

export type NewsId = `${number}`;

const extendedApi = apiBase.injectEndpoints({
  endpoints: builder => ({
    getNews: builder.query<CommonNews, { id: Number }>({
      query: ({ id }) => ({
        url: `/news/${id}`,
        method: 'get',
      }),
      providesTags: (_, __, { id }) => [getNewsTag(`${id}`)],
    }),

    getAllNews: builder.query<CommonNewsListItem[], GetNewsParams>({
      query: ({ page, ...params }) => ({
        url: `/news?${qs.stringify({
          include: 'object',
          order: params.order,
          filter: params.filter,
          retrieve_size: params.pageSize,
          index: page * params.pageSize,
          type: 2,
          show_all_system_news: false,
          show_only_customer_news: true,
        })}`,
        method: 'get',
      }),
      providesTags: result => [
        ...(result ?? []).map(({ id }) => getNewsTag(`${id}`)),
        'News',
      ],
    }),

    updateNews: builder.mutation<void, UpdateNewsChange>({
      query: data => ({
        url: `news/${data.id}`,
        method: 'put',
        data,
      }),
      onQueryStarted: (_, args) =>
        afterQueryMessages(
          t`News update successful`,
          t`News update failed`,
          args
        ),
      invalidatesTags: (_, __, { id }) => [getNewsTag(`${id}`)],
    }),

    createNews: builder.mutation<{ status: string }, NewsChange>({
      query: data => ({ url: 'news/add', method: 'post', data }),
      onQueryStarted: (_, args) =>
        afterQueryMessages(
          t`News create successful`,
          t`News create failed`,
          args
        ),
      invalidatesTags: ['News'],
    }),

    removeNews: builder.mutation<void, string[]>({
      query: ids => ({
        url: `/news?ids=${ids.join(',')}`,
        method: 'delete',
      }),
      invalidatesTags: (_, __, arg) => arg.map(id => getNewsTag(id)),
    }),

    fetchNews: builder.query<NewsListItem[], void>({
      query: () => ({ url: '/news', method: 'get' }),
    }),

    fetchNewsPiece: builder.query<NewsPiece, string>({
      query: id => ({ url: `/news/${id}`, method: 'get' }),
    }),

    fetchFrontpageNews: builder.query<
      NewsListItem[],
      { limit?: number; lang?: string }
    >({
      query: ({ limit, lang }) => ({
        url: `/news/frontpage`,
        method: 'get',
        params: { limit: limit ?? 15, lang },
      }),
      providesTags: (_, __, { lang }) => [getFrontpageNewsTag(lang ?? '')],
    }),

    fetchUnreadNews: builder.query<NewsPiece[], string>({
      queryFn: async (userId, _, __, fetch) => {
        // Unread news depends on the current user, so we need to define
        // a customized query where we can run multiple requests based on
        // the return value of another
        const unreadNews = await fetch({
          url: `/users/${userId}/unreadNews?include=object`,
          method: 'get',
        });

        if (unreadNews.error) throw unreadNews.error;

        const newsResponse = unreadNews.data
          ? await Promise.all(
              (unreadNews.data as NewsObject[]).map(newsPiece =>
                'object' in newsPiece
                  ? { data: newsPiece.object }
                  : fetch({ url: `/news/${newsPiece.id}`, method: 'get' })
              )
            )
          : [];

        if (newsResponse.some(responseItem => responseItem.error)) {
          return {
            error: newsResponse.find(responseItem => responseItem.error) as {
              status?: number | undefined;
              data?: any;
            },
          };
        } else {
          const newsData = newsResponse.map(newsItem => newsItem.data);
          return { data: newsData as NewsPiece[] };
        }
      },
    }),

    markAsRead: builder.mutation<NewsPiece, string>({
      query: id => ({ url: `/news/${id}/read`, method: 'put' }),
    }),
  }),
});

export const {
  useGetNewsQuery,
  useGetAllNewsQuery,
  useCreateNewsMutation,
  useRemoveNewsMutation,
  useUpdateNewsMutation,
  useFetchNewsQuery,
  useFetchNewsPieceQuery,
  useFetchFrontpageNewsQuery,
  useFetchUnreadNewsQuery,
  useMarkAsReadMutation,
} = extendedApi;
