import React from 'react';
import styled from 'styled-components';
import { t } from '@lingui/macro';

import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import { requestIsClosed } from 'src/comments/common/utils';
import ItemThumbnailImage from '~common/items/ItemThumbnailImage';
import { ApproveIcon, EditIcon } from '~misc/icons';
import IconButton from '~common/inputs/IconButton';

interface Props {
  pageNumber: string;
  thumbnailUrl: string;
  selected: boolean;
  onclick: () => void;
  approved: boolean;
  disapproved: boolean;
  leftAlign?: boolean;
  handleEditChange: (approved: boolean, pageNumber: string) => void;
}

const RequireChangesButton = ({ onClick }) => {
  return (
    <StyledToolTip placement="right" title={t`Require changes on this page`}>
      <IconButton
        onClick={onClick}
        color="inherit"
        aria-label={t`Require changes on this page`}
      >
        <EditIcon />
      </IconButton>
    </StyledToolTip>
  );
};

const ApprovePageButton = ({ onClick }) => {
  return (
    <StyledToolTip placement="right" title={t`Approve this page`}>
      <IconButton
        onClick={onClick}
        color="inherit"
        aria-label={t`Approve this page`}
      >
        <ApproveIcon />
      </IconButton>
    </StyledToolTip>
  );
};

const PageItem: React.FC<Props & React.HTMLProps<HTMLButtonElement>> = ({
  pageNumber,
  thumbnailUrl,
  selected,
  onclick,
  approved,
  disapproved,
  leftAlign,
  handleEditChange,
}) => {
  const commentingReady = useSelector(state => state.comments.commentingReady);
  const commentRequest = useSelector(state => state.comments.commentRequest);

  return (
    <PageItemWrapper selected={selected}>
      <div onClick={onclick}>
        <PageID>{pageNumber}</PageID>
        <PageThumbnail
          key={pageNumber}
          name={`${pageNumber}`}
          thumbnailUrl={thumbnailUrl}
          isPageThumbnail
          approved={approved}
          leftAlign={leftAlign}
        />
        {approved && (
          <IconOverlay>
            <ApproveIcon aria-label={t`Page approved`} />
          </IconOverlay>
        )}
        {disapproved && (
          <IconOverlay color="orange">
            <EditIcon aria-label={t`Page disapproved`} />
          </IconOverlay>
        )}
      </div>

      {commentingReady &&
        commentRequest &&
        !requestIsClosed(commentRequest?.expirationTime) && (
          <IconsWrapper $selected={selected}>
            {(approved || disapproved) &&
              (!approved ? (
                <ApprovePageButton
                  onClick={() => handleEditChange(true, pageNumber)}
                />
              ) : (
                <RequireChangesButton
                  onClick={() => handleEditChange(false, pageNumber)}
                />
              ))}
            {!approved && !disapproved && (
              <>
                <RequireChangesButton
                  onClick={() => handleEditChange(false, pageNumber)}
                />
                <ApprovePageButton
                  onClick={() => handleEditChange(true, pageNumber)}
                />
              </>
            )}
          </IconsWrapper>
        )}
    </PageItemWrapper>
  );
};

/* Copied from products */
const PageItemWrapper = styled.div<any>`
  display: block;
  position: relative;
  height: 10rem;
  width: 100%;
  background-color: ${props =>
    props.selected && props.theme.palette.primary.light};

  &:hover {
    background-color: ${props =>
      !props.selected && props.theme.palette.primary.lightFade};
    cursor: pointer;
  }
`;

const PageID = styled.div`
  position: absolute;
  top: 0.8rem;
  left: 1.5rem;
`;

const StyledToolTip = styled(Tooltip)`
  &:hover {
    background-color: ${p => p.theme.palette.primary.darkFade};
  }
`;

interface ThumbnailProps {
  approved?: boolean;
  leftAlign?: boolean;
  theme: any;
}

export const PageThumbnail = styled(ItemThumbnailImage)<ThumbnailProps>`
  && {
    object-fit: cover;
    ${props =>
      !props.leftAlign
        ? `
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `
        : `
      margin-left: 0;
      border: 0;
    `}
    border-color: ${props =>
      props.approved && props.theme.palette.commenting.status.success};
    width: auto;
    height: auto;
    max-height: 75%;
    max-width: 70%;
  }
`;

const IconsWrapper = styled.div<{ $selected?: boolean }>`
  display: flex;
  flex-direction: column;
  float: right;
  color: ${p => (p.$selected ? 'white' : p.theme.palette.primary.main)};
`;

const IconOverlay = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: ${props =>
    props.color || props.theme.palette.commenting.status.success};
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  > .MuiSvgIcon-root {
    fill: white;
    width: 2.5rem;
    height: 2.5rem;
  }
`;

export default PageItem;
