import React from 'react';
import styled from 'styled-components';
import Typography from '~misc/Typography';
interface WrapperProps {
  deleteOptio?: boolean;
}
const SynkkaDiv = styled.div<WrapperProps>`
  && {
    top: auto;
    bottom: 0px;
    border-radius: 100%;
    text-align: center;
    width: 20px;
    height: 20px;
    background-color: gray;
    color: white;
    font-size: 12px;
    text-transform: uppercase;
    ${props =>
      props.deleteOptio &&
      `background-image: linear-gradient(to left top, transparent 47%, currentColor 47%, currentColor 53%, transparent 53%);
    `}
  }
`;

interface IconProps {
  deleteOptio?: true;
}
const Icon = ({ deleteOptio }: IconProps) => {
  return (
    <SynkkaDiv deleteOptio={deleteOptio}>
      <Typography variant="caption">S</Typography>
    </SynkkaDiv>
  );
};

export default Icon;
