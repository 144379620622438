import React, { useState } from 'react';
import styled from 'styled-components';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  ListItemText,
  ListItemButton,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails,
  AccordionSummaryProps,
  AccordionProps,
  List,
  ListItemIcon,
} from '@mui/material';
import { t } from '@lingui/macro';

import NaviLinkBase from './NaviLink';

import { app } from '~common/app.model';
import Typography from '~common/misc/Typography';
import { useActions } from '~common/utils/hooks.utils';
import { OpenInNewIcon } from '~common/misc/icons';

type ListItemProps = {
  text: string;
  href: string;
  'data-test': string;
  external?: boolean;
  hidden?: boolean;
};

type AccordionListItemProps = {
  text: string;
  childItems: ListItemProps[];
};

export function NavigationListItem({ text, href, ...rest }: ListItemProps) {
  const { toggleDrawer } = useActions(app.actions);

  const closeDrawer = () => {
    toggleDrawer(false);
  };

  return (
    <li>
      <NaviLink
        to={href}
        data-test={rest['data-test']}
        onClick={closeDrawer}
        aria-labelledby={`link-text-${text.replace(/ /g, '').toLowerCase()}`}
      >
        <LinkText text={text} />
      </NaviLink>
    </li>
  );
}

export function AccordionListItem({
  text,
  childItems,
}: AccordionListItemProps) {
  const [expanded, setExpanded] = useState(false);
  if (childItems.every(item => item.hidden)) return null;
  return (
    <li>
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary aria-label={text}>
          <AccordionItem text={text} expanded={expanded} />
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {childItems
              .filter(item => !item.hidden)
              .map(childItem =>
                childItem.external ? (
                  <AdminListItem
                    text={childItem.text}
                    key={childItem.href}
                    href={childItem.href}
                    data-test={childItem['data-test']}
                  />
                ) : (
                  <NavigationListItem
                    text={childItem.text}
                    key={childItem.href}
                    href={childItem.href}
                    data-test={childItem['data-test']}
                  />
                )
              )}
          </List>
        </AccordionDetails>
      </Accordion>
    </li>
  );
}

export function AdminListItem({ text, href, ...rest }: ListItemProps) {
  const { toggleDrawer } = useActions(app.actions);

  const closeDrawer = () => {
    toggleDrawer(false);
  };

  return (
    <li>
      <ALink
        aria-labelledby={`link-text-${text.replace(/ /g, '').toLowerCase()}`}
        href={href}
        data-test={rest['data-test']}
        target="_blank"
        onClick={e => {
          e.preventDefault();
          closeDrawer();
          window.open(href);
        }}
      >
        <LinkText text={text} openInNew />
      </ALink>
    </li>
  );
}

export function LinkText({
  text,
  openInNew,
}: {
  text: string;
  openInNew?: boolean;
}) {
  return (
    <ListItemButton tabIndex={-1}>
      <ListItemText
        disableTypography
        primary={
          <>
            <CenteredTypography
              variant="button"
              color="primary"
              style={{ whiteSpace: 'normal' }}
              id={`link-text-${text.replace(/ /g, '').toLowerCase()}`}
            >
              {openInNew && (
                <ListItemIcon aria-label={t`Open in a new tab:`}>
                  <OpenInNewIcon color="primary" fontSize="small" />
                </ListItemIcon>
              )}
              {text}
            </CenteredTypography>
          </>
        }
      />
    </ListItemButton>
  );
}

function AccordionItem({
  text,
  expanded,
}: {
  text: string;
  expanded: boolean;
}) {
  return (
    <ListItemButton tabIndex={-1}>
      <ListItemText
        disableTypography
        primary={
          <>
            <CenteredTypography variant="button" color="primary">
              {text}
              {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </CenteredTypography>
          </>
        }
      />
    </ListItemButton>
  );
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: 'none',
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  margin: 0,
  padding: 0,
  border: 'none',
  '& .MuiAccordionSummary-content': {
    margin: theme.spacing(0),
  },
}));

// REFACTOR: NaviLink should support external links also
const ALink = styled.a`
  display: block;
  text-decoration: none;
`;

const NaviLink = styled(NaviLinkBase)`
  color: unset;
`;

const CenteredTypography = styled(Typography)`
  display: flex;
  align-items: center;
`;
