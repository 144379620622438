import React from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';

import { Button } from '@mui/material';
import { ProductPageThumbnail } from '../common/types';
import Typography from '~misc/Typography';
import ItemThumbnailImage from '~common/items/ItemThumbnailImage';

interface Props {
  onAddPage: (id: number) => void;
  onAddMassImportPages: () => void;
  pageTemplates: ProductPageThumbnail[];
  setOpen: (open: boolean) => void;
  isMassImport?: boolean;
}

const AddPage: React.FC<Props> = ({
  setOpen,
  pageTemplates,
  onAddPage,
  onAddMassImportPages,
  isMassImport = false,
}) => (
  <AddPageWrapper>
    <Title variant="h5">
      <Trans>CHOOSE A TEMPLATE FOR THIS PAGE</Trans>
    </Title>
    <Templates>
      {pageTemplates.map(template => (
        <PageThumbnail
          key={template.id}
          name={`${template.id}`}
          thumbnailUrl={template.thumbnailUrl}
          isPageThumbnail
          onclick={() => {
            setOpen(false);
            onAddPage(template.id);
          }}
        />
      ))}
    </Templates>
    {isMassImport && (
      <Button
        variant="text"
        color="primary"
        size="small"
        onClick={() => {
          setOpen(false);
          onAddMassImportPages();
        }}
      >
        <Trans>Add mass import pages</Trans>
      </Button>
    )}
  </AddPageWrapper>
);

const AddPageWrapper = styled.div`
  padding: 1rem;
  position: relative;
  height: 100%;
  width: 100%;
`;

const Templates = styled.div`
  display: flex;
  padding: 1rem;
  max-width: 500px;
  justify-content: space-between;
  align-items: space-between;
  position: relative;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  overflow: auto;
`;

// TODO: Use consistently with variant, font-size here is temporary
const Title = styled(Typography)`
  && {
    font-size: 10pt;
    text-align: center;
  }
`;

const PageThumbnail = styled(ItemThumbnailImage)`
  display: flex;
  object-fit: contain;
  margin: 0.5rem;
  width: 6rem;
  height: 6rem;
  cursor: pointer;
  &:hover {
    box-shadow: ${({ theme }) => theme.shadows[4]};
  }
` as typeof ItemThumbnailImage;

export default AddPage;
