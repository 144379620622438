import React from 'react';
import { connect } from 'react-redux';
import { IconButtonProps } from '@mui/material/IconButton';

import { app } from '~common/app.model';
import Button from '~inputs/Button';
import IconButton from '~inputs/IconButton';
import ShareButton from '~inputs/ShareButton';

interface Props extends IconButtonProps {
  children?: any;
  modalType: string;
  modalProps?: object;
  openModalType: string;
  setOpenModal: (modalType: string, modalProps?: object) => void;
  // Button props
  isIconButton?: boolean;
  shareButton?: boolean;
  variant?: any;
  size?: any;
  color?: any;
  startIcon?: any;
  className?: any;
  hideText?: boolean;
}

const ModalButton = (
  {
    children,
    modalType,
    modalProps,
    openModalType,
    shareButton = false,
    setOpenModal,
    isIconButton = false,
    ...buttonProps
  }: Props,
  ref
) => {
  const handleSetOpen = open => {
    if (open) setOpenModal(modalType, modalProps);
  };

  return (
    <>
      {shareButton && (
        <ShareButton
          onClick={() => {
            handleSetOpen(true);
          }}
          {...buttonProps}
          ref={ref}
        />
      )}
      {!shareButton &&
        (isIconButton ? (
          <IconButton
            size="large"
            {...buttonProps}
            ref={ref}
            onClick={() => {
              handleSetOpen(true);
            }}
          >
            {children}
          </IconButton>
        ) : (
          <Button
            onClick={() => {
              handleSetOpen(true);
            }}
            {...buttonProps}
            ref={ref}
          >
            {children}
          </Button>
        ))}
    </>
  );
};

export default connect(
  (state: any) => ({
    openModalType: state.app.openModalType,
  }),
  {
    setOpenModal: app.actions.setOpenModal,
  },
  null,
  { forwardRef: true }
)(React.forwardRef(ModalButton));
