import styled from 'styled-components';
import MaterialAppBar from '@mui/material/AppBar';

const AppBar = styled(MaterialAppBar)<any>`
  @media print {
    display: none !important;
  }

  #menuButton,
  #backButton button {
    margin-left: -12px;
    margin-right: ${props => props.theme.spacing(1)};
  }

  @media screen and (max-width: 1199px) {
    && {
      .MuiToolbar-gutters {
        padding-left: 12px;
        padding-right: 16px;
      }
    }
  }
`;

export default AppBar;
