import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiSwitch from '@mui/material/Switch';
import styled from 'styled-components';

type Props = React.ComponentProps<typeof MuiSwitch> & {
  label: string;
};

/** Controlled switch component. */
export const Switch = React.forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const { label, value, ...restProps } = props;

    return (
      <StyledLabel
        label={label}
        control={<StyledSwitch checked={!!value} {...restProps} ref={ref} />}
      />
    );
  }
);

Switch.displayName = 'Switch';

const StyledLabel = styled(FormControlLabel)`
  margin-right: 0;
`;

const StyledSwitch = styled(MuiSwitch)`
  & :not(.Mui-checked) .MuiSwitch-thumb {
    border: 1px solid ${p => p.theme.palette.grey[300]};
  }
`;
