import React from 'react';
import withTheme from '@mui/styles/withTheme';
import { Theme } from '~common/theme';

interface Props {
  theme: Theme;
  width?: string;
  height?: string;
  backgroundColor?: string;
}

const Icon: React.FC<Props> = ({ theme, width, height, backgroundColor }) => {
  // Original black colors: #000000, #020202
  // Old lightgrey colors: #dddddd, #dfdfdf
  const color = theme.palette.primary.main;
  const lightColor = theme.palette.primary.lighter;
  // enableBackground: 'new 0 0 100 100',
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '80'}
      height={height || '80'}
      viewBox="0 0 100 100"
      style={
        {
          marginTop: '8px',
          stroke: backgroundColor || color,
          enableBackground: 'new 0 0 100 100',
        } as any
      }
    >
      <g>
        <path
          style={
            {
              fill: 'none',
              strokeWidth: '5.0622',
              strokeLinecap: 'round',
              strokeMiterlimit: '10',
            } as any
          }
          d="M3.1,45.1c0,0,37.3,25.4,39.5,25.4s54.4-31.1,54.4-31.1"
        />
        <path
          style={
            {
              fill: 'none',
              stroke: backgroundColor || lightColor,
              strokeWidth: '5.0622',
              strokeLinecap: 'round',
              strokeMiterlimit: '10',
            } as any
          }
          d="M3.1,56.2c0,0,3.3,2.3,8,5.4c11.1,7.5,29.9,20,31.5,20c2.2,0,54.4-31.1,54.4-31.1"
        />
        <path
          style={
            {
              fill: 'none',
              strokeWidth: '5.0622',
              strokeLinecap: 'round',
              strokeMiterlimit: '10',
            } as any
          }
          d="M3.1,67.4c0,0,3.3,2.3,8,5.4c11.1,7.5,29.9,20,31.5,20c2.2,0,54.4-31.1,54.4-31.1"
        />
        <path
          style={
            {
              fill: 'none',
              strokeWidth: '3.6448',
              strokeMiterlimit: '10',
            } as any
          }
          d="M3.1,33.3c0,0.5,9.1,6.7,18.6,12.9c7.1,4.6,37.4,3.4,47.7-2.3C81.8,37,94,29.4,96.5,27.9
  		c0.2-0.1,0.2-0.4,0-0.5L58.6,7.3c-0.2-0.1-0.5-0.1-0.7,0C53,9.4,3.1,32.3,3.1,33.3z"
        />
        <path
          style={
            {
              fill: backgroundColor || color,
            } as any
          }
          d="M15.5,40.9c0.1,0,0.1,0,0.2,0c1,0.1,8.8,0.5,14.2-2.3c6.1-3.2,9.9-9.5,12.8-9.5c3,0,10.9,10.2,17.5,11.1
  		c4,0.5,11.9-1.1,15-1.8c0.2-0.1,0.4,0.3,0.2,0.4l-17,10.3l-29.5,1.2l-14.3-8.8c-0.2-0.1-0.1-0.4,0.1-0.4L15.5,40.9z"
        />
      </g>
    </svg>
  );
};

export default withTheme(Icon);
