import { useMemo, useCallback } from 'react';
import qs, { ParsedQs } from 'qs';
import { useHistory, useLocation } from 'react-router-dom';

export const isIOS = () =>
  (/iPad|iPhone|iPod/.test(navigator.userAgent) ||
    // iPad version < 13
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)) &&
  !(window as any).MSStream;

export const isAndroid = () => navigator.userAgent.match(/Android/i);

/** Like useState, but keeps the state in locations search query */
export function useSearchParams<Params extends ParsedQs = ParsedQs>() {
  const { search } = useLocation();
  const history = useHistory();

  /** Search query as parsed object */
  const searchParams: Params = useMemo(() => {
    const params = search.startsWith('?') ? search.substring(1) : search;
    return qs.parse(params) as Params;
  }, [search]);

  /** Manipulates the history and saves the parameters in search query
   * either replacing the current location or by pushing a new entry */
  const setSearchParams = useCallback(
    (
      params: Params | ((params: Params) => Params),
      method: 'replace' | 'push' = 'replace'
    ) => {
      const newParams =
        typeof params === 'function' ? params(searchParams) : params;
      const searchQuery = qs.stringify(newParams);
      if (method === 'replace') {
        history.replace({ search: searchQuery });
      } else {
        history.push({ search: searchQuery });
      }
    },
    [searchParams]
  );

  return [searchParams, setSearchParams] as const;
}
