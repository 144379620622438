import React from 'react';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro';

import { getNonFolderIds } from './utils';
import { MagentoIcon } from '~common/misc/icons';
import { BasicOption } from '~common/navigation/MenuButton';
import { useActions, useSelection } from '~common/utils/hooks.utils';
import { getMagentoLink } from '~common/content.api';
import { app } from '~common/app.model';
import { File } from '~common/content.types';
import { Customer } from '~common/app.types';
import { Lang } from '~common/utils/i18n';

interface Options {
  /** Ignore folders from selection */
  skipFolders?: boolean;
}

/** Returns an option to be used to order items from Magento */
export function useMagentoOption(
  fileIds: string[],
  options?: Options
): BasicOption {
  const customer = useSelector(state => state.app.customer);
  const language = useSelector(state => state.app.settings?.language);
  const customerId = customer?.id;
  const filesById = useSelector(state => state.commonContent.filesById);
  const userRights = useSelector(state => state.app.userRights);
  const showErrorMessage = useActions(app.actions.showErrorMessage);

  const filteredFileIds = options?.skipFolders
    ? getNonFolderIds(fileIds, filesById)
    : fileIds;

  const { selection } = useSelection(new Set(filteredFileIds), items => ({
    hasValidFiles: items.some(item =>
      canOrderFromMagento(item, customer, language)
    ),
    onlyValidFiles: items.every(item =>
      canOrderFromMagento(item, customer, language)
    ),
  }));

  return {
    value: 'order-from-magento',
    title: t`Order as a print product`,
    icon: <MagentoIcon />,
    disabled: !selection.onlyValidFiles,
    hidden: !userRights?.MAGENTO_ORDER || !selection.hasValidFiles,
    onSelect: async () => {
      if (!customerId) return;
      const redirectUrl = await getMagentoLink(customerId, filteredFileIds);
      if (redirectUrl) window.open(redirectUrl, '_blank');
      else showErrorMessage(t`Magento order failed`);
    },
  };
}

export function canOrderFromMagento(
  item?: File,
  customer?: Customer | null,
  language?: Lang
) {
  const magentoProductTypesMetaId =
    customer?.configById['metafield.magento.product.type'];

  if (
    item &&
    !item.isFolder &&
    !item.isMasterProduct &&
    magentoProductTypesMetaId
  ) {
    const productTypesMetafield =
      item?.metaValuesById &&
      item?.metaValuesById[Number(magentoProductTypesMetaId)];
    return productTypesMetafield && productTypesMetafield[language ?? 'fi'];
  }
  return false;
}
