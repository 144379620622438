import {
  handleActions,
  createActions,
  setValueTo,
  overwriteWithPayload,
  extendWithPayload,
} from '~utils/ducks.utils';
import { FileAttachmentId, HandleFileConflict } from '~common/content.types';
import { ContentModeType, TranslatedData } from '~common/common.types';
import { getCurrentFolderId } from '~common/content.utils';
// TODO
type MetaFieldSuggestion = any;
export interface ContentState {
  checkedContent?: {
    items: Set<string>;
    contentId: `${number}` | 'search';
  };
  shareLoading: boolean;
  saveLoading: boolean;
  loading: boolean;
  metaFieldSuggestionsById: { [id: string]: MetaFieldSuggestion };
  mode?: ContentModeType;
}
export interface ShareObject {
  fileId: string;
  folderId: string;
  subject: string;
  recipients: string;
  message: string;
  conversions: FileAttachmentId[];
  sendMethod: number;
}
export interface FileToUpdate {
  id: string;
  name: string;
  propertiesById: Record<string, string>;
  metaById: Record<string, string | null>;
  infoByLang?: TranslatedData<string>;
  versioning?: boolean;
  successInfoMessage?: string;
  failedInfoMessage?: string;
}

const initialState: ContentState = {
  checkedContent: undefined,
  shareLoading: false,
  saveLoading: false,
  loading: false,
  metaFieldSuggestionsById: {},
  mode: undefined,
};

type FileConflict = {
  conflictStrategy: HandleFileConflict;
  conflictIds?: string[];
};

// TODO: most actions not typed
const actions = createActions('CONTENT', {
  // Product
  createProduct: (
    templateId: string,
    copyProduct = false,
    redirectInPlace = false
  ) => ({
    templateId,
    copyProduct,
    redirectInPlace,
  }),
  // File
  shareFile: (share: ShareObject) => ({ share }),
  updateFile: (file: FileToUpdate) => ({ file }),
  updateFiles: (files: FileToUpdate[]) => ({ files }),
  manageSynkka: (ids: string[], options = {}) => ({ ids, options }),
  // Folder
  createFolder: (folder, disableRefresh) => ({ folder, disableRefresh }),
  createSubfolder: (createProps, selectProps) => ({
    createProps,
    selectProps,
  }),
  afterCreateFolder: folderId => ({ folderId }),
  // Content
  setItemChecked: (item: string, checked) => ({ item, checked }),
  setItemsChecked: (items: { node: { id: string } }[], checked) => ({
    items,
    checked,
  }),
  setCheckedContent: (checkedContent?: Set<string>) => ({
    checkedContent,
  }),
  setShareLoading: (shareLoading: boolean) => ({ shareLoading }),
  setSaveLoading: (saveLoading: boolean) => ({ saveLoading }),
  moveContent: (params: {
    destFolderId: string;
    itemIds: string[];
    /** move content or create link, if undefined, the user can select the mode */
    mode?: 'link' | 'move' | 'copy';
    disableSuccessMessage?: boolean;
  }) => params,
  // REFACTOR: move download state/actions/sagas to files/download/model.ts
  downloadContent: download => ({
    download,
  }),
  downloadAsZip: ({
    selectedNodeIds,
    versions,
    workspaceId,
    nameConfigId,
    lang,
  }) => ({
    selectedNodeIds,
    versions,
    workspaceId,
    nameConfigId,
    lang,
  }),
  // Confirm
  confirmMove: (conflict?: FileConflict) => ({ ...conflict }),
  confirmCopy: (conflict?: FileConflict) => ({ ...conflict }),
  confirmCreateLink: (conflict?: FileConflict) => ({ ...conflict }),

  fetchMetaFieldSuggestions: ({ search, metaFieldId, lang }) => ({
    search,
    metaFieldId,
    lang,
  }),
  afterFetchMetaFieldSuggestions: (
    metaFieldSuggestionsById: ContentState['metaFieldSuggestionsById']
  ) => ({ metaFieldSuggestionsById }),

  setMode: (mode?: ContentModeType) => ({ mode }),
});

// Reducer
export default handleActions(initialState)
  .handle(actions.shareFile, setValueTo('loading', true))
  .handle(actions.manageSynkka, setValueTo('loading', true))
  .handle(actions.setShareLoading, overwriteWithPayload)
  .handle(actions.setSaveLoading, overwriteWithPayload)
  .handle(actions.moveContent, setValueTo('loading', true))
  .handle(actions.setCheckedContent, (state, action) => ({
    ...state,
    checkedContent: action.payload.checkedContent && {
      items: action.payload.checkedContent,
      contentId: getCurrentFolderId() ?? 'search',
    },
  }))
  .handle(actions.afterFetchMetaFieldSuggestions, extendWithPayload)
  .handle(actions.setMode, overwriteWithPayload);

// Bundle things in a model
export const content = {
  actions,
  selector: {},
};
