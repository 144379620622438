import React from 'react';
import styled from 'styled-components';
import Box, { BoxProps } from '@mui/material/Box';

interface Props extends BoxProps {
  children: any;
  backgroundColor?: string;
  fullHeight?: boolean;
  theme?: any;
}

const Element = (props: Props) => <Styled {...props}>{props.children}</Styled>;

// TODO: fullHeight
// TODO: get color and background color from theme
const Styled = styled(Box)`
  margin: 0 0 ${(props: Props) => props.theme.spacing(2)} 0;
  height: ${(props: Props) => (props.fullHeight ? '456px' : 'initial')};
  color: ${(props: Props) =>
    props.backgroundColor === 'primary' ? '#ffffff' : 'inherit'};
  background-color: ${(props: Props) =>
    props.backgroundColor === 'primary' ? '#448BA7' : 'inherit'};
`;

export default Element;
