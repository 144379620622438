// NOTE: deprecated

import styled from 'styled-components';

interface Props {
  background?: string;
  theme?: any;
}

const Page = styled.div<Props>`
  display: flex;
  flex-direction: column;

  position: relative;
  height: 100%;
  max-width: 100vw;
  background-color: ${({ background, theme }) =>
    theme.palette.background[background || 'default']};

  @media print {
    background-color: transparent !important;
  }
`;

export default Page;
