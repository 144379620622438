import React from 'react';
import styled from 'styled-components';
import { useDrop } from 'react-dnd';
import CloudUpload from '@mui/icons-material/CloudUpload';

import { dndTypes } from '~common/misc/drag/constants';

type DropItem = {
  type: typeof dndTypes['NATIVE_FILE'];
  files: File[];
};

interface Props {
  addUploadFiles: (files: File[]) => void;
}

export function UploadDropzone({ addUploadFiles }: Props) {
  const [{ isOver, canDrop, isDragging }, drop] = useDrop({
    accept: dndTypes.NATIVE_FILE,
    drop: (item: DropItem) => {
      addUploadFiles(item.files);
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      isDragging: monitor.getItem() && Array.isArray(monitor.getItem().files),
    }),
  });

  return (
    <Zone ref={drop} show={isDragging} canDrop={canDrop} hover={isOver}>
      <UploadIcon />
    </Zone>
  );
}

interface ZoneProps {
  show: boolean;
  hover: boolean;
  canDrop: boolean;
}

const Zone = styled.div<ZoneProps>`
  --right-sidebar-width: 282px;
  --navbar-height: 56px;
  --button-bar-height: 73px;
  --bottom-bar-height: 57px;
  --margin: ${p => p.theme.spacing(1)};

  display: ${p => (p.show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;

  position: fixed;
  min-width: unset;
  width: calc(var(--right-sidebar-width) - var(--margin) * 2);
  top: calc(
    var(--navbar-height) + var(--button-bar-height) * 2 + var(--margin)
  );
  bottom: calc(var(--bottom-bar-height) + var(--margin));
  right: var(--margin);

  background-color: ${p =>
    p.hover
      ? 'rgba(20, 110, 255, 0.7)'
      : p.canDrop
      ? 'rgba(20, 110, 255, 0.5)'
      : 'rgba(20, 110, 255, 0.2)'};
  border-radius: 5px;
  border: 3px blue solid;

  z-index: 999999999;
`;

const UploadIcon = styled(CloudUpload)`
  font-size: 72pt !important;
`;
