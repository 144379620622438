import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { t } from '@lingui/macro';

import { useContentMode } from '../../common/utils';
import { content } from '../../model';
import { useAddToWorkspace } from '../hooks';

import { useGetContentsQuery, useGetFolderQuery } from '~common/content.api';
import { useActions, usePrevious } from '~common/utils/hooks.utils';
import { Results } from '~common/common.types';

export function useShoppingCart() {
  const id = useSelector(state => state.app.shoppingCart?.id);
  const { data, isLoading: cartLoading } = useGetFolderQuery(
    id ? { id } : skipToken
  );

  return { data, isLoading: cartLoading };
}

export function useShoppingCartContents() {
  const id = useSelector(state => state.app.shoppingCart?.id);
  const { data: contents, isLoading: contentLoading } = useGetContentsQuery(
    id ? { id } : skipToken
  );

  return { contents, isLoading: contentLoading };
}

export function useAddToShoppingCart() {
  const id = useSelector(state => state.app.shoppingCart?.id);

  const { addToWorkspace } = useAddToWorkspace({
    customMessages: {
      notAllowed: t`Adding to shopping cart not allowed for some of the selected files.`,
      conflicts: t`Shopping cart already contains file(s)`,
      added: t`File(s) added to shopping cart`,
    },
  });

  const addToShoppingCart = (
    itemIds: string[],
    newNamesById?: Record<string, string>
  ) => id && addToWorkspace({ workspaceId: id, itemIds, newNamesById });

  return { addToShoppingCart };
}

/** Ensures that when the shopping cart is opened in the middle,
 * all of the items are selected by default. And if any items are added,
 * they are also selected by default.
 */
export function usePreselectShoppingCartContent(results: Results) {
  const checkedContentId = useSelector(
    state => state.content.checkedContent?.contentId
  );
  const setItemsChecked = useActions(content.actions.setItemsChecked);
  const { mode } = useContentMode();
  const shoppingCartId =
    useSelector(state => state.app.shoppingCart?.id) || undefined;

  const prevResults = usePrevious(results, res => res.status !== 'loading');

  // Select all shopping cart items if selection not yet made
  useEffect(() => {
    if (
      mode === 'shopping' &&
      results.status !== 'loading' &&
      checkedContentId !== shoppingCartId
    ) {
      setItemsChecked(results.items, true);
    }
  }, [mode, results, checkedContentId, shoppingCartId]);

  // Select newly added items
  useEffect(() => {
    if (mode !== 'shopping' || !prevResults || results.key !== prevResults.key)
      return;
    const newItems = results.items.filter(
      item =>
        !prevResults.items.some(prevItem => prevItem.node.id === item.node.id)
    );
    if (newItems.length) {
      setItemsChecked(newItems, true);
    }
  }, [mode, results]);
}
