import React from 'react';
import styled from 'styled-components';
import StarRateIcon from '@mui/icons-material/StarRate';
import Typography from '~misc/Typography';
import { ShareIcon } from '~common/misc/icons';

interface ItemLabelProps extends React.HTMLAttributes<HTMLDivElement> {
  isLink?: boolean;
  isShared?: boolean;
  inCart?: boolean;
  variant?: string;
  name: string;
  isSearch?: boolean;
  childrenCount?: number | undefined;
}

const ItemLabel = ({
  isLink,
  isShared,
  inCart = false,
  variant,
  name,
  isSearch = false,
  childrenCount,
  ...rest
}: ItemLabelProps) => {
  const displayName = name + (childrenCount ? ` (${childrenCount})` : '');
  return isLink && (!inCart || isSearch) ? (
    <Wrapper {...rest}>
      <StarRateIcon fontSize="inherit" color="inherit" />
      <Typography variant={variant} noWrap>
        {displayName}
      </Typography>
    </Wrapper>
  ) : isShared ? (
    <Wrapper {...rest}>
      <ShareIcon fontSize="inherit" color="primary" />
      <Typography variant={variant} noWrap>
        {displayName}
      </Typography>
    </Wrapper>
  ) : (
    <Typography {...rest} variant={variant} noWrap>
      {displayName}
    </Typography>
  );
};

const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
  gap: ${p => p.theme.spacing(0.5)};
`;

export default ItemLabel;
