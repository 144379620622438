import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { getNotFoundImgSrc } from '~common/utils/img.utils';

interface Props extends Omit<React.HTMLProps<HTMLImageElement>, 'as'> {
  altText?: string;
  thumbnailUrl: string;
  isPageThumbnail?: boolean;
  onclick?: (id: string) => void;
}

const ItemThumbnailImage: React.FC<Props> = ({
  name,
  thumbnailUrl,
  isPageThumbnail,
  altText = '',
  onclick,
  ...props
}) => {
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [showBorder, setShowBorder] = useState(false);

  const showBorderCond = () => {
    isPageThumbnail &&
      imageRef.current &&
      !imageRef.current.src.includes('notfound') &&
      setShowBorder(true);
  };
  return (
    <Thumbnail
      {...props}
      src={thumbnailUrl}
      alt={altText}
      isPageThumbnail={isPageThumbnail}
      onClick={onclick}
      ref={imageRef}
      showBorder={showBorder}
      onLoad={e => {
        props.onLoad && props.onLoad(e);
        showBorderCond();
      }}
      onError={() => {
        if (imageRef.current) {
          imageRef.current.onerror = null;
          imageRef.current.src = getNotFoundImgSrc();
        }
      }}
    />
  );
};

// TODO: Type
const Thumbnail = styled.img<any>`
  height: ${props => (props.isPageThumbnail ? '70px' : '160px')};
  margin: 0 auto;
  display: block;
  border: ${props =>
    props.showBorder
      ? `2px solid ${props.theme.palette.primary.main}`
      : 'none'};
  border-radius: ${props => (props.isPageThumbnail ? '0' : '4px 4px 0px 0px')};
`;

export default ItemThumbnailImage;
