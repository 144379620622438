import { useSelector } from 'react-redux';

import {
  horizontalAlign,
  verticalAlign,
  priceDecimalStyles,
  textDirection,
  ElementConstant,
  getElementTypes,
} from './constants';
import {
  AvailableColor,
  AvailableFont,
  ElementLock,
  ProductElement,
  ProductUnit,
  TextElement,
} from './types';
import { getLangValue } from '~common/app.utils';
import { sanitizeHtml } from '~common/utils/misc.utils';

const POINTS_IN_MM = 0.35277777777778;
const POINTS_IN_INCH = 0.013888888888889;
const INCH_IN_POINTS = 72;

export const getDescription = element => {
  const description: string | undefined = getLangValue(
    element.masterElement.descriptionsByLang
  );
  return description && sanitizeHtml(description);
};

export const parseValuesFromElement = (
  element: ProductElement<TextElement>
) => {
  const { data, size, leading, align, valign, direction } =
    element.element.text;
  const { fillColorId, lineColorId, priceDecimalsSup, fontUuid, lineWidth } =
    element.element;
  const {
    isPercentTextLeading,
    availableFillColors,
    availableLineColors,
    availableFontUuids,
  } = element.masterElement;
  const colorIndex = availableFillColors
    .map(color => color.id)
    .indexOf(fillColorId);
  const color = availableFillColors[colorIndex];
  const lineColor = availableLineColors.find(color => color.id === lineColorId);
  const fontIndex = availableFontUuids.map(font => font.uuid).indexOf(fontUuid);
  const values = {
    text: data,
    fontSize: `${size}pt`,
    htmlFont: availableFontUuids[fontIndex]?.htmlFontFamily
      ? availableFontUuids[fontIndex].htmlFontFamily?.split(',')[0] || ''
      : '',
    color: color && {
      name: getLangValue(color.name),
      value: `#${color.hexValue}`,
    },
    lineColor: lineColor && {
      name: getLangValue(lineColor.name),
      value: `#${lineColor.hexValue}`,
    },
    lineHeight:
      leading === 0
        ? '0.00'
        : isPercentTextLeading
        ? `${leading / 100}`
        : `${leading}pt`,
    lineWidth: parseNumber(lineWidth),
    hAlign: horizontalAlign[align],
    vAlign: verticalAlign[valign],
    priceDecimalStyle:
      priceDecimalStyles[priceDecimalsSup] &&
      priceDecimalStyles[priceDecimalsSup].name,
    font: availableFontUuids[fontIndex] && availableFontUuids[fontIndex].name,
    fontFamily:
      availableFontUuids[fontIndex] && availableFontUuids[fontIndex].familyName,
    textDirection: textDirection[direction] && textDirection[direction].name,
  };
  return values;
};

export type ElementStyles = ReturnType<typeof parseValuesFromElement>;

const names = (options: any) =>
  Object.values(options).map((option: ElementConstant) => option.name);
const parseNumber = (number: number, precision = 3) => {
  if (!number) return '0.00';
  if (number.toString().replace('.', '').length >= precision)
    return number.toString();
  return number.toPrecision(precision);
};

export const parseOptionsFromElement = (
  element: ProductElement<TextElement>
) => {
  const { sizes } = element.element.text;
  const { availableFillColors, availableLineColors, availableFontUuids } =
    element.masterElement;
  const colors = availableFillColors.map(color => getLangValue(color.name));
  const lineColors = availableLineColors.map(color => getLangValue(color.name));
  const fonts = availableFontUuids.map(font => font.name);
  const fontFamilies = new Set(availableFontUuids.map(font => font.familyName));

  const fontSizes: string[] = [];
  const fontTinySizeByOrigSize = {};
  const fontOrigSizeByTinySize = {};
  if (sizes) {
    sizes
      .split(',')
      .filter(x => x.indexOf('=') !== -1)
      .forEach(size => {
        const origSize = `${size.split('=')[0].trim()}pt`;
        const tinySize = size.split('=')[1].trim();
        fontTinySizeByOrigSize[origSize] = tinySize;
        fontOrigSizeByTinySize[tinySize] = origSize;
        fontSizes.push(origSize);
      });
  }

  const options = {
    fontSize: fontSizes,
    fontTinySizeByOrigSize,
    fontOrigSizeByTinySize,
    color: colors,
    lineColor: lineColors,
    font: fonts,
    fontFamily: [...fontFamilies],
    lineHeight: ['1.00', '1.25', '1.50', '2.00'],
    hAlign: names(horizontalAlign),
    vAlign: names(verticalAlign),
    priceDecimalStyle: names(priceDecimalStyles),
    textDirection: names(textDirection),
  };
  return options;
};

export const scaleFontSizeToPixels = (
  fontSize: string,
  heightR: number,
  productUnit: ProductUnit,
  scale?: number
) => {
  const sizeInPoints = Number.parseFloat(fontSize);

  let size = sizeInPoints * heightR;
  if (productUnit.metric === 'MM') {
    size = size * POINTS_IN_MM;
  } else if (productUnit.metric === 'INCH') {
    size = size * POINTS_IN_INCH;
  } else if (productUnit.metric === 'PIXEL') {
    const resolutionScale = productUnit.resolution
      ? productUnit.resolution / INCH_IN_POINTS
      : 1;
    size = size * resolutionScale;
  }

  // Issue #489: Make large font a bit smaller to fit inside the element borders
  const scaling = scale || (size >= 64 ? 0.7 : size >= 32 ? 0.8 : 0.9);

  return `${size * scaling}px`;
};

// For finding option specified in element constants
export const findKeyByValue = (
  value: string,
  options: Record<string, ElementConstant>,
  attribute: string
) => Object.keys(options).find(key => options[key][attribute] === value);

export const findAttributeByValue = (
  value: string,
  options: (AvailableColor | AvailableFont)[],
  attribute: string,
  resultAttribute: string
) => {
  try {
    const item = options.find(
      option =>
        option[attribute] === value ||
        Object.values(option[attribute]).indexOf(value) !== -1
    );
    return item && item[resultAttribute];
  } catch (e) {
    console.log(e.message);
  }
};

export const parseFontStyles = (element: ProductElement, fontUuid: string) => {
  const { availableFontUuids } = element.masterElement;
  const familyName = findAttributeByValue(
    fontUuid,
    availableFontUuids,
    'uuid',
    'familyName'
  );
  const currentFonts = availableFontUuids.filter(
    font => font.familyName === familyName
  );
  const options: Record<string, string> = {};
  currentFonts.forEach(font => {
    options[font.style] = font.uuid;
  });
  return options;
};

export const generatePdfData = (
  text: string,
  fonts: Record<number, string>,
  isRichTextField: boolean
) => {
  let newText = text || '';
  // New lines
  newText = newText.replace(
    /<p><br><\/p>(\n)?|(\r|<br>|<br \/>)?\n|<br>|<br \/>/gi,
    '<nextline>'
  );
  // Remove first paragraph
  newText = newText.replace('<p>', '');
  // Paragraphs (remove redundant new lines)
  newText = newText
    .replace(/(<nextline>)?<p>(<nextline>)?/gi, '<nextparagraph>')
    .replace(/<\/p>/gi, '');

  newText = cleanPdfData(newText);

  if (!isRichTextField) {
    const defaultFont = fonts[Object.keys(fonts)[0]];
    // Underline
    newText = newText
      .replace(
        /<span style="text-decoration: underline;">|<u>/gi,
        '<underline=true >'
      )
      .replace(/<\/span>|<\/u>/gi, '<underline=false >');
    // Bolditalic
    newText = newText
      .replace(
        /<em><strong>/gi,
        `<fontname=${fonts[4] || defaultFont} encoding=unicode >`
      )
      .replace(
        /<\/strong><\/em>/gi,
        `<fontname=${fonts[1] || defaultFont} encoding=unicode >`
      );
    // Bold
    newText = newText
      .replace(
        /<strong>/gi,
        `<fontname=${fonts[3] || defaultFont} encoding=unicode >`
      )
      .replace(
        /<\/strong>/gi,
        `<fontname=${fonts[1] || defaultFont} encoding=unicode >`
      );
    // Italic
    newText = newText
      .replace(
        /<em>/gi,
        `<fontname=${fonts[2] || defaultFont} encoding=unicode >`
      )
      .replace(
        /<\/em>/gi,
        `<fontname=${fonts[1] || defaultFont} encoding=unicode >`
      );
  }
  return newText;
};

/**
 * Strip duplicate style tags
 */
const cleanPdfData = (pdfText: string) => {
  // snippets contain text content with leading tags attached
  const snippets = pdfText.match(/(<([^>]|>(?=<))*>|^)([^<]|$)+/g) || [];
  const tags = snippets.map(snippet =>
    (snippet.match(/<([^>])*>/g) || []).join('')
  );
  // styles contain snippet's pdf styles as key value pairs with ineffective values ignored
  // as well as components such as <nextline> or <matchbox=...> which will be left untouched
  const styles = tags.map(tag => {
    const components = tag.match(/<((matchbox)[^>]*|\w*)>/g) || [];
    tag = components.reduce((a, c) => a.replace(c, ''), tag);
    const keys = tag.match(/\w*=/g) || [];
    const values = (tag.match(/=(({[^}]*})|[^>|\s]*)/g) || []).map(value =>
      value.substring(1)
    );
    return keys.reduce((a, c, i) => ({ ...a, [c]: values[i] }), { components });
  });
  // texts contain the visible text content of snippets
  const texts = snippets
    .map(snippet => snippet.match(/(>|^)[^>]*$/g) || [''])
    .map(texts =>
      texts[0].startsWith('>') ? texts[0].substring(1) : texts[0]
    );

  if (!texts[snippets.length - 1])
    styles[snippets.length - 1] = {
      components: styles[snippets.length - 1].components,
    };

  // recreate pdf content with only the effective tags
  let newText = '';
  for (let i = 0; i < snippets.length; i++) {
    const { components, ...textStyles } = styles[i];
    newText += components.join('') + '<';
    newText += Object.keys(textStyles).reduce(
      (a, c) => a + c + textStyles[c] + ' ',
      ''
    );
    newText += '>' + texts[i];
  }
  newText = newText.replace('<>', '');
  return newText;
};

export function getElementTitle(
  element: ProductElement,
  index,
  elementTypes = getElementTypes(),
  minLength = 0
) {
  const masterEl = element.masterElement;
  const type = ('text' in masterEl && masterEl.text?.type) || masterEl.type;
  const typeName = elementTypes[type] ? elementTypes[type].name : '';
  const name: string = getLangValue(masterEl.namesByLang) || typeName;
  const title = index || index === 0 ? `${index + 1}. ${name}` : name;
  return title.length >= minLength ? title : '';
}

export function getGroupTitle(groupNames: string, language: string) {
  const langTitle = groupNames
    .split(';')
    .filter(name => name.startsWith(language + ':'));
  return langTitle.length > 0 ? langTitle[0].substring(3) : groupNames;
}

const isUnlocked = (el: ProductElement, locked: ElementLock) => {
  if (el.element.type === 'IMAGE') {
    return !locked.imageUpload || !locked.imageSelect;
  }
  if (el.element.type === 'VECTOR') {
    return !locked.fillColor || !locked.lineColor;
  }
  if (el.element.type === 'TEXT') {
    const unlockedText =
      !locked.textData ||
      !locked.textSelect ||
      !locked.fillColor ||
      !locked.textSize ||
      !locked.textLeading ||
      !locked.textValign ||
      !locked.textAlign ||
      !locked.font;
    if (el.element.text.type === 'TEXT_PRICE') {
      return unlockedText || !locked.priceDecimalsSup;
    }
    if (el.element.text.type === 'TEXT_FIELD_CURVING') {
      return unlockedText || !locked.textDirection;
    }
    return unlockedText;
  }
};

export function useSelectedElement() {
  const elementsById = useSelector(state => state.products.elementsById);
  const selectedElementId = useSelector(
    state => state.products.selectedElementId
  );

  return selectedElementId ? elementsById[selectedElementId] : undefined;
}

export function useElementsOfCurrentPage(options?: {
  sortByArea?: boolean;
  includeLockedElements?: boolean;
  reverse?: boolean;
}) {
  const elementsById = useSelector(state => state.products.elementsById);

  return Object.values(elementsById)
    .filter(
      el =>
        options?.includeLockedElements || isUnlocked(el, el.masterElement.lock)
    )
    .sort((a, b) => {
      const aArea = a.element.location.w * a.element.location.h;
      const bArea = b.element.location.w * b.element.location.h;
      const aLineNr = a.masterElement.lineNr;
      const bLineNr = b.masterElement.lineNr;

      const reverse = options?.reverse ? -1 : 1;

      return (
        reverse *
        (options?.sortByArea && aArea !== bArea
          ? aArea - bArea
          : aLineNr - bLineNr)
      );
    });
}
