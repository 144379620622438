import { channel } from 'redux-saga';
import {
  all,
  put,
  call,
  take,
  takeEvery,
  fork,
  select,
} from 'redux-saga/effects';

import * as userApi from '~common/user.api';

const eventChannel = channel();

const ponsseLangs = {
  en: 'en_US',
  fi: 'fi_FI',
  de: 'de_DE',
  fr: 'fr_FR',
  se: 'sv_SE',
};

const contentHubLangs = {
  en_US: 'en',
  fi_FI: 'fi',
  de_DE: 'de',
  fr_FR: 'fr',
  sv_SE: 'se',
};

const menuEnabled = customer => {
  return (
    customer.staticName === 'ponsse' &&
    customer.configById['custom.appbar.enabled'] === true
  );
};

function* afterReadCustomer(action) {
  const customer = action.payload.customer;
  if (!menuEnabled(customer)) return;

  if (!document.querySelector('ponsse-menu-web')) {
    // Init ponsse menu component
    const menu = document.createElement('ponsse-menu-web');
    menu.setAttribute('title', 'Material Bank');
    menu.setAttribute(
      'menu-api-url',
      'https://iam-common-prod-iem-asc.azurewebsites.net/api'
    );
    menu.setAttribute(
      'maplet-api-url',
      'https://hgmeep9am5.execute-api.eu-west-1.amazonaws.com/v0/org/fi'
    );

    menu.addEventListener('signIn', () => {
      eventChannel.put({ type: 'APP/LOGOUT', payload: {} });
    });

    menu.addEventListener('signOut', () => {
      eventChannel.put({ type: 'APP/LOGOUT', payload: {} });
    });

    // TODO: language event is obsolete?
    menu.addEventListener('language', event => {
      const code = (event as any).detail.code;
      const contentHubLang = contentHubLangs[code];
      if (contentHubLang) {
        eventChannel.put({
          type: 'APP/SET_LANGUAGE',
          payload: {
            language: contentHubLang,
          },
        });
      }
    });

    // Add ponsse menu height to default app minHeight
    const menuHeight = '50px';
    const appElement = document.querySelector<HTMLDivElement>('#app');
    if (appElement) appElement.style.maxHeight = `calc(100vh - ${menuHeight})`;

    // Insert ponsse menu component to HTML page
    document.body.insertBefore(menu, document.body.firstChild);

    // Insert ponsse javascript to HTML page
    const ponsseJs = document.createElement('script');
    ponsseJs.type = 'text/javascript';
    ponsseJs.src = 'ponsse-menu-web.js';
    document.body.appendChild(ponsseJs);

    // Set ponsse menu styles based on window width
    const setMenuPosition = () => {
      const menu = document.querySelector('ponsse-menu-web') as any;
      menu.style.width = '100%';
      menu.style.top = '0';
      menu.style.left = '0';
      menu.style.zIndex = '1800';

      const overlay = document.querySelector('.pm-overlay') as any;
      if (overlay) {
        overlay.style.zIndex = '2000';
      }

      const expansions = document.querySelectorAll(
        'ponsse-menu-web .expansion-mask'
      ) as any;

      if (window.innerWidth <= 1199) {
        menu.style.position = 'fixed';
        for (const exp of expansions) {
          exp.style.overflowY = 'auto';
          exp.style.height = 'calc(100vh - 50px)';
        }
      } else {
        menu.style.position = 'static';
        for (const exp of expansions) {
          exp.style.overflowY = undefined;
          exp.style.height = undefined;
        }
      }
    };

    window.addEventListener('resize', setMenuPosition);
    setMenuPosition();
    setTimeout(() => {
      setMenuPosition();
    }, 1000);
  }
}

function* watchAfterReadCustomer() {
  yield takeEvery('APP/AFTER_READ_CUSTOMER', afterReadCustomer);
}

function* afterReadUserSettings() {
  const customer = yield select(state => state.app.customer);
  if (!menuEnabled(customer)) return;

  yield put({
    type: 'APP/UPDATE_THEME',
    payload: {
      theme: {
        extraAppBarHeight: 50,
      },
    },
  });
}

function* watchAfterReadUserSettings() {
  yield takeEvery('APP/AFTER_READ_USER_SETTINGS', afterReadUserSettings);
}

// TODO: remove
const nullHack = (obj: any) => {
  return obj !== 'null' ? obj : null;
};

function* afterReadUser(action) {
  const customer = yield select(state => state.app.customer);
  if (!menuEnabled(customer)) return;

  const user = action.payload.user;

  const menu = document.querySelector('ponsse-menu-web') as any;
  if (menu) {
    menu.title =
      user.sessionLanguage === 'fi' ? 'Materiaalipankki' : 'Material Bank';

    const userProfile: any = yield call(userApi.readUserProfile, {
      customerStaticName: customer.staticName,
    });

    if (!userProfile) {
      menu.userProfile = {};
    } else {
      menu.userProfile = {
        userId: nullHack(userProfile.id),
        fistNames: nullHack(userProfile.firstNames),
        lastName: nullHack(userProfile.lastName),
        name:
          nullHack(userProfile.name) ||
          `${nullHack(userProfile.firstNames)} ${nullHack(
            userProfile.lastName
          )}`,
        email: nullHack(userProfile.email),
        // languageCode: nullHack(userProfile.localeCode),
        languageCode:
          ponsseLangs[user.sessionLanguage] ||
          ponsseLangs[customer.defaultLanguage],
        profilephoto: '',
        company: !userProfile.representation
          ? {}
          : {
              id: nullHack(userProfile.representation.id),
              name1: nullHack(userProfile.representation.name),
              name2: null,
              externalId: nullHack(userProfile.externalId),
              externalIdType: nullHack(userProfile.externalIdType),
              displayName: nullHack(userProfile.representation.name),
              person:
                userProfile.person === true || userProfile.person === 'true'
                  ? 'true'
                  : 'false',
            },
      };
    }
  }
}

function* watchAfterReadUser() {
  yield takeEvery('APP/AFTER_READ_USER', afterReadUser);
}

// Event

export function* watchEventChannel() {
  while (true) {
    const action = yield take(eventChannel);
    yield put(action);
  }
}

// Combine all

export default function* sagas(): any {
  yield all([
    fork(watchAfterReadCustomer),
    fork(watchAfterReadUserSettings),
    fork(watchAfterReadUser),
    fork(watchEventChannel),
  ]);
}
