import { updateWith } from 'lodash';
import { PayloadAction } from '@reduxjs/toolkit';

import { RightsTreeUpdate } from './types';
import { GroupRightsById } from '~common/content.types';
import { DeepPartial } from '~common/utils/types.utils';
import { createSlice } from '~common/utils/ducks.utils';

/**
 * In order to avoid massive rerenders, we store the rights editing modals
 * states in Redux, so we can use `useSelector` to access deep objects
 * in recursively nested components. This is how we only ever rerender the
 * single components whose "state" changes, and not a whole recursive
 * subtree.
 */
export type RightsTreeState = {
  /**
   * As opposed to `FolderRightsModal`, here the object keys are folder
   * ids and not group ids
   */
  changes: DeepPartial<GroupRightsById>;
};

const initialState: RightsTreeState = {
  changes: {},
};

const slice = createSlice({
  name: 'rightsTree',
  initialState,
  reducers: {
    onChange: (state, action: PayloadAction<RightsTreeUpdate>) => {
      updateWith(
        state.changes,
        [action.payload.nodeId, 'rights', `${action.payload.rightId}`],
        currentValue => ({
          ...currentValue,
          selected: action.payload.newValue,
          partiallySelected: false,
        }),
        Object
      );
    },
    reset: () => initialState,
  },
});

export default slice.reducer;

export const rightsTree = {
  actions: slice.actions,
  selector: {},
};
