import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import Providers from './Providers';
import App from './App';
import configureStore from './store';
import { connectUtilsToStore } from './common/app.utils';
import { connectApiToStore } from '~common/utils/api.utils';
import config, { history } from '~common/config';

const store = configureStore(history);
connectApiToStore(store);
connectUtilsToStore(store);

const AppWithProviders = () => (
  <Provider store={store}>
    <BrowserRouter basename={`${config.pathPrefix}`} />
    <ConnectedRouter history={history}>
      <Providers>
        <App />
      </Providers>
    </ConnectedRouter>
  </Provider>
);

export default AppWithProviders;
