export const ALL_CUSTOMER_LANGUAGES = [
  'de',
  'da',
  'en',
  'es',
  'fi',
  'lt',
  'lv',
  'no',
  'pl',
  'ru',
  'se',
  'tr',
  'fr',
] as const;

export default {
  locales: ALL_CUSTOMER_LANGUAGES,
  catalogs: [
    {
      path: 'src/common/locales/{locale}/messages',
      include: ['src'],
    },
  ],
  format: 'po',
  compileNamespace: 'es',
  sourceLocale: 'en',
  fallbackLocales: {
    default: 'en',
  },
};
