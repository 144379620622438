import React, { useState } from 'react';
import styled from 'styled-components';
import { FormControl, FormLabel as MuiFormLabel } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { format } from 'date-fns';
import { t } from '@lingui/macro';

import { useSelector } from 'react-redux';
import { MetaData, ArchiveField } from '../content.types';
import DropDown from './DropDown';
import TimePicker from './TimePicker';
import DatePicker from '~common/inputs/DatePicker';
import StyledFormLabel from '~inputs/FormLabel';
import { Switch } from '~common/inputs/Switch';
import { LocalizedText } from '~common/app.utils';
import TextField from '~common/inputs/TextField';

interface Props {
  field: ArchiveField;
  metaData?: Record<string, MetaData>;
  onChange?: (value: string | null, fieldKey?: string) => void;
  disabled?: boolean;
  label?: string | LocalizedText;
  error?: boolean;
  helperText?: string;
  showSwitch?: boolean;
  switchLabel?: string;
  readOnly?: boolean;
  valueToString?: (
    selected: boolean,
    fromDate: Date | null,
    toDate: Date | null
  ) => string;
  isNew?: boolean;
}
const ArchiveFields = ({
  field,
  readOnly,
  metaData,
  onChange = () => {},
  disabled,
  label,
  error,
  helperText,
  showSwitch,
  switchLabel,
  valueToString,
  isNew,
}: Props) => {
  const {
    scheduleTimeFieldId,
    scheduledFieldId,
    destinationPathFieldId,
    id: fieldId,
  } = field;

  const nextYearDate = new Date();
  nextYearDate.setFullYear(nextYearDate.getFullYear() + 1);
  const customer = useSelector(state => state.app.customer);

  const options = [
    { name: t`One year`, value: '1' as const },
    { name: t`Two years`, value: '2' as const },
    { name: t`Three years`, value: '3' as const },
    { name: t`Four years`, value: '4' as const },
    { name: t`Five years`, value: '5' as const },
    { name: t`Specific`, value: '0' as const },
  ];

  const [operation, setOperation] = useState<typeof options[number]['value']>(
    isNew ? '1' : '0'
  );

  const getDate = (value: typeof options[number]['value']) => {
    const currentDate = new Date();
    if (['1', '2', '3', '4', '5'].includes(value)) {
      currentDate.setFullYear(currentDate.getFullYear() + Number(value));
    }
    return currentDate;
  };

  const archiveDateTime =
    metaData?.[scheduleTimeFieldId] &&
    typeof metaData?.[scheduleTimeFieldId] === 'string'
      ? new Date(metaData[scheduleTimeFieldId] as string)
      : isNew
      ? nextYearDate
      : new Date();

  const defaultFolder: string = customer?.configById[
    'archive.folder.default'
  ] as string;

  const selected = Boolean(fieldId) && metaData?.[fieldId] === 'true';
  const dateDisabled = (!selected && showSwitch) || Number(operation) > 0;

  const onDateChange = (fieldId: string, value: Date | null) => {
    onChange(
      value && !isNaN(value.getTime())
        ? format(value, "yyyy-MM-dd'T'HH:mm:00.000XX")
        : null,
      fieldId
    );
  };
  const onSelectedChange = (value: boolean) => {
    onChange(value ? 'true' : 'false', fieldId);
    onChange(value ? 'true' : 'false', scheduledFieldId);
    if (value && customer?.configById['archive.folder.default'] !== null) {
      onChange(defaultFolder, destinationPathFieldId);
    }
    onDateChange(scheduleTimeFieldId, getDate(operation));
  };

  const readOnlyValue = valueToString
    ? valueToString(selected, archiveDateTime, archiveDateTime)
    : selected
    ? archiveDateTime.toISOString()
    : t`Unscheduled`;

  if (readOnly)
    return (
      <FormControl component="fieldset" disabled={disabled}>
        {label && (
          <MuiFormLabel component="legend">
            <StyledFormLabel label={label} compact disabled={disabled} />
          </MuiFormLabel>
        )}
        <ReadOnlyTextField readOnly value={readOnlyValue} disabled={disabled} />
      </FormControl>
    );

  return (
    <FormControl component="fieldset" error={error} disabled={disabled}>
      {label && (
        <MuiFormLabel component="legend">
          <StyledFormLabel label={label} compact disabled={disabled} />
        </MuiFormLabel>
      )}
      <ElementContainer>
        {field?.id && showSwitch && (
          <Switch
            label={switchLabel ?? ''}
            id={field.id}
            value={selected}
            disabled={disabled}
            onChange={event => onSelectedChange(event.target.checked)}
          />
        )}
        <DropDown
          value={operation}
          showEmpty={false}
          onChange={event => {
            event.preventDefault();
            const currentDate = getDate(event.target.value);
            onDateChange(scheduleTimeFieldId, currentDate);
            setOperation(event.target.value);
          }}
          options={options}
          disabled={disabled || (!selected && showSwitch)}
        />
        <DatePicker
          id={scheduleTimeFieldId}
          value={archiveDateTime}
          onChange={(value: Date) => {
            onDateChange(scheduleTimeFieldId, value);
          }}
          onClear={() => onDateChange(scheduleTimeFieldId, null)}
          error={error}
          labelProps={{ compact: true }}
          disabled={disabled || dateDisabled}
        />
        <TimePicker
          id={scheduleTimeFieldId}
          value={archiveDateTime}
          onChange={(value: Date) => {
            onDateChange(scheduleTimeFieldId, value);
          }}
          onClear={() => onDateChange(scheduleTimeFieldId, null)}
          error={error}
          labelProps={{ compact: true }}
          disabled={disabled || dateDisabled}
        />
      </ElementContainer>
      {helperText && (
        <FormHelperText variant="standard">{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

const ReadOnlyTextField = styled(TextField)`
  // Makes the ReadOnlyTextField as tall as the range selector
  padding-block: 4px;
`;

const ElementContainer = styled.div`
  display: flex;
  gap: ${p => p.theme.spacing(1)};
  flex-wrap: wrap;

  & > * {
    min-width: min-content;
  }
  .date-picker {
    min-width: 130px;
    flex: 1 0 min-content;
  }
  .time-picker {
    min-width: 95px;
    flex: 1 0 min-content;
  }
`;

export default ArchiveFields;
