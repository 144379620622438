/**
 * Material UI:s DataGrid won't quit all of our use cases as it only supports
 * 100 item long pages with the free version, while some customers require
 * up to 200 items per page; therefore we need to implement our own accessible
 * Table/DataGrid component.
 *
 * Basic MUI Table doesn't provide us with basically any a11y features, so
 * here we mishmash React Aria and MUI Table together to form a better form (heh)
 *
 * Main structure adapted from:
 * https://react-spectrum.adobe.com/react-aria/useTable.html
 */

import React, { useRef, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';

import { mergeProps } from '@react-aria/utils';
import { useCheckbox } from '@react-aria/checkbox';
import { useFocusRing } from '@react-aria/focus';
import {
  useTable,
  AriaTableProps as ReactAriaTableProps,
  useTableCell,
  useTableColumnHeader,
  useTableHeaderRow,
  useTableRow,
  useTableRowGroup,
  useTableSelectAllCheckbox,
  useTableSelectionCheckbox,
} from '@react-aria/table';
import {
  useTableState,
  TableStateProps,
  Column as BaseColumn,
} from '@react-stately/table';
import { useToggleState } from '@react-stately/toggle';
import { VisuallyHidden } from '@react-aria/visually-hidden';

import {
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
} from '@mui/material';
import { ArrowDownIcon, ArrowUpIcon, SortableIcon } from '~common/misc/icons';
import Checkbox from '~common/inputs/Checkbox';
import { FunctionComponent } from '~common/utils/types.utils';
import { focusRing, muiFocusRing } from '~common/utils/styled.utils';

// Re-export primitives for composing a table
export { Cell, Row, TableBody, TableHeader } from '@react-stately/table';

// We want to enable alignment for columns, so we need to add additional
// props for typescript, the BaseColumn component already supports passing
// them through so re-export with typecasting is enough for us.
type ColumnProps = React.ComponentProps<typeof BaseColumn> & {
  align?: 'left' | 'center' | 'right';
};
export const Column: FunctionComponent<ColumnProps> = BaseColumn;

type TableWrapperProps = {
  $outlined?: boolean;
  $scroll?: boolean;
  $header?: boolean;
  $checkboxColumn?: boolean;
};

export const TableWrapper = styled.div<TableWrapperProps>`
  display: flex;

  flex-direction: column;

  height: 100%;
  overflow: ${p => (p.$scroll ? 'auto' : 'none')};
  border: ${p =>
    p.$outlined ? `2px solid ${p.theme.palette.grey['300']}` : 'none'};

  && .MuiTableRow-head {
    display: ${p => (p.$header ? 'table-row' : 'none')};
  }
  && .MuiTableRow-root td:nth-child(1) {
    display: ${p => (p.$checkboxColumn ? 'table-cell' : 'none')};
  }
`;

export type TableItem = { id: React.Key };

type TableProps<T extends TableItem> = React.ComponentProps<typeof MuiTable> &
  Omit<TableStateProps<T>, 'showSelectionCheckboxes' | 'selectionBehavior'> &
  Omit<ReactAriaTableProps<T>, 'scrollRef'> & {
    /** Manual override for the component being rendered for each row */
    rowComponent?: TableRowProps<T>['component'];
    stickySelectionColumn?: boolean;
  };

/** Generic and accessible Table component
 *
 * When providing `items` for `<TableBody>`, you should ensure each
 * object in the provided array includes an `id` field, which will be
 * used internally as a React key.
 *
 * Column specification can be done either statically or dynamically.
 * More in-depth spec:
 *
 * https://react-spectrum.adobe.com/react-aria/useTable.html#dynamic-collections
 *
 * @example
 *  // Static column specification:
 *
 *  <Table>
 *    <TableHeader>
 *      <Column>Name</Column>
 *      <Column>Email</Column>
 *    </TableHeader>
 *    <TableBody items={data}>
 *      {item => (
 *        <Row>
 *          <Cell>{item.name}</Cell>
 *          <Cell>{item.email}</Cell>
 *        </Row>
 *      )}
 *    </TableBody>
 *  </Table>
 *
 *  // Dynamic column specification:
 *
 *  const colSpec = [
 *    { name: 'Name', key: 'name' },
 *    { name: 'Email', key: 'email' },
 *  ];
 *
 *  <Table>
 *    <TableHeader columns={colSpec}>
 *      {column => (
 *        <Column>{column.name}</Column>
 *      )}
 *    </TableHeader>
 *    <TableBody items={data}>
 *      {item => (
 *        <Row>
 *          {columnKey => <Cell>{item[columnKey]</Cell>}
 *        <Row>
 *      )}
 *    </TableBody>
 *  </Table>
 */
export function Table<T extends TableItem>({
  stickySelectionColumn,
  ...props
}: TableProps<T>) {
  const state = useTableState({
    ...props,
    showSelectionCheckboxes: props.selectionMode !== 'none',
  });
  const ref = useRef<HTMLTableElement>(null);
  const bodyRef = useRef<HTMLTableSectionElement>(null);
  const { collection } = state;
  // If we keep passing `onMouseDown` and pass it to the root
  // table element, it blocks us from using `react-dnd` in
  // `BaseBrowserTable`. Nothing seems to break when we omit
  // it, though, so let's just hope nothing really goes wrong
  // by doing this. In case something breaks, we might want to
  // provide a prop to disable/enable passing this
  const {
    gridProps: { onMouseDown, onFocus, ...gridProps },
  } = useTable(
    {
      ...props,
      scrollRef: bodyRef,
    },
    state,
    ref
  );

  // Ensure focused row, cell or cell child is always visible
  useEffect(() => {
    const key = state.selectionManager.focusedKey;
    if (key && state.selectionManager.isFocused) {
      // The table is currently in focus, and either a row or a cell is focused.
      // We need to dig the correct row ID from table state, and scroll it into
      // view if we're moving focus on the top or bottom edge of current scroll
      // viewport.
      const data = state.collection.getItem(key);
      if (!data) return;
      const elementKey = data.type === 'cell' ? data.parentKey : data.key;
      const element = document.querySelector(`tr[data-key="${elementKey}"]`);
      element?.scrollIntoView({ block: 'nearest' });
    }
  }, [state.selectionManager.focusedKey, state.selectionManager.isFocused]);

  return (
    <StyledTable
      {...gridProps}
      onFocus={e => {
        // useTable won't handle focus events bubbled through modals
        // We wan't to catch these and return the focus to the table
        if (
          !state.selectionManager.isFocused &&
          !e.currentTarget.contains(e.target)
        ) {
          state.selectionManager.setFocused(true);
          return;
        }
        onFocus?.(e);
      }}
      ref={ref}
      stickyHeader
    >
      <TableRowGroup type="thead">
        {collection.headerRows.map(headerRow => (
          <TableHeaderRow key={headerRow.key} item={headerRow} state={state}>
            {[...headerRow.childNodes].map(column =>
              column.props.isSelectionCell ? (
                <TableSelectAllCell
                  key={column.key}
                  column={column}
                  state={state}
                  sticky={stickySelectionColumn}
                />
              ) : (
                <TableColumnHeader
                  key={column.key}
                  column={column}
                  state={state}
                />
              )
            )}
          </TableHeaderRow>
        ))}
      </TableRowGroup>
      <TableRowGroup ref={bodyRef} type="tbody">
        {[...collection.body.childNodes].map(row => (
          <TableRow
            key={row.key}
            item={row}
            state={state}
            component={props.rowComponent}
          >
            {[...row.childNodes].map(cell =>
              cell.props.isSelectionCell ? (
                <TableCheckboxCell
                  key={cell.key}
                  cell={cell}
                  state={state}
                  sticky={stickySelectionColumn}
                />
              ) : (
                <TableCell key={cell.key} cell={cell} state={state} />
              )
            )}
          </TableRow>
        ))}
      </TableRowGroup>
    </StyledTable>
  );
}

const StyledTable = styled(MuiTable)`
  --default-row-height: 4rem;

  & tr {
    scroll-margin-top: var(
      --header-row-height,
      var(--row-height, var(--default-row-height))
    );
  }
`;

type TableRowGroupProps = {
  type: 'thead' | 'tbody';
  children: React.ReactNode;
};

const TableRowGroup = React.forwardRef<
  HTMLTableSectionElement,
  TableRowGroupProps
>((props, ref) => {
  const { type, children } = props;
  const { rowGroupProps } = useTableRowGroup();
  const Element = type === 'thead' ? StyledTableHead : MuiTableBody;
  return (
    <Element ref={ref} {...rowGroupProps}>
      {children}
    </Element>
  );
});

const StyledTableHead = styled(MuiTableHead)`
  & th {
    background-color: ${p => p.theme.palette.common.white};
    border-bottom: 2px solid ${p => p.theme.palette.grey[300]};
  }
`;

TableRowGroup.displayName = 'TableRowGroup';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type TableHeaderRowProps<T extends TableItem> = {
  children: React.ReactNode;
  item: Parameters<typeof useTableHeaderRow<T>>[0]['node'];
  state: Parameters<typeof useTableHeaderRow<T>>[1];
};

function TableHeaderRow<T extends TableItem>({
  item,
  state,
  children,
}: TableHeaderRowProps<T>) {
  const ref = useRef<HTMLTableRowElement>(null);
  const { rowProps } = useTableHeaderRow({ node: item }, state, ref);

  return (
    <StyledTableHeaderRow {...rowProps} ref={ref}>
      {children}
    </StyledTableHeaderRow>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type TableColumnHeaderProps<T extends TableItem> = {
  column: Parameters<typeof useTableColumnHeader<T>>[0]['node'];
  state: Parameters<typeof useTableColumnHeader<T>>[1];
};

function TableColumnHeader<T extends TableItem>({
  column,
  state,
}: TableColumnHeaderProps<T>) {
  const ref = useRef<HTMLTableCellElement>(null);
  // If we pass onClick handler down to the header cell,
  // "Select all"-checkbox of browser tables stops registering clicks.
  // Mouse and keyboard navigation seemed to work, but might break virtual events.
  // TODO: refactor selection handling of browser tables to use table state's selectedKeys and onSelectionChange
  const {
    columnHeaderProps: { onClick, ...columnHeaderProps },
  } = useTableColumnHeader({ node: column }, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();
  const theme = useTheme();

  let sortIcon = <SortableIcon />;
  if (state.sortDescriptor?.column === column.key) {
    if (state.sortDescriptor?.direction === 'ascending') {
      sortIcon = <ArrowUpIcon />;
    } else {
      sortIcon = <ArrowDownIcon />;
    }
  }

  return (
    <StyledTableHeaderCell
      {...mergeProps(columnHeaderProps, focusProps)}
      colSpan={column.colspan}
      style={{
        textAlign:
          column.props.align ?? ((column.colspan ?? 0) > 1 ? 'center' : 'left'),
        outline: 'none',
        boxShadow: isFocusVisible
          ? `inset 0 0 0 3px ${theme.palette.secondary.main}`
          : 'none',
        cursor: column.props.allowsSorting ? 'pointer' : 'default',
        width: column.props?.width,
      }}
      ref={ref}
    >
      {column.rendered}
      {column.props.allowsSorting && sortIcon}
    </StyledTableHeaderCell>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type TableRowProps<T extends TableItem> = {
  children: React.ReactNode;
  item: Parameters<typeof useTableRow<T>>[0]['node'];
  state: Parameters<typeof useTableRow<T>>[1];
  /** Optional override for the row component */
  component?: FunctionComponent<
    Omit<
      React.HTMLProps<HTMLTableRowElement> & {
        item: T;
        children: React.ReactNode;
        rowIndex: number;
        columnCount: number;
        style: React.CSSProperties;
      },
      'ref' | 'as'
    >
  >;
};

function TableRow<T extends TableItem>({
  item,
  children,
  state,
  component,
}: TableRowProps<T>) {
  const ref = useRef<HTMLTableRowElement>(null);
  const { rowProps } = useTableRow({ node: item }, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();
  const theme = useTheme();

  // We need to do a bit of dirty typecasting for the extra props to not
  // cause typescript to flip on a conditional component with two completely
  // different props signatures
  const RowComponent: FunctionComponent<any> = component ?? DefaultTableRow;
  const extraProps = component
    ? {
        item: item.value,
        rowIndex: item.index ?? 0,
        columnCount: state.collection.columnCount,
      }
    : {};

  return (
    <RowComponent
      style={{
        outline: 'none',
        boxShadow:
          !!component && isFocusVisible
            ? `inset 0 0 0 3px ${theme.palette.secondary.main}`
            : 'none',
      }}
      focused={isFocusVisible}
      {...mergeProps(rowProps, focusProps)}
      ref={ref}
      {...extraProps}
    >
      {children}
    </RowComponent>
  );
}

export const DefaultTableRow = styled(MuiTableRow)<{ focused?: boolean }>`
  height: var(--row-height, var(--default-row-height));
  min-height: var(--row-height, var(--default-row-height));

  background-color: ${p => p.theme.palette.common.white};
  &:nth-child(even) {
    background-color: ${p => p.theme.palette.grey['80']};
  }

  &:before {
    --ring-distance: 0 !important;
    border-radius: 0 !important;
    transition: none !important;
  }

  & th {
    button {
      color: inherit;
    }
  }

  ${p => focusRing(!!p.focused)};
`;

const StyledTableHeaderRow = styled(DefaultTableRow)`
  --height: var(
    --header-row-height,
    var(--row-height, var(--default-row-height))
  );

  height: var(--height);
  min-height: var(--height);
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type TableCellProps<T extends TableItem> = {
  cell: Parameters<typeof useTableCell<T>>[0]['node'];
  state: Parameters<typeof useTableCell<T>>[1];
};

function TableCell<T extends TableItem>({ cell, state }: TableCellProps<T>) {
  const ref = useRef<HTMLTableCellElement>(null);
  const { gridCellProps } = useTableCell({ node: cell }, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();
  const theme = useTheme();

  return (
    <StyledTableCell
      {...mergeProps(gridCellProps, focusProps)}
      style={{
        outline: 'none',
        boxShadow: isFocusVisible
          ? `inset 0 0 0 3px ${theme.palette.secondary.main}`
          : 'none',
        cursor: 'default',
        textAlign: cell.column?.props.align,
      }}
      ref={ref}
    >
      {cell.rendered}
    </StyledTableCell>
  );
}

function TableCheckboxCell<T extends TableItem>({
  cell,
  state,
  sticky,
}: TableCellProps<T> & { sticky?: boolean }) {
  const ref = useRef<HTMLTableCellElement>(null);
  const { gridCellProps } = useTableCell({ node: cell }, state, ref);
  const { checkboxProps } = useTableSelectionCheckbox(
    { key: cell.parentKey ?? 'KEY' },
    state
  );
  const theme = useTheme();

  const inputRef = useRef(null);
  const { inputProps } = useCheckbox(
    checkboxProps,
    useToggleState(checkboxProps),
    inputRef
  );

  return (
    <StyledTableCell
      {...gridCellProps}
      ref={ref}
      style={{
        ...(sticky
          ? { position: 'sticky', left: 0, backgroundColor: 'inherit' }
          : {}),
        boxShadow:
          gridCellProps.tabIndex === 0 && inputProps.disabled
            ? `inset 0 0 0 3px ${theme.palette.secondary.main}`
            : 'none',
      }}
    >
      <StyledCheckbox {...(inputProps as any)} />
    </StyledTableCell>
  );
}

function TableSelectAllCell<T extends TableItem>({
  column,
  state,
  sticky,
}: TableColumnHeaderProps<T> & { sticky?: boolean }) {
  const ref = useRef<HTMLTableCellElement>(null);
  const isSingleSelectionMode =
    state.selectionManager.selectionMode === 'single';
  const { columnHeaderProps } = useTableColumnHeader(
    { node: column },
    state,
    ref
  );

  const { checkboxProps } = useTableSelectAllCheckbox(state);
  const inputRef = useRef(null);
  const { inputProps } = useCheckbox(
    checkboxProps,
    useToggleState(checkboxProps),
    inputRef
  );

  return (
    <StyledTableCell
      {...columnHeaderProps}
      ref={ref}
      style={{
        width: '3rem',
        ...(sticky ? { position: 'sticky', left: 0, zIndex: 3 } : {}),
      }}
    >
      {
        /*
          In single selection mode, the checkbox will be hidden.
          So to avoid leaving a column header with no accessible content,
          use a VisuallyHidden component to include the aria-label from the checkbox,
          which for single selection will be "Select."
        */
        isSingleSelectionMode && (
          <VisuallyHidden>{inputProps['aria-label']}</VisuallyHidden>
        )
      }
      <StyledCheckbox
        {...(inputProps as any)}
        indeterminate={checkboxProps.isIndeterminate}
        ref={inputRef}
        style={isSingleSelectionMode ? { visibility: 'hidden' } : undefined}
      />
    </StyledTableCell>
  );
}

const StyledTableCell = styled(MuiTableCell)`
  padding: ${p => p.theme.spacing(0.5, 1.5)};
  border: unset;
`;

const StyledTableHeaderCell = styled(StyledTableCell)`
  & > svg {
    margin-left: ${p => p.theme.spacing(0.5)};
    vertical-align: middle;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  && {
    margin-right: 0;
    padding: 0;
    height: 20px;
  }

  &&.Mui-focusVisible {
    --ring-distance: -8px !important;
    ${muiFocusRing}
  }
`;
