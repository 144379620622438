import React, { useState } from 'react';
import styled from 'styled-components';
import makeStyles from '@mui/styles/makeStyles';
import MaterialPopover, { PopoverProps } from '@mui/material/Popover';
import Fade from '@mui/material/Fade';

interface Props extends PopoverProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  setOpen: (open: boolean) => any;
  children: any;
}

const Popup = ({
  open,
  setOpen,
  anchorEl,
  children,
  ...popoverProps
}: Props) => {
  const [left, setLeft] = useState<number>();

  const calcRect = React.useCallback(
    popoverRef => {
      requestAnimationFrame(() => {
        if (anchorEl && popoverRef) {
          const [paper] = popoverRef.querySelectorAll('.MuiPopover-paper');
          const popoverRect = paper.getBoundingClientRect();
          const anchorRect = anchorEl.getBoundingClientRect();

          const x1 = anchorRect.x + anchorRect.width / 2;
          const x2 = popoverRect.x + popoverRect.width / 2;
          const offset = x1 - x2;

          setLeft(offset);
        }
      });
    },
    [anchorEl]
  );

  const useStyles = makeStyles(theme => ({
    popup: {
      alignItems: 'center',
      justifyContent: 'center',
      '& > *': {
        overflow: 'visible',
        boxSizing: 'border-box',
      },
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      position: 'relative' as const,
      overflow: 'hidden',
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute' as const,
        top: '-20px',
        left: '50%',
        transform: left ? `translateX(${left - 5}px)` : '',
        borderBottom: '10px solid white',
        borderRight: '10px solid transparent',
        borderLeft: '10px solid transparent',
        borderTop: '10px solid transparent',
        zIndex: 100,
      },
    },
  }));

  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MaterialPopover
      className={classes.popup}
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      ref={calcRect}
      {...popoverProps}
    >
      <Fade in={open}>
        <StyledContent className={classes.paper}>{children}</StyledContent>
      </Fade>
    </MaterialPopover>
  );
};

const StyledContent = styled.div`
  border-radius: 4px;
`;

export default Popup;
