import React from 'react';

// NOTE: A workaround, as importing svg as <img> insufficient.
// Ideally import from /assets

const HierarchyIcon = props => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24.2 24.15" {...props}>
      <g data-name="Group 371">
        <path data-name="Path 542" d="M0 0h24.2v24.15H0z" fill="none" />
        <path
          data-name="Line 91"
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          d="M7.547 6.792v14.76"
        />
        <path
          data-name="Line 92"
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          d="M6.659 9.012h11.542"
        />
        <path
          data-name="Line 93"
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          d="M6.659 14.783h11.542"
        />
        <path
          data-name="Line 94"
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          d="M6.659 20.553h11.542"
        />
        <g
          data-name="Ellipse 38"
          transform="translate(15.315 6.792)"
          fill="currentColor"
          stroke="currentColor"
        >
          <circle cx={2.22} cy={2.22} r={2.22} stroke="none" />
          <circle cx={2.22} cy={2.22} r={1.72} fill="none" />
        </g>
        <g
          data-name="Ellipse 42"
          transform="translate(15.315 12.563)"
          fill="currentColor"
          stroke="currentColor"
        >
          <circle cx={2.22} cy={2.22} r={2.22} stroke="none" />
          <circle cx={2.22} cy={2.22} r={1.72} fill="none" />
        </g>
        <g
          data-name="Ellipse 43"
          transform="translate(15.315 18.334)"
          fill="currentColor"
          stroke="currentColor"
        >
          <circle cx={2.22} cy={2.22} r={2.22} stroke="none" />
          <circle cx={2.22} cy={2.22} r={1.72} fill="none" />
        </g>
        <g
          data-name="Ellipse 41"
          transform="translate(5.105 2.131)"
          fill="currentColor"
          stroke="currentColor"
        >
          <circle cx={2.442} cy={2.442} r={2.442} stroke="none" />
          <circle cx={2.442} cy={2.442} r={1.942} fill="none" />
        </g>
      </g>
    </svg>
  );
};

export default HierarchyIcon;
