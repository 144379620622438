import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Item, SuggestionBoxStyle } from 'src/content/search/SuggestionBox';
import useAutocomplete from '@mui/material/useAutocomplete';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { debounce } from 'lodash';
import { useGetMetaFieldSuggestionsQuery } from 'src/content/common/api';
import { skipToken } from '@reduxjs/toolkit/query/react';
import TextField from './TextField';
import Row from '~layout/Row';
import { useEvent, useSafeEffect } from '~common/utils/hooks.utils';
import { Props } from '~common/common.types';
import { combineRefs } from '~common/utils/fn.utils';

const KEYWORD_REGEX = '([,;]|^)(\\s*)([^,;]*?)$';
const SEPARATOR_REGEX = /[,;]\s*/;

type AutocompleteProps = {
  metaFieldId: string;
  onChange?: (value: string | undefined) => void;
  value?: string;
  style?: CSSProperties;
} & Props<typeof TextField>;

const AutocompleteField = React.forwardRef<HTMLDivElement, AutocompleteProps>(
  (
    {
      metaFieldId,
      value: outerValue,
      onChange,
      onBlur,
      onFocus,
      style,
      ...rest
    }: AutocompleteProps,
    ref
  ) => {
    const lang = useSelector(state => state.app.settings?.language);

    const [value, setValue] = useState('');
    const [caretPosition, setCaretPosition] = useState(0);

    const [suggestionArgs, setSuggestionArgs] =
      useState<Parameters<typeof useGetMetaFieldSuggestionsQuery>[0]>();
    const { data: suggestions } = useGetMetaFieldSuggestionsQuery(
      suggestionArgs ?? skipToken
    );

    const [suggOpen, setSuggOpen] = useState(false);

    const {
      getRootProps,
      getInputProps,
      getListboxProps,
      getOptionProps,
      setAnchorEl,
    } = useAutocomplete({
      id: rest.id || 'metafield-autocomplete',
      options: suggestions ?? [],
      filterOptions: options => [...options, { value }],
      open: true,
      onChange: (e, sugg) => {
        if (!sugg) {
          return;
        }

        /* replace the last keyword in the field with the selected one
        e.g. hello[, world] or [hello] 
       */
        const newValue = value.replace(
          new RegExp(KEYWORD_REGEX, 'g'),
          `$1$2${sugg.value}`
        );

        setValue(newValue);
        if (onChange) onChange(newValue);
        setCaretPosition(newValue.length);
        setSuggOpen(false);
      },
      getOptionLabel: option => option.value,
    });

    // changing suggestionArgs fetches suggestions from server,
    // let's debounce that update
    const updateSuggestionArgs = useCallback(
      debounce(
        (val: string) =>
          setSuggestionArgs(
            lang
              ? {
                  metaFieldId,
                  search: (val.match(new RegExp(KEYWORD_REGEX, 'g')) ??
                    [])[0]?.replace(SEPARATOR_REGEX, ''),
                  lang,
                }
              : undefined
          ),
        500
      ),
      [metaFieldId, lang]
    );
    useEffect(() => {
      updateSuggestionArgs(value);
    }, [value]);

    useEffect(() => {
      setValue(outerValue ?? '');
    }, [outerValue]);

    useSafeEffect(() => {
      if (!inputRef.current) {
        return;
      }
      inputRef.current.selectionStart = caretPosition;
      inputRef.current.selectionEnd = caretPosition;
    }, [caretPosition]);

    const inputRef = useRef<HTMLInputElement>(null);
    const suggestionsRef = useRef<HTMLDivElement>(null);

    const [width, setWidth] = useState<number>();

    const offsetWidth = inputRef.current?.offsetWidth;

    useEffect(() => {
      if (!offsetWidth) {
        return;
      }
      setWidth(offsetWidth);
    }, [offsetWidth]);

    useEvent('focusin', e => {
      const target = e.target;
      const input = inputRef.current;
      const suggestions = suggestionsRef.current;
      if (!input || !suggestions || !(target instanceof HTMLElement)) {
        return;
      }

      const shouldClose =
        !input.contains(target) && !suggestions.contains(target);
      setSuggOpen(!shouldClose);
    });

    const inputProps = getInputProps() as any;

    return (
      <Container style={style}>
        <Row {...getRootProps()} ref={setAnchorEl} padding={0}>
          <TextField
            {...rest}
            {...inputProps}
            onFocus={e => {
              if (onFocus) onFocus(e);
              if (inputProps.onFocus) inputProps.onFocus(e);
            }}
            onBlur={e => {
              if (onBlur) onBlur(e);
              if (inputProps.onBlur) inputProps.onBlur(e);
            }}
            value={value}
            onChange={e => {
              setValue(e.target.value);
              if (onChange) onChange(e.target.value);
              setSuggOpen(true);
            }}
            inputRef={combineRefs(inputRef, ref)}
            style={{ width: '100%' }}
          />
        </Row>
        {value && suggOpen && suggestions && suggestions.length > 0 && (
          <Suggestions width={width} ref={suggestionsRef}>
            <List {...getListboxProps()}>
              {suggestions.map((option, index) => (
                <StyledItem
                  {...getOptionProps({ option, index })}
                  onFocus={e => {
                    // let mui handle the focus
                    // probly ok?
                    // these were divs before anyways so can't be worse right ??
                    // setTimeout because otherwise the box closes too quickly
                    e.persist();
                    setTimeout(() => e.target.blur());
                  }}
                  key={option.value}
                  tabIndex={-1}
                >
                  {option.value}
                </StyledItem>
              ))}
            </List>
          </Suggestions>
        )}
      </Container>
    );
  }
);
AutocompleteField.displayName = 'AutocompleteField';

const Container = styled.div`
  position: relative;
`;

const Suggestions = styled.div<{ width: number | undefined }>`
  position: absolute;
  width: ${props => props.width}px;
  color: black;
  z-index: 1000;
`;

const StyledItem = styled(Item).attrs({
  as: 'button',
})`
  background: none;
  display: flex;
  border: none;
  width: 100%;
`;

const List = styled.ul`
  ${SuggestionBoxStyle}
  padding: 4px;
  overflow: visible;
  position: relative;
  top: 2px;
`;

export default AutocompleteField;
