import React, { useRef } from 'react';
import { IconButtonProps } from '@mui/material/IconButton';

import Button from '~inputs/Button';
import IconButton from '~inputs/IconButton';
import Popup from '~sections/Popup';

type Props = (IconButtonProps | React.ComponentProps<typeof Button>) & {
  content: any;
  children?: any;
  // Button props
  isIconButton?: boolean;
  customButton?: any;
  className?: any;
};

const PopupButton = ({
  content,
  children,
  isIconButton = false,
  customButton,
  ...buttonProps
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef(null);
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      {(customButton && customButton(handleOpen, anchorRef)) ||
        (isIconButton ? (
          <IconButton
            onClick={handleOpen}
            {...buttonProps}
            ref={anchorRef}
            size="large"
          >
            {children}
          </IconButton>
        ) : (
          <Button onClick={handleOpen} {...buttonProps} ref={anchorRef}>
            {children}
          </Button>
        ))}

      <Popup open={open} setOpen={setOpen} anchorEl={anchorRef.current}>
        {content(setOpen)}
      </Popup>
    </>
  );
};

export default PopupButton;
