import React, { DOMAttributes, useRef, AriaAttributes } from 'react';
import { useLocation } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { AriaCheckboxProps } from 'react-aria';
import { Item } from 'react-stately';
import { t } from '@lingui/macro';

import { useSelector } from 'react-redux';
import { prevFunc, nextFunc, SelectCallback } from '~paging/utils';

import ProgressBar from '~sections/ProgressBar';
import {
  Criteria,
  GenericData,
  Results as ResultsType,
} from '~common/common.types';
import { useWindowSizeChangeEffect } from '~common/utils/layout.utils';
import { FunctionComponent } from '~common/utils/types.utils';
import { getLangValue } from '~common/app.utils';
import { useGetFolderQuery } from '~common/content.api';
import { File } from '~common/content.types';
import { GridList } from '~common/layout/GridList';
import { EMBED_LEVEL, isEmbeded, useUrlParams } from '~common/content.utils';

/** Helper for consistent ID strings */
const getItemId = (id: string, index: number) => `${id}-results-item-${index}`;

/** Status message renderer for abnormal `results` */
const getMessage = (results: any) => {
  if (results.status === 'error') {
    return <div>Error</div>;
  }
  return null;
};

type ThumbnailComponentProps<
  T extends GenericData,
  P extends Record<string, unknown>
> = {
  index: number;
  selectedIndex: number;
  id: string;
  item: T;
  prevItem: T;
  criteria: Criteria;
  filters: unknown;
  resultNumber: number;
  selected: boolean;
  onShow: (index: number) => void;
  onSelect: SelectCallback;
  onUnselect: () => void;
  onSelectPrev: () => void;
  onSelectNext: () => void;
  checkboxProps?: AriaCheckboxProps;
  inlinePreviewProps?: DOMAttributes<HTMLElement>;
  params: P;
};

type NewSelectedComponentProps<
  T extends GenericData,
  P extends Record<string, unknown>
> = {
  anchorRef?: React.RefObject<HTMLElement | null>;
  isFullscreen?: boolean;
  item?: T;
  params?: P;
  className?: string;
};

type Props<
  D extends GenericData,
  P extends Record<string, unknown>,
  R extends ResultsType<D>,
  S extends D
> = {
  id: string;
  criteria: Criteria;
  collection: string | 'search';
  results: R;
  filters?: unknown;
  selectedItem?: S;
  onUpdatePaging: (newPaging: Partial<Criteria>) => void;
  onSelectItem: SelectCallback;
  onShowItem?: (index: number) => void;
  params?: unknown;
  checkedItems?: Set<string>;
  setCheckedContent?: (items: Set<string>) => void;
  // Item components
  itemComponentParams: P;
  ThumbnailComponent: FunctionComponent<ThumbnailComponentProps<D, P>>;
  SelectedFileComponent?: FunctionComponent<NewSelectedComponentProps<S, P>>;
  SelectedFolderComponent?: FunctionComponent<NewSelectedComponentProps<S, P>>;
  showItemOnFullScreen: boolean;
  // Other
  separateFolders?: boolean;
  loading?: boolean;
  marginBottom?: string;
  horizontal?: boolean;
  className?: string;
  gridWrapperRef?: React.RefObject<HTMLDivElement>;
};

export default function FileBrowser<
  D extends GenericData,
  P extends Record<string, unknown>,
  R extends ResultsType<D>,
  S extends D
>({
  id,
  criteria,
  collection,
  results,
  filters,
  selectedItem,
  onUpdatePaging,
  onSelectItem,
  onShowItem,
  checkedItems,
  setCheckedContent,
  // Item components
  ThumbnailComponent,
  SelectedFileComponent,
  SelectedFolderComponent,
  itemComponentParams,
  showItemOnFullScreen,
  // Other
  separateFolders,
  marginBottom,
  horizontal,
  loading,
  className,
  gridWrapperRef,
}: Props<D, P, R, S>) {
  const { pathname } = useLocation();

  const wrapperPropsRef = useRef<HTMLUListElement>(null);

  const { data: folder } = useGetFolderQuery(
    collection !== 'search' ? { id: collection } : skipToken
  );

  const { embed } = useUrlParams();

  const getId = (item: GenericData) =>
    'node' in item ? item.node.id : item.id;

  const inlinePreview =
    !showItemOnFullScreen && (SelectedFileComponent || SelectedFolderComponent);
  const getInlinePreviewProps = (item: GenericData): AriaAttributes =>
    inlinePreview
      ? {
          'aria-expanded':
            (selectedItem && getId(item) === getId(selectedItem)) ?? false,
          'aria-controls': `opened-${getId(item)}`,
        }
      : {};

  const getItemProps = (item: D, index: number) => ({
    index,
    selectedIndex: criteria.selectedIndex,
    id: getItemId(id, index),
    item,
    prevItem: results.items[index - 1],
    criteria,
    filters,
    resultNumber: index + criteria.pageSize * criteria.page + 1,
    selected: (selectedItem && getId(item) === getId(selectedItem)) ?? false,
    onShow: () => onShowItem?.(index),
    onSelect: () => onSelectItem(index, index),
    onUnselect: () => onSelectItem(-1, index),
    onSelectPrev: prevFunc(index, criteria, onUpdatePaging, onSelectItem),
    onSelectNext: nextFunc(
      index,
      criteria,
      onUpdatePaging,
      onSelectItem,
      results
    ),
    params: itemComponentParams,
    inlinePreviewProps: {
      role: 'button',
      ...getInlinePreviewProps(item),
    },
  });

  const getSelectedItemProps = (item: S, index: number) => ({
    item,
    onUnselect: () => onSelectItem(-1, index),
    onSelectPrev: prevFunc(index, criteria, onUpdatePaging, onSelectItem),
    onSelectNext: nextFunc(
      index,
      criteria,
      onUpdatePaging,
      onSelectItem,
      results
    ),
    params: itemComponentParams,
    wrapperProps: {
      id: `opened-${getId(item)}`,
      as: 'section',
    },
  });

  const folderishComponent =
    selectedItem &&
    (('isFolder' in selectedItem &&
      selectedItem.isFolder &&
      selectedItem.node.inCart) ||
      ('isCart' in selectedItem && selectedItem?.isCart));

  const ItemThumbnail = ThumbnailComponent;
  const OpenedItem =
    selectedItem && folderishComponent
      ? SelectedFolderComponent
      : SelectedFileComponent;

  // Make the last folder of the listing span all remaining columns, so
  // that other materials start on a new row. Skipped on search page, as
  // the results aren't grouped with folders first there.
  useWindowSizeChangeEffect(() => {
    const gridElement = wrapperPropsRef.current;
    if (!gridElement || pathname === '/search' || !separateFolders) return;

    const gridColumnsCss = window
      .getComputedStyle(gridElement)
      .getPropertyValue('grid-template-columns');
    const columnCount = gridColumnsCss.split(' ').length;
    const columnWidth = gridColumnsCss.split(' ')[0];

    const folderCount = results.items.filter(
      item => 'isFolder' in item && item.isFolder
    ).length;
    const folderColumn = folderCount % columnCount;

    const lastFolder = gridElement.querySelector(
      `li:nth-of-type(${folderCount})`
    ) as HTMLElement;

    if (lastFolder && !isEmbeded(EMBED_LEVEL.JUST_CONTENT, embed)) {
      const items = document.querySelectorAll<HTMLElement>('li');
      Array.from(items).forEach(item => {
        item.style.removeProperty('grid-column');
        item.style.removeProperty('max-width');
      });
      if (columnCount > 1 && folderColumn > 0) {
        lastFolder.style.setProperty('grid-column', `${folderColumn} / -1`);
        lastFolder.style.setProperty('max-width', columnWidth);
      }
    }
  }, [results.items]);

  const anchorRef = useRef<HTMLUListElement>(null);

  // Show progress bar until contents are available on state
  if (
    !results ||
    !results.items ||
    results.totalCount === -1 ||
    results.status === 'loading' ||
    loading
  ) {
    return <ProgressBar />;
  }

  // On error and no results, show error message only
  if (results.totalCount === -1 && results.status === 'error') {
    return <>{getMessage(results)}</>;
  }

  const collectionDescription =
    collection === 'search'
      ? t`Search results.`
      : folder && !folder.removed
      ? t`Contents of ${getLangValue(folder.namesByLang)}.`
      : '';
  const pageDescription =
    criteria.pageSize >= results.totalCount
      ? ''
      : t`Page ${criteria.page + 1} of ${Math.ceil(
          criteria.pageSize / results.totalCount
        )}`;

  const items = results.items.map((item, index) => ({
    ...item,
    index,
    key: getId(item),
    selectedItem: index === criteria.selectedIndex && OpenedItem && (
      <OpenedItem
        className="selected-item"
        anchorRef={anchorRef}
        {...getSelectedItemProps(item as unknown as S, index)}
      />
    ),
    inlinePreviewProps: getInlinePreviewProps(item),
  }));

  return (
    <>
      {getMessage(results)}
      {!showItemOnFullScreen && (
        <GridList
          selectionMode={setCheckedContent ? 'multiple' : 'none'}
          selectionBehavior="toggle"
          scrollElementRef={gridWrapperRef}
          wrapperRef={wrapperPropsRef}
          items={items}
          className={className}
          selectedKeys={checkedItems}
          selectedItemId={selectedItem && getId(selectedItem)}
          onSelectionChange={setCheckedContent}
          onSelectItem={onSelectItem}
          onAction={key => {
            const index = items.findIndex(x => x.key === key);
            if (index < 0) return;
            if (selectedItem && getId(selectedItem) === getId(items[index]))
              onSelectItem(-1, index);
            else onSelectItem(index, index);
          }}
          aria-label={`${collectionDescription} ${pageDescription}`}
          marginBottom={marginBottom}
          horizontal={horizontal}
        >
          {item => (
            <Item
              textValue={getLangValue((item as unknown as File).namesByLang)}
            >
              <ItemThumbnail {...getItemProps(item, item.index)} />
            </Item>
          )}
        </GridList>
      )}
      {showItemOnFullScreen && OpenedItem && selectedItem && (
        <OpenedItem
          isFullscreen={true}
          item={selectedItem}
          params={itemComponentParams}
        />
      )}
    </>
  );
}
