import { all } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';
import { enableMapSet } from 'immer';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { History } from 'history';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import content, { ContentState } from './content/model';
import contentSagas from './content/sagas';
import search, { SearchState } from './content/search/model';
import products, { ProductState } from './content/products/model';
import productsSagas from './content/products/sagas';
import home from './home/model';
import homeSagas from './home/sagas';
import orders, { OrderState } from './orders/model';
import ordersSagas from './orders/sagas';
import workspaces, { WorkspacesState } from './content/workspaces/model';
import frontpageSettings, {
  FrontpageSettingsState,
} from './settings/layout/model';
import rightsTree, { RightsTreeState } from './settings/rights/model';
import comments, { CommentState } from './comments/model';
import commentsSagas from './comments/sagas';
import reportsDashboard from './reports/dashboard/ducks';
import reportsCount from './reports/count/ducks';

import integrationSagas from './integration/ponsse/sagas';

import { apiBase } from '~common/api.base';
import config from '~common/config';
import app, { AppState } from '~common/app.model';
import appSagas from '~common/app.sagas';
import commonContent, {
  ContentState as CommonContentState,
} from '~common/content.model';
import commonContentSagas from '~common/content.sagas';

declare module 'react-redux' {
  interface DefaultRootState {
    [key: string]: any;
    search: SearchState;
    content: ContentState;
    commonContent: CommonContentState;
    orders: OrderState;
    comments: CommentState;
    products: ProductState;
    frontpageSettings: FrontpageSettingsState;
    rightsTree: RightsTreeState;
    app: AppState;
    workspaces: WorkspacesState;
    // RTK Query module
    api: any; // TODO: Type this, you Typescript wizard!
  }
}

// This is required as long as we have Sets or Maps in redux and use immer with them.
// TODO: don't have Sets in state (https://redux.js.org/style-guide/style-guide#do-not-put-non-serializable-values-in-state-or-actions)
enableMapSet();

function* rootSaga(): any {
  yield all([
    appSagas(),
    commonContentSagas(),
    contentSagas(),
    homeSagas(),
    integrationSagas(),
    ordersSagas(),
    productsSagas(),
    commentsSagas(),
  ]);
}

export default function configureStore(history: History) {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [
    routerMiddleware(history),
    sagaMiddleware,
    thunk,
    apiBase.middleware,
  ];

  if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger as any);
  }

  const devToolsCompose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

  const composeEnhancers = devToolsCompose
    ? devToolsCompose({
        trace: true,
        traceLimit: 10, // This is default limit imposed by Chrome.
        // Increase if you need to see more of stacktrace.
        maxAge: 20,
        serialize: {
          options: true,
        },
      })
    : compose;

  const enhancer = composeEnhancers(applyMiddleware(...middlewares));

  const rootReducer = combineReducers({
    app,
    commonContent,
    content,
    search,
    home,
    orders,
    products,
    frontpageSettings,
    rightsTree,
    workspaces,
    comments,
    dashboard: reportsDashboard,
    events: reportsCount,
    router: connectRouter(history),
    [apiBase.reducerPath]: apiBase.reducer,
  });

  const store = createStore(rootReducer, undefined, enhancer);

  sagaMiddleware.run(rootSaga);
  store.dispatch({ type: 'APP/INIT', payload: { shareKey: config.shareKey } });

  return store;
}
