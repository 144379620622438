import React from 'react';
import cryptoSha1 from 'crypto-js/sha1';

// NOTE: This is just an example
export const times = (timesArg: number) => {
  return (func: Function) => {
    return Array(timesArg)
      .fill('-')
      .map((_, index) => {
        return func(index);
      });
  };
};

export const sha1 = value => {
  return cryptoSha1(value);
};

export const scrollToBottom = () => {
  window.scrollTo(0, document.body.scrollHeight);
};

export const range = (n: number) => [...Array(n).keys()];

export const sleep = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));

export const clamp = (value: number, min: number, max: number) =>
  Math.max(min, Math.min(max, value));

export const randomNumBetween = (min: number, max: number) =>
  Math.floor(Math.random() * max) + min;

export const isNumeric = (n: any) => !isNaN(parseFloat(n)) && isFinite(n);

export const truncate = (str: string, len: number) => {
  if (str.length > len) return `${str.substring(0, len - 3)}...`;
  return str;
};

// eslint-disable-next-line
export const noop = () => {};

// eslint-disable-next-line
export const noopWithArgs = (...args: any[]) => {};

export const isEmptyObj = (obj: any) => {
  return Object.keys(obj).length === 0;
};

export const isEmpty = value =>
  value === '' || value === null || value === undefined;

// Returns default value if the given value is empty ('', null, or undefined)
export const nonEmpty = (value, defaultValue) => {
  return isEmpty(value) ? defaultValue : value;
};
export const addIfNotIncluded = (collection, item) => {
  if (item === null || item === undefined) return collection;
  return collection.includes(item) ? collection : collection.concat(item);
};

export const toLowerCaseIfString = text => {
  return typeof text === 'string' ? text.toLowerCase() : text;
};

// Used to modify values in object
export const objectMap = <T extends {}, R>(
  object: T,
  mapFn: (obj: T[keyof T]) => R
) => {
  return Object.keys(object).reduce(function (result, key) {
    result[key] = mapFn(object[key]);
    return result;
  }, {} as Record<keyof T, R>);
};

/**
 * Returns the greatest common divisor of `a` and `b`
 */
export function gcd(a: number, b: number): number {
  if (b === 0) {
    return a;
  }
  return gcd(b, a % b);
}

/**
 * Combine multiple refs into one
 *
 * @example
 *
 * const aRef = useRef(null);
 * const bRef = useRef(null);
 *
 * return <div ref={combineRefs(aRef, bRef)}></div>
 *
 */
export function combineRefs<T>(
  ...refs: (React.MutableRefObject<T> | React.LegacyRef<T>)[]
): React.RefCallback<T> {
  return (instance: T) => {
    for (const ref of refs) {
      if (typeof ref === 'function') {
        ref(instance);
      } else if (ref !== null) {
        (ref as React.MutableRefObject<T | null>).current = instance;
      }
    }
  };
}

/**
 * Returns a set with all the elements of `b` that are not in `a`
 */
export function diff<T>(a: Set<T>, b: Set<T>) {
  const diffSet = new Set<T>();
  for (const el of b) {
    if (a.has(el)) {
      continue;
    }
    diffSet.add(el);
  }
  return diffSet;
}

/**
 * Naive implentation of converting React CSS objects to vanilla CSS strings.
 * Use with caution!
 */
export function styleToString(style: React.CSSProperties) {
  return Object.keys(style).reduce(
    (acc, key) =>
      acc +
      key
        .split(/(?=[A-Z])/)
        .join('-')
        .toLowerCase() +
      ':' +
      style[key] +
      ';',
    ''
  );
}

/**
 * Helper function for `Array.filter()` for filtering away undefined items while
 * narrowing typing from `(T | undefined)[]` to `T[]`
 */
export function filterUndefined<T>(item: T | undefined): item is T {
  return item !== undefined;
}

/**
 * Helper function for `Array.filter()` for filtering away objects which contain
 * any undefined fields, narrowing type to an object where no field can be
 * undefined.
 */
export function filterFieldsRequired<T>(item: T): item is Required<T> {
  return Object.keys(item).every(key => item[key] !== undefined);
}

/**
 * Transforms an array of `T` into an object where each array item is a value of
 * the returned object and is keyed by `id` by default
 */
export function arrayToObject<
  T extends { id: string | number },
  K extends T['id']
>(data: T[]): Record<K, T> {
  return data.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.id]: curr,
    }),
    {} as Record<K, T>
  );
}

/**
 * Returns the elements of array `a` that are not included in array `b`
 */
export function difference<T extends unknown>(a: T[], b: T[]) {
  return a.filter(item => !b.includes(item));
}

/**
 * Returns the elements of array `a` that are included in array `b`
 */
export function intersection<T extends unknown>(a: T[], b: T[]) {
  return a.filter(item => b.includes(item));
}

/**
 * Checks whether two arrays have any elements in common
 */
export function isOverlap<T>(a: T[], b: T[]): boolean {
  return a.some(element => b.includes(element));
}

/**
 * Splits an array to two arrays, determined by `predicate`.
 * If `predicate` returns `true` for an item, it is placed into the first
 * array, and if `false` it is placed into the second array.
 */
export function partition<T>(arr: T[], predicate: (item: T) => boolean) {
  return arr.reduce(
    (acc, curr) => {
      acc[predicate(curr) ? 0 : 1].push(curr);
      return acc;
    },
    [[] as T[], [] as T[]] as const
  );
}

/**
 * Utilizes a naive check to see if two arrays contain the same elements. Is not
 * guaranteed to work for arrays with duplicates.
 */
export function elementsMatch<T>(a: T[], b: T[]): boolean {
  return (
    a.length === b.length &&
    a.every(item => b.includes(item)) &&
    b.every(item => a.includes(item))
  );
}

/** manipulate map keys */
export function mapKeys<T extends object>(
  map: T,
  manipulate: (key: string) => string
) {
  return Object.keys(map).reduce(
    (a, key) => ({
      ...a,
      [manipulate(key)]: map[key],
    }),
    {} as T
  );
}

/**
 * Recursively maps a tree, running `callback` on each node.
 *
 * Returns a flat array of results from calling `callback` on each node.
 */
export function mapTree<
  Node extends { children: Node[] },
  Return extends unknown
>(root: Node, callback: (node: Node) => Return): Return[] {
  return [
    callback(root),
    ...root.children.flatMap(child => mapTree(child, callback)),
  ];
}

/** Returns a new object without given fields */
export function omitFields<T, K extends keyof T>(
  object: T,
  keys: K[]
): Omit<T, K> {
  const newObject = { ...object };
  for (const key of keys) {
    delete newObject[key];
  }
  return newObject;
}

/** Returns a new object only the given fields */
export function filterFields<T, K extends keyof T>(
  object: T,
  keys: K[]
): Pick<T, K> {
  return keys.reduce((obj, key) => {
    obj[key] = object[key];
    return obj;
  }, {} as Pick<T, K>);
}
