import mime from 'mime';
import { plural, t } from '@lingui/macro';

import { dndTypes, DndType } from './constants';
import { content } from '../../../content/model';
import { useAddToWorkspace } from '../../../content/workspaces/hooks';
import { useAddToShoppingCart } from '../../../content/workspaces/shopping/hooks';
import { useRestoreFromArchiveMutation } from '../../../content/archive/api';

import {
  BaseFile,
  DndItem,
  File as AppFile,
  UploadFile,
} from '~common/content.types';
import {
  isInsideWorkspace,
  isInWorkspaces,
  isInArchives,
} from '~common/content.utils';
import { useActions } from '~common/utils/hooks.utils';
import { app } from '~common/app.model';
import { getLangValue } from '~common/app.utils';

/** Helper for normalized drag & drop type functions */
function getFileNodeAndParents(
  file: AppFile | BaseFile,
  parents: BaseFile[] | undefined = undefined
) {
  let fileNode: BaseFile;
  let nodeParents: BaseFile[] = [];

  if ('node' in file) {
    fileNode = { ...file, id: file.node.id, fileType: file.node.fileType };
    nodeParents = file.node.parents ?? [];
  } else {
    fileNode = file;
    nodeParents = parents ?? [];
  }

  return { fileNode, nodeParents };
}

/** Returns the appropriate drag & drop type for a given file */
export function getDndType(file: AppFile): DndType;
export function getDndType(baseFile: BaseFile, parents: BaseFile[]): DndType;
export function getDndType(
  file: AppFile | BaseFile,
  parents: BaseFile[] | undefined = undefined
): DndType {
  // Prepare common variables regardless of argument type
  const { fileNode, nodeParents } = getFileNodeAndParents(file, parents);

  if (isInArchives(fileNode, nodeParents)) {
    return dndTypes.ARCHIVE_ITEM;
  }

  switch (fileNode.fileType) {
    case 'nt:file':
    case 'nt:masterProduct':
    case 'nt:userProduct':
      return dndTypes.FILE;
    case 'nt:linkedFile':
      if (isInsideWorkspace(fileNode, nodeParents)) {
        return dndTypes.WORKSPACE_FILE;
      } else {
        return dndTypes.FILE_LINK;
      }
    case 'nt:linkedFolder':
      if (isInsideWorkspace(fileNode, nodeParents)) {
        return dndTypes.WORKSPACE_FOLDER_LINK;
      } else {
        return dndTypes.FOLDER_LINK;
      }
    case 'nt:cart':
      if (fileNode.name === '_SHOPPING_CART') return dndTypes.SHOPPING_CART;
      return dndTypes.WORKSPACE;
    case 'nt:folder':
      if (isInsideWorkspace(fileNode, nodeParents)) {
        return dndTypes.WORKSPACE_FOLDER;
      } else if (isInWorkspaces(fileNode, nodeParents)) {
        return dndTypes.WORKSPACE_LIST_FOLDER;
      } else {
        return dndTypes.FOLDER;
      }
    default:
      return dndTypes.UNDEFINED;
  }
}

/** Returns an array of drag & drop types that a file should accept when
 * dropping items over it */
export function getAcceptedDndTypes(file: AppFile): DndType[];
export function getAcceptedDndTypes(
  baseFile: BaseFile,
  parents: BaseFile[]
): DndType[];
export function getAcceptedDndTypes(
  file: AppFile | BaseFile,
  parents: BaseFile[] | undefined = undefined
): DndType[] {
  const { fileNode, nodeParents } = getFileNodeAndParents(file, parents);

  // Check file type for appropriate return types
  if (fileNode.fileType === 'nt:cart') {
    // Shopping cart is a special kind of cart
    if (fileNode.name === '_SHOPPING_CART')
      return [dndTypes.FILE, dndTypes.FILE_LINK, dndTypes.WORKSPACE_FILE];
    return [
      dndTypes.FILE,
      dndTypes.FILE_LINK,
      dndTypes.WORKSPACE_FILE,
      dndTypes.FOLDER,
      dndTypes.FOLDER_LINK,
      dndTypes.WORKSPACE_FOLDER_LINK,
    ];
  } else if (fileNode.fileType === 'nt:folder') {
    if (isInsideWorkspace(fileNode, nodeParents)) {
      // file is a workspace folder
      return [dndTypes.FILE, dndTypes.FILE_LINK, dndTypes.WORKSPACE_FILE];
    } else if (isInWorkspaces(fileNode, nodeParents)) {
      // file is a workspace list folder
      return [dndTypes.WORKSPACE, dndTypes.WORKSPACE_LIST_FOLDER];
    } else {
      // file is a normal folder
      return [
        dndTypes.FILE,
        dndTypes.FILE_LINK,
        dndTypes.FOLDER,
        dndTypes.FOLDER_LINK,
        dndTypes.ARCHIVE_ITEM,
      ];
    }
  } else if (fileNode.fileType === 'nt:archiveFolder') {
    return [dndTypes.ARCHIVE_ITEM];
  }
  return [];
}

type Options = {
  iOS?: boolean;
  renameIOSImages?: boolean;
  timestamp?: boolean;
};

export function blobToUploadFile(file: File, options?: Options): UploadFile {
  let filetype: string | null = file.type;

  // fallback to mime types database
  if (!filetype) {
    filetype = mime.getType(file.name.substring(file.name.lastIndexOf('.')));
    // fallback to generic filetype
    if (!filetype) filetype = 'application/octet-stream';
  }
  const mimetype = filetype.split('/');

  let name = file.name.normalize();
  let originalName: string | undefined;
  // NOTE: iOS names all the ad-hoc images as image.jpg so we might want to rename those to avoid unnecessary conflicts
  // HAX: I wish this could be done more robustly. We might want to also check that the image is recently taken? lastModified?
  if (options?.renameIOSImages && options?.iOS && name === 'image.jpg') {
    originalName = name;
    name = `image_${Math.random().toString(36).substr(2, 5)}.jpg`;
  } else if (options?.timestamp) {
    originalName = name;
    const nameParts = name.split('.');
    const [extension, ...others] = [nameParts.pop(), ...nameParts];
    name = `${others.join('.')}-${new Date().toISOString()}.${extension}`;
  }

  return {
    id: name,
    blob: file,
    preview: URL.createObjectURL(file),
    name,
    originalName,
    type: mimetype[0],
    subtype: mimetype[1],
    versioning: false, // TODO: is it always false?
  };
}

/**
 * Generic onDrop handler for nodes, which handles different types of data
 * being dropped onto some other types of data, for example:
 *
 * - Dropping a file onto a folder
 * - Dropping a folder onto a workspace
 * - Dropping a file tree node onto a workspace tree node
 */
export function useOnNodeDrop(
  target: AppFile | undefined,
  options?: { groupedWorkspaces: boolean }
): (droppedItem: DndItem) => void;
export function useOnNodeDrop(
  target: BaseFile | undefined,
  options: { parents: BaseFile[]; groupedWorkspaces?: boolean }
): (droppedItem: DndItem) => void;
export function useOnNodeDrop(
  target: AppFile | BaseFile | undefined,
  options?: { parents?: BaseFile[]; groupedWorkspaces?: boolean }
): (droppedItem: DndItem) => void {
  const moveContent = useActions(content.actions.moveContent);
  const { addToWorkspace } = useAddToWorkspace();
  const { addToShoppingCart } = useAddToShoppingCart();
  const setOpenModal = useActions(app.actions.setOpenModal);
  const [restoreArchived] = useRestoreFromArchiveMutation();

  if (target === undefined) return () => {};

  const { fileNode, nodeParents } = getFileNodeAndParents(
    target,
    options?.parents
  );

  /** Modal callback depending on drop target:
   *
   * Target is a regular folder
   * => prompts user to confirm move/copy/link of dropped item
   *
   * Target is a workspace folder
   * => automatically links or moves dropped item depending on item type
   *
   * Target is a workspace
   * => automatically links dropped item into workspace
   */
  const callback = (droppedItem: DndItem) => {
    const targetType = getDndType(fileNode, nodeParents);

    if (targetType === dndTypes.WORKSPACE) {
      addToWorkspace({
        workspaceId: fileNode.id,
        itemIds: droppedItem.itemIds,
      });
    } else if (targetType === dndTypes.SHOPPING_CART) {
      addToShoppingCart(droppedItem.itemIds);
    } else {
      let moveMode: 'move' | 'link' | undefined;
      if (targetType === dndTypes.WORKSPACE_FOLDER) {
        if (droppedItem.type === dndTypes.WORKSPACE_FILE) {
          moveMode = 'move';
        } else {
          moveMode = 'link';
        }
      }

      // Restore archived items to materialbank
      if (
        droppedItem.type === dndTypes.ARCHIVE_ITEM &&
        targetType !== dndTypes.ARCHIVE_ITEM
      ) {
        const destFolderName = getLangValue(fileNode.namesByLang);
        setOpenModal('COMMON/CONFIRM_MODAL', {
          title: t`Confirm restore`,
          confirmText: plural(droppedItem.itemIds.length, {
            one: `Are you sure you want to restore an item into ${destFolderName}`,
            other: `Are you sure you want to restore ${droppedItem.itemIds.length} items into ${destFolderName}`,
          }),
          confirmButtonText: t`Restore`,
          onConfirm: () =>
            restoreArchived({
              selectedNodes: droppedItem.itemIds,
              destinationFolderUuid: fileNode.id,
            }),
        });
        return;
      }

      moveContent({
        destFolderId: options?.groupedWorkspaces
          ? nodeParents[0].id
          : fileNode.id,
        itemIds: droppedItem.itemIds,
        mode: moveMode,
      });
    }
  };

  return callback;
}
