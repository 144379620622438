import { DefaultRootState } from 'react-redux';
import qs from 'qs';
import { ShareObject } from './model';
import config from '~common/config';
import api from '~common/utils/api.utils';
import { apiBase } from '~common/api.base';

export const shareFile = async ({
  share,
  senderId,
}: {
  share: ShareObject;
  senderId?: number;
}) => {
  // TODO: Remove hardcoded URL
  // TODO: Change to API endpoint once ready
  const {
    fileId,
    folderId,
    subject,
    recipients,
    message,
    conversions,
    sendMethod = 1,
  } = share;

  const body = {
    command: 'sendCart',
    uuid: folderId,
    subjectHasBeenSet: true,
    weeksValid: 1,
    selectedNodes: fileId,
    hiddParam: true,
    selectedLanguage: 'en',
    // Backend will use the default sender if senderId is -1
    senderId: senderId ?? -1,
    emails: recipients,
    'subject(en)': subject,
    'subject(fi)': subject,
    message,
    validityStartDate: '',
    validityStartTime: '',
    validInDaysMode: 0,
    daysValid: 1,
    sendMethod,
    'cartContent[0].uuid': fileId,
    'cartContent[0].attachment': true,
  };

  const bodyFormData = new FormData();

  Object.keys(body).forEach(key => {
    bodyFormData.append(key, body[key]);
  });

  conversions.forEach(conversion => {
    if (conversion.includes('customerAttachment.'))
      bodyFormData.append(
        'cartContent[0].selectedCustomerFileVersion',
        conversion.replace('customerAttachment.', '')
      );
    else if (conversion.includes('attachment.'))
      bodyFormData.append(
        'cartContent[0].selectedFileVersion',
        conversion.replace('attachment.', '')
      );
    else bodyFormData.append(`cartContent[0].${conversion}Version`, 'on');
  });

  const res = await api.http.post(
    `${config.BASE_URL}/NiboWEB/${config.customer}/sendCart.do`,
    bodyFormData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  );
  return res;
};

interface Suggestion {
  value: string;
}

interface GetMetaFieldSuggestionsParams {
  metaFieldId: string;
  search: string;
  lang: string;
}

const extendedApi = apiBase.injectEndpoints({
  endpoints: builder => ({
    getMetaFieldSuggestions: builder.query<
      Suggestion[],
      GetMetaFieldSuggestionsParams
    >({
      queryFn: async (
        { search, metaFieldId, lang },
        { getState },
        _,
        baseQuery
      ) => {
        const state = getState() as DefaultRootState;
        const customerId = state.app.customer?.id;
        const defLang = state.app.settings?.language;
        const interpretedLang = (metaFieldId.match(/_(.*?)$/) ?? [])[1];
        const params = {
          search,
          // fields: ['_meta_...'] doesn't work
          'fields[]': `_meta_${metaFieldId}_txt`,
          limit: 10,
          lang: lang ?? interpretedLang ?? defLang,
          defaultLang: defLang,
        };

        const res = await baseQuery({
          url: `/customers/${customerId}/suggestions?${qs.stringify(params)}`,
          method: 'get',
        });
        if (res.error) {
          return { error: res.error };
        } else {
          return {
            data: (res.data as { items: Suggestion[] }[])[0]?.items.sort(
              (a, b) => a.value.localeCompare(b.value)
            ),
          };
        }
      },
    }),
  }),
});

export const { useGetMetaFieldSuggestionsQuery } = extendedApi;
