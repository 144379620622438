const offset = -7;
const themeColor = '#176B88';
const cropperStyles = {
  move: {
    outline: `2px dashed ${themeColor}`,
  },
  gridLine: {
    outline: `1px dashed ${themeColor}`,
  },
  dotInner: {
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    border: `2px solid ${themeColor}`,
    borderWidth: '2px',
    borderColor: themeColor,
  },
  dotInnerN: {
    top: offset + 1,
    left: '50%',
    marginLeft: offset + 1,
    height: '7px',
    width: '7px',
  },
  dotInnerS: {
    bottom: offset,
    left: '50%',
    marginLeft: offset + 1,
    height: '7px',
    width: '7px',
  },
  dotInnerE: {
    right: offset,
    top: '50%',
    marginTop: offset + 1,
    height: '7px',
    width: '7px',
  },
  dotInnerW: {
    left: offset,
    top: '50%',
    marginTop: offset + 1,
    height: '7px',
    width: '7px',
  },
  dotInnerNE: {
    top: offset,
    right: offset,
  },
  dotInnerSE: {
    bottom: offset,
    right: offset,
  },
  dotInnerNW: {
    top: offset,
    left: offset,
  },
  dotInnerSW: {
    bottom: offset,
    left: offset,
  },
  lineN: {
    left: '16px',
    width: 'calc(100% - 32px)',
  },
  lineS: {
    left: '16px',
    width: 'calc(100% - 32px)',
  },
  lineW: {
    top: '16px',
    height: 'calc(100% - 32px)',
  },
  lineE: {
    top: '16px',
    height: 'calc(100% - 32px)',
  },
  dotInnerCenterVertical: {
    backgroundColor: themeColor,
  },
  dotInnerCenterHorizontal: {
    backgroundColor: themeColor,
  },
};

export default cropperStyles;
