import React from 'react';
import styled from 'styled-components';
import { InputProps, InputAdornment } from '@mui/material';
import { t } from '@lingui/macro';
import TextField from './TextField';

import DropDown from './DropDown';
import DatePicker from './DatePicker';
import IconButton from './IconButton';
import { FormLabelProps } from './FormLabel';
import AutocompleteField from './AutocompleteField';
import FormDateRange from './FormDateRange';
import ValidityPeriod from './ValidityPeriod';
import TreeField from './TreeField';
import { PROPERTY_VALIDITY_PERIOD } from '../../content/common/utils';
import Archive from './Archive';
import {
  ArchiveField,
  DateRangeField,
  MetaData,
  MetaField,
} from '~common/content.types';
import PopupEditorField from '~common/inputs/PopupEditorField';
import { LocalizedText } from '~common/app.utils';
import { CloseIcon } from '~common/misc/icons';
import { FieldError } from '~common/utils/form.utils';

interface BaseProps {
  id: string;
  fieldType: string;
  value: any;
  onChange: (value: any, fieldKey?: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  error?: boolean;
  variant?: any;
  fullWidth?: boolean;
  options?: any;
  optionsLang?: string;
  disabled?: boolean;
  readOnly?: boolean;
  labelText?: string | LocalizedText;
  required?: boolean;
  helperText?: string;
  autoComplete?: string;
  allowClearing?: boolean;
  InputProps?: InputProps;
  labelProps?: Partial<FormLabelProps>;
  metaFieldId?: string;
  maxRows?: number;
  field?: MetaField;
  fieldError?: FieldError;
  metaData?: Record<string, MetaData>;
  lang?: string;
  isDefaultLang?: boolean;
  asPopup?: boolean;
  isNew?: boolean;
}

type DateInputProps<T> =
  | T
  | (T & {
      fieldType: 'date' | 'VALUE_TYPE_DATE';
      reverseAdornments?: boolean;
    });

type ClearanceProps<T> =
  | (T & { allowClearing?: false; onClear?: undefined })
  | (T & { allowClearing: true; onClear: () => void });

type Props = ClearanceProps<DateInputProps<BaseProps>>;

const FormField = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      fieldType,
      value,
      onChange,
      allowClearing,
      onClear,
      metaFieldId,
      maxRows,
      optionsLang,
      options,
      field,
      fieldError,
      asPopup = false,
      isNew = false,
      disabled,
      ...rest
    }: Props,
    ref
  ) => {
    const handleChange = (e: any) => {
      onChange(e.target.value);
    };

    const adornment =
      value && allowClearing ? (
        <InputAdornment position="start" style={{ marginRight: 0 }}>
          <IconButton
            style={{ padding: '6px 6px 7px' }}
            aria-label={t`Clear`}
            onClick={e => {
              e.stopPropagation();
              if (onClear) onClear();
            }}
            size="large"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </InputAdornment>
      ) : undefined;

    const inputProps = { startAdornment: adornment };
    switch (fieldType) {
      case 'wysiwyg':
        return (
          <PopupEditorField
            onChange={onChange}
            initialValue={value}
            disabled={disabled || rest.readOnly}
            {...rest}
          />
        );
      case 'VALUE_TYPE_TEXT':
      case 'VALUE_TYPE_EMAIL':
        return (
          <StyledTextField
            value={value}
            onChange={handleChange}
            type={fieldType.substring(11).toLowerCase()}
            inputProps={{
              inputmode: fieldType.substring(11).toLowerCase(),
            }}
            InputProps={inputProps}
            inputRef={ref}
            disabled={disabled || rest.readOnly}
            {...rest}
          />
        );
      case 'text':
      case 'email':
        return (
          <TextField
            value={value}
            onChange={handleChange}
            type={fieldType}
            inputProps={{
              inputmode: fieldType,
            }}
            InputProps={inputProps}
            inputRef={ref}
            disabled={disabled || rest.readOnly}
            {...rest}
          />
        );
      case 'VALUE_TYPE_TEXTAREA':
      case 'textarea':
      case 'VALUE_TYPE_EMAILS':
        return (
          <StyledTextField
            value={value}
            onChange={handleChange}
            multiline
            rows={rest.variant === 'form' ? 2 : undefined}
            maxRows={maxRows ?? (rest.variant === 'form' ? 20 : undefined)}
            InputProps={inputProps}
            inputRef={ref}
            disabled={disabled || rest.readOnly}
            {...rest}
          />
        );
      case 'emails':
        return (
          <TextField
            value={value}
            onChange={handleChange}
            multiline
            rows={rest.variant === 'form' ? 2 : undefined}
            maxRows={rest.variant === 'form' ? 20 : undefined}
            InputProps={inputProps}
            inputRef={ref}
            disabled={disabled || rest.readOnly}
            {...rest}
          />
        );
      case 'VALUE_TYPE_NUMERIC':
        return (
          <StyledTextField
            value={value}
            onChange={handleChange}
            type="number"
            inputProps={{
              inputmode: 'decimal',
            }}
            InputProps={inputProps}
            inputRef={ref}
            disabled={disabled || rest.readOnly}
            {...rest}
          />
        );
      case 'numeric':
        return (
          <TextField
            value={value}
            onChange={handleChange}
            type="number"
            inputProps={{
              inputmode: 'decimal',
            }}
            InputProps={inputProps}
            inputRef={ref}
            disabled={disabled || rest.readOnly}
            {...rest}
          />
        );
      case 'VALUE_TYPE_DROPDOWN':
      case 'dropdown':
        return (
          <DropDown
            value={value}
            onChange={handleChange}
            options={options}
            optionsLang={optionsLang}
            selectProps={inputProps}
            disabled={disabled || rest.readOnly}
            {...rest}
          />
        );
      case 'dropdown-multiple':
        return (
          <DropDown
            value={value || []}
            onChange={handleChange}
            options={options}
            optionsLang={optionsLang}
            multiple
            selectProps={inputProps}
            disabled={disabled || rest.readOnly}
            {...rest}
          />
        );
      case 'VALUE_TYPE_DATE':
      case 'date':
        return (
          <DatePicker
            value={value}
            onChange={value => handleChange({ target: { value } })}
            onClear={() => handleChange({ target: { value: null } })}
            InputProps={inputProps}
            ref={ref}
            {...rest}
            disabled={disabled || rest.readOnly}
            labelProps={{ compact: true }}
          />
        );
      case 'autocomplete':
        return (
          <AutocompleteField
            onChange={value => handleChange({ target: { value } })}
            metaFieldId={metaFieldId ?? ''}
            value={value}
            InputProps={inputProps}
            ref={ref}
            disabled={disabled || rest.readOnly}
            {...rest}
          />
        );
      case 'tree-multiple':
      case 'tree':
        return (
          <TreeField
            value={value ?? []}
            onChange={value => handleChange({ target: { value } })}
            metaFieldId={metaFieldId ?? ''}
            disabled={disabled || rest.readOnly}
            {...rest}
          />
        );
      case 'archive':
        return (
          <Archive
            field={field as ArchiveField}
            metaData={rest.metaData}
            lang={rest.lang ?? ''}
            onChange={onChange}
            disabled={disabled || rest.readOnly}
            error={rest.error}
            helperText={rest.helperText}
            asPopup={asPopup}
            isNew={isNew}
          />
        );
      case 'date-range':
        return field?.id === PROPERTY_VALIDITY_PERIOD ? (
          <ValidityPeriod
            field={field as DateRangeField}
            isLangSupportField={false}
            metaData={rest.metaData}
            lang={rest.lang ?? ''}
            isDefaultLang={rest.isDefaultLang ?? false}
            onChange={onChange}
            disabled={disabled || rest.readOnly}
            error={rest.error}
            helperText={rest.helperText}
            asPopup={asPopup}
          />
        ) : field ? (
          <FormDateRange
            field={field as DateRangeField}
            metaData={rest.metaData}
            lang={rest.lang ?? ''}
            onChange={onChange}
            disabled={disabled || rest.readOnly}
            error={Boolean(fieldError)}
            helperText={fieldError?.errorDetail}
          />
        ) : null;
    }
    return null;
  }
);
FormField.displayName = 'FormField';

const StyledTextField = styled(TextField)`
  && > * {
    margin-bottom: 0 !important;
  }
`;

export default FormField;
