import { Key } from 'react';
import qs from 'qs';
import { t } from '@lingui/macro';
import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';

import api, { afterQueryMessages } from './utils/api.utils';
import config from './config';
import sampleHome from './sampleHomePage';
import { convertUserIn } from './app.utils';
import { app } from './app.model';

import { app as appModel } from '~common/app.model';
import { apiBase } from '~common/api.base';
import { User, UserResponse } from '~common/app.types';
import {
  CommonUser,
  UserUpsertFormFields,
  UserId,
  MailSuggestion,
} from '~common/user.types';

// TODO: remove and return from API as json
export const asJSON = (value: any) => {
  return value && typeof value === 'string' ? JSON.parse(value) : value;
};

export const readUserRights = async ({ customerId }): Promise<any> => {
  const params = {
    customerId,
  };
  const response = await api.http.get(
    `/users/me/rights?${qs.stringify(params)}`
  );
  return {
    ...response.data,
  };
};

export const getSessionLanguage = async () => {
  const response = await api.http.get(`/sessions/current/language`);
  return response.data;
};

export const readUserSettings = async (customerId: string) => {
  // TODO: pass customerId, maybe also userId
  const response = await api.http.get(`/settings?customerId=${customerId}`);

  // TODO: Return theme as JSON from API
  const theme = asJSON(response.data.theme) || {
    logo: config.logo,
    primaryColor: config.primaryColor,
    secondaryColor: config.secondaryColor,
  };

  // TODO: return home, login, theme from API
  return {
    ...response.data,
    home: asJSON(response.data.home) || sampleHome,
    login: {
      layout: 1,
      backgroundImageUrl:
        'https://gredi.materialbank.net/NiboWEB/GreDi/getFile.do?cart=true&ticket=503042&cartUuid=13501901&uuid=13501902&type=original',
    },
    theme: {
      ...config.theme,
      ...theme,
    },
  };
};

const readAjaxAction = async ({
  command,
  customerStaticName,
}): Promise<any> => {
  const params = new URLSearchParams();
  params.append('command', command);

  const response = await api.http.post(
    `${config.url}/NiboWEB/${customerStaticName}/ajaxNiboLayout.do`,
    params,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );
  return {
    ...response.data,
  };
};

export const readUserProfile = async ({ customerStaticName }): Promise<any> => {
  return readAjaxAction({
    command: 'getIdUserProfile',
    customerStaticName,
  });
};

export const readLogoutRedirectUrl = async ({
  customerStaticName,
}): Promise<any> => {
  return readAjaxAction({
    command: 'getRedirectURLInLogout',
    customerStaticName,
  });
};

export const login = async ({
  customer,
  redirectUrl,
  loginParams,
}): Promise<any> => {
  let url = `${config.url}/NiboWEB/${customer}/`;
  if (redirectUrl) {
    if (redirectUrl.endsWith('/logout')) {
      redirectUrl = redirectUrl.replace('/logout', '');
    }
    const params = {
      redirectUrl,
    };
    url = `${url}loginNotify.do?${qs.stringify(params)}`;
  }
  if (loginParams && Object.keys(loginParams).length > 0) {
    const params = {
      ...loginParams,
      customerPath: customer,
      directLogin: true,
    };
    url = `${url}${
      url.indexOf('loginNotify.do?') !== -1 ? '&' : 'loginNotify.do?'
    }${qs.stringify(params)}`;
  }
  window.location.replace(url);
  return {};
};

export const logout = async (
  { customer, session },
  restricted?: boolean
): Promise<any> => {
  // JBOSS SSO fix: Try to logout from API also
  // NOTE: Probably not required for Wildfly
  try {
    await api.http.delete(`/sessions/current?clean=true`);
  } catch {}

  const params = {
    RedirectUrlInLogout:
      session && session.redirectURL ? session.redirectURL : undefined,
    restricted: restricted ? 'true' : 'false',
  };
  window.location.href = `${
    config.url
  }/NiboWEB/${customer}/logout.do?${qs.stringify(params)}`;

  return true;
};

const usersAdapter = createEntityAdapter<CommonUser>();

export type UsersOrderField =
  | 'username'
  | 'last_login_time'
  | 'last_modify_time'
  | 'first_name'
  | 'family_name'
  | 'email'
  | 'created';
export type GetUsersParams = {
  order?: `${UsersOrderField}` | `${UsersOrderField}_desc`;
  page: number;
  pageSize: number;
  filter?: string;
  groupId?: `${number}`;
  status?: 'active' | 'locked' | 'waiting' | 'inactive' | 'removed';
  all?: 'true';
};

type UsersQueryResult = {
  users: CommonUser[];
  totalCount: number;
};

type InfoChange = {
  firstName?: string;
  familyName?: string;
  email?: string;
  language?: string;
};

type PasswordChange = {
  oldPassword: string;
  newPassword: string;
};

type SetPassword = {
  token: string;
  newPassword: string;
};

type GetTokenIsValidParams = {
  token: string;
};

type UserUpdate = {
  id: Key;
  user: UserUpsertFormFields;
};

export type ChangeStatus = {
  /** remove is recoverable action. delete is permanent. */
  action: 'lock' | 'remove' | 'delete' | 'restore' | 'activate' | 'unlock';
} & ({ users: number[] } | { waitingUsers: number[] });

type AnotherUserPassword = {
  id: Key;
  newPassword: string;
};

export type UserGroupsById = {
  [groupId: string]: {
    id: number;
    userIds: string[];
  };
};

export type GroupsOfUsers = {
  [userId: string]: {
    userId: number;
    groupIds: string[];
  };
};

type GetUserGroupsByIdParams = {
  customerId: string;
  userIds: string[];
};

type OverwriteUserGroupsParams = {
  customerId: string;
  data: {
    groupsOfUsers: GroupsOfUsers;
  };
};

const getUserTag = (userId: string) => ({
  type: 'User' as const,
  id: userId,
});

const convertCommonUserIn = (user: CommonUser) => ({
  ...user,
  id: `${user.id ? user.id : `waiting-${user.waitingId}`}`,
});

const extendedApi = apiBase.injectEndpoints({
  endpoints: builder => ({
    getCurrentUser: builder.query<User, void>({
      query: () => ({ url: '/users/me', method: 'get' }),
      transformResponse: (response: UserResponse) => convertUserIn(response),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(app.actions.afterReadUser(data));
        } catch (error) {
          console.log(error);
        }
      },
      providesTags: ['User'],
    }),

    getUser: builder.query<CommonUser, { id: UserId }>({
      query: ({ id }) => ({
        url: `/users${
          id.includes('waiting')
            ? `/waiting/${id.replace('waiting-', '')}`
            : `/${id}`
        }`,
        method: 'get',
      }),
      transformResponse: convertCommonUserIn,
      providesTags: (_, __, { id }) => [getUserTag(id)],
    }),

    getAllUsers: builder.query<EntityState<CommonUser>, void>({
      query: () => ({ url: '/users?all=true', method: 'get' }),
      transformResponse: (response: { users: CommonUser[] }) =>
        usersAdapter.setMany(
          usersAdapter.getInitialState(),
          response.users.map(convertCommonUserIn)
        ),
      providesTags: result =>
        (result?.ids ?? []).map(id => getUserTag(`${id}`)),
    }),

    getUsers: builder.query<UsersQueryResult, GetUsersParams>({
      query: ({ page, ...params }) => ({
        url: `/users?${qs.stringify({
          ...params,
          startIndex: page * params.pageSize,
        })}`,
        method: 'get',
      }),
      transformResponse: (response: UsersQueryResult) => ({
        ...response,
        users: response.users.map(convertCommonUserIn),
      }),
      providesTags: result =>
        (result?.users ?? []).map(({ id }) => getUserTag(`${id}`)),
    }),

    updateUserSettings: builder.mutation<void, InfoChange>({
      query: data => ({ url: '/users/me', method: 'put', data }),
      onQueryStarted: (_, args) =>
        afterQueryMessages(
          t`Settings changed succesfully`,
          t`Something went wrong`,
          args
        ),
      invalidatesTags: ['User'],
    }),

    changeUserPassword: builder.mutation<void, PasswordChange>({
      query: data => ({ url: '/users/me/changePassword', method: 'put', data }),
      onQueryStarted: (_, args) =>
        afterQueryMessages(
          t`Password succesfully changed`,
          t`Wrong old password`,
          args
        ),
      invalidatesTags: ['User'],
    }),

    setUserPassword: builder.mutation<void, SetPassword>({
      query: data => ({ url: '/users/set_password', method: 'put', data }),
      onQueryStarted: (_, args) =>
        afterQueryMessages(
          t`Password succesfully set`,
          t`Something went wrong`,
          args
        ),
      invalidatesTags: ['User'],
    }),

    getTokenIsValid: builder.query<UserGroupsById, GetTokenIsValidParams>({
      query: ({ token }) => ({
        url: `users/token/?token=${token}`,
        method: 'get',
      }),
    }),

    getMailSuggestions: builder.query<MailSuggestion[], void>({
      query: () => ({ url: '/users/me/email-suggestions', method: 'get' }),
      transformResponse: (response: string[]) =>
        response.map(item => ({ id: item, value: item })),
    }),

    updateUser: builder.mutation<void, UserUpdate>({
      query: ({ user, id }) => ({
        url: `users/${id}`,
        method: 'put',
        data: user,
      }),
      onQueryStarted: (_, args) =>
        afterQueryMessages(
          t`User update successful`,
          t`User update failed`,
          args
        ),
      invalidatesTags: (_, __, { id }) => [
        'User',
        'GroupsByUsers',
        getUserTag(`${id}`),
      ],
    }),

    createUser: builder.mutation<{ status: string }, UserUpsertFormFields>({
      query: data => ({ url: 'users/add', method: 'post', data }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const {
            data: { status },
          } = await queryFulfilled;
          if (status === 'maxUserCountExceeded') {
            dispatch(
              app.actions.showInfoMessage(
                t`User creation successful. Maximum number of users are exceeded!`
              )
            );
          } else {
            dispatch(app.actions.showInfoMessage(t`User creation successful`));
          }
        } catch {
          dispatch(app.actions.showErrorMessage(t`User creation failed`));
        }
      },
      invalidatesTags: ['User'],
    }),

    approveUser: builder.mutation<void, UserUpsertFormFields>({
      query: ({ waitingId, ...data }) => ({
        url: `users/waiting/${waitingId}/add`,
        method: 'post',
        data: { ...data, accountType: 0 },
      }),
      onQueryStarted: (_, args) =>
        afterQueryMessages(
          t`User approval successful`,
          t`User approval failed`,
          args
        ),
      invalidatesTags: ['User'],
    }),

    changeUserStatus: builder.mutation<void, ChangeStatus>({
      query: data => ({
        url: `users/change-status`,
        method: 'put',
        data,
      }),
      onQueryStarted: (_, args) =>
        afterQueryMessages(
          t`User status change successful`,
          t`User status change failed`,
          args
        ),
      invalidatesTags: ['User'],
    }),

    changeAnotherUserPassword: builder.mutation<void, AnotherUserPassword>({
      query: ({ id, newPassword }) => ({
        url: `users/${id}/change-password`,
        method: 'put',
        data: { newPassword },
      }),
      onQueryStarted: (_, args) =>
        afterQueryMessages(
          t`Password change successful`,
          t`Password change failed`,
          args
        ),
      invalidatesTags: ['User'],
    }),
    getGroupsByUserIds: builder.query<UserGroupsById, GetUserGroupsByIdParams>({
      query: ({ customerId, userIds }) => ({
        url: `/customers/${customerId}/user_groups/?userIds=${userIds.join(
          ','
        )}`,
        method: 'get',
      }),
      transformResponse: (data: UserGroupsById) =>
        !data || typeof data === 'string' ? {} : data, // We always want an object
      providesTags: (_, __, { userIds }) => [
        'GroupsByUsers',
        ...userIds.map(getUserTag),
      ],
    }),
    overwriteUserGroups: builder.mutation<void, OverwriteUserGroupsParams>({
      query: ({ customerId, data }) => ({
        url: `/customers/${customerId}/user_groups/update_all`,
        method: 'put',
        data,
      }),
      invalidatesTags: (_, __, { data }) => [
        'GroupsByUsers',
        ...Object.keys(data.groupsOfUsers).map(getUserTag),
      ],

      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            appModel.actions.showInfoMessage(t`User groups changed succesfully`)
          );
        } catch {
          dispatch(appModel.actions.showErrorMessage(t`Something went wrong`));
        }
      },
    }),
    updateSessionLanguage: builder.mutation<void, string>({
      query: lang => ({
        url: `/sessions/current/language/${lang}`,
        method: 'put',
      }),
    }),
  }),
});

const {
  util: { invalidateTags },
  endpoints: { getCurrentUser },
} = extendedApi;

export const userCacheSelector = getCurrentUser.select();
export const invalidateUser = () => invalidateTags(['User']);
export const {
  useGetUserQuery,
  useGetCurrentUserQuery,
  useGetAllUsersQuery,
  useGetUsersQuery,
  useChangeUserPasswordMutation,
  useSetUserPasswordMutation,
  useGetTokenIsValidQuery,
  useUpdateUserSettingsMutation,
  useGetMailSuggestionsQuery,
  useUpdateUserMutation,
  useCreateUserMutation,
  useApproveUserMutation,
  useChangeUserStatusMutation,
  useChangeAnotherUserPasswordMutation,
  useGetGroupsByUserIdsQuery,
  useOverwriteUserGroupsMutation,
  useUpdateSessionLanguageMutation,
} = extendedApi;
