import { apiBase } from '~common/api.base';
import config from '~common/config';
import { app } from '~common/app.model';

const extendedApi = apiBase.injectEndpoints({
  endpoints: builder => ({
    getShoppingCartId: builder.query<{ id: `${number}` }, { username: string }>(
      {
        query: ({ username }) => ({
          url: `/fileIds/${config.customer}/_users/${username}/_SHOPPING_CART`,
          method: 'get',
        }),
        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
          try {
            const { data } = await queryFulfilled;
            dispatch(app.actions.afterReadShoppingCart(data));
          } catch (error) {
            console.log(error);
          }
        },
        providesTags: ['ShoppingCart'],
      }
    ),
  }),
});

export const { useGetShoppingCartIdQuery } = extendedApi;
