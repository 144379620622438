import React from 'react';
import styled from 'styled-components';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import {
  FoldersIcon,
  SearchIcon,
  WorkspaceIcon,
  SettingsIcon,
  RectangleCommentIcon,
} from '~misc/icons';
import { LeftDockContent, RightDockContent } from '~common/app.model';

export interface OpenDockProps<
  DockContents = LeftDockContent | RightDockContent
> {
  open: boolean;
  content?: DockContents;
  activeTab?: string;
}

interface MinimizedProps<DockContents> {
  openDock: ({ open, content, activeTab }: OpenDockProps<DockContents>) => void;
}

// TODO: Move to dedicated file
const MinimizedDockContent = styled.div`
  width: 100%;
  min-width: 0;
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;

const DockButton = styled.div`
  display: flex;
  color: ${({ theme }) => theme.palette.primary.main};
  justify-content: center;
  align-items: center;
  padding: 10px 5px;
  width: 100%;
  max-width: 100%;
  cursor: pointer;
  transition: all 0.2s ease-out;
  &:hover {
    background-color: ${({ theme }: any) => theme.palette.primary.lightFade};
  }
`;

export const MinimizedDockTabs: React.FC<MinimizedProps<LeftDockContent>> = ({
  openDock,
}) => (
  <MinimizedDockContent>
    <DockButton
      onClick={() =>
        openDock({
          open: true,
          content: '/contentTabs',
          activeTab: 'folder',
        })
      }
    >
      <FoldersIcon />
    </DockButton>
    <DockButton>
      <SearchIcon
        onClick={() =>
          openDock({
            open: true,
            content: '/contentTabs',
            activeTab: 'search',
          })
        }
      />
    </DockButton>
  </MinimizedDockContent>
);
export const MinimizedProductPages: React.FC<
  MinimizedProps<LeftDockContent>
> = ({ openDock }) => (
  <MinimizedDockContent>
    <DockButton
      onClick={() => openDock({ open: true, content: '/productPages' })}
    >
      <InsertDriveFileIcon />
    </DockButton>
  </MinimizedDockContent>
);

export const MinimizedCommentPages: React.FC<
  MinimizedProps<LeftDockContent>
> = ({ openDock }) => (
  <MinimizedDockContent>
    <DockButton
      onClick={() => openDock({ open: true, content: '/commentPages' })}
    >
      <InsertDriveFileIcon />
    </DockButton>
  </MinimizedDockContent>
);

export const MinimizedWorkspaces: React.FC<
  MinimizedProps<RightDockContent>
> = ({ openDock }) => (
  <MinimizedDockContent>
    <DockButton
      onClick={() => openDock({ open: true, content: '/workspaces' })}
    >
      <WorkspaceIcon />
    </DockButton>
  </MinimizedDockContent>
);

export const MinimizedProductElement: React.FC<
  MinimizedProps<RightDockContent>
> = ({ openDock }) => (
  <MinimizedDockContent>
    <DockButton
      onClick={() => openDock({ open: true, content: '/productElement' })}
    >
      <SettingsIcon />
    </DockButton>
  </MinimizedDockContent>
);

export const MinimizedCommentList: React.FC<
  MinimizedProps<RightDockContent>
> = ({ openDock }) => (
  <MinimizedDockContent>
    <DockButton
      onClick={() => openDock({ open: true, content: '/commentList' })}
    >
      <RectangleCommentIcon />
    </DockButton>
  </MinimizedDockContent>
);
