import React, { DOMAttributes, useEffect, useRef, AriaAttributes } from 'react';
import { useSelector } from 'react-redux';
import Selecto from 'react-selecto';
import {
  AriaGridListOptions,
  FocusScope,
  mergeProps,
  useFocusManager,
  useFocusRing,
  useGridList,
  useGridListItem,
  useGridListSelectionCheckbox,
} from 'react-aria';
import { useListState, ListProps, ListState } from 'react-stately';
import styled from 'styled-components';
import { content } from './../../content/model';
import ItemsSelector from './ItemsSelector';
import { useActions } from '~common/utils/hooks.utils';
import { GenericData } from '~common/common.types';
import { combineRefs } from '~common/utils/fn.utils';

type ListItemProps<D extends GenericData> = D & {
  index: number;
  key: string;
  selectedItem?: false | JSX.Element | undefined;
  inlinePreviewProps?: DOMAttributes<HTMLElement> & AriaAttributes;
};
interface GridListProps<D extends GenericData>
  extends AriaGridListOptions<ListItemProps<D>>,
    ListProps<ListItemProps<D>> {
  scrollElementRef?: React.RefObject<HTMLDivElement>;
  wrapperRef?: React.Ref<HTMLUListElement>;
  className?: string;
  selectedItemId?: string;
  onSelectItem?: (index: number, eventTargetIndex: number) => void;
  nestedFocusScope?: boolean;
  marginBottom?: string;
  horizontal?: boolean;
}

export function GridList<D extends GenericData>({
  scrollElementRef,
  wrapperRef,
  selectedItemId,
  onSelectItem,
  className,
  nestedFocusScope,
  marginBottom,
  horizontal,
  ...props
}: GridListProps<D>) {
  const customer = useSelector(state => state.app.customer);
  const state = useListState(props);
  const listRef = useRef<HTMLUListElement>(null);
  const selectoRef = useRef<Selecto>(null);
  const scrollerElement = scrollElementRef?.current as HTMLDivElement;
  const selectorEnabled =
    customer?.configById['items.selector.enabled'] === true &&
    scrollerElement !== undefined;

  const {
    gridProps: { onFocus, onKeyDown, ...gridProps },
  } = useGridList(props, state, listRef);

  useEffect(() => {
    if (selectedItemId) state.selectionManager.setFocusedKey(selectedItemId);
  }, [selectedItemId]);
  const { setItemsChecked } = useActions(content.actions);
  return (
    <ContainerDiv $selectorEnabled={selectorEnabled}>
      {selectorEnabled && (
        <ItemsSelector
          selectoRef={selectoRef}
          targetsSelector={['li', "section[id^='opened-']"]}
          scrollerElement={scrollerElement}
          getElementNodeId={el => el.getAttribute('data-key')}
          setItemsChecked={setItemsChecked}
        />
      )}
      <ResultsList
        $selectorEnabled={selectorEnabled}
        {...gridProps}
        className={className}
        ref={wrapperRef ? combineRefs(listRef, wrapperRef) : listRef}
        onFocus={e => {
          // useGridList won't handle focus events bubbled through modals
          // We wan't to catch these and return the focus to the table
          if (
            !state.selectionManager.isFocused &&
            !e.currentTarget.contains(e.target)
          ) {
            state.selectionManager.setFocused(true);
            return;
          }
          onFocus?.(e);
        }}
        onKeyDown={e => {
          if (
            nestedFocusScope &&
            (e.key === 'ArrowUp' || e.key === 'ArrowDown')
          )
            e.stopPropagation();
          onKeyDown?.(e);
        }}
        $marginBottom={marginBottom}
        $horizontal={horizontal}
      >
        {[...state.collection].map(item => (
          <FocusScope key={item.key}>
            <ListItem
              item={item}
              state={state}
              nestedFocusScope={nestedFocusScope}
              onSelectItem={onSelectItem}
            />
          </FocusScope>
        ))}
        {/* Yeah so, for some reason, this prevents Tab from focusing on any of the grid's hidden buttons */}
        <span tabIndex={0} />
      </ResultsList>
    </ContainerDiv>
  );
}

export const ContainerDiv = styled.div<{ $selectorEnabled: boolean }>`
  padding: ${p =>
    p.$selectorEnabled ? p.theme.spacing(1) : p.theme.spacing(0)};
`;

// NOTE: This is copied from FileBrowser. TODO: Styles could be more generic
const ResultsList = styled.ul<{
  $marginBottom?: string;
  $horizontal?: boolean;
  $selectorEnabled: boolean;
}>`
  --min-column-width: 11rem; // 176px, magic number from history

  position: relative;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--min-column-width), 1fr)
  );
  grid-auto-flow: dense;

  ${p =>
    p.$horizontal &&
    `grid-auto-columns: var(--min-column-width);
    grid-auto-flow: column;
    overflow-x: auto;`}

  grid-gap: ${p =>
    p.$selectorEnabled ? p.theme.spacing(2.5) : p.theme.spacing(1.5)};

  margin-top: 0;
  padding-left: 0;
  margin-bottom: ${({ $marginBottom }) => $marginBottom || '0'};

  li {
    list-style: none;
    scroll-margin-top: var(--scroll-offset-top);
  }

  li:focus-within {
    outline: none;
  }
  li.focus-visible {
    box-shadow: 0 0 0 3px ${p => p.theme.palette.secondary.main};
  }

  @media print {
    margin-bottom: 0;
  }
`;

interface GridListItemProps<D extends GenericData> {
  item: any; // Node<ListItemProps<D>>;
  state: ListState<ListItemProps<D>>;
  nestedFocusScope?: boolean;
  onSelectItem?: (index: number, eventTargetIndex: number) => void;
}
function ListItem<D extends GenericData>({
  item,
  state,
  nestedFocusScope,
  onSelectItem,
}: GridListItemProps<D>) {
  const ref = React.useRef<HTMLLIElement>(null);
  const {
    rowProps: {
      onKeyDownCapture,
      // We'll handle the mouse and pointer events on our own
      onClick,
      onMouseDown,
      onMouseUp,
      onPointerDown,
      onPointerUp,
      ...rowProps
    },
    gridCellProps,
    isPressed,
  } = useGridListItem({ node: item, shouldSelectOnPressUp: true }, state, ref);

  const { isFocusVisible, focusProps } = useFocusRing();
  const { isFocusVisible: isFocusWithin, focusProps: focusWithinProps } =
    useFocusRing({
      within: true,
    });

  useEffect(() => {
    if (isFocusVisible) {
      ref.current?.scrollIntoView({ block: 'nearest' });
    }
  }, [isFocusVisible]);

  const { checkboxProps } = useGridListSelectionCheckbox(
    { key: item.key },
    state
  );

  const focusManager = useFocusManager();
  const onKeyDown = (e: React.KeyboardEvent) => {
    if (!e.currentTarget.contains(e.target as Element)) {
      return;
    }
    switch (e.key) {
      case 'Tab':
        if (
          (e.shiftKey
            ? focusManager?.focusPrevious
            : focusManager?.focusNext)?.({
            wrap: false,
            tabbable: true,
          })
        ) {
          e.stopPropagation();
          e.preventDefault();
        } else {
          if (e.eventPhase === 1) onKeyDownCapture?.(e as any);
        }
        break;
      case 'ArrowRight':
        // default is prevented but propagation is not stopped
        // => nested focus scope present but no next node found
        if (e.isDefaultPrevented() && e.eventPhase === 3) {
          focusManager?.focusFirst();
          e.stopPropagation();
        } else if (
          focusManager?.focusNext({
            wrap: !nestedFocusScope,
          })
        ) {
          e.stopPropagation();
          e.preventDefault();
        } else {
          e.preventDefault();
        }
        break;
      case 'ArrowLeft':
        // default is prevented but propagation is not stopped
        // => nested focus scope present but no previous node found
        if (e.isDefaultPrevented() && e.eventPhase === 3) {
          focusManager?.focusPrevious({
            from: e.currentTarget ?? undefined,
          });
          e.stopPropagation();
        } else if (
          focusManager?.focusPrevious({
            wrap: !nestedFocusScope,
          })
        ) {
          e.stopPropagation();
          e.preventDefault();
        } else {
          e.preventDefault();
        }
        break;
      case 'Escape':
        if (item.value.selectedItem) {
          onSelectItem?.(-1, item.value.index);
          e.stopPropagation();
        } else {
          if (e.eventPhase === 1) onKeyDownCapture?.(e as any);
        }
        break;
      default:
        if (e.eventPhase === 1) onKeyDownCapture?.(e as any);
        break;
    }
  };

  // Return focus to the list item if the selected item is removed and focus is somewhere there
  useEffect(() => {
    if (!item.value.selectedItem && isFocusWithin) {
      focusManager?.focusFirst();
    }
  }, [Boolean(item.value.selectedItem)]);

  return (
    <>
      <li
        {...mergeProps(item.value.inlinePreviewProps, rowProps, focusProps)}
        ref={ref}
        className={`${isPressed ? 'pressed' : ''} ${
          isFocusVisible ? 'focus-visible' : ''
        }`}
        onKeyDownCapture={onKeyDown}
      >
        <div
          {...gridCellProps}
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.stopPropagation();
            }
          }}
        >
          {React.cloneElement(item.rendered, {
            checkboxProps,
            wrapperProps:
              isFocusVisible || (isFocusWithin && item.value.selectedItem)
                ? { className: 'focus-visible' }
                : undefined,
          })}
        </div>
      </li>
      {item.value.selectedItem && (
        <span
          style={{ gridColumn: '1 / -1' }}
          onKeyDown={onKeyDown}
          {...focusWithinProps}
          onFocus={e => {
            // If the focus jumps here,
            // we want to keep the focus within and not focus the grid
            e.stopPropagation();
            focusWithinProps.onFocus?.(e);
          }}
        >
          {item.value.selectedItem}
        </span>
      )}
    </>
  );
}
