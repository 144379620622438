import React from 'react';
import { t } from '@lingui/macro';
import { MetaData, DateRangeField } from '../content.types';
import PopupMetaField from './PopupMetaField';
import FormDateRange from '~common/inputs/FormDateRange';
import { getDateString } from '~common/utils/date.utils';
import { getLocalizedValue } from '~common/app.utils';

interface Props {
  field: DateRangeField;
  isLangSupportField: boolean;
  metaData?: Record<string, MetaData>;
  lang: string;
  isDefaultLang: boolean;
  onChange: (value: string, fieldKey?: string) => void;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  labelText?: string;
  asPopup?: boolean;
}
const ValidityPeriod = ({
  field,
  isLangSupportField,
  metaData,
  lang,
  isDefaultLang,
  onChange,
  disabled,
  error,
  helperText,
  asPopup,
  ...rest
}: Props) => {
  const validityFieldId = field.id;
  const label = getLocalizedValue<string>(field.namesByLang, lang);
  const fromDateFieldId = (field as DateRangeField).fromDateFieldId;
  const toDateFieldId = (field as DateRangeField).toDateFieldId;
  const selected =
    Boolean(validityFieldId) && metaData?.[validityFieldId] === 'true';
  const fromDate =
    typeof metaData?.[fromDateFieldId] === 'string'
      ? new Date(metaData[fromDateFieldId] as string)
      : null;
  const toDate =
    typeof metaData?.[toDateFieldId] === 'string'
      ? new Date(metaData[toDateFieldId] as string)
      : null;

  return asPopup ? (
    <PopupMetaField
      value={getStringValue(selected, fromDate, toDate)}
      error={error}
      helperText={helperText}
      labelText={label}
      {...rest}
    >
      <FormDateRange
        field={field}
        metaData={metaData}
        lang={lang ?? ''}
        onChange={onChange}
        disabled={disabled}
        error={error}
        helperText={error ? helperText : undefined}
        showSwitch={true}
        switchLabel={t`Limited`}
        variant="two-row"
      />
    </PopupMetaField>
  ) : (
    <FormDateRange
      field={field}
      metaData={metaData}
      lang={lang}
      label={label}
      readOnly={!isLangSupportField && !isDefaultLang}
      valueToString={getStringValue}
      onChange={onChange}
      disabled={disabled}
      error={error}
      helperText={error ? helperText : undefined}
      showSwitch={!!validityFieldId}
      switchLabel={t`Limited`}
    />
  );
};

function getStringValue(
  selected: boolean,
  fromDate: Date | null,
  toDate: Date | null
) {
  return `${selected ? t`Limited` : `Unlimited`} ${
    (fromDate || toDate) && selected
      ? `${fromDate ? getDateString(fromDate) : ''} - ${
          toDate ? getDateString(toDate) : ''
        }`
      : ''
  }`;
}

export default ValidityPeriod;
