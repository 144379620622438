import React from 'react';
import styled from 'styled-components';

interface Props {
  children?: React.ReactNode;
  id: string;
  tabId: string;
  open: boolean;
}

const TabPanel = ({ id, tabId, open, children, ...rest }: Props) => {
  return (
    <Wrapper
      role="tabpanel"
      hidden={!open}
      id={id}
      aria-labelledby={tabId}
      {...rest}
    >
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
`;

export default TabPanel;
