import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import TreeView from '@mui/lab/TreeView';
import { useTheme } from 'styled-components';

import { DefaultTheme } from '@mui/styles';
import CategoryTreeItem from './CategoryTreeItem';
import { File, Fileish, FolderTreeEntry } from '~common/content.types';
import {
  CollapsedFolderIconOutlined,
  ExpandedFolderIconOutlined,
} from '~common/misc/icons';
import { Lang } from '~common/utils/i18n';
import { useGetFilesQuery, useGetFolderTreeQuery } from '~common/content.api';

interface Props {
  data: FolderTreeEntry;
  selectedNodes: string[];
  setSelectedNodes: (nodes: string[]) => void;
  disabledNodes?: string[];
  readOnly?: boolean;
  multiple?: boolean;
  language?: Lang;
}

export const FolderSearchTree = ({
  data,
  disabledNodes,
  selectedNodes,
  setSelectedNodes,
  readOnly,
  language: initialLanguage,
  multiple = true,
}: Props) => {
  const userLanguage =
    useSelector(state => state.app.settings?.language) ?? 'fi';
  const language = initialLanguage ?? userLanguage;
  const tree = data as FolderTreeEntry;

  const [expanded, setExpanded] = useState(new Set(['']));

  const expandedArr = useMemo(() => [...expanded], [expanded]);

  const { data: files, isSuccess } = useGetFilesQuery({
    ids: selectedNodes,
    include: 'path',
  });

  useEffect(() => {
    if (isSuccess && files) {
      const filesArray = Object.values(files);

      const isFileWithNode = (file: Fileish): file is File => {
        return (
          'node' in file &&
          file.node !== null &&
          Array.isArray(file.node.parents)
        );
      };

      const newParentIds = filesArray
        .filter(isFileWithNode)
        .reduce((acc, file) => {
          file.node.parents?.forEach(parent => acc.add(parent.id));
          return acc;
        }, new Set<string>());

      setExpanded(expanded => new Set([...expanded, ...newParentIds]));
    }
  }, [files, isSuccess]);

  const theme = useTheme();

  return (
    <TreeView
      defaultExpandIcon={<CollapsedFolderIconOutlined />}
      defaultCollapseIcon={<ExpandedFolderIconOutlined />}
      onNodeToggle={(e, nodes) => setExpanded(new Set(nodes))}
      expanded={expandedArr}
    >
      {
        <RenderTreeItem
          node={tree}
          parents={[]}
          expanded={expanded}
          setExpanded={setExpanded}
          language={language}
          selectedNodes={selectedNodes}
          multiple={multiple}
          setSelectedNodes={setSelectedNodes}
          disabledNodes={disabledNodes}
          theme={theme}
          readOnly={readOnly}
        />
      }
    </TreeView>
  );
};

const RenderTreeItem = ({
  node,
  parents = [],
  expanded,
  setExpanded,
  language,
  selectedNodes,
  multiple,
  setSelectedNodes,
  disabledNodes,
  theme,
  readOnly,
}: {
  node: FolderTreeEntry;
  parents: FolderTreeEntry[];
  expanded: Set<string>;
  setExpanded: React.Dispatch<React.SetStateAction<Set<string>>>;
  language: string;
  selectedNodes: string[];
  multiple: boolean;
  setSelectedNodes: (nodes: string[]) => void;
  disabledNodes?: string[];
  theme: DefaultTheme;
  readOnly?: boolean;
}) => {
  const isExpanded = expanded.has(node.node.id);

  const { data: newData } = useGetFolderTreeQuery(
    {
      rootId: node.node.id,
      language,
      type: 'folder',
      depth: 2,
    },
    {
      skip: !isExpanded,
    }
  );

  if (newData) {
    node = newData as FolderTreeEntry;
    parents = [node, ...parents];
  }

  const getChildren = (node: FolderTreeEntry): FolderTreeEntry[] => {
    const arr: FolderTreeEntry[] = [...(node.children as FolderTreeEntry[])];
    for (const child of node.children) {
      const children = getChildren(child);
      arr.push(...children);
    }
    return arr;
  };

  const selectedChildren = getChildren(node).filter(child =>
    selectedNodes.includes(child.node.id)
  );

  return (
    <CategoryTreeItem
      node={node}
      parents={[]}
      setExpanded={setExpanded}
      language={language}
      selectedNodes={selectedNodes}
      multiple={multiple}
      setSelectedNodes={setSelectedNodes}
      disabledNodes={disabledNodes}
      theme={theme}
      readOnly={readOnly}
      baseFile={node.node}
      selectedChildren={selectedChildren}
      isExpanded={isExpanded}
    >
      {isExpanded &&
        node.children.map(child => {
          return (
            <RenderTreeItem
              key={child?.node?.id}
              node={child}
              parents={[...parents, node]}
              expanded={expanded}
              setExpanded={setExpanded}
              language={language}
              selectedNodes={selectedNodes}
              multiple={multiple}
              setSelectedNodes={setSelectedNodes}
              disabledNodes={disabledNodes}
              theme={theme}
              readOnly={readOnly}
            />
          );
        })}
    </CategoryTreeItem>
  );
};

export default FolderSearchTree;
