import React, { forwardRef } from 'react';
import styled from 'styled-components';
import MuiTextField, { TextFieldProps } from '@mui/material/TextField';

import FormLabel, { FormLabelProps } from './FormLabel';
import { getMuiVariant } from '~utils/form.utils';
import { LocalizedText } from '~common/app.utils';

type Props = Omit<TextFieldProps, 'helperText' | 'error'> & {
  errorMessage?: string;
};

export const TextField = React.forwardRef<HTMLInputElement, Props>(
  ({ errorMessage, ...props }, ref) => {
    return (
      <StyledTextField
        {...props}
        ref={ref}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        error={!!errorMessage}
        helperText={errorMessage}
      />
    );
  }
);

TextField.displayName = 'TextField';

const StyledTextField = styled(MuiTextField)`
  margin-bottom: 0px !important;

  & .MuiInputLabel-root {
    transform: scale(1) translateY(-24px);
    transform: unset;
    position: unset;
  }

  & .MuiInputBase-root {
    background-color: ${p => p.theme.palette.common.white};
    padding-right: 4px;

    .MuiInputAdornment-root > button {
      margin-bottom: 0;
    }
  }

  & .MuiInputBase-multiline {
    padding: 0;
  }

  & .MuiOutlinedInput-input {
    padding: ${p => p.theme.spacing(1.5, 1)};
  }

  & .MuiOutlinedInput-notchedOutline > legend > span {
    display: none;
  }

  & .MuiFormHelperText-root {
    margin-left: 0px;
    margin-right: 0px;
  }

  & .MuiInputLabel-asterisk {
    color: ${p => p.theme.palette.error.main};
  }
`;

interface OldProps extends Omit<TextFieldProps, 'variant'> {
  readOnly?: boolean;
  disabled?: boolean;
  labelText?: string | LocalizedText;
  labelProps?: Partial<FormLabelProps>;
  variant?: 'form' | TextFieldProps['variant'];
}

/* eslint-disable react/display-name */
const OldTextField = forwardRef<HTMLDivElement, OldProps>(
  // don't pass required to MuiTextField
  ({ labelText, labelProps, required, ...props }, ref) => {
    return (
      <StyledOldTextField
        {...props}
        value={props.value === null ? '' : props.value}
        id={props.id}
        error={!!props.error}
        helperText={props.error && props.helperText}
        variant={getMuiVariant(props.variant) ?? 'standard'}
        InputProps={{
          readOnly: props.readOnly,
          ...(props.InputProps || {}),
        }}
        inputProps={{
          'aria-required': required,
        }}
        InputLabelProps={{
          shrink: !!labelText && false,
          style: {
            top: labelText && '-2.5rem',
          },
        }}
        disabled={props.disabled}
        ref={ref}
        label={
          props.label ||
          (labelText && (
            <FormLabel
              label={labelText}
              disabled={props.disabled}
              required={required}
              {...labelProps}
            />
          ))
        }
      >
        {props.children}
      </StyledOldTextField>
    );
  }
);

const StyledOldTextField = styled(MuiTextField)`
  input {
    ${p => p.onClick && p.InputProps?.readOnly && 'cursor: pointer;'}
  }
`;

export default OldTextField;
