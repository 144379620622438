import { useMemo, useEffect, useState } from 'react';
import { t } from '@lingui/macro';

import {
  RightsTreeNode,
  UpdateGroupRightsParams,
  OverwriteGroupRightsParams,
  SimpleObjectRights,
} from './types';
import { ObjectType, UserGroup } from '../types';

import {
  FileType,
  GroupRightsById,
  RightsTreeResponseNode,
  ObjectRights,
  ObjectRightKey,
} from '~common/content.types';
import { DeepPartial } from '~common/utils/types.utils';
import { filterUndefined } from '~common/utils/fn.utils';

export const rightIds = Object.values(ObjectRightKey).filter(
  key => !isNaN(Number(key))
) as ObjectRightKey[];

export const folderRightIds = Object.values(ObjectRightKey)
  .filter(key => !isNaN(Number(key)))
  .filter(key => key <= ObjectRightKey.REMOVE_CONTENT) as ObjectRightKey[];

/**
 * Returns a translated name based on a right id
 */
export function getRightName(rightId: ObjectRightKey) {
  switch (+rightId) {
    case ObjectRightKey.READ:
      return t`Read`;
    case ObjectRightKey.EDIT_OBJECT:
      return t`Edit object`;
    case ObjectRightKey.EDIT_RIGHTS:
      return t`Edit rights`;
    case ObjectRightKey.REMOVE_OBJECT:
      return t`Remove object`;
    case ObjectRightKey.ADD_CONTENT:
      return t`Add content`;
    case ObjectRightKey.EDIT_CONTENT:
      return t`Edit content`;
    case ObjectRightKey.REMOVE_CONTENT:
      return t`Remove content`;
    case ObjectRightKey.MAKE_ORDER:
      return t`Make order`;
    case ObjectRightKey.MAKE_OFFER_REQUEST:
      return t`Make offer request`;
    case ObjectRightKey.MAKE_COMMENT_REQUEST:
      return t`Make comment request`;
  }
}

export function getObjectTypeFromFileType(fileType: FileType): ObjectType {
  const objectTypes = {
    'nt:folder': 1,
    'nt:linkedFolder': 1,
    'nt:cart': 2,
  };

  if (fileType in objectTypes) {
    return objectTypes[fileType];
  } else {
    throw new Error('No object type specified');
  }
}

/**
 * Generates a new empty usergroup row, where all checkboxes are unselected,
 * and if the group being generated has some right columns that are completely
 * disallowed for any of the currently selected objects' types, those checkboxes
 * get marked as disabled.
 */
export function createNewUserGroupRow(
  group: UserGroup,
  objectTypes: ObjectType[],
  rightIds: ObjectRightKey[]
) {
  return {
    id: group.id,
    name: group.name,
    rights: rightIds.reduce(
      (acc, rightId) => ({
        ...acc,
        [rightId]: {
          id: rightId,
          selected: false,
          partiallySelected: false,
          disabled: objectTypes.some(
            objectType =>
              group.disabledByObjectType &&
              group.disabledByObjectType[objectType].disabled.includes(rightId)
          ),
        },
      }),
      {} as ObjectRights
    ),
  };
}

/**
 * Strips a rights object from all unnecessary fields so that it can be used
 * as a HTTP request payload
 */
function generateRightsPayload(
  rights: ObjectRights,
  rightIds: ObjectRightKey[]
) {
  return rightIds.reduce(
    (accumulatedRights, currentRightId) => ({
      ...accumulatedRights,
      [currentRightId]: rights[currentRightId].selected,
    }),
    {} as SimpleObjectRights
  );
}

/**
 * Generates a payload of only id's and `selected` states of checkboxes
 * by picking out chosen groupIds from all groups
 */
export function generateRightsChangePayload(
  groupIds: string[],
  groups: GroupRightsById,
  rightIds: ObjectRightKey[]
) {
  return groupIds.reduce(
    (accumulatedGroupRights, currentGroupId) => ({
      ...accumulatedGroupRights,
      [Number(currentGroupId)]: {
        groupId: currentGroupId,
        rights: generateRightsPayload(groups[currentGroupId].rights, rightIds),
      },
    }),
    {} as OverwriteGroupRightsParams['data']['groupRights']
  );
}

/**
 * Generates an array of changes to be pushed to server when updating rights
 * in a tree
 */
export function generateTreeChangePayload(
  groupIds: string[],
  changes: DeepPartial<GroupRightsById>,
  currentTree: RightsTreeNode[]
): UpdateGroupRightsParams['data'] {
  return Object.keys(changes).reduce((payload, objectId) => {
    const currentNode = findInTree(currentTree, objectId) as RightsTreeNode;
    const mergedRights = {
      ...currentNode.rights,
      ...changes[objectId]?.rights,
    } as RightsTreeNode['rights'];

    return {
      ...payload,
      [objectId]: groupIds.reduce(
        (acc, groupId) => ({
          ...acc,
          [groupId]: {
            groupId: Number(groupId),
            rights: generateRightsPayload(mergedRights, folderRightIds),
          },
        }),
        {} as GroupRightsById
      ),
    };
  }, {});
}

/**
 * Parse tree returned from server by removing unnecessary nesting and combining
 * data from different user groups into one object so that it can be rendered
 * in one table
 */
export function rightsTreeNodesIn(groupIds: string[]) {
  function recurse(
    node: RightsTreeResponseNode,
    childIndex: number
  ): RightsTreeNode {
    // Generate the node's rights by combining separate rights objects
    // from all the different user groups whose data is being displayed
    const rights = folderRightIds.reduce((acc, rightId) => {
      const id = rightId;
      const disabled = groupIds.some(
        groupId => node.node.rights?.[groupId]?.rights?.[rightId]?.disabled
      );
      const selected = groupIds.every(
        groupId => node.node.rights?.[groupId]?.rights?.[rightId]?.selected
      );

      // There is at least one group with the right disabled and one with
      // the right enabled, or at least one group with any partiallySelected
      const partiallySelected =
        (groupIds.some(
          groupId => node.node.rights?.[groupId]?.rights?.[rightId]?.selected
        ) &&
          groupIds.some(
            groupId => !node.node.rights?.[groupId]?.rights?.[rightId]?.selected
          )) ||
        groupIds.some(
          groupId =>
            node.node.rights?.[groupId]?.rights?.[rightId]?.partiallySelected
        );

      return {
        ...acc,
        [rightId]: { id, disabled, selected, partiallySelected },
      };
    }, {} as RightsTreeNode['rights']);

    return {
      ...node.node,
      positionInChildren: childIndex,
      rights,
      userRights: node.node.userRights ?? [],
      childCount: node.childCount,
      children: node.children?.map(recurse) ?? [],
    };
  }

  return (data: RightsTreeResponseNode, childIndex: number) =>
    recurse(data, childIndex);
}

/** Returns the amount of nodes in a tree */
export function countTreeNodes<T extends { children: (T | undefined)[] }>(
  rootNodes: T[]
): number {
  return (
    rootNodes.length +
    rootNodes.reduce(
      (sum, curr) =>
        sum + countTreeNodes(curr.children.filter(filterUndefined)),
      0
    )
  );
}

/**
 * Finds a node in a tree with a matching value, by default the field `id`
 */
export function findInTree<
  T extends { children: T[] },
  V extends T[K],
  K extends keyof T
>(treeRoots: T[] | T, valueToFind: V, key?: K): T | null;
export function findInTree<T extends { children: T[] }, V extends unknown>(
  treeRoots: T[] | T,
  valueToFind: V,
  valueGetter: (node: T) => V
): T | null;
export function findInTree<
  T extends { children: T[] },
  V extends T[K],
  K extends keyof T
>(
  treeRoots: T[] | T,
  valueToFind: V,
  keyOrGetter: K | ((node: T) => V) | undefined = undefined
): T | null {
  let stack: T[];
  if (Array.isArray(treeRoots)) stack = [...treeRoots];
  else stack = [treeRoots];

  while (stack.length) {
    const node = stack.pop();
    if (typeof keyOrGetter === 'function') {
      const getter = keyOrGetter;
      if (node && getter(node) === valueToFind) return node;
    } else {
      const key = keyOrGetter ?? ('id' as K);
      if (node && node[key] === valueToFind) return node;
    }
    if (node && node.children.length > 0) stack.push(...node.children);
  }

  return null;
}

type Node = { id: string; children: Node[]; childCount: number };
/**
 * Generates a tree representation of independent node objects, nesting them
 * appropriately as indicated by their `children` fields.
 */
export function buildTree<T extends Node>(
  rootNodes: T[],
  nodesById: Record<string, T>
): T[] {
  return rootNodes.map(node => ({
    ...node,
    children: node.children.flatMap(child => {
      if (child.childCount > 0 && nodesById[child.id]) {
        return buildTree([nodesById[child.id]], nodesById);
      } else {
        return child;
      }
    }),
  }));
}

/**
 * Converts a list of right keys into a `SimpleObjectRights`-object,
 * where each key provided in list is marked as allowed and each key
 * not included is marked as not allowed.
 */
export function simpleRightsFromKeys(rightKeys: ObjectRightKey[]) {
  return rightIds.reduce(
    (acc, key) => ({
      ...acc,
      [key]: rightKeys.includes(key),
    }),
    {} as SimpleObjectRights
  );
}

/** Calculates the depth of the most indented open node.
 * Needs to be told the opened and closed nodes. */
export function useMaxDepth(tree: Node[]) {
  const parentsById = useMemo(() => {
    const data: Record<string, string[]> = {};
    const recursion = (node: Node, parentIds: string[]) => {
      data[node.id] = parentIds;
      node.children.forEach(child => recursion(child, [...parentIds, node.id]));
    };
    tree.forEach(node => recursion(node, []));
    return data;
  }, [tree]);

  const [openedDepthsById, setOpenedDepthsById] = useState<
    Record<string, number | undefined>
  >(tree.reduce((data, node) => ({ ...data, [node.id]: 0 }), {}));
  useEffect(() => {
    setOpenedDepthsById(depths => ({
      ...depths,
      ...tree.reduce((data, node) => ({ ...data, [node.id]: 0 }), {}),
    }));
  }, [tree]);

  const maxDepth = Math.max(
    0,
    ...Object.entries(openedDepthsById)
      .filter(
        ([id, depth]) =>
          depth !== undefined &&
          parentsById[id]?.every(
            parent => openedDepthsById[parent] !== undefined
          )
      )
      .map((_, depth) => depth)
  );

  return [maxDepth, setOpenedDepthsById] as const;
}
