import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro';

import { useLingui } from '@lingui/react';
import ContentDockTabs from './content/DockTabs';
import ProductPages from './content/products/pages';
import CommentPages from './comments/edit/pages';

import Dock from '~sections/Dock';
import ErrorBoundary from '~utils/ErrorBoundary';

import {
  MinimizedDockTabs,
  MinimizedProductPages,
  MinimizedCommentPages,
} from '~sections/MinimizedDockContent';
import { app } from '~common/app.model';
import { useActions } from '~common/utils/hooks.utils';

const contentsById = {
  '/contentTabs': {
    label: t`Content(aineistot)`,
    element: ContentDockTabs,
    minimized: MinimizedDockTabs,
    as: 'nav',
  },
  '/productPages': {
    label: t`Pages`,
    element: ProductPages,
    minimized: MinimizedProductPages,
  },
  '/commentPages': {
    label: t`Pages`,
    element: CommentPages,
    minimized: MinimizedCommentPages,
  },
};

function LeftDock() {
  const { open, content, activeTab } = useSelector(state => state.app.leftDock);
  const share = useSelector(state => state.app.share);
  const isGuestCommenter = share?.type === 'o';

  const user = useSelector(state => state.app.user);
  const visible = useSelector(
    state => !!state.app.leftDock.visible && (!!user || isGuestCommenter)
  );
  const toggleLeft = useActions(app.actions.toggleLeft);

  const contentObj = content && contentsById[content];

  const Content = contentObj?.element ?? 'div';
  const MinimizedContent = contentObj?.minimized ?? 'div';
  const variant = content === '/contentTabs' ? 'adaptive' : 'narrow';

  const { i18n } = useLingui();

  return (
    <Dock
      as={contentObj && 'as' in contentObj && contentObj.as}
      aria-label={contentObj?.label}
      visible={visible && open && !!content}
      minimized={visible && !open && !!content}
      minimizedContent={<MinimizedContent openDock={toggleLeft} />}
      variant={variant}
      key={i18n.locale} // remount to update translated values
    >
      <ToggleHover onClick={() => toggleLeft({ open: false, content })}>
        <Toggle />
      </ToggleHover>
      <ErrorBoundary>
        {content ? (
          <Content activeTab={activeTab ?? undefined} />
        ) : (
          <div id="leftDock-root" />
        )}
      </ErrorBoundary>
    </Dock>
  );
}

export const Toggle = styled.div`
  position: absolute;
  width: 3px;
  min-width: 0;
  height: 100%;
  border-radius: 3px;
  left: 50%;
  top: 0;
  background-color: ${({ theme }) => theme.palette.primary.main};
  transition: all 0.2s ease-out;
`;

export const Resize = styled.div`
  position: absolute;
  height: 20px;
  width: 3px;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) scale(0);
  transform-origin: center center;
  transition: all 0.2s ease-out;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    border-radius: 3px;
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
  &::before {
    left: 3px;
  }
  &::after {
    right: 5px;
  }
`;

export const ToggleHover = styled.div`
  position: absolute;
  width: 20px;
  min-width: 0;
  height: 40px;
  left: 100%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.2s ease-out;
  cursor: pointer;
  z-index: 99;

  &:hover > ${Toggle} {
    transform: scaleY(1.3);
  }
  &:hover > ${Resize} {
    transform: translateY(-50%) translateX(-50%) scale(1.3);
  }
`;

export default LeftDock;
