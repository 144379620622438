import { t } from '@lingui/macro';
import { range } from 'lodash';
import React, { useMemo } from 'react';
import styled, { DefaultTheme } from 'styled-components';

type Variant = 'small' | 'big';

const ThumbnailSelector = ({
  imageUrls,
  selected,
  onChange,
  onHover,
  variant = 'small',
}: {
  imageUrls: string[];
  selected: number;
  onChange: (index: number) => void;
  onHover: (index: number | undefined) => void;
  variant?: Variant;
}) => {
  // hehe
  const ballRefs = useMemo(
    () =>
      range(imageUrls.length).map(() => React.createRef<HTMLButtonElement>()),
    [imageUrls.length]
  );

  const focusBall = (index: number) => {
    ballRefs[index].current?.focus();
  };

  const onMouseEnter = (index: number) => {
    onHover(index);
  };

  const onFocus = (index: number) => {
    if (variant === 'small') {
      onChange(index);
      const ref = ballRefs[index];

      ref.current?.focus();
      ref.current?.blur();
    }
  };

  const onClick = (index: number) => {
    if (variant === 'big') {
      onChange(index);
    }
  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    const el = e.target;

    if (el instanceof HTMLElement) {
      el.focus();
    }

    if (e.key === 'ArrowRight') {
      const nextIndex = (selected + 1) % imageUrls.length;
      onChange(nextIndex);
      focusBall(nextIndex);
      return true;
    }
    if (e.key === 'ArrowLeft') {
      const nextIndex = selected <= 0 ? imageUrls.length - 1 : selected - 1;
      onChange(nextIndex);
      focusBall(nextIndex);
      return true;
    }
  };

  // always show at least 3 balls
  const disabledBallCount = Math.max(3 - imageUrls.length, 0);

  return (
    <>
      <SelectorWrapper
        variant={variant}
        size={variant === 'small' ? 8 : 16}
        onMouseLeave={() => onHover(undefined)}
      >
        {imageUrls.map((url, i) => (
          <SelectorBall
            variant={variant}
            tabIndex={selected === i && variant === 'big' ? 0 : -1}
            onMouseEnter={() => onMouseEnter(i)}
            onClick={() => onClick(i)}
            onFocus={() => onFocus(i)}
            key={`thumbnail-${i}`}
            ref={ballRefs[i]}
            selected={selected === i}
            aria-label={t`Preview ${i + 1}`}
            onKeyUp={e => {
              const catched = onKeyUp(e);

              if (!catched) {
                return;
              }

              e.stopPropagation();
              e.preventDefault();
            }}
          />
        ))}

        {range(disabledBallCount).map((v, i) => (
          <SelectorBall
            selected={i === 0 && imageUrls.length === 0}
            variant={variant}
            key={`thumbnail-${i + imageUrls.length}`}
            disabled
            aria-label={t`Preview ${i + 1}`}
          />
        ))}
      </SelectorWrapper>
    </>
  );
};

const SelectorWrapper = styled.div<{ size: number; variant: Variant }>`
  width: 100%;
  justify-content: center;
  display: flex;
  bottom: 0;

  ${props =>
    props.variant === 'big'
      ? `
  padding: ${props.theme.spacing(2)};

  :focus-within {
    outline: 2px solid black;
  }`
      : `
  padding-top: 4px;`}

  && button {
    width: ${props => `${props.size}px`} !important;
    height: ${props => `${props.size}px`} !important;
  }
`;

function getBackgroundStyle(
  theme: DefaultTheme,
  selected?: boolean,
  disabled?: boolean
) {
  const hoverStyle = `
    :focus,
    :hover {
      outline: none;
      background-color: ${props => props.theme.palette.primary.main};
    }
  `;

  if (selected) {
    return `
      ${hoverStyle}
      background-color: ${theme.palette.primary.main};`;
  }

  if (disabled) {
    return `background-color: ${theme.palette.grey[300]};`;
  }

  return `
    ${hoverStyle}
    background-color: ${theme.palette.primary.main};
    opacity: 0.4;`;
}

const SelectorBall = styled.button<{
  selected?: boolean;
  disabled?: boolean;
  variant: Variant;
}>`
  border: none;
  padding: 0;
  border-radius: 50%;
  border: none;
  cursor: ${props => (props.disabled ? 'initial' : 'pointer')};
  margin: 0 2px;

  transition: background-color 0.2s;

  ${props => getBackgroundStyle(props.theme, props.selected, props.disabled)}
`;

export default ThumbnailSelector;
