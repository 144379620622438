import React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { PageSelector } from './PageSelector';
import Typography from '~misc/Typography';
import TabContent from '~sections/TabContent';
import { getLangValue, getContentLanguage } from '~common/app.utils';
import { sanitizeHtml } from '~common/utils/misc.utils';

const ProductPages = () => {
  const product = useSelector(state => state.products.product);
  // TODO: selectedPage selector

  return (
    <>
      <TabContent>
        {product && (
          <ProductInfoWrapper>
            <Typography variant="h1">
              <Trans>Customize</Trans>: {product.name}
            </Typography>
            <DescriptionText
              lang={getContentLanguage(product.description)}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(getLangValue(product.description)),
              }}
            ></DescriptionText>
          </ProductInfoWrapper>
        )}
        <PageSelector />
      </TabContent>
    </>
  );
};

const ProductInfoWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(2)};
  padding-bottom: 0;
`;

const DescriptionText = styled(Typography)`
  white-space: pre-line;
`;

export default ProductPages;
