import React from 'react';
import { TimePicker as Picker } from '@mui/x-date-pickers/TimePicker';

import { IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import { t } from '@lingui/macro';

import FormLabel, { FormLabelProps } from './FormLabel';
import { CloseIcon } from '~misc/icons';
import { LocalizedText, determineIfObjectOrString } from '~common/app.utils';

interface Props
  extends Omit<React.ComponentProps<typeof Picker>, 'renderInput'> {
  id: string;
  onClear: any;
  labelText?: string | LocalizedText;
  ariaLabel?: string;
  required?: boolean;
  labelProps?: Partial<FormLabelProps>;
  error?: boolean;
  helperText?: string;
  inputProps?: Record<string, unknown>;
}

const StyledButt = withStyles({
  root: {
    padding: '6px 6px 7px',
  },
})(IconButton);

const TimePicker = ({
  id,
  value,
  onChange,
  onClear,
  labelText,
  error,
  helperText,
  required,
  ariaLabel,
  labelProps,
  ...pickerprops
}: Props) => {
  return (
    <Picker
      {...pickerprops}
      ampm={false}
      inputFormat="HH:mm"
      label={
        labelText && (
          <FormLabel
            label={
              determineIfObjectOrString(labelText) ? labelText.value : labelText
            }
            disabled={pickerprops.disabled}
            required={required}
            {...labelProps}
          />
        )
      }
      value={value}
      onChange={onChange}
      renderInput={params => (
        <TextField
          helperText={!!error && helperText}
          error={!!error}
          inputMode="numeric"
          variant="standard"
          aria-label={!labelText ? ariaLabel || t`Select time` : undefined}
          aria-required={required}
          className="time-picker"
          {...(pickerprops.inputProps || {})}
          {...params}
        />
      )}
      InputProps={{
        endAdornment: (
          <StyledButt
            aria-label={t`Clear`}
            onClick={e => {
              e.stopPropagation();
              onClear();
            }}
          >
            <CloseIcon fontSize="small" />
          </StyledButt>
        ),
        'aria-label': ariaLabel || t`Select time`,
      }}
      InputAdornmentProps={{
        position: 'start',
        style: { marginRight: '-2px' },
      }}
    />
  );
};

export default TimePicker;
