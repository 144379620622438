import { throttle } from 'lodash';
import {
  useEffect,
  useRef,
  useState,
  useMemo,
  DependencyList,
  MutableRefObject,
  useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  ActionCreator,
  ActionCreatorsMapObject,
  bindActionCreators,
} from 'redux';
import {
  BatchedExecutionCallbacks,
  executeBatchedOperations,
} from './io.utils';
import { File } from '~common/content.types';

// Hook for listening keyboard events
export const useKeyboardEvent = (key: any, callback: any) => {
  useEffect(() => {
    const handler = function (event) {
      if (event.key === key) {
        callback();
      }
    };
    window.addEventListener('keydown', handler);
    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, []);
};

/** Listen for chosen key presses and return an appropriate boolean */
export function useIsKeyPressed(targetKey: string): boolean {
  const [keyPressed, setKeyPressed] = useState(false);

  // Set pressed state when events for the chosen key fire
  function downHandler(e: KeyboardEvent): void {
    if (e.key === targetKey) {
      setKeyPressed(true);
    }
  }
  const upHandler = (e: KeyboardEvent): void => {
    if (e.key === targetKey) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    // Remove event listeners when hook unmounts
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  return keyPressed;
}

// Hook for using prev props
export const usePrevious = <T>(
  value: T,
  shouldUpdate?: (value: T) => boolean
) => {
  const ref = useRef<T>();
  // Store current value in ref
  useEffect(() => {
    if (!shouldUpdate || shouldUpdate(value)) ref.current = value;
  }, [value]); // Only re-run if value changes

  return ref.current;
};

export function useTimer(
  interval: number,
  callback: () => void,
  deps: DependencyList = []
) {
  const [timer, setTimer] = useState();
  useEffect(() => {
    if (timer) clearTimeout(timer);
    const newTimer = setTimer(setInterval(callback, interval) as any);
    return () => clearTimeout(newTimer as any);
  }, deps);
}

export function useAnimationFrame(
  callback: () => void,
  deps: DependencyList = []
) {
  const handleRef = useRef<number>();

  useEffect(() => {
    if (handleRef.current) cancelAnimationFrame(handleRef.current);
    const tick = () => {
      callback();
      handleRef.current = requestAnimationFrame(tick);
    };
    handleRef.current = requestAnimationFrame(tick);
    return () => {
      if (handleRef.current) cancelAnimationFrame(handleRef.current);
    };
  }, deps);
}

export function useMousePos(axis: 'x' | 'y') {
  const [pos, setPos] = useState(0);

  function onMouseMove(event: MouseEvent) {
    const pos = event[`client${axis.toUpperCase()}`];
    setPos(pos);
  }

  useEffect(() => {
    addEventListener('mousemove', onMouseMove);

    return () => {
      removeEventListener('mouseMove', onMouseMove);
    };
  }, []);

  return pos;
}

export function useSafeEffect(
  callback: () => void | (() => void),
  dependencyArray: DependencyList
) {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    return callbackRef.current();
  }, dependencyArray);
}

export const useSelection = <Selection extends Record<string, boolean> = {}>(
  checkedContent: Set<string> | string[],
  additions?: (items: (File | undefined)[]) => Selection
) => {
  // NOTE: Danger alert! This expects that the checked files have already been
  // read individually using readFile or such with the appropriate include parameters
  // REFACTOR: Get individual files using rtk-query so we can get rid of this whappajack
  const filesById = useSelector(state => state.commonContent.filesById);

  const items = useMemo(
    () => [...checkedContent].map(id => filesById[id]?.file),
    [checkedContent, filesById]
  );

  const selection = {
    hasFolders: items.some((f: File) => f?.isFolder),
    onlyConcreteFolders: items.every(
      (f: File) => f?.isFolder && f?.node.id === f?.concrete?.id
    ),
    hasProducts: items.some(
      (f: File) => f?.isMasterProduct || f?.isUserProduct
    ),
    ...(additions ? additions(items) : {}),
  };

  return { selection: selection as typeof selection & Selection, items };
};

export type ScrollCallback<T extends HTMLElement> = (
  e: MouseEvent,
  root: T
) => void;

/**
 * Hook for listening for throttled scroll events on an element.
 */
export const useOnScroll = <T extends HTMLElement>(throttleInterval = 200) => {
  const rootRef = useRef<T>(null) as MutableRefObject<T | null>;
  const callbacks = useRef<ScrollCallback<T>[]>([]);

  useEffect(() => {
    const el = rootRef.current;
    if (!el) {
      return;
    }
    const onScroll = throttle(async (e: MouseEvent) => {
      if (!rootRef.current) {
        return;
      }
      for (const callback of callbacks.current) {
        callback(e, rootRef.current);
      }
    }, throttleInterval);

    el.addEventListener('scroll', onScroll);
    return () => el.removeEventListener('scroll', onScroll);
  }, [rootRef]);

  return {
    /**
     * Ref for registering the element on which to listen for scroll events
     */
    registerRoot: (el: T) => (rootRef.current = el),
    /**
     * Register a callback function to be called in the throttled onscroll -event
     */
    registerCallback: (callback: ScrollCallback<T>) =>
      callbacks.current.push(callback),
    /**
     * Hook for registering a scroll callback when the component mounts
     */
    useScrollEvent: (callback: ScrollCallback<T>) => {
      useEffect(() => {
        callbacks.current.push(callback);
      }, []);
    },
  };
};

/**
 * Hook for registering a callback on a raw dom event
 */
export const useEvent = <K extends keyof GlobalEventHandlersEventMap>(
  event: K,
  callback: (e: GlobalEventHandlersEventMap[K]) => void,
  root: Document | HTMLElement | Window = document
) => {
  useEffect(() => {
    const on = (e: GlobalEventHandlersEventMap[K]) => {
      callback(e);
    };

    root.addEventListener(event, on);
    return () => root.removeEventListener(event, on);
  }, []);
};

export const useIsMount = () => {
  const isMount = useRef(true);
  useEffect(() => {
    isMount.current = false;
  }, []);
  return isMount.current;
};

export const useIsUnmount = () => {
  const isUnmount = useRef(false);
  useEffect(
    () => () => {
      isUnmount.current = true;
    },
    []
  );
  return isUnmount;
};

/**
 * Map redux actions of a store to same functions wrapped with dispatch.
 * Can also wrap a single action instead of an object of actions, returning
 * the one action.
 *
 * ref: https://react-redux.js.org/api/hooks#recipe-useactions
 */
export function useActions<A, T extends ActionCreator<A>, K>(
  actions: T,
  deps?: K[]
): T;
export function useActions<T extends ActionCreatorsMapObject, K>(
  actions: T,
  deps?: K[]
): T;
export function useActions<T extends ActionCreatorsMapObject, K>(
  actions: T,
  deps?: K[]
) {
  const dispatch = useDispatch();
  return useMemo(
    () => bindActionCreators(actions, dispatch),
    deps ? [dispatch, ...deps] : [dispatch]
  );
}

/**
 * Stores a list of unique elements. Useful for storing numbers of
 * opened accordion IDs, for example.
 *
 * Returns the list of elements, a curried adder function and a curried
 * remover function
 */
export function useListState<T = number>() {
  const [elements, setElements] = useState<T[]>([]);

  const getAddElement = useCallback(
    (elem: T | T[]) => () =>
      setElements(elements => {
        if (Array.isArray(elem)) {
          if (elem.every(item => elements.includes(item))) return elements;
          return [
            ...elements,
            ...elem.filter(item => !elements.includes(item)),
          ];
        } else {
          return elements.includes(elem) ? elements : [...elements, elem];
        }
      }),
    [setElements]
  );

  const getRemoveElement = useCallback(
    (elem: T) => () =>
      setElements(elements => elements.filter(element => element !== elem)),
    [setElements]
  );

  const reset = useCallback(() => setElements([]), [setElements]);

  return [elements, getAddElement, getRemoveElement, reset] as const;
}

/**
 * Watches the intersection of element with given ref and window.
 * `options` are the same that can be given to `IntersectionObserver` directly,
 * and `freezeOnceVisible` stops the intersection observer after the element
 * becomes visible for the first time, which is useful with e.g. image loading
 */
export function useIntersectionObserver(
  elementRef: React.RefObject<Element>,
  options: IntersectionObserverInit = {
    threshold: 0,
    root: undefined,
    rootMargin: '0%',
  },
  freezeOnceVisible = true
): boolean {
  const { threshold, root: initialRoot, rootMargin } = options;
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  // Use the defined root if defined
  // Default to any data-intersection-root element that contains the observed element
  // (that is the modal) or to the document body
  const root =
    initialRoot ||
    (Array.from(document.querySelectorAll('[data-intersection-root]')).find(x =>
      x.contains(elementRef?.current)
    ) ??
      document.body);

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry);
  };

  useEffect(() => {
    const node = elementRef?.current; // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) return;

    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    return () => observer.disconnect();
  }, [elementRef, threshold, root, rootMargin, frozen]);

  return entry?.isIntersecting ?? false;
}

/** Executes async operations in a rolling window and provides status data
 *
 * If you don't need updates on progress, consider using directly `executeBatchedOperations` from `~utils/io.utils.ts`
 */
export function useBatchedOperations<T>(batchSize = 5) {
  const [isLoading, setLoading] = useState(false);
  const [isReady, setReady] = useState(false);
  const [results, setResults] = useState<(T | { error: any })[]>();
  const [progress, setProgress] = useState(0);

  const start = useCallback(
    (
      operations: (() => Promise<T>)[],
      callbacks?: BatchedExecutionCallbacks<T>
    ) => {
      setLoading(true);
      setReady(false);
      setResults(undefined);
      executeBatchedOperations<T>(
        { operations, windowSize: batchSize },
        {
          ...callbacks,
          onAllReady: results => {
            callbacks?.onAllReady?.(results);
            setLoading(false);
            setReady(true);
            setResults(results);
          },
          onReady: (res, index, ready) => {
            callbacks?.onReady?.(res, index, ready);
            setProgress((ready / operations.length) * 100);
          },
        }
      );
    },
    [batchSize]
  );

  return {
    start,
    isLoading,
    isReady,
    progress,
    results,
  };
}

/** Simple `useState`-based hook for toggling a boolean value */
export function useToggle(initial = false) {
  const [state, setState] = useState(initial);

  function toggle() {
    setState(current => !current);
  }

  return [state, toggle, setState] as const;
}
