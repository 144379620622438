import React from 'react';
import { t } from '@lingui/macro';

import { Fileish, File, fileExists } from '~common/content.types';
import { ModalOption } from '~common/navigation/MenuButton';
import { ShareIcon } from '~common/misc/icons';

export function useInternalShareOption(
  workspace: Fileish | undefined
): ModalOption {
  return {
    value: 'share-internal',
    hidden: !fileExists(workspace) || !workspace.isCart,
    disabled: !fileExists(workspace) || !workspace.userRights?.editRights,
    title: t`Share usage rights`,
    icon: <ShareIcon fontSize="small" />,
    modalType: 'WORKSPACE/INTERNAL_SHARE',
    modalProps: { workspaceId: (workspace as File)?.node.id },
  };
}
