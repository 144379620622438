import React, { useState, useEffect } from 'react';
import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { MenuItem } from '@mui/material';

import { skipToken } from '@reduxjs/toolkit/query/react';
import { isVideoFile, isCroppableFile } from './utils';
import FileDownloadNameModelSelector from './FileDownloadNameModelSelector';
import { useWindowSize } from '~common/utils/layout.utils';

import * as api from '~common/content.api';
import { readFile as apiReadFile } from '~common/content.api';
import MenuButton from '~navigation/MenuButton';
import { getLangValue } from '~common/app.utils';
import { commonContent } from '~common/content.model';
import { CropIcon, DownloadIcon } from '~misc/icons';
import { useActions } from '~common/utils/hooks.utils';
import { FileAttachment } from '~common/content.types';

interface Props {
  fileId?: string;
  folderId?: string;
  children?: any;
  isSearch?: boolean;
  onOpen?: () => void;
  onExit?: () => void;
}

const FileDownloadButton = React.forwardRef<
  HTMLButtonElement,
  Props & Partial<React.ComponentProps<typeof MenuButton>>
>(
  (
    {
      fileId,
      folderId,
      children,
      isSearch,
      onOpen,
      onExit,
      listItem,
      ...buttonProps
    },
    ref
  ) => {
    const filesById = useSelector(state => state.commonContent.filesById);
    const userRights = useSelector(state => state.app.userRights);
    const language = useSelector(state => state.app.settings?.language);
    const config = useSelector(state => state.app.customer?.configById);
    const password = useSelector(state => state.commonContent.password);

    const readFile = useActions(commonContent.actions.readFile);
    const [selectedNameModel, setSelectNameModel] = useState(0);

    const useNameModels = config && config['download.name.model.enabled'];
    const file = fileId ? filesById[fileId] && filesById[fileId]?.file : null;
    const folder = folderId
      ? filesById[folderId] && filesById[folderId]?.file
      : null;

    const windowSize = useWindowSize();

    const onClick = () => {
      if ((!file || !file.attachments || !file.userRights) && fileId)
        readFile(fileId);
    };

    const [attachments, setAttachments] = useState<FileAttachment[] | null>(
      null
    );

    useEffect(() => {
      if (file && file.attachments && (isSearch || folder)) {
        if (
          isSearch ||
          (folder &&
            !folder.removed &&
            file.node.parentId === folder.node.id &&
            folder.node.parents?.[0].fileType !== 'nt:cart')
        ) {
          setAttachments(file.attachments);
        } else if (folder && !folder.removed) {
          // download options have to be fetched if the file is undirect child of the folder
          // or the containing folder is cart folder
          const folderId =
            folder.node.parents?.[0].fileType === 'nt:cart'
              ? folder.node.parents?.[0].id
              : folder.node.id;
          apiReadFile({
            id: file.node.id,
            params: {
              include: 'attachments,downloadOptions',
              isCropView: 'true',
              lang: language,
              parentId: folderId,
              password,
            },
          }).then(res => !res.removed && setAttachments(res.attachments || []));
        }
      }
    }, [file, folder, password]);

    const onSelect = value => {
      if (value === 'crop') {
        // Modal was opened
      } else if (value === 'all') {
        alert('TODO: download all as zip?');
      } else if (file) {
        api.download({
          id: file.node.id,
          type: value,
          nameConfigId: selectedNameModel,
          lang: language,
        });
      }
    };

    let options: any[] = [];
    const isVideo = file && isVideoFile(file);
    const isCroppable = file && isCroppableFile(file);
    if (attachments) {
      options = attachments
        .filter(att => att.type !== 'pdflink' && att.downloadable)
        .map(att => ({
          value: att.id,
          title:
            getLangValue(att.namesByLang) +
            (att.sizeInfo ? ` ${att.sizeInfo.value} ${att.sizeInfo.unit}` : ''),
          icon: <DownloadIcon fontSize="small" />,
          hidden: !file || !file.userRights || !file.userRights.download,
        }));
      // Show model selector only if useNameModels is true and there is download items to download
      if (useNameModels && options.filter(o => !o.hidden).length > 0) {
        options.unshift(
          {
            component: (
              <MenuItem>
                <FileDownloadNameModelSelector
                  selectedValue={selectedNameModel}
                  handleChange={setSelectNameModel}
                />
              </MenuItem>
            ),
          },
          {
            value: 'divider1',
            title: '-',
            type: 'divider',
          }
        );
      }
      options.push(
        // TODO: enable "download all"
        // {
        //   value: 'divider1',
        //   title: '-',
        //   type: 'divider',
        //   hidden: !file || !file.userRights || !file.userRights.view,
        // },
        // {
        //   value: 'all',
        //   title: t`All`,
        //   icon: <DownloadIcon fontSize="small" />,
        //   hidden: !file || !file.userRights || !file.userRights.view,
        // },
        {
          value: 'divider2',
          title: '-',
          type: 'divider',
          hidden:
            !file ||
            !file.userRights ||
            !file.userRights.crop ||
            isVideo ||
            !isCroppable ||
            windowSize.innerWidth < 780,
        },
        {
          value: 'crop',
          title: t`Do crop`,
          icon: <CropIcon fontSize="small" />,
          hidden:
            !file ||
            !file.userRights ||
            !file.userRights.crop ||
            isVideo ||
            !isCroppable ||
            windowSize.innerWidth < 780,
          type: 'modal',
          modalType: 'FILE/CROP',
          modalProps: {
            file,
            folder,
            userRights,
          },
        }
      );
    }
    // if there are options to show...
    return (
      <MenuButton
        {...buttonProps}
        options={options}
        onSelect={onSelect}
        onClick={onClick}
        hideEmptyMenu={false}
        onOpen={onOpen}
        onExit={onExit}
        aria-label={t`Download ${file ? getLangValue(file?.namesByLang) : ''}`}
        disabled={!!(attachments && !options.filter(o => !o.hidden).length)}
        listItem={listItem}
        ref={ref}
      >
        {children}
      </MenuButton>
    );
  }
);

FileDownloadButton.displayName = 'FileDownloadButton';

export default FileDownloadButton;
