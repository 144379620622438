import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import MenuButton from '~navigation/MenuButton';
import { DropDownIcon } from '~misc/icons';
import { convertLangToFull } from '~common/app.utils';
import { app as appModel } from '~common/app.model';
import { useActions } from '~common/utils/hooks.utils';
interface Props {
  drawer?: boolean;
}

const LanguageButton: React.FC<Props> = ({ drawer }) => {
  const languages = useSelector(state => state.app.customer?.languages);
  const language = useSelector(state => state.app.settings?.language);
  const setLanguage = useActions(appModel.actions.setLanguage);
  const options =
    languages?.map(lang => {
      return {
        value: lang,
        title: convertLangToFull(lang),
        onSelect: () => {
          setLanguage(lang);
        },
      };
    }) || [];

  return (
    <StyledMenuButton color="inherit" options={options} drawer={drawer}>
      {convertLangToFull(language)}
      <DropDownIcon />
    </StyledMenuButton>
  );
};

// REFACTOR: duplicate styling with UserMenuButton
const StyledMenuButton = styled(MenuButton)<{ drawer?: boolean }>`
  && {
    margin-left: ${p => (p.drawer ? '-8px' : '0px')};
    text-transform: none;

    svg {
      margin-left: 4px;
    }
  }
`;

export default LanguageButton;
