import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { i18n } from '@lingui/core';
import { I18nProvider as LinguiProvider } from '@lingui/react';

import { Lang, ALL_CUSTOMER_LANGUAGES } from '~utils/i18n';

// Lingui keeps nagging about missing locales if we don't pre-populate
// it with empty dummy data when using lazy loading
const mockData = ALL_CUSTOMER_LANGUAGES.reduce(
  (acc, lang) => ({ ...acc, [lang]: {} }),
  {}
);
i18n.load(mockData);

/** Lazily load different localizations */
async function loadMessages(language: Lang) {
  // Compile translation files on the fly
  // https://lingui.js.org/ref/loader.html
  const { messages } = await import(
    `./common/locales/${language}/messages.mjs` /* @vite-ignore */
  );
  i18n.load(language, messages);
}

type Props = {
  language: Lang;
  children: React.ReactNode;
};

export default function I18nProvider({ language, children }: Props) {
  // If messages for new language haven't been loaded, load them before
  // activating the language for the app
  useEffect(() => {
    // Just to ignore private property type error
    if (isEmpty((i18n as any)._messages[language])) {
      loadMessages(language).then(() => i18n.activate(language));
    } else {
      i18n.activate(language);
    }
  }, [language]);

  return <LinguiProvider i18n={i18n}>{children}</LinguiProvider>;
}
