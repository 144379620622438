import React from 'react';
import { ThemeProvider as ScThemeProvider } from 'styled-components';
import { DndProvider } from 'react-dnd';
import { useSelector } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import {
  ThemeProvider,
  adaptV4Theme,
  StyledEngineProvider,
} from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BackgroundUploadProvider } from './content/files/common/BackgroundUploadProvider';

import I18nProvider from './I18nProvider';
import createTheme from '~common/theme';
import { Lang } from '~utils/i18n';

interface Props {
  children: React.ReactNode;
}

function Providers({ children }: Props) {
  const language = useSelector(state => state.app.settings?.language);
  const themeOptions = useSelector(state => state.app.settings?.theme);
  if (!language || !themeOptions) return null;

  const theme = createTheme(adaptV4Theme(themeOptions as any));
  return (
    <DndProvider backend={HTML5Backend}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ScThemeProvider theme={theme}>
            <I18nProvider language={language as Lang}>
              <LocalizationProvider dateAdapter={AdapterDateFns as any}>
                <CssBaseline />
                <BackgroundUploadProvider>{children}</BackgroundUploadProvider>
              </LocalizationProvider>
            </I18nProvider>
          </ScThemeProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </DndProvider>
  );
}

export default Providers;
