import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import withTheme from '@mui/styles/withTheme';
import makeStyles from '@mui/styles/makeStyles';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';

import { Theme } from '~common/theme';
import IconButton from '~inputs/IconButton';
import { app } from '~common/app.model';
import { CloseIcon } from '~misc/icons';
import { useActions } from '~common/utils/hooks.utils';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: theme.palette.success.main,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  icon: {
    fontSize: 20,
    marginRight: '10px',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface WrapperProps {
  className?: string;
  message?: string;
  onClose?: () => void;
  screenReaderOnly?: boolean;
  variant: keyof typeof variantIcon;
}

const SnackbarContentWrapper = React.forwardRef<HTMLDivElement, WrapperProps>(
  (props, ref) => {
    const classes = useStyles();
    const { className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];

    return (
      <SnackbarContent
        className={classes[variant]}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={classes.icon} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={onClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>,
        ]}
        role=""
        ref={ref}
        {...other}
      />
    );
  }
);

SnackbarContentWrapper.displayName = 'SnackbarContentWrapper';

function SnackbarHandler() {
  const snackbarId = useSelector(state => state.app.openSnackbarId);
  const snackbarProps = useSelector(state => state.app.openSnackbarProps);
  const closeSnackbar = useActions(app.actions.closeSnackbar);
  const handleClose = () => {
    setOpen(false);

    // NOTE: Clear in redux state after 1sec to prevent variant change
    setTimeout(() => {
      closeSnackbar();
    }, 1000);
  };

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!!snackbarId);
  }, [snackbarId]);

  const [portalHeight, setPortalHeight] = useState(0);
  const portalSizeObserver = useCallback((node: HTMLDivElement) => {
    if (!node) return;

    const observer = new ResizeObserver(() => {
      setPortalHeight(node.getBoundingClientRect().height);
    });
    observer.observe(node);
  }, []);

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open && !snackbarProps?.screenReaderOnly}
        style={{
          transform: portalHeight
            ? `translateY(-${portalHeight + 8}px)`
            : undefined,
        }}
        autoHideDuration={snackbarProps?.duration ?? 5000}
        onClose={handleClose}
        action={[
          <CloseButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon />
          </CloseButton>,
        ]}
      >
        <SnackbarContentWrapper
          onClose={handleClose}
          variant={snackbarProps?.type ?? 'success'}
          message={snackbarProps?.message ?? ''}
        />
      </Snackbar>
      {open && (
        <ScreenReaderAlert role="alert">
          {snackbarProps?.message}
        </ScreenReaderAlert>
      )}
      <FakeSnackbar
        ref={portalSizeObserver}
        id="permanent-snackbar-portal"
      ></FakeSnackbar>
    </>
  );
}

const FakeSnackbar = styled.div`
  z-index: 1400;
  position: fixed;
  bottom: 8px;
  left: 8px;
  right: 8px;

  @media (min-width: 600px) {
    bottom: 24px;
    right: 24px;
    left: auto;
  }
`;

const ScreenReaderAlert = styled.p`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  color: transparent;
`;

const CloseButton = withTheme(styled(IconButton)`
  && {
    width: ${props => props.theme.spacing(4)};
    height: ${props => props.theme.spacing(4)};
  }
`);

export default SnackbarHandler;
