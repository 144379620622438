import React from 'react';
import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { MetaData, ArchiveField } from '../content.types';
import PopupMetaField from './PopupMetaField';
import ArchiveFields from './ArchiveFields';
import { getDateString, getTime } from '~common/utils/date.utils';
import { getLocalizedValue } from '~common/app.utils';

interface Props {
  field: ArchiveField;
  metaData?: Record<string, MetaData>;
  lang: string;
  onChange?: (value: string, fieldKey?: string) => void;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  labelText?: string;
  asPopup?: boolean;
  isNew?: boolean;
}
const Archive = ({
  field,
  metaData,
  lang,
  onChange,
  disabled,
  error,
  helperText,
  asPopup,
  isNew,
  ...rest
}: Props) => {
  const archiveFieldId = field.id;
  const label = getLocalizedValue<string>(field.namesByLang, lang);
  const scheduleTimeFieldId = field.scheduleTimeFieldId;
  const defaultLang = useSelector(state => state.app.customer?.defaultLanguage);

  const selected =
    Boolean(archiveFieldId) && metaData?.[archiveFieldId] === 'true';
  const scheduleTimeTime =
    typeof metaData?.[scheduleTimeFieldId] === 'string'
      ? new Date(metaData[scheduleTimeFieldId] as string)
      : null;

  if (metaData?.['nibo:version-history-uuid']) return null;

  return asPopup ? (
    <PopupMetaField
      value={getStringValue(selected, scheduleTimeTime)}
      error={error}
      helperText={helperText}
      labelText={label}
      {...rest}
    >
      <ArchiveFields
        field={field}
        metaData={metaData}
        onChange={onChange}
        disabled={disabled}
        error={error}
        helperText={error ? helperText : undefined}
        showSwitch={true}
        switchLabel={t`Timing`}
        isNew={isNew}
      />
    </PopupMetaField>
  ) : (
    <ArchiveFields
      field={field}
      metaData={metaData}
      label={label}
      readOnly={lang !== defaultLang}
      valueToString={getStringValue}
      onChange={onChange}
      disabled={disabled}
      error={error}
      helperText={error ? helperText : undefined}
      showSwitch={!!archiveFieldId}
      switchLabel={t`Timing`}
      isNew={isNew}
    />
  );
};

function getStringValue(selected: boolean, fromDate: Date | null) {
  return `${selected ? t`Scheduled` : t`Unscheduled`} ${
    selected
      ? `${
          fromDate
            ? getDateString(fromDate) + ' ' + getTime(fromDate.toISOString())
            : ''
        }`
      : ''
  }`;
}

export default Archive;
