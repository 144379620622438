import { WorkflowSettings } from './types';

import { apiBase } from '~common/api.base';
import { convertUserIn } from '~common/app.utils';
import { useQueries } from '~common/utils/ducks.utils';

const getWorkflowTag = (id: string) => ({
  type: 'Workflow' as const,
  id,
});

const extendedApi = apiBase.injectEndpoints({
  endpoints: builder => ({
    getWorkflow: builder.query<WorkflowSettings, { id: string }>({
      query: args => ({
        url: `workflows/${args.id}/settings?include=info,translatedDefaults`,
        method: 'get',
      }),
      transformResponse: (data: WorkflowSettings) => ({
        ...data,
        approverGroup: data.approverGroup
          ? {
              ...data.approverGroup,
              members: data.approverGroup.members?.map(user =>
                convertUserIn(user)
              ),
            }
          : undefined,
      }),
      providesTags: (_, __, { id }) => [getWorkflowTag(id)],
    }),
  }),
});

export const { getWorkflow } = extendedApi.endpoints;

export const { useGetWorkflowQuery } = extendedApi;

export const useGetWorkflowQueries = useQueries(
  extendedApi.endpoints.getWorkflow
);
