import { t } from '@lingui/macro';
import {
  ConsentRequestParams,
  ConsentRequestReminderParams,
  ConsentStatusHistory,
  ConsentStatusHistoryResponse,
  GetConsentStatusHistoryParams,
  GetPersonsByNodeIdsAndStatusesParams,
  TaggedPersonById,
  UpdateConsentStatusParams,
} from './types';
import { apiBase } from '~common/api.base';
import { getFileTag } from '~common/content.api';
import { afterQueryMessages } from '~common/utils/api.utils';

const extendedApi = apiBase.injectEndpoints({
  endpoints: builder => ({
    sendConsentRequest: builder.mutation<void, ConsentRequestParams>({
      query: ({ ...data }) => ({
        url: `/consents/requests`,
        method: 'post',
        data,
      }),
      onQueryStarted: (_, args) =>
        afterQueryMessages(t`Sending ready`, t`Send failed`, args),
      invalidatesTags: (_, __, arg) => arg.fileIds.map(id => getFileTag(id)),
    }),

    sendConsentRequestReminder: builder.mutation<
      void,
      ConsentRequestReminderParams
    >({
      query: ({ ...data }) => ({
        url: `/consents/requests/reminder`,
        method: 'post',
        data,
      }),
      onQueryStarted: (_, args) =>
        afterQueryMessages(t`Sending ready`, t`Send failed`, args),
    }),

    updateConsentStatus: builder.mutation<void, UpdateConsentStatusParams>({
      query: ({ ...data }) => ({
        url: `/consents/requests/status`,
        method: 'put',
        data,
      }),
      invalidatesTags: (_, __, arg) => arg.fileIds.map(id => getFileTag(id)),
    }),

    removeConsent: builder.mutation<void, { id: string; fileId: string }>({
      query: ({ id }) => ({
        url: `/consents/requests/${id}/remove`,
        method: 'put',
      }),
      invalidatesTags: (_, __, { fileId }) => [getFileTag(fileId)],
    }),
    getPersonsByNodeIdsAndStatuses: builder.query<
      TaggedPersonById,
      GetPersonsByNodeIdsAndStatusesParams
    >({
      query: ({ nodeIds, statuses }) => {
        const nodeIdsString = nodeIds.join(',');
        const statusesString = statuses.join(',');

        return {
          url: `/consents/requests/persons?nodeIds=${nodeIdsString}&statuses=${statusesString}&timestamp=${Date.now()}`,
          method: 'get',
        };
      },
      keepUnusedDataFor: 0,
    }),
    getConsentStatusHistory: builder.query<
      ConsentStatusHistoryResponse,
      GetConsentStatusHistoryParams
    >({
      query: ({ pageSize, page }) => {
        return {
          url: `/consents/history?page=${page}&pageSize=${pageSize}`,
          method: 'get',
        };
      },
      keepUnusedDataFor: 0,
      transformResponse: (response: ConsentStatusHistory[], meta) => {
        return {
          ...response,
          list: response,
          totalCount: parseInt(meta?.headers['x-totalcount'] ?? '0'),
        };
      },
    }),
  }),
});

export const {
  useUpdateConsentStatusMutation,
  useSendConsentRequestReminderMutation,
  useRemoveConsentMutation,
  useSendConsentRequestMutation,
  useGetPersonsByNodeIdsAndStatusesQuery,
  useGetConsentStatusHistoryQuery,
} = extendedApi;
