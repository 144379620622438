import { NativeTypes } from 'react-dnd-html5-backend';

export const dndTypes = {
  FILE: 'FILE',
  FILE_LINK: 'FILE_LINK',
  FOLDER: 'FOLDER',
  FOLDER_LINK: 'FOLDER_LINK',
  /** Sometimes called cart. Can contain links to files, links to folders
   * and (non-linked) workspace folders */
  WORKSPACE: 'WORKSPACE',
  /** File inside a workspace, technically same as FILE_LINK but requires
   * different behaviour for drag & drop */
  WORKSPACE_FILE: 'WORKSPACE_FILE',
  /** A folder inside a workspace which can not contain further nested
   * folders or links to folders */
  WORKSPACE_FOLDER: 'WORKSPACE_FOLDER',
  WORKSPACE_FOLDER_LINK: 'WORKSPACE_FOLDER_LINK',
  /** A folder in workspace listing which can only contain workspaces
   * or other workspace list folders */
  WORKSPACE_LIST_FOLDER: 'WORKSPACE_LIST_FOLDER',
  /** A special kind of workspace */
  SHOPPING_CART: 'SHOPPING_CART',
  /** An archived folder or file */
  ARCHIVE_ITEM: 'ARCHIVE_ITEM',
  /** File from the user's operating system */
  NATIVE_FILE: NativeTypes.FILE,
  /** This type shouldn't be given to anything nor should it be accepted
   * by any drop, but we need some catch-all "error"-type */
  UNDEFINED: 'UNDEFINED',
} as const;

export type DndType = typeof dndTypes[keyof typeof dndTypes];
