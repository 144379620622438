import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import {
  Link,
  Card,
  LinkItem,
  CardItem,
  StaticCard,
  StaticCardTextKey,
  LinkGroupMode,
  LinkTextKey,
} from './types';
import { TranslatedData } from '~common/common.types';

type Target = Link | Card | LinkItem | CardItem;

/**
 * Generates a new ID for a card or a link, or a child of either if
 * `parentId` is passed
 */
export function getNewId(items: Target[], parentId?: number) {
  if (Number.isInteger(parentId)) {
    const targetItem = items.find(item => item.id === parentId);
    if (targetItem && 'items' in targetItem) {
      const subitemIds = (targetItem.items ?? []).map(subItem => subItem.id);
      return subitemIds.length ? Math.max(...subitemIds) + 1 : 0;
    }
    return 0;
  } else {
    const itemIds = items.map(item => item.id);
    return itemIds.length ? Math.max(...itemIds) + 1 : 1;
  }
}

/**
 * Returns a new object with each language as a key for a string,
 * defaulting to ''
 */
export const getTranslatedObject = (languages: string[], value = '') =>
  languages.reduce((acc, curr) => ({ ...acc, [curr]: value }), {});

/** Converts the possible string value to a translated object with
 * the default language holding the value.
 */
export const convertToTranslatedObject = (
  value: string | TranslatedData,
  languages: string[]
) => {
  if (!value) return getTranslatedObject(languages);
  if (typeof value !== 'string') return value;
  const translatedObject = getTranslatedObject(languages);
  if (languages.length) translatedObject[languages[0]] = value;
  return translatedObject;
};

/**
 * Returns a value of a field defined by `fieldKey` as a string, digging
 * it from a translated field if necessary.
 */
export const getFieldValue = <T extends Target, K extends keyof T>(
  target: T,
  fieldKey: K,
  activeLang: string
): string =>
  typeof target[fieldKey] === 'string'
    ? target[fieldKey]
    : target[fieldKey]?.[activeLang] || '';

/**
 * Updates a field inside an object, regardless of it being translated
 * or not.
 */
export const updateFieldValue = <T extends Target, K extends keyof T>(
  target: T,
  fieldKey: K,
  newValue: string,
  activeLang: string
): T =>
  typeof target[fieldKey] === 'string'
    ? { ...target, [fieldKey]: newValue }
    : {
        ...target,
        [fieldKey]: { ...target[fieldKey], [activeLang]: newValue },
      };

/**
 * Filters items based on their permissions and the current permissions
 */
export function filterItems<T extends Link | Card>(
  items: T[],
  currentGroups: number[]
) {
  let arr = items;
  // If no permissions set, display everything
  if (currentGroups.length === 0) return items;

  // Display items matching any group with user, or if item has no groups set
  // it's always visible
  const includeItem = (item: Target) =>
    !item.userGroups ||
    item.userGroups.length === 0 ||
    item.userGroups.some(groupId => currentGroups.includes(groupId));

  // Filter top level items
  arr = arr.filter(includeItem);

  // Filter all subitems
  arr = arr.map(item => {
    if ('items' in item) {
      return {
        ...item,
        items: (item as Link | StaticCard).items?.filter(includeItem),
      };
    } else {
      return item;
    }
  });

  return arr;
}

/** Generates a key for storing group changes in redux state */
export function getGroupChangesKey(
  itemType: 'link' | 'card',
  itemId: number,
  parentId: number | undefined = undefined
) {
  return `${itemType}-${itemId}-${parentId}`;
}

/** Definitions of the input elements for static card links */
export function getCardLinkInputs(): {
  label: string;
  fieldKey: StaticCardTextKey;
  required?: boolean;
}[] {
  return [
    {
      label: t`Text`,
      fieldKey: 'name',
      required: true,
    },
    {
      label: t`Card destination`,
      fieldKey: 'url',
      required: true,
    },
  ];
}

/** Definitions of the input elements for visual links */
export function getVisualLinkInputs(
  link: Link,
  defaultMode?: LinkGroupMode
): { label: string; fieldKey: LinkTextKey; required?: boolean }[] {
  const mode =
    link.items && link.items.length > 0
      ? getGroupModeOfLink(link, defaultMode)
      : 'none';

  const inputs = [
    {
      label: t`Text`,
      fieldKey: 'name' as const,
    },
  ];
  if (mode === 'group') {
    return inputs;
  } else if (mode === 'menu') {
    return [
      ...inputs,
      {
        label: t`Picture address`,
        fieldKey: 'image' as const,
        required: true,
      },
    ];
  } else {
    return [
      ...inputs,
      {
        label: t`Picture address`,
        fieldKey: 'image' as const,
        required: true,
      },
      {
        label: t`Link destination`,
        fieldKey: 'url' as const,
        required: true,
      },
    ];
  }
}

/** Definitions of the input elements for sublinks of visual links */
export function getVisualLinkItemInputs(
  linkGroupMode?: LinkGroupMode
): { label: string; fieldKey: LinkTextKey; required?: boolean }[] {
  const inputs = [
    {
      label: t`Text`,
      fieldKey: 'name' as const,
      required: linkGroupMode === 'menu',
    },
    {
      label: t`Link destination`,
      fieldKey: 'url' as const,
      required: true,
    },
  ];

  if (linkGroupMode === 'menu') {
    return inputs;
  } else {
    return [
      ...inputs,
      {
        label: t`Picture address`,
        fieldKey: 'image' as const,
        required: true,
      },
    ];
  }
}

export function getGroupModeOfLink(link: Link, defaultMode?: LinkGroupMode) {
  return link.linkGroupMode
    ? link.linkGroupMode === 'menu'
      ? ('menu' as const)
      : ('group' as const)
    : defaultMode;
}

type PanelKeyIdentifier = readonly [
  panelType: 'card' | 'link',
  panelId: number,
  inputKey?: 'name' | 'url' | 'image' | undefined
];
type PanelItemKeyIdentifier = readonly [
  panelType: 'card' | 'link',
  panelId: number,
  itemId: number,
  inputKey?: 'name' | 'url' | 'image' | undefined
];

export function getErrorMessageKey(...args: PanelKeyIdentifier): string;
export function getErrorMessageKey(...args: PanelItemKeyIdentifier): string;
export function getErrorMessageKey(...args): string {
  return args.filter(x => x !== undefined).join('.');
}

export function parseErrorMessageKey(key: string) {
  const matchItem = key.match(/(card|link)\.(\d*)\.(\d*)\.(.*)$/);
  if (matchItem)
    return {
      panelType: matchItem[1] as 'card' | 'link',
      panelId: Number(matchItem[2]),
      itemId: Number(matchItem[3]),
      inputKey: matchItem[4] as StaticCardTextKey | LinkTextKey,
    };

  const matchPanel = key.match(/(card|link)\.(\d*)\.(.*)$/);
  if (matchPanel)
    return {
      panelType: matchPanel[1] as 'card' | 'link',
      panelId: Number(matchPanel[2]),
      inputKey: matchPanel[3] as LinkTextKey,
    };

  return {};
}
export function useHasErrors(...args: PanelKeyIdentifier);
export function useHasErrors(...args: PanelItemKeyIdentifier);
export function useHasErrors(
  ...args: PanelKeyIdentifier | PanelItemKeyIdentifier
) {
  const errorMessages = useSelector(
    state => state.frontpageSettings.errorMessages
  );
  // just casting to one of the tuples to avoid typescript errors
  return Object.keys(errorMessages).some(key =>
    key.startsWith(getErrorMessageKey(...(args as PanelKeyIdentifier)))
  );
}
