import { all, put, takeEvery, fork, takeLatest } from 'redux-saga/effects';

import { encodeTags } from 'src/content/common/utils';
import { convertSortOption, updateContentCriteria } from './content.utils';
import * as api from './content.api';
import { convertUserIn } from './app.utils';
import { call, select } from './utils/saga.utils';

import { fileIncludeAllList } from './content.constants';
import { commonContent } from './content.model';

// File

function* readFile(action) {
  try {
    const include = fileIncludeAllList; // REFACTOR: perhaps publicity should be retrieved only when required
    const file = yield* call(api.readFile, {
      id: action.payload.id,
      params: {
        include,
        password: action.payload.password,
      },
    });
    yield put({
      type: 'CONTENT/AFTER_READ_FILE',
      payload: {
        id: action.payload.id,
        file,
        overwriteStateMeta: include.includes('meta'),
      },
    });
  } catch (error) {
    yield put({
      type: 'CONTENT/AFTER_READ_FILE_ERROR',
      payload: { error },
    });
  }
}

function* watchReadFile() {
  yield takeEvery('CONTENT/READ_FILE', readFile);
}

// Files

// REFACTOR: combine readFiles and readMultipleFiles, for instance so that the saga can detect from the include string,
// whether it can use the more efficient endpoint or multiple requests.

/** This uses the more efficient endpoint of the two (readMultipleFiles and readFiles),
 * that enables to fetch multiple files with a single request.
 * However, that endpoint supports currently only include=basic,image,rights.
 * So, if you need to fetch anything more complex, use readMultipleFiles. */
function* readFiles(action) {
  try {
    const { ids, include } = action.payload;
    const filesById = yield* call(api.readFiles, {
      ids,
      params: {
        include: `basic,image,${include || ''}`,
      },
    });

    // Read text file contents
    yield all(
      Object.values(filesById)
        .filter(
          file =>
            action.payload.fetchContents &&
            !file.removed &&
            file.mimeGroup === 'text'
        )
        .map(file => {
          return put({
            type: 'CONTENT/READ_FILE_CONTENT',
            payload: {
              id: !file.removed && file.node.id,
            },
          });
        })
    );

    yield put({
      type: 'CONTENT/AFTER_READ_FILES',
      payload: { filesById, overwriteStateMeta: include?.includes('meta') },
    });
  } catch (error) {
    yield put({
      type: 'CONTENT/AFTER_READ_FILE_ERROR',
      payload: { error },
    });
  }
}

function* watchReadFiles() {
  yield takeEvery('CONTENT/READ_FILES', readFiles);
}

/** NOTE: This uses multiple fetches to read all files, readFiles uses only a single one. Why not use that.
 * It uses a bit more limited endpoint, but if that's sufficient, you should use that one. */
function* readMultipleFiles(action) {
  try {
    if (!action.payload.ids?.length) return;
    const { ids, include } = action.payload;

    const files = yield all(
      ids.map(id =>
        call(api.readFile, {
          id,
          params: {
            // REFACTOR: perhaps publicity should be retrieved only when required
            include: `basic,${include}`,
          },
        })
      )
    );

    const filesById = files.reduce(
      (obj, f) => ({
        ...obj,
        ...(f.concrete ? { [f.concrete.id]: f } : {}),
        [f.node.id]: f,
      }),
      {}
    );

    yield put({
      type: 'CONTENT/AFTER_READ_FILES',
      payload: { filesById, overwriteStateMeta: include?.includes('meta') },
    });
  } catch (error) {
    console.error(error);
  }
}

function* watchReadMultipleFiles() {
  yield takeEvery('CONTENT/READ_MULTIPLE_FILES', readMultipleFiles);
}

// Contents

function* readFileContent(action) {
  try {
    const content = yield* call(api.readOriginalContent, {
      id: action.payload.id,
    });

    yield put({
      type: 'CONTENT/AFTER_READ_FILE_CONTENT',
      payload: { id: action.payload.id, content },
    });
  } catch (error) {
    yield put({
      type: 'CONTENT/AFTER_READ_FILE_CONTENT_ERROR',
      payload: { error },
    });
  }
}

function* watchReadFileContent() {
  yield takeEvery('CONTENT/READ_FILE_CONTENT', readFileContent);
}

// Folder

function* readFolder(action) {
  try {
    if (!action.payload.id) return;

    const folder = yield* call(api.readFolder, {
      id: action.payload.id,
      params: {
        // REFACTOR: perhaps publicity should be retrieved only when required
        include: `basic,rights,path,info,publicity,
        ${action.payload.include || ''}`,
        ...action.payload.params,
      },
    });
    yield put({
      type: 'CONTENT/AFTER_READ_FOLDER',
      payload: {
        id: action.payload.id,
        folder,
      },
    });
  } catch (error) {
    yield put({
      type: 'CONTENT/AFTER_READ_FOLDER_ERROR',
      payload: { error },
    });
  }
}

function* watchReadFolder() {
  // yield takeEvery('CONTENT/SHOW_FOLDER', readFolder); // TODO: remove?
  yield takeEvery('CONTENT/READ_FOLDER', readFolder);
  yield takeEvery('CONTENT/READ_CONTENT', readFolder);
}

// Content
function* readContent(action) {
  try {
    // REFACTOR: Server-side API should return metaFields in a proper format
    // TODO: Or should we forget meta fields for content?
    const { customerId, lang, userContentFolderId } = yield* select(state => {
      return {
        customerId: state.app.customer?.id,
        lang: state.app.settings?.language,
        userContentFolderId: state.app.settings?.userContentFolderId,
      };
    });

    // Convert page/pageSize to offset/limit, and ignore selectedIndex/Id
    const { page, pageSize, sortBy, selectedIndex, selectedId, tags, ...rest } =
      action.payload.criteria;
    const searchParams = {
      offset: pageSize ? page * pageSize : 0,
      limit: pageSize,
      sort: sortBy,
      tags: tags && encodeTags(tags),
      ...rest,
    };

    let data;
    if (action.payload.id) {
      // Read folder content by folder id
      data = yield* call(api.readFolderContent, {
        id: action.payload.id,
        params: {
          // Fetch only basic information by default
          include:
            action.payload.include !== null &&
            action.payload.include !== undefined
              ? action.payload.include
              : 'basic,basicRights,synkka,publicity,users,emails',
          lang,
          // The default workspace is located in user's own material folder
          // We don't want to include that or any other workspaces that might have gotten lost in own material
          omit: action.payload.id === userContentFolderId ? 'cart' : undefined,
          ...searchParams,
        },
      });
    } else {
      data = yield* call(api.readContent, {
        customerId,
        params: {
          type: 'material', // Search only material by default
          include: 'synkka',
          lang,
          ...searchParams,
        },
        returnObjects: true,
      });
    }
    yield put({
      type: 'CONTENT/AFTER_READ_CONTENT',
      payload: {
        id: action.payload.id,
        criteria: action.payload.criteria,
        items: data.items,
        totalCount: data.totalCount,
      },
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: 'CONTENT/AFTER_READ_CONTENT_ERROR',
      payload: {
        id: action.payload.id,
        criteria: action.payload.criteria,
        error,
      },
    });
  }
}

function* watchReadContent() {
  yield takeEvery('CONTENT/READ_CONTENT', readContent);
}

function* showFileInFolder(
  action: ReturnType<typeof commonContent.actions.showFileInFolder>
) {
  try {
    // TODO: Or should we forget meta fields for content?
    const { lang, customerId, customerConfig } = yield* select(state => {
      return {
        lang: state.app.settings?.language,
        customerId: state.app.customer?.id,
        customerConfig: state.app.customer?.configById,
      };
    });

    const { page, pageSize, sortBy, selectedIndex, selectedId, tags, ...rest } =
      action.payload.criteria;

    const isFolder = !!action.payload.folderId;

    // Convert sort. Ignore offset, limit for folders, on search, search withing the adjacent pages.
    const searchParams = {
      offset: isFolder ? 0 : Math.max(0, page - 1 * pageSize),
      limit: isFolder ? 9999 : page > 0 ? pageSize * 3 : pageSize * 2,
      sort: convertSortOption(sortBy, isFolder, customerConfig),
      tags: tags && encodeTags(tags),
      ...rest,
    };

    const data = isFolder
      ? yield* call(api.readFolderContent, {
          id: action.payload.folderId,
          params: {
            // include: 'basic', // Fetch only basic information by default
            lang,
            ...searchParams,
          },
        })
      : yield* call(api.readContent, {
          customerId,
          params: searchParams,
          returnObjects: false,
        });

    const index = data.items.findIndex(item => {
      return (item.node?.id ?? item.id) === action.payload.fileId;
    });

    if (index !== -1) {
      // Refresh selectedIndex and page number based on index
      yield call(
        updateContentCriteria,
        action.payload.folderId,
        {
          ...action.payload.criteria,
          page: Math.floor((index + searchParams.offset) / pageSize),
          selectedIndex: index % pageSize,
          selectedId: action.payload.fileId,
        },
        action.payload.historyUpdateMode,
        action.payload.enterMode
      );
    } else throw new Error('Selected file not found');
    // TODO: We probably should handle the case of moving to a folder
    // while item was selected in a previous folder here instead of
    // passing `onOpenFolder` callbacks around to child components in
    // src/content/FolderInfoBar.tsx
  } catch (error) {
    yield put({
      type: 'CONTENT/AFTER_SHOW_FILE_IN_FOLDER_ERROR',
      payload: { error },
    });
    yield call(
      updateContentCriteria,
      action.payload.folderId,
      {
        ...action.payload.criteria,
        selectedIndex: -1,
        selectedId: null,
      },
      action.payload.historyUpdateMode,
      action.payload.enterMode
    );
  }
}

function* watchShowFileInFolder() {
  yield takeLatest('CONTENT/SHOW_FILE_IN_FOLDER', showFileInFolder);
}

// Workflows

function* fetchWorkflow(action) {
  try {
    const workflow = yield* call(api.fetchWorkflows, action.payload);
    yield put({
      type: 'CONTENT/AFTER_WORKFLOW_FETCH',
      payload: {
        id: action.payload.id,
        workflow: {
          ...workflow,
          approverGroup: workflow.approverGroup
            ? {
                ...workflow.approverGroup,
                members: workflow.approverGroup.members?.map(user =>
                  convertUserIn(user)
                ),
              }
            : undefined,
        },
      },
    });
  } catch (error) {
    console.log(error);
  }
}

function* watchFetchWorkflow() {
  yield takeEvery('CONTENT/FETCH_WORKFLOW', fetchWorkflow);
}

// Combine all

export default function* sagas(): any {
  yield all([
    fork(watchReadFile),
    fork(watchReadFileContent),
    fork(watchReadFiles),
    fork(watchReadMultipleFiles),
    fork(watchReadFolder),
    fork(watchShowFileInFolder),
    fork(watchReadContent),
    fork(watchFetchWorkflow),
  ]);
}
