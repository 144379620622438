import React, { useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import SnackbarContent from '@mui/material/SnackbarContent';

import styled from 'styled-components';
import { CircularProgress, LinearProgress, Tooltip } from '@mui/material';
import CheckIcon from '@mui/icons-material/CheckCircle';
import PauseIcon from '@mui/icons-material/PauseCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { t } from '@lingui/macro';
import { FileToAdd } from './add.types';
import { useAddFiles } from './add.utils';

import IconButton from '~inputs/IconButton';
import {
  CloseIcon,
  ExpandLessIcon,
  ExpandMoreIcon,
  PlayIcon,
  RedoIcon,
} from '~misc/icons';
import { Typography } from '~common/misc/Typography';
import { useActions, useToggle } from '~common/utils/hooks.utils';
import { UploadStatus } from '~common/content.constants';
import { app } from '~common/app.model';
import { SuccessCheckIcon } from '~common/items/FileSlide';
import { invalidateFile } from '~common/content.api';

const BackgroundUploadContext = React.createContext<{
  upsertUploadBatch: (batch: UploadBatch, batchId?: string) => string;
  getBatch: (batchId: string) => UploadBatch | undefined;
  getConflictingFiles: (name: string, folderId: string) => FileToAdd[];
}>({
  // These are just placeholders
  upsertUploadBatch: () => '',
  getBatch: () => undefined,
  getConflictingFiles: () => [],
});

type UploadBatchParams = Parameters<typeof useAddFiles<FileToAdd>>[0];
type UploadBatchMethods = ReturnType<typeof useAddFiles> & {
  batchStatus: UploadStatus;
};

type UploadBatch = {
  params: UploadBatchParams;
  methods?: UploadBatchMethods;
};

let batchNumber = 0;

export function BackgroundUploadProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const showInfoMessage = useActions(app.actions.showInfoMessage);
  const [uploadBatches, setUploadBatches] = useState<
    Record<string, UploadBatch>
  >({});

  /** Prevent user from exiting/reloading while upload in progress */
  useEffect(() => {
    const listener = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = true;
    };

    if (Object.keys(uploadBatches).length)
      window.addEventListener('beforeunload', listener);
    return () => window.removeEventListener('beforeunload', listener);
  }, [Object.keys(uploadBatches).length]);

  const contextValue = {
    upsertUploadBatch: (batch: UploadBatch, batchId?: string) => {
      const newBatchId = ++batchNumber;
      setUploadBatches(batches => {
        const newBatches = { ...batches, [newBatchId]: batch };
        if (batchId) delete newBatches[batchId];
        return newBatches;
      });
      return newBatchId.toString();
    },
    getBatch: (batchId: string) => uploadBatches[batchId] ?? undefined,
    getConflictingFiles: (filename: string, folderId: string) =>
      Object.values(uploadBatches).flatMap(batch => {
        if (batch.params.targetFolderId !== folderId) return [];

        return batch.params.files.filter(
          file => (file.affixedName ?? file.name) === filename
        );
      }),
  };

  const getSetFiles =
    (batchId: string) => (action: React.SetStateAction<FileToAdd[]>) =>
      setUploadBatches(batches =>
        batches[batchId]
          ? {
              ...batches,
              [batchId]: {
                ...batches[batchId],
                params: {
                  ...batches[batchId]?.params,
                  files: Array.isArray(action)
                    ? action
                    : action(batches[batchId].params.files),
                },
              },
            }
          : batches
      );

  const getSetBatchMethods =
    (batchId: string) => (methods: UploadBatchMethods) =>
      setUploadBatches(batches => ({
        ...batches,
        [batchId]: {
          ...batches[batchId],
          methods,
        },
      }));

  const activeBatchId = Object.keys(uploadBatches).find(
    key => !uploadBatches[key].methods?.uploadDone
  );
  const activeBatch = activeBatchId ? uploadBatches[activeBatchId] : undefined;
  const allFiles = Object.values(uploadBatches).flatMap(
    batch => batch.params.files
  );
  const batchStatuses = new Set(
    Object.values(uploadBatches).map(x => x.methods?.batchStatus)
  );
  const status =
    activeBatch?.methods?.batchStatus ??
    (batchStatuses.has(UploadStatus.CANCELLED)
      ? UploadStatus.CANCELLED
      : batchStatuses.has(UploadStatus.ERROR)
      ? UploadStatus.ERROR
      : undefined);

  const portalEl = document.querySelector('#permanent-snackbar-portal');

  useEffect(() => {
    if (
      allFiles.length &&
      allFiles.every(x => x.uploadStatus === UploadStatus.UPLOADED)
    ) {
      showInfoMessage(t`Files uploaded successfully`);
      setUploadBatches({});
    }
  }, [uploadBatches]);

  const retry = () => {
    Object.keys(uploadBatches).forEach(batchId => {
      getSetFiles(batchId)(files =>
        files.map(x => ({
          ...x,
          uploadStatus:
            x.uploadStatus !== UploadStatus.UPLOADED
              ? UploadStatus.WAITING
              : x.uploadStatus,
        }))
      );
      uploadBatches[batchId]?.methods?.reset();
    });
  };

  const pause = () => {
    Object.values(uploadBatches).forEach(batch => {
      if (batch.methods?.uploading) batch.methods.cancelUpload();
    });
  };

  const clear = () => {
    setUploadBatches({});
  };

  return (
    <BackgroundUploadContext.Provider value={contextValue}>
      {Object.keys(uploadBatches).map(key => (
        <BackgroundUploader
          key={key}
          setFiles={getSetFiles(key)}
          setBatchMethods={getSetBatchMethods(key)}
          params={uploadBatches[key].params}
          active={status !== UploadStatus.CANCELLED && key === activeBatchId}
        />
      ))}
      {children}
      {portalEl && Object.keys(uploadBatches).length
        ? createPortal(
            <BackgroundUploadSnackbar
              files={allFiles}
              activeBatch={activeBatch}
              status={status}
              retry={retry}
              pause={pause}
              clear={clear}
            />,
            portalEl
          )
        : null}
    </BackgroundUploadContext.Provider>
  );
}

export function useBackgroundUploadContext() {
  return useContext(BackgroundUploadContext);
}

interface BackgroundUploaderProps {
  params: UploadBatch['params'];
  setFiles: React.Dispatch<React.SetStateAction<FileToAdd[]>>;
  setBatchMethods: (methods: UploadBatchMethods) => void;
  active: boolean;
}

function BackgroundUploader({
  params,
  setFiles,
  setBatchMethods,
  active,
}: BackgroundUploaderProps) {
  const { startUpload, ...batchMethods } = useAddFiles({
    ...params,
    setFiles,
  });
  const invalidateFolder = useActions(invalidateFile);

  /** Start the upload process */
  useEffect(() => {
    if (active && !batchMethods.uploading && !batchMethods.uploadCancelled)
      startUpload();
  }, [active]);

  /** Reload folder contents when the background uploader is done and removed */
  useEffect(() => {
    return () => {
      invalidateFolder(params.targetFolderId);
    };
  }, []);

  useEffect(() => {
    setBatchMethods({
      ...batchMethods,
      startUpload,
      batchStatus: batchMethods.batchStatus ?? UploadStatus.WAITING,
    });
  }, [params, batchMethods.batchStatus]);

  return null;
}

function BackgroundUploadSnackbar({
  files,
  activeBatch,
  status,
  retry,
  pause,
  clear,
}: {
  files: FileToAdd[];
  activeBatch?: UploadBatch;
  status?: UploadStatus;
  retry: () => void;
  pause: () => void;
  clear: () => void;
}) {
  const [expanded, toggleExpanded] = useToggle(false);

  return (
    <StyledSnackbarContent
      message={
        <BackgroundUploadMessage>
          {expanded ? <BackgroundUploadSnackbarDetails files={files} /> : null}

          <CenteringWrapper>
            <BackgroundUploadSnackbarPreview
              files={files}
              progress={activeBatch?.methods?.uploadPercentage}
              status={status}
              retry={retry}
              pause={pause}
              clear={clear}
            />
          </CenteringWrapper>
        </BackgroundUploadMessage>
      }
      action={[
        <IconButton
          key="expand"
          onClick={toggleExpanded}
          color="inherit"
          size="large"
        >
          {expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </IconButton>,
      ]}
    />
  );
}

const BackgroundUploadMessage = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(2)};
`;

const CenteringWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

function BackgroundUploadSnackbarDetails({ files }: { files: FileToAdd[] }) {
  const statusIcons = {
    [UploadStatus.UPLOADING]: <CircularProgress size={20} color="inherit" />,
    [UploadStatus.UPLOADED]: <CheckIcon color="inherit" fontSize="small" />,
    [UploadStatus.CANCELLED]: <PauseIcon color="inherit" fontSize="small" />,
    [UploadStatus.ERROR]: <ErrorIcon color="inherit" fontSize="small" />,
  };

  return (
    <DetailsStack>
      {files.map(file => (
        <FileDetailsRow key={file.preview}>
          {file.uploadStatus && file.uploadStatus in statusIcons ? (
            statusIcons[file.uploadStatus]
          ) : (
            <div style={{ width: '20px', height: '20px' }} />
          )}
          <UploadFileName>{file.affixedName ?? file.name}</UploadFileName>
        </FileDetailsRow>
      ))}
    </DetailsStack>
  );
}

const DetailsStack = styled.div`
  display: flex;
  flex-direction: column;
`;

const UploadFileName = styled(Typography).attrs({ variant: 'subtitle1' })`
  /* text-align: center; */
`;

const FileDetailsRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing(2)};

  ${UploadFileName} {
    flex: 1;
  }
`;

function BackgroundUploadSnackbarPreview({
  progress,
  files,
  status,
  retry,
  pause,
  clear,
}: {
  progress?: number;
  files: FileToAdd[];
  status?: UploadStatus;
  retry: () => void;
  pause: () => void;
  clear: () => void;
}) {
  if (!status) return null;

  if (status === UploadStatus.UPLOADING)
    return (
      <StatusStack>
        <ProgressStack>
          <Typography>
            {t`Uploading ${
              files.filter(
                file =>
                  file.uploadStatus === UploadStatus.UPLOADED ||
                  file.uploadStatus === UploadStatus.UPLOADING
              ).length
            } / ${files.length}`}
          </Typography>
          <ProgressBar variant="determinate" value={progress} />
          <Typography>{progress ? progress.toFixed(0) : 0} %</Typography>
        </ProgressStack>

        <Tooltip title={t`Stop`}>
          <IconButton color="inherit" onClick={pause}>
            <PauseIcon />
          </IconButton>
        </Tooltip>
      </StatusStack>
    );

  return (
    <StatusStack>
      {status === UploadStatus.ERROR ? (
        <ErrorIcon color="inherit" />
      ) : status === UploadStatus.CANCELLED ? (
        <PauseIcon color="inherit" />
      ) : status === UploadStatus.UPLOADED ? (
        <SuccessCheckIcon />
      ) : null}

      <Typography variant="subtitle1">
        {status === UploadStatus.ERROR
          ? t`Upload of ${
              files.filter(x => x.uploadStatus === UploadStatus.ERROR).length
            } files failed`
          : status === UploadStatus.CANCELLED
          ? t`Upload stopped`
          : status === UploadStatus.UPLOADED
          ? t`Upload finished`
          : null}
      </Typography>

      {status === UploadStatus.ERROR ? (
        <span>
          <Tooltip title={t`Retry`}>
            <IconButton color="inherit" onClick={retry}>
              <RedoIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t`Cancel`}>
            <IconButton color="inherit" onClick={clear}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </span>
      ) : status === UploadStatus.CANCELLED ? (
        <span>
          <Tooltip title={t`Continue`}>
            <IconButton color="inherit" onClick={retry}>
              <PlayIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t`Cancel`}>
            <IconButton color="inherit" onClick={clear}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </span>
      ) : null}
    </StatusStack>
  );
}

const ProgressStack = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 4px;
  flex: 1;

  min-width: 150px;
  max-width: 300px;
`;

const StatusStack = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${p => p.theme.spacing(1)};
  flex: 1;
`;

const ProgressBar = styled(LinearProgress).attrs({ color: 'inherit' })``;

const StyledSnackbarContent = styled(SnackbarContent)`
  background: ${p => p.theme.palette.success.main};

  .MuiSnackbarContent-message {
    flex: 1;
  }
`;
