import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import sanitizeHTML from 'sanitize-html';
import { InputAdornment } from '@mui/material';
import { t } from '@lingui/macro';

import TextField from './TextField';
import IconButton from './IconButton';
import EditorField from './EditorField';
import { EditIcon, RevealIcon } from '~misc/icons';
import Popup from '~sections/Popup';

interface Props
  extends Omit<React.ComponentProps<typeof TextField>, 'onChange'> {
  id: string;
  initialValue?: string;
  onChange: (value: string) => void;
  labelText?: any;
  label?: any;
}

/** TODO: this is basically copypaste from `FormPopupWysiwygField.tsx`, when we no
 * longer have underlined inputs lets remove this and only use the other one
 *
 * @deprecated */
const PopupEditorField = ({
  id,
  initialValue,
  onChange,
  labelText,
  label,
  readOnly,
  ...rest
}: Props) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <TextField
        ref={anchorRef}
        label={label}
        labelText={labelText}
        onClick={() => !rest.disabled && setOpen(true)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <EditButton
                aria-label={readOnly ? t`Show` : t`Edit`}
                disabled={rest.disabled}
              >
                {readOnly ? (
                  <RevealIcon fontSize="small" />
                ) : (
                  <EditIcon fontSize="small" />
                )}
              </EditButton>
            </InputAdornment>
          ),
          readOnly: true,
        }}
        value={sanitizeHTML(initialValue ?? '', {
          allowedTags: [],
        }).replace(/\n/g, '\t\t')}
        {...rest}
      />
      <Popup open={open} setOpen={setOpen} anchorEl={anchorRef.current}>
        <EditorField
          id={id}
          initialValue={initialValue}
          onChange={onChange}
          readOnly={readOnly}
        />
      </Popup>
    </>
  );
};

const EditButton = styled(IconButton)`
  && {
    padding: ${p => p.theme.spacing(1)};
    margin-bottom: ${p => p.theme.spacing(1)};
  }
`;

export default PopupEditorField;
