import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { InputAdornment } from '@mui/material';
import { t } from '@lingui/macro';

import TextField from './TextField';
import IconButton from './IconButton';
import { EditIcon, RevealIcon } from '~misc/icons';
import Popup from '~sections/Popup';

type Props = Omit<React.ComponentProps<typeof TextField>, 'onChange'>;

const PopupMetaField = ({
  id,
  value,
  labelText,
  label,
  readOnly,
  children,
  helperText,
  error,
  ...rest
}: Props) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <TextField
        ref={anchorRef}
        label={label}
        labelText={labelText}
        onClick={() => !rest.disabled && setOpen(true)}
        helperText={helperText}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <EditButton
                aria-label={readOnly ? t`Show` : t`Edit`}
                disabled={rest.disabled}
              >
                {readOnly ? (
                  <RevealIcon fontSize="small" />
                ) : (
                  <EditIcon fontSize="small" />
                )}
              </EditButton>
            </InputAdornment>
          ),
          readOnly: true,
        }}
        value={value}
        error={Boolean(error)}
        {...rest}
      />
      <Popup open={open} setOpen={setOpen} anchorEl={anchorRef.current}>
        <StyledChild>{children}</StyledChild>
      </Popup>
    </>
  );
};

const EditButton = styled(IconButton)`
  && {
    padding: ${p => p.theme.spacing(1)};
    margin-bottom: ${p => p.theme.spacing(1)};
  }
`;

const StyledChild = styled.div`
  && {
    padding: ${p => p.theme.spacing(2)};
  }
`;

export default PopupMetaField;
