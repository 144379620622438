import React from 'react';
import { parseFontStyles, generatePdfData } from '../common/utils';

import { ProductElement, TextElement } from '../common/types';
import FilePicker from '../../common/FilePicker';
import { File } from '~common/content.types';

interface Props {
  element: ProductElement<TextElement>;
  updateElement: (id: string, element: TextElement, origin: 'sidebar') => void;
  readElementOptions: (elementId: string) => {};
  mobileLayout?: boolean;
}

const TextSelector = ({
  element,
  updateElement,
  readElementOptions,
  mobileLayout,
}: Props) => {
  // Common props
  const selectedText = element.element.textUuid;
  const { lock, availableTextUuids } = element.masterElement;

  const onSelectText = (file: File | null) => {
    const el = element.element;
    const text = file?.textContent ?? '';
    const fonts = parseFontStyles(element, el.fontUuid);
    const pdfData = generatePdfData(text, fonts, false);
    updateElement(
      element.id,
      {
        ...el,
        text: { ...el.text, data: text, uuid: file ? file.node.id : -1 }, // uuid of -1 is considered null
        pdfData,
      },
      'sidebar'
    );
  };

  return (
    <FilePicker
      contentType="text"
      selectedFileId={selectedText !== -1 ? selectedText : undefined}
      fileIds={availableTextUuids.map(file => file.uuid)}
      readFilesImpl={() => readElementOptions(element.id)}
      onSelectNull={
        !lock.textSelect && !lock.clearSelection
          ? () => onSelectText(null)
          : undefined
      }
      onSelectFile={!lock.textSelect ? onSelectText : undefined}
      contentStyle={{ height: 'auto' }}
      horizontal={mobileLayout}
    />
  );
};

export default TextSelector;
