import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { Trans, t } from '@lingui/macro';
import styled from 'styled-components';

import FileBrowser from './FileBrowser';
import Thumbnail from './Thumbnail';

import { commonContent, criteriaHash } from '~common/content.model';
import { getFileName } from '~common/content.utils';
import { prevFunc, nextFunc } from '~paging/utils';
import Box from '~layout/Box';
import Row from '~layout/Row';
import TextField from '~inputs/TextField';
import Typography from '~misc/Typography';
import IconButton from '~inputs/IconButton';
import { getLangValue } from '~common/app.utils';
import { ParentItemIcon } from '~misc/icons';
import { useGetFolderQuery } from '~common/content.api';
import { File } from '~common/content.types';
import { useActions } from '~common/utils/hooks.utils';
import Button from '~common/inputs/Button';

const ThumbnailComponent = (props: any) => (
  <Thumbnail
    {...props}
    showCheckbox={false}
    showActions={false}
    thumbnailType="SELECTOR"
  />
);

const mapItems = (
  contentType,
  contents: { items: File[] },
  contentById,
  filter,
  filterBy,
  language,
  defaultLanguage
) => {
  if (!contents || !contents.items) return [];

  const items =
    !filter && !filterBy
      ? contents.items
      : contents.items.filter(item => {
          const b1 =
            !filter ||
            getFileName(item, language, defaultLanguage)
              .toLowerCase()
              .includes(filter.toLowerCase());
          const b2 = !filterBy || filterBy(item);
          return b1 && b2;
        });

  if (contentType !== 'text') return items;
  return items.map(item => ({
    ...item,
    textContent: contentById[item.node.id]?.content,
  }));
};

export interface FileBrowseSearchProps {
  mode?: 'browse' | 'search' | 'browseAndSearch';
  contentType?: 'file' | 'text';
  rootFolderId: string;
  initialFolderId?: string;
  selectedFileId?: string;
  onSelectBrowsedFile: (file: any, index: number) => void;
  onSelectFolder?: (file: any) => void;
  onBrowseOutside?: () => void;
  filterBy?: (file: any) => boolean;
  horizontal?: boolean;
  selectedElementId?: string | null | undefined;
}

const PAGE_SIZE = 100;

const FileBrowseSearch = ({
  mode = 'browseAndSearch',
  contentType = 'file',
  rootFolderId,
  initialFolderId,
  selectedFileId,
  onSelectBrowsedFile,
  onSelectFolder,
  onBrowseOutside,
  filterBy,
  horizontal,
  selectedElementId,
}: FileBrowseSearchProps) => {
  const contentsByCriteriaHash = useSelector(
    state => state.commonContent.contentsByCriteriaHash
  );
  const filesById = useSelector(state => state.commonContent.filesById);
  const contentById = useSelector(state => state.commonContent.contentById);
  const language = useSelector(state => state.app.settings?.language);

  const defaultLanguage = useSelector(
    state => state.app.customer?.defaultLanguage
  );
  const { readContent, readFileContent } = useActions(commonContent.actions);

  const getPartialCriteria = (
    folderId?: string,
    selectedElementId?: string | null
  ) => {
    return {
      sortBy: folderId
        ? 'name'
        : selectedElementId
        ? 'orderByCreated'
        : 'default',
      // userElementId for reducing the search result
      userElementId: selectedElementId,
    };
  };

  const [visiblePageSize, setVisiblePageSize] = useState(PAGE_SIZE);
  const [filter, setFilter] = useState('');
  const [folderId, setFolderId] = useState(initialFolderId);
  const [criteria, setCriteria] = useState({
    selectedIndex: -1,
    selectedId: null,
    page: 0,
    // NOTE: Take into account filter and filterBy when you implement paging!
    // -> Do all filtering in API?
    pageSize: PAGE_SIZE * 100,
    search: '',
    tags: [],
    ...getPartialCriteria(folderId, selectedElementId),
  });

  useEffect(() => {
    setCriteria(criteria => ({
      ...criteria,
      ...getPartialCriteria(folderId, selectedElementId),
    }));
  }, [folderId, selectedElementId]);

  // Current folder id
  const currentFolderId =
    mode === 'search' && (criteria.search || criteria.userElementId)
      ? undefined
      : folderId;

  const { data: folder } = useGetFolderQuery(
    currentFolderId ? { id: currentFolderId } : skipToken
  );

  // Read content from server if criteria has changed
  const hash = criteriaHash(currentFolderId, criteria);
  useEffect(() => {
    readContent(currentFolderId, criteria, '');
  }, [hash]);
  const contents = useMemo(
    () => ({
      ...contentsByCriteriaHash[hash],
      items: contentsByCriteriaHash[hash]?.items?.slice(0, visiblePageSize),
    }),
    [contentsByCriteriaHash[hash], visiblePageSize]
  );

  const selectedItem =
    (selectedFileId && filesById[selectedFileId]?.file) || undefined;

  useEffect(() => {
    if (contents && contents.items && contentType === 'text') {
      contents.items.forEach(item => {
        if (item.mimeGroup === 'text') {
          readFileContent(item.node.id);
        }
      });
    }
  }, [contents]);

  const results = {
    totalCount: contents && contents.items ? Number(contents.totalCount) : -1,
    items: mapItems(
      contentType,
      contents,
      contentById,
      filter,
      filterBy,
      language,
      defaultLanguage
    ),
    status: null,
  };

  const onUpdatePaging = () => {};

  const onSelectItem = (index, eventTargetIndex) => {
    const item =
      index !== -1 ? results.items[index] : results.items[eventTargetIndex];
    const isFolder = item && (item.isFolder || item.isCart);
    if (isFolder) {
      setFolderId(item.node.id);
      setCriteria({ ...criteria, search: '' });
      setVisiblePageSize(PAGE_SIZE);
      if (onSelectFolder) {
        onSelectFolder(item);
      }
    } else {
      onSelectBrowsedFile(item, index);
    }
  };

  const fileBrowserProps = {
    id: 'picker',
    criteria,
    collection: currentFolderId ?? 'search',
    results,
    selectedItem,
    onUpdatePaging,
    onSelectItem,
    onSelectPrevItem: prevFunc(
      criteria.selectedIndex,
      criteria,
      onUpdatePaging,
      onSelectItem
    ),
    onSelectNextItem: nextFunc(
      criteria.selectedIndex,
      criteria,
      onUpdatePaging,
      onSelectItem,
      results
    ),
    ThumbnailComponent,
    SelectedFileComponent: undefined,
    SelectedFolderComponent: undefined,
    itemComponentParams: {
      folder,
      filesById,
      language,
      defaultLanguage,
    },
    showItemOnFullScreen: false,
    horizontal,
  };

  const doSearch = event => {
    if (event.key === 'Enter' || !event.key) {
      setCriteria({
        ...criteria,
        search: event.target.value,
      });
      setVisiblePageSize(PAGE_SIZE);
    }
  };

  const doFilter = event => {
    if (event.key === 'Enter' || !event.key) {
      setFilter(event.target.value);
    }
  };

  const isLoading =
    !results ||
    !results.items ||
    results.totalCount === -1 ||
    results.status === 'loading';

  const showSearch = mode === 'search' || mode === 'browseAndSearch';
  const showFilter = !showSearch && !isLoading;

  if (!folder) return null;

  return (
    <>
      <Box>
        <FilterRow>
          {showSearch && (
            <TextField
              id={`${t`Do search`}-FileBrowser`}
              labelText={t`Do search`}
              fullWidth={true}
              onBlur={doSearch}
              onKeyPress={doSearch}
            />
          )}
          {showFilter && (
            /* NOTE: Filtering on every keypress disabled for now.
             Might be too heavy when there are hundreds of results (HAMK). */
            <TextField
              id={`${t`Filter`}-FileBrowser`}
              labelText={t`Filter`}
              fullWidth={true}
              onBlur={doFilter}
              onKeyPress={doFilter}
            />
          )}
        </FilterRow>
        {folder && !folder.removed && (
          <Row>
            {folder.node.parentId &&
              (folder.node.id !== rootFolderId || onBrowseOutside) && (
                <IconButton
                  size="small"
                  edge="start"
                  onClick={() => {
                    if (folder.node.id === rootFolderId && onBrowseOutside) {
                      onBrowseOutside();
                    } else {
                      setFolderId(folder.node.parentId);
                    }
                  }}
                >
                  <ParentItemIcon />
                </IconButton>
              )}
            <Typography variant="h1">
              {getLangValue(folder.namesByLang) || folder.name}
            </Typography>
          </Row>
        )}
        {!folder && criteria.search && (
          <Row>
            <Typography variant="h1">
              <Trans>Results</Trans>
            </Typography>
          </Row>
        )}
      </Box>
      <FileBrowser {...fileBrowserProps} />
      {Number.parseInt(contents?.totalCount) > visiblePageSize && (
        <LoadMoreButton
          onClick={() => setVisiblePageSize(size => size + PAGE_SIZE)}
        >{t`Load more`}</LoadMoreButton>
      )}
    </>
  );
};

const FilterRow = styled(Row)`
  margin-top: ${p => p.theme.spacing(1)};
`;

const LoadMoreButton = styled(Button)`
  display: block;
  margin: auto;
`;

export default FileBrowseSearch;
