import { File, RemovedFile } from '~common/content.types';

export const getProductId = (file?: File | RemovedFile) => {
  if (!file || file.removed || !file.isUserProduct || !file.propertiesById)
    return null;
  return file.propertiesById['nibo:user-product-id'];
};

export const getCroppedElement = (element: any, { crop, img, isCrop }: any) => {
  const { x: w, y: h } = img;
  const { x1, y1, x2, y2 } = crop;
  return {
    ...element.element,
    crop: {
      h,
      w,
      x1: x1 / w,
      x2: x2 / w,
      y1: y1 / h,
      y2: y2 / h,
      zoom: element.element.location.w / (x2 - x1),
    },
    isCrop: !!isCrop,
  };
};

export const getIsProductPublished = (file: File) =>
  file.propertiesById['nibo:master-product-publish'] === 'true' ||
  file.allowedShareOut;
