import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios, { CancelTokenSource } from 'axios';

import { t } from '@lingui/macro';
import FilePicker from '../../common/FilePicker';
import { ImageElement, Product, ProductElement } from '../common/types';
import { createFiles } from '~common/content.api';
import { File, UploadFile, UploadProgress } from '~common/content.types';
import ProgressStatus from '~common/misc/ProgressStatus';
import { useActions } from '~common/utils/hooks.utils';
import { app } from '~common/app.model';

interface Props {
  element: ProductElement<ImageElement>;
  product: Product;
  updateElement: (id: string, element: ImageElement) => {};
  selectElement: (id: string | null) => {};
  readElementOptions: (elementId: string) => {};
  productNodeId: string;
  rootFolderId?: string;
  mobileLayout?: boolean;
}

const Picture = ({
  element,
  product,
  updateElement,
  readElementOptions,
  productNodeId,
  rootFolderId,
  mobileLayout,
}: Props) => {
  const customerConfig = useSelector(state => state.app.customer?.configById);
  const customDefConvProductProfile = useSelector(
    state => state.app.customer?.customerConvertProductDefault
  );
  const customDefConvProductProfileId = customDefConvProductProfile ?? 0;
  const metaLanguage = useSelector(state => state.products.metaLanguage);
  const showErrorMessage = useActions(app.actions.showErrorMessage);
  const setOpenModal = useActions(app.actions.setOpenModal);
  const userLanguage = useSelector(state => state.app.user?.language) ?? 'fi';

  // Common props
  const selectedFileId = element.element.imageUuid;
  const { lock, availableImageUuids } = element.masterElement;

  const onSelectFile = (file: File | { id: string } | null) => {
    updateElement(element.id, {
      ...element.element,
      isCrop: false, // crop has to be disabled when updating the image
      imageUuid: file ? ('node' in file ? file.node.id : file.id) : -1, // uuid of -1 is considered null
      // if no specific metaLang defined, use the global meta language or the user's language
      // https://trello.com/c/03OnqLFB/315-personointi-k%C3%A4ytt%C3%A4j%C3%A4n-oletuskielen-k%C3%A4ytt%C3%A4minen-linkitetyiss%C3%A4-metatiedoissa
      metaLang: metaLanguage || userLanguage,
    });
  };

  const [progress, setProgress] = useState<number | undefined>();

  const [cancelTokenSource, setCancelTokenSource] =
    useState<CancelTokenSource>();

  // Upload file props
  // TODO: determine from element settings, or set undefined if disabled
  const onUploadFile = async (file: any) => {
    const cancelTokenSource = axios.CancelToken.source();
    setCancelTokenSource(cancelTokenSource);

    await setProgress(0);
    try {
      const response = await createFiles(
        productNodeId,
        file.blob,
        file.name,
        file.type,
        {},
        product.convertProfile || {},
        (progress: UploadProgress) => {
          setProgress(progress.uploaded / progress.size);
        },
        true,
        cancelTokenSource.token
      );
      onSelectFile(response.data);
    } catch (e) {
      showErrorMessage(t`Upload failed`);
    }
    await setProgress(undefined);
  };

  const beforeUpload = (file: UploadFile, handleUpload: () => void) => {
    if (
      product.convertProfile !== undefined ||
      customDefConvProductProfileId > 0
    ) {
      const maxSize = product.uploadMaxSize * 1024 * 1024;
      if (maxSize > 0 && file.blob.size > maxSize) {
        showErrorMessage(t`The file is too large!`);
      } else {
        setOpenModal('COMMON/CONFIRM_MODAL', {
          title: t`Upload with conversion!`,
          confirmText: t`Uploaded material will be converted to appropriate format, continue?`,
          onConfirm: () => {
            handleUpload();
          },
        });
      }
    } else {
      handleUpload();
    }
  };

  return (
    <>
      <FilePicker
        contentType="file"
        selectedFileId={selectedFileId !== -1 ? selectedFileId : undefined}
        onSelectNull={
          !lock.imageSelect && (!lock.clearSelection || product.version >= 2)
            ? () => onSelectFile(null)
            : undefined
        }
        order={customerConfig?.['personalize.image.select.order']}
        // Select file from a list of files
        fileIds={availableImageUuids.map(file => file.uuid)}
        readFilesImpl={() => readElementOptions(element.id)}
        onSelectFile={!lock.imageSelect ? onSelectFile : undefined}
        filterBy={file => !file.isFolder || file.name !== 'lowres'} // issue #411
        // Browse for a file
        rootFolderId={rootFolderId}
        initialFolderId={rootFolderId}
        onSelectBrowsedFile={!lock.search ? onSelectFile : undefined}
        // Upload a file
        onUploadFile={!lock.imageUpload ? onUploadFile : undefined}
        onCancelUpload={() => {
          if (!cancelTokenSource) {
            return;
          }
          setProgress(undefined);
          cancelTokenSource.cancel();
        }}
        uploading={progress !== undefined}
        horizontal={mobileLayout}
        contentStyle={{ height: 'auto' }}
        beforeUpload={beforeUpload}
        selectedElementId={element.id}
      />

      {progress !== undefined ? <ProgressStatus progress={progress} /> : null}
    </>
  );
};

export default Picture;
