import { WorkflowProcessStep } from '~common/workflows/constants';

export const orderProcessSteps = [
  WorkflowProcessStep.CONTENTS,
  WorkflowProcessStep.DETAILS,
  WorkflowProcessStep.SUMMARY,
  WorkflowProcessStep.SENDING,
  WorkflowProcessStep.SENT,
];

export const defaultOrderAmount = 1;
