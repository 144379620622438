export enum WorkflowProcessStep {
  CONTENTS,
  DETAILS,
  SUMMARY,
  SENDING,
  SENT,
}

export const orderWorkflowType = 'TYPE_ORDER_WORKFLOW';
export const commentWorkflowType = 'TYPE_COMMENT_REQUEST_WORKFLOW';

export const imageCommentType = 'COMMENT_TYPE_IMAGE';
