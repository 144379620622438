import React from 'react';
import { XYCoord, useDragLayer, useDragDropManager } from 'react-dnd';
import styled from 'styled-components';

import ContentDragPreview from './ContentDragPreview';

import { snapToGrid } from '~utils/misc.utils';
import { dndTypes } from '~common/misc/drag/constants';

interface Props {
  snapToGrid?: boolean;
}

/**
 * Mostly based on old UI implementation and link below:
 * https://react-dnd.github.io/react-dnd/examples/drag-around/custom-drag-layer
 */
export default function CustomDragLayer(props: Props) {
  const {
    itemType,
    isDragging,
    item,
    itemIds,
    initialOffset,
    currentOffset,
    clientOffset,
    showCustomDragLayer,
    isPreviewImage,
  } = useDragLayer(monitor => ({
    item: monitor.getItem(),
    itemIds: monitor.getItem()?.itemIds ?? [],
    isPreviewImage: monitor.getItem() && monitor.getItem().isPreviewImage,
    itemType: monitor.getItemType(),
    showCustomDragLayer:
      monitor.getItem() && monitor.getItem().showCustomDragLayer,
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
    clientOffset: monitor.getClientOffset(),
  }));

  const manager = useDragDropManager();
  const monitor = manager.getMonitor();
  const registry = manager.getRegistry();
  const targetIds = monitor.getTargetIds();

  const shouldShrink = targetIds.some(
    // Accessing the internal spec that probably shouldn't be used
    id => registry.getTarget(id).spec.options?.shrinkOnHover
  );

  const removableCount = itemIds.length;

  // Ensures that cursor is in preview image
  const centerOffset = clientOffset &&
    currentOffset &&
    item &&
    item.item &&
    (item.item.isFolder || removableCount > 1 || isPreviewImage) && {
      x: (clientOffset.x - currentOffset.x) / 2,
      y: (clientOffset.y - currentOffset.y) / 2,
    };

  if (!isDragging) {
    return null;
  }

  return (
    <DragLayerWrapper>
      <div
        style={getItemStyles(
          initialOffset,
          currentOffset,
          centerOffset,
          props.snapToGrid || false
        )}
      >
        {showCustomDragLayer &&
        Object.values(dndTypes).includes(itemType as any) ? (
          <ContentDragPreview
            item={item.item}
            dragCount={removableCount}
            style={getPreviwStyles(shouldShrink)}
          />
        ) : null}
      </div>
    </DragLayerWrapper>
  );
}

const DragLayerWrapper = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 3000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const getItemStyles = (
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
  centerOffset: XYCoord | null,
  isSnapToGrid: boolean
) => {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  let { x, y } = currentOffset;
  if (centerOffset) {
    const { x: centerX, y: centerY } = centerOffset;
    x += centerX;
    y += centerY;
  }

  if (isSnapToGrid) {
    x -= initialOffset.x;
    y -= initialOffset.y;
    [x, y] = snapToGrid(x, y);
    x += initialOffset.x;
    y += initialOffset.y;
  }

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
};

function getPreviwStyles(shouldShrink: boolean) {
  const scale = shouldShrink ? 0.2 : 1;
  const transform = `scale(${scale})`;
  return {
    transition: 'transform .2s .4s',
    transformOrigin: 'center center',
    transform,
    WebkitTransform: transform,
  };
}
