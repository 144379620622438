import React, { useRef, useState } from 'react';
import { t } from '@lingui/macro';
import styled from 'styled-components';
import { useDrop } from 'react-dnd';
import { Badge } from '@mui/material';

import { useShoppingCart, useShoppingCartContents } from './hooks';
import ShoppingCartPreview from './ShoppingCartPreview';

import IconButton from '~common/inputs/IconButton';
import { ShoppingCartIcon } from '~common/misc/icons';
import Popup from '~common/sections/Popup';
import { getAcceptedDndTypes, useOnNodeDrop } from '~common/misc/drag/utils';
import { combineRefs } from '~common/utils/fn.utils';

function ShoppingCartButton() {
  const { data: cartData } = useShoppingCart();
  const cart = !cartData?.removed ? cartData : undefined;
  const { contents } = useShoppingCartContents();

  const onDrop = useOnNodeDrop(cart);
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: cart ? getAcceptedDndTypes(cart) : [],
    drop: onDrop,
    options: {
      shrinkOnHover: true,
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const [isOpen, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <StyledButton
        color="inherit"
        onClick={() => setOpen(open => !open)}
        aria-label={t`Open shopping cart`}
        ref={combineRefs(anchorRef, drop)}
        $hover={isOver && canDrop}
      >
        <Badge badgeContent={contents?.totalCount}>
          <ShoppingCartIcon />
        </Badge>
      </StyledButton>
      <StyledPopup open={isOpen} setOpen={setOpen} anchorEl={anchorRef.current}>
        <ShoppingCartPreview onClose={() => setOpen(false)} />
      </StyledPopup>
    </>
  );
}

const StyledButton = styled(IconButton)<{ $hover?: boolean }>`
  ${p => p.$hover && `background-color: ${p.theme.palette.action.hover};`}
`;

const StyledPopup = styled(Popup)`
  // Bind the popover to right, might otherwise overflow the screen if width changes
  & > .MuiPaper-root {
    left: unset !important;
    right: ${p => p.theme.spacing(2)};
    overflow: hidden;
    display: flex;
  }
`;

export default ShoppingCartButton;
