import React, { useState } from 'react';
import { Tooltip, ButtonGroup, Button } from '@mui/material';
import styled from 'styled-components';
import { InfoIcon } from '~common/misc/icons';
import { sanitizeHtml } from '~common/utils/misc.utils';

interface InfoElementButton {
  title: string;
  isHovered: boolean;
  isSelected: boolean;
  instructions: string;
  onClick: (e: React.MouseEvent) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const InfoElementButton = ({
  title,
  isHovered,
  isSelected,
  instructions,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: InfoElementButton) => {
  const [showInfo, setShowInfo] = useState(false);
  return (
    <>
      <StyledRow>
        <Tooltip
          title={title}
          enterDelay={1200}
          leaveDelay={100}
          placement="left"
        >
          <ButtonGroup
            variant={isSelected ? 'contained' : 'outlined'}
            color="primary"
            fullWidth
          >
            <ElementButton
              onClick={onClick}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              $hovered={isHovered && !isSelected}
            >
              {title}
            </ElementButton>
            {!!instructions &&
              title.substring(title.indexOf('.') + 2) !== instructions && (
                <InfoButton
                  onClick={e => {
                    e.stopPropagation();
                    setShowInfo(!showInfo);
                  }}
                >
                  <InfoIcon />
                </InfoButton>
              )}
          </ButtonGroup>
        </Tooltip>
      </StyledRow>
      {showInfo && !!instructions && (
        <Instructions
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(instructions),
          }}
        ></Instructions>
      )}
    </>
  );
};

interface ElementButtonProps {
  theme: any;
  $hovered: boolean;
}

const ElementButton = styled(Button)<ElementButtonProps>`
  && {
    flex: 1;
    text-transform: none;
    justify-content: flex-start;
    margin-bottom: 0px;
    min-height: 36px;
    overflow-x: hidden;

    .MuiButton-label {
      display: block;
      text-align: left;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    ${props =>
      props.$hovered &&
      `
      background-color: ${props.theme.palette.primary.lightFade};
    `}
  }
`;

// REFACTOR: uses the same magic number as in MetaFieldInfoButton.tsx
const InfoButton = styled(Button)`
  flex: 0;

  .MuiSvgIcon-root {
    font-size: 1.2em;
  }
`;

const StyledRow = styled.div<{ showBar?: boolean }>`
  display: block;
  position: relative;
  padding: ${p => p.theme.spacing(0.5)} 0;
  width: 100%;
  max-width: 100%;
`;

const Instructions = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding: 0 ${p => p.theme.spacing(0.5)};
  margin-top: ${p => -p.theme.spacing(0.5)};
  margin-bottom: ${p => p.theme.spacing(1)};
`;

export default InfoElementButton;
