import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { t } from '@lingui/macro';
import qs from 'qs';

import { getNonFolderIds } from './utils';

import { useWindowSize } from '~common/utils/layout.utils';
import { File } from '~common/content.types';
import { useSelection } from '~common/utils/hooks.utils';
import { BasicOption } from '~common/navigation/MenuButton';
import { AllowLevel, allowLevel } from '~common/content.utils';
import { EditIcon } from '~common/misc/icons';

type Options = {
  /** defaults to `false` */
  skipFolders?: boolean;
};

/** Returns an `option` to be used for editing content */
export function useEditMultipleOption(
  fileIds: string[],
  options?: Options
): BasicOption {
  const history = useHistory();
  const windowSize = useWindowSize();

  const config = useSelector(state => state.app.customer?.configById) ?? {};
  const filesById = useSelector(state => state.commonContent.filesById);

  const filteredFileIds = options?.skipFolders
    ? getNonFolderIds(fileIds, filesById)
    : fileIds;

  const { selection } = useSelection(new Set(fileIds), files => ({
    hasEditableFiles: files.some(isEditableFile),
    onlyEditableFiles: files.every(isEditableFile),
  }));

  const isMassEditEnabled = config?.['material.upload.multiple'];
  const editAllowed = allowLevel(
    'edit',
    fileIds,
    filesById,
    options?.skipFolders
  );

  return {
    value: 'editMultiple',
    title: t`Edit`,
    icon: <EditIcon fontSize="small" />,
    disabled:
      !selection.onlyEditableFiles || editAllowed === AllowLevel.ForSome,
    hidden:
      editAllowed === AllowLevel.ForNone ||
      !selection.hasEditableFiles ||
      !isMassEditEnabled ||
      windowSize.innerWidth < 780,
    onSelect: () => {
      history.push(
        `/files/editMultiple?${qs.stringify(
          { fileIds: filteredFileIds },
          { arrayFormat: 'repeat' }
        )}`
      );
    },
  };
}

function isEditableFile(file: File | undefined) {
  return file?.userRights?.edit && (file.isFile || file.isMasterProduct);
}
