import React from 'react';
import styled from 'styled-components';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

interface Props {
  children: any;
  open: boolean;
  onOpen: any;
  onClose: any;
  contentStyle?: React.CSSProperties;
}

const Drawer = ({ contentStyle = {}, ...props }: Props) => (
  <SwipeableDrawer {...props} swipeAreaWidth={12}>
    <DrawerContent style={contentStyle}>{props.children}</DrawerContent>
  </SwipeableDrawer>
);

const DrawerContent = styled.div`
  @media print {
    display: none !important;
  }

  width: 240px;
  padding: ${props => props.theme.spacing(3)} 0;
`;

export default Drawer;
