import React from 'react';

const Icon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      {...props}
    >
      <g>
        <circle
          cx="12"
          cy="12"
          r="11"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
        />
        <line
          x2="10"
          transform="translate(7 12)"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
        />
        <line
          y2="10"
          transform="translate(12 7)"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default Icon;
