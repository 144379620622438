import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { FormatListBulleted } from '@mui/icons-material';
import { MenuItem, Menu } from '@mui/material';
import { getElementTypes } from '../common/constants';

import { products } from '../common/model';
import {
  getDescription,
  getElementTitle,
  getGroupTitle,
  useElementsOfCurrentPage,
  useSelectedElement,
} from '../common/utils';

import { ElementSwitcher, showElementSwitcher } from './ElementSwitcher';
import { ProductElement, Element } from '../common/types';
import InfoElementButton from './InfoElementButton';
import Bar from '~sections/Bar';
import Content from '~sections/Content';
import ActionBar from '~sections/ActionBar';
import Typography from '~misc/Typography';
import { BackIcon } from '~misc/icons';
import { useActions } from '~common/utils/hooks.utils';

const ElementsMenu = ({
  hoveredElementId,
  product,
  selectElement,
  hoverElement,
  elements,
  selectedElement,
  elementTypes,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  if (!product?.published) {
    return null;
  }

  return (
    <>
      <Tooltip title={<Trans>Change element</Trans>}>
        <IconButton onClick={handleClick}>
          <FormatListBulleted color="primary" />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {elements.map((element, index) => (
          <StyledMenuItem key={element.id}>
            <InfoElementButton
              title={getElementTitle(element, index, elementTypes)}
              isHovered={element.id === hoveredElementId}
              isSelected={element.id === selectedElement?.id}
              instructions={getDescription(element)}
              onClick={e => {
                e.stopPropagation();
                setAnchorEl(null);
                selectElement(element.id);
              }}
              onMouseEnter={
                hoverElement
                  ? () => hoverElement && hoverElement(element.id)
                  : undefined
              }
              onMouseLeave={
                hoverElement
                  ? () => hoverElement && hoverElement(null)
                  : undefined
              }
            />
          </StyledMenuItem>
        ))}
      </Menu>
    </>
  );
};

const Elements = () => {
  const hoveredElementId = useSelector(
    state => state.products.hoveredElementId
  );
  const product = useSelector(state => state.products.product);
  const selectElement = useActions(products.actions.selectElement);
  const hoverElement = useActions(products.actions.hoverElement);

  const [groupElements, setGroupElements] = useState<ProductElement<Element>[]>(
    []
  );
  const [groupTitle, setGroupTitle] = useState<string>('');

  const hasGroupElements = groupElements.length > 0;

  const language = useSelector(state => state.app.settings?.language);

  const pageElements = useElementsOfCurrentPage();

  const elements = groupElements.length ? groupElements : pageElements;

  const selectedElement = useSelectedElement();
  const showSelectedElement = showElementSwitcher(selectedElement);
  const elementTypes = getElementTypes();

  if (!product?.published) {
    return null;
  }

  return showSelectedElement ? (
    <React.Fragment>
      <Bar padding={1} paddingHoriz={2}>
        <TopActionBar left>
          <Tooltip title={<Trans>Go back</Trans>}>
            <IconButton
              color="primary"
              size="small"
              edge="start"
              onClick={() => selectElement(null)}
            >
              <BackIcon color="primary" />
            </IconButton>
          </Tooltip>
          {selectedElement && (
            <Tooltip
              title={getElementTitle(selectedElement, null, elementTypes, 0)}
            >
              <TitleDiv>
                <Title variant="h2">
                  {getElementTitle(selectedElement, null, elementTypes)}
                </Title>
              </TitleDiv>
            </Tooltip>
          )}
        </TopActionBar>
        <ElementsMenu
          hoveredElementId={hoveredElementId}
          product={product}
          selectElement={selectElement}
          hoverElement={hoverElement}
          elements={elements}
          selectedElement={selectedElement}
          elementTypes={elementTypes}
        />
      </Bar>
      <ElementSwitcher />
      <div />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Bar padding={1} paddingHoriz={2} variant="borderless">
        <TopActionBar left>
          {hasGroupElements && (
            <Tooltip title={<Trans>Go back</Trans>}>
              <IconButton
                color="primary"
                size="small"
                edge="start"
                onClick={() => setGroupElements([])}
              >
                <BackIcon color="primary" />
              </IconButton>
            </Tooltip>
          )}
          <Typography variant="h2">
            {!hasGroupElements && <Trans>Elements</Trans>}
            {hasGroupElements && groupTitle}
          </Typography>
        </TopActionBar>
      </Bar>
      <Content padding={1} paddingHoriz={2} onClick={() => selectElement(null)}>
        {!hasGroupElements &&
          Object.entries(
            elements
              .filter(el => el.masterElement.groupName)
              .reduce(
                (acc, el) => ({
                  ...acc,
                  [el.masterElement.groupName]: [
                    ...(acc[el.masterElement.groupName] ?? []),
                    el,
                  ],
                }),
                {}
              )
          ).map((entry, _index) => (
            <InfoGroupButton
              key={entry[0] ?? ''}
              isHovered={false}
              isSelected={false}
              title={getGroupTitle(entry[0], language ?? 'fi')}
              instructions={''}
              onClick={e => {
                e.stopPropagation();
                setGroupElements(entry[1] as ProductElement<Element>[]);
                setGroupTitle(getGroupTitle(entry[0], language ?? 'fi'));
              }}
            />
          ))}
        {elements
          .filter(el => hasGroupElements || !el.masterElement.groupName)
          .map((el, index) => (
            <InfoElementButton
              key={el.id}
              title={getElementTitle(el, index, elementTypes)}
              isHovered={el.id === hoveredElementId}
              isSelected={el.id === selectedElement?.id}
              instructions={getDescription(el)}
              onClick={e => {
                e.stopPropagation();
                selectElement(el.id);
              }}
              onMouseEnter={
                hoverElement
                  ? () => hoverElement && hoverElement(el.id)
                  : undefined
              }
              onMouseLeave={
                hoverElement
                  ? () => hoverElement && hoverElement(null)
                  : undefined
              }
            />
          ))}
      </Content>
    </React.Fragment>
  );
};

const InfoGroupButton = styled(InfoElementButton)``;

// REFACTOR: copied from workspaces/index.tsx
const TopActionBar = styled(ActionBar)<{ left?: boolean }>`
  display: flex;
  justify-content: ${({ left }) => (left ? 'flex-start' : 'flex-end')};
  align-items: center;
  overflow-x: hidden;
`;

const TitleDiv = styled.div`
  overflow-x: hidden;
`;

// REFACTOR: copied from workspaces/index.tsx
const Title = styled(Typography)`
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  vertical-align: middle;
`;

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export default Elements;
