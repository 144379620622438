// NOTE: deprecated. Use Bar instead.
import styled from 'styled-components';
import PageBar from './PageBar';

const PageBottomBar = styled(PageBar).attrs(props => ({
  padding: props.padding ?? 1,
  paddingHoriz: props.paddingHoriz ?? 3,
}))`
  border-top: 1px solid ${props => props.theme.palette.border.section};
  background-color: ${props => props.theme.palette.common.white};
  gap: ${props => props.theme.spacing(1)};
  flex-wrap: wrap;
`;

export default PageBottomBar;
