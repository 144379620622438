import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Tooltip } from '@mui/material';
import { t } from '@lingui/macro';

import { useIsWorkspaceSaved } from './hooks';
import { WorkspaceActionBar } from './WorkspaceActionBar';

import { useDownloadOption } from '../actionOptions/useDownloadOption';
import { useEditMultipleOption } from '../actionOptions/useEditMultipleOption';
import { useAddToOrderOption } from '../actionOptions/useAddToOrderOption';
import { useCommentRequestOption } from '../actionOptions/useCommentRequestOption';
import { useSendToSynkkaOption } from '../actionOptions/useSendToSynkkaOption';
import { useRemoveFromSynkkaOption } from '../actionOptions/useRemoveFromSynkkaOption';
import { useMagentoOption } from '../actionOptions/useMagentoOption';
import { useCustomerSpecificActionsOptions } from '../actionOptions/useCustomerSpecificActionOptions';
import { useOpenWorkspaceOption } from './actionOptions/useOpenWorkspaceOption';
import { useCreateFolderOption } from './actionOptions/useCreateFolderOption';
import { useCopyWorkspaceOption } from './actionOptions/useCopyWorkspaceOption';
import { useInternalShareOption } from './actionOptions/useInternalShareOption';
import { useShareHistoryOption } from './actionOptions/useShareHistoryOption';
import { useDeleteWorkspaceOption } from './actionOptions/useDeleteWorkspaceOption';
import { useSaveWorkspaceOption } from './actionOptions/useSaveWorkspaceOption';
import { useAddToShoppingCartOption } from '../actionOptions/useAddToShoppingCartOption';
import { useRemoveFromWorkspaceOption } from '../actionOptions/useRemoveFromWorkspaceOption';
import { getNonFolderIds } from '../actionOptions/utils';

import ModalButton from '~navigation/ModalButton';
import { MoreHorizIcon, ShareIcon } from '~misc/icons';
import { useOverflowButtonArrangement } from '~utils/layout.utils';
import { File } from '~common/content.types';
import MenuButton from '~common/navigation/MenuButton';

interface Props {
  isShareDisabled: boolean;
  xsmall?: boolean;
  workspace: File;
}

/** Actions and a menu for interacting with a workspace and its contents
 * at the bottom of the right dock */
export function WorkspaceBottomActions({
  isShareDisabled,
  xsmall,
  workspace,
}: Props) {
  const filesById = useSelector(state => state.commonContent.filesById);
  const checkedContent = useSelector(
    state => state.workspaces.checkedWorkspaceContent
  );

  const isWorkspaceSaved = useIsWorkspaceSaved(workspace);

  // Options that we'd prefer to see as discrete buttons
  const shareOption = {
    value: 'share',
    title: t`Share`,
    icon: <ShareIcon />,
    modalType: 'WORKSPACE/SHARE',
    modalProps: {
      workspaceId: workspace.node.id,
      isWorkspaceSaved,
    },
    disabled: isShareDisabled,
  };

  const downloadOption = useDownloadOption(checkedContent, {
    isWorkspace: true,
  });
  const customerSpecificActionsOptions =
    useCustomerSpecificActionsOptions(checkedContent);

  const buttons = [
    {
      element: (
        <ModalButton
          shareButton
          size="small"
          color="secondary"
          {...shareOption}
        />
      ),
      option: shareOption,
    },
    {
      element: (
        <ModalButton
          startIcon={downloadOption.icon}
          size="small"
          variant="contained"
          color="secondary"
          {...downloadOption}
        >
          {downloadOption.title}
        </ModalButton>
      ),
      shortElement: (
        <Tooltip title={downloadOption.title ?? false}>
          <ModalButton
            color="secondary"
            variant="contained"
            size="small"
            aria-label={downloadOption.title}
            {...downloadOption}
          >
            {downloadOption.icon}
          </ModalButton>
        </Tooltip>
      ),
      option: downloadOption,
    },
  ];

  const { visibleButtons, excessOptions } =
    useOverflowButtonArrangement(buttons);

  const checkedContentWitoutFolders = getNonFolderIds(
    checkedContent,
    filesById
  );

  // Options that should be behind a menu anyways
  const editMultipleOption = useEditMultipleOption(checkedContent, {
    skipFolders: true,
  });
  const addToOrderOption = useAddToOrderOption(checkedContent, {
    skipFolders: true,
  });
  const commentRequestOption = useCommentRequestOption(checkedContent, {
    skipFolders: true,
  });
  const sendToSynkkaOption = useSendToSynkkaOption(checkedContent);
  const removeFromSynkkaOption = useRemoveFromSynkkaOption(checkedContent);
  const magentoOption = useMagentoOption(checkedContent, { skipFolders: true });
  const addToShoppingCartOption = useAddToShoppingCartOption(
    checkedContentWitoutFolders
  );
  const removeFromWorkspaceOption = useRemoveFromWorkspaceOption(
    workspace.node.id,
    checkedContent
  );

  const workspaceOptions = [
    addToShoppingCartOption,
    addToOrderOption,
    commentRequestOption,
    ...customerSpecificActionsOptions,
    sendToSynkkaOption,
    removeFromSynkkaOption,
    magentoOption,
    editMultipleOption,
    removeFromWorkspaceOption,
  ];

  const openWorkspaceOption = useOpenWorkspaceOption(workspace, {
    isInSidebar: true,
  });
  const createFolderOption = useCreateFolderOption(workspace);
  const copyWorkspaceOption = useCopyWorkspaceOption(workspace);
  const internalShareOption = useInternalShareOption(workspace);
  const shareHistoryOption = useShareHistoryOption(workspace);
  const deleteWorkspaceOption = useDeleteWorkspaceOption(workspace);
  const saveWorkspaceOption = useSaveWorkspaceOption(workspace);

  const workspaceCheckedContentOptions = [
    openWorkspaceOption,
    createFolderOption,
    copyWorkspaceOption,
    internalShareOption,
    shareHistoryOption,
    deleteWorkspaceOption,
    saveWorkspaceOption,
  ];

  const options = [
    ...(excessOptions.length > 0
      ? [
          ...excessOptions,
          {
            value: 'excessDivider',
            type: 'divider',
          },
        ]
      : []),

    ...workspaceOptions,
    {
      value: 'workspaceSpecificDivider',
      type: 'divider',
    },
    ...workspaceCheckedContentOptions,
  ];

  return (
    <BottomActionBar>
      {visibleButtons}

      <MenuButton
        variant="outlined"
        size="small"
        xsmall={xsmall}
        aria-label={t`More actions`}
        options={options}
      >
        <MoreHorizIcon />
      </MenuButton>
    </BottomActionBar>
  );
}

const BottomActionBar = styled(WorkspaceActionBar)`
  justify-content: flex-start;
  align-items: stretch;
  max-width: 100%;
  flex: unset;
`;
