import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { t } from '@lingui/macro';
import { IntlProvider } from 'react-intl';
import { mapKeys } from 'lodash';
import Axios from 'axios';

import { useUploadToWorkspace } from '../../workspaces/hooks';
import { FileData } from './types';
import Crop from './Crop';
import commonTranslations from '../../common/messages';
import fileTranslations from './translations';
import fileContentApi from './fileContent.api';
import { app } from '~common/app.model';
import { getFileExtension } from '~common/content.utils';
import { useActions } from '~common/utils/hooks.utils';
import { File } from '~common/content.types';
import { useGetFolderQuery } from '~common/content.api';

interface Props
  extends Pick<
    React.ComponentProps<typeof Crop>,
    'onCrop' | 'allowOverflow' | 'use'
  > {
  file: File;
  mode?: 'download' | 'crop' | 'addMaterial';
  existingFiles?: any[];
}

const CropWrapper = ({
  file,
  existingFiles,
  mode = 'download',
  ...props
}: Props) => {
  const customerId = useSelector(state => state.app.customer?.id);
  const userLang = useSelector(state => state.app.settings?.language) ?? 'fi';
  const userRights = useSelector(state => state.app.userRights);

  const closeModal = useActions(app.actions.closeModal);
  const setOpenSnackbar = useActions(app.actions.setOpenSnackbar);

  const { data: parentData } = useGetFolderQuery({ id: file.node.parentId });
  const parent = parentData && !parentData.removed ? parentData : undefined;

  const sidebarWorkspaceId = useSelector(
    state => state.workspaces.currentWorkspaceId
  );

  const cropFileNameModelId = useSelector(
    state => state.app.customer?.downloadConfig?.cropFileNameModelId
  );
  const lang = userLang;
  const messages = {
    ...commonTranslations[lang](),
    ...mapKeys(fileTranslations[lang](), (value, key: string) => `file.${key}`),
  };

  const history = useHistory();

  const getFileName = (file: File, data: FileData) => {
    const orgName = file.name.split('.').slice(0, -1).join('.');
    return `${orgName}_${data.size.x}x${data.size.y}`;
  };

  const onSuccess = () =>
    setOpenSnackbar('WORKSPACE/CROP_SUCCESS', {
      type: 'success',
      message: t`Cropped item created`,
    });
  const onUploadDone = () => {
    onSuccess();
    closeModal();
  };

  const uploadToSidebarWorkspace = useUploadToWorkspace(sidebarWorkspaceId, {
    onUploadDone,
  });
  const uploadToParentWorkspace = useUploadToWorkspace(file.node.parentId, {
    onUploadDone,
  });

  const getUploadFile = async (data: FileData) => {
    const req = await Axios.get(
      fileContentApi.getLink({
        ...data,
        isDownload: true,
        maskid: undefined,
      }),
      {
        responseType: 'arraybuffer',
        withCredentials: true,
      }
    );
    const mimetype = (
      req.headers['content-type'] ?? file?.propertiesById['nibo:mime-type']
    )?.split('/');
    const name = getFileName(file, data);
    const ext = getFileExtension(file);

    const existing = existingFiles?.filter(f => f.orgName === name) ?? [];
    const lastNameOffset = existing.reduce(
      (max, cur) => (cur.nameOffset > max ? cur.nameOffset : max),
      -1
    );
    const nameOffset = lastNameOffset + 1;
    const cropName =
      nameOffset === 0 ? `${name}.${ext}` : `${name}_${nameOffset}.${ext}`;

    const blob = new globalThis.File([req.data], cropName);

    return {
      blob,
      preview: URL.createObjectURL(blob),
      name: cropName,
      orgName: name,
      nameOffset,
      type: mimetype[0],
      subtype: mimetype[1],
      metaById: file.metaById,
    };
  };

  const addOptions = [
    {
      value: 'folder' as const,
      title: t`Same folder`,
      hidden:
        !parent ||
        !parent.isFolder ||
        !parent.userRights?.addFiles ||
        parent.node.inCart ||
        !userRights?.MATERIAL_MANAGE ||
        !userRights?.MATERIAL_MULTIPLE_FILE_UPLOAD ||
        mode === 'crop',
    },
    {
      value: 'workspace-center' as const,
      title: t`Same workspace`,
      hidden:
        !parent ||
        !(parent.node.inCart || parent.isCart) ||
        !uploadToParentWorkspace.allowed ||
        !userRights?.CARTS_MANAGE ||
        mode === 'crop',
    },
    {
      value: 'workspace-side' as const,
      title: t`Active workspace`,
      hidden:
        !uploadToSidebarWorkspace.allowed ||
        !userRights?.CARTS_MANAGE ||
        mode === 'crop',
    },
  ];

  const onAddMaterial = async (
    value: typeof addOptions[number]['value'],
    data: FileData
  ) => {
    switch (value) {
      case 'folder':
        return onUploadToFolder(data);
      case 'workspace-center':
        return onUploadToWorkspace(data, 'center');
      case 'workspace-side':
        return onUploadToWorkspace(data, 'side');
    }
  };

  const onUploadToFolder = async (data: FileData) => {
    const uploadFile = await getUploadFile(data);

    const newFiles = [...(existingFiles ?? []), uploadFile];

    const targetPath = `/folders/${file.node.parentId}/add`;
    // This is done like this to prevent history.goBack() from scrolling through the files
    // in the add files -view
    const method =
      history.location.pathname === targetPath ? history.replace : history.push;
    method(targetPath, {
      files: newFiles,
      croppedFile: file,
    });

    onSuccess();
    closeModal();
  };

  const onUploadToWorkspace = async (
    data: FileData,
    target: 'side' | 'center'
  ) => {
    const uploadToWorkspace =
      target === 'side' ? uploadToSidebarWorkspace : uploadToParentWorkspace;
    if (!uploadToWorkspace.allowed) return;
    const uploadFile = await getUploadFile(data);

    await uploadToWorkspace.uploadFile(uploadFile.blob);
  };

  return (
    <IntlProvider locale={lang} messages={messages}>
      <Crop
        closeModal={closeModal}
        nodeId={file.node.id}
        customerId={customerId ?? ''}
        hideSidebar={mode === 'crop'}
        download={mode === 'download' || mode === 'addMaterial'}
        addOptions={addOptions}
        onAddMaterial={onAddMaterial}
        onDownload={() => onSuccess()}
        hideDownload={mode === 'addMaterial'}
        nameConfigId={cropFileNameModelId}
        {...props}
      />
    </IntlProvider>
  );
};

export const ProductCropWrapper = ({
  file,
  proportions,
  onCrop,
}: {
  file: File;
  proportions: { x: number; y: number };
  onCrop: React.ComponentProps<typeof CropWrapper>['onCrop'];
}) => {
  return (
    <CropWrapper
      mode="crop"
      file={file}
      use={{ proportions, contentType: 'original', id: -1 }}
      onCrop={onCrop}
      allowOverflow
    />
  );
};

export default CropWrapper;
