import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { t } from '@lingui/macro';

import { getNonFolderIds } from './utils';

import { useActions } from '~common/utils/hooks.utils';
import { BasicOption } from '~common/navigation/MenuButton';
import { AllowLevel, allowLevel } from '~common/content.utils';
import { SendIcon } from '~common/misc/icons';
import { app } from '~common/app.model';

type Options = {
  /** defaults to `false` */
  skipFolders?: boolean;
  shoppingCartOrder?: boolean;
};

/** Returns an `option` to be used for adding content to order */
export function useAddToOrderOption(
  fileIds: string[],
  options?: Options
): BasicOption {
  const history = useHistory();
  const filesById = useSelector(state => state.commonContent.filesById);
  const startOrder = useActions(app.actions.startOrder);

  const filteredFileIds = options?.skipFolders
    ? getNonFolderIds(fileIds, filesById)
    : fileIds;

  const addToOrderAllowed = allowLevel(
    'order',
    fileIds,
    filesById,
    options?.skipFolders
  );

  return {
    value: 'addToOrder',
    title: t`Place an order`,
    icon: <SendIcon fontSize="small" />,
    disabled:
      addToOrderAllowed === AllowLevel.ForSome || !filteredFileIds.length,
    hidden: addToOrderAllowed === AllowLevel.ForNone,
    onSelect: () => {
      startOrder(new Set(filteredFileIds), options?.shoppingCartOrder ?? false);
      history.push('/orders/add');
    },
  };
}
