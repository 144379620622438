import { ThunkAction } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';
import { Action } from 'redux';
import {
  select as selectEffect,
  call as callEffect,
  putResolve as putResolveEffect,
} from 'redux-saga/effects';

/** Typesafe wrapper for 'redux-saga/effects' select
 *
 * @example
 * // Note the yield* to get the proper types inferred
 * const language = yield* select(state => state.app.settings?.language);
 */
export function* select<T>(selector: (state: DefaultRootState) => T) {
  const result = yield selectEffect(selector);
  return result as T;
}

type Awaited<T> = T extends PromiseLike<infer U> ? U : T;
/** Typesafe wrapper for 'redux-saga/effects' call
 *
 * @example
 * // Note the yield* to get the proper types inferred
 * const response = yield* call(api.doStuff, { params });
 */
export function* call<T, Fn extends (...args: any[]) => T>(
  fn: Fn,
  ...args: Parameters<Fn>
) {
  const result = yield callEffect(fn, ...args);
  return result as Awaited<ReturnType<Fn>>;
}

/** Typesafe wrapper for 'redux-saga/effects' putResolve
 *
 * Can be used to inititate RTK queries in sagas
 *
 * @example
 * // Note the yield* to get the proper types inferred
 * const { data } = yield* putResolve(getStuff.initiate(params));
 */
export function* putResolve<
  ReturnType,
  State = any,
  ExtraThunkArg = any,
  BasicAction extends Action = Action
>(action: ThunkAction<ReturnType, State, ExtraThunkArg, BasicAction>) {
  const result = yield putResolveEffect(action);
  return result as Awaited<ReturnType>;
}
