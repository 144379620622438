import React from 'react';

const Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
    >
      <g transform="translate(0.5 0.5)">
        <rect
          data-name="Rectangle 6"
          width="18"
          height="18"
          rx="2"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          data-name="Line 10"
          y2="8"
          transform="translate(9 5)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          data-name="Line 11"
          x2="8"
          transform="translate(5 9)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default Icon;
