/** The id of the tag used for searching direct children of a folder */
export const ABSOLUTE_FOLDER_TAG_ID = 'parent_id_ss';

/** Suggestion IDs that should be added as tags to a search */
export const TAGGABLE_SUGGESTION_IDS = ['dirCateg', 'emails'];

/** Suggestion IDs must contain one of these strings in order to be
 * shown to the user as an option */
export const SUGGESTION_IDS = [
  'materialname',
  'foldername',
  'cartname',
  'othername',
  ...TAGGABLE_SUGGESTION_IDS,
];
