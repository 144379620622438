import React from 'react';
import { useSelector } from 'react-redux';
import { Trans, t } from '@lingui/macro';
import styled from 'styled-components';

import { comments } from '../../common/model';
import { Commenter } from '../../common/types';

import PageItem from './PageItem';
import PageBar from '~sections/PageBar';
import Typography from '~misc/Typography';
import TabContent from '~sections/TabContent';
import config from '~common/config';
import { sanitizeHtml } from '~common/utils/misc.utils';
import DropDown from '~inputs/DropDown';
import { useActions } from '~common/utils/hooks.utils';
import { getShareKeyParameter } from '~common/content.api';

const CommentRequestPages = () => {
  const pagesById = useSelector(state => state.comments.pagesById);
  const selectedPageId = useSelector(state => state.comments.selectedPageId);
  const commentRequest = useSelector(state => state.comments.commentRequest);
  const share = useSelector(state => state.app.share);
  const user = useSelector(state => state.app.user) || undefined;

  const { selectPage, showCommentRequest, approvePage } = useActions(
    comments.actions
  );
  const currentCommenter = useSelector(state =>
    comments.selector.getCurrentCommenter(user, state)
  );

  const handleEditChange = (approved: boolean, pageNumber: string) => {
    if ((pageNumber && currentCommenter) || !!share) {
      approvePage(
        Number(pageNumber) - 1,
        approved,
        currentCommenter?.userId || ''
      );
    }
  };

  return (
    <TabContent>
      {commentRequest && (
        <CommentRequestInfoWrapper>
          <Typography variant="h1">
            <Trans>Comment</Trans>: {commentRequest.title}
          </Typography>
          <DescriptionText
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(commentRequest.name),
            }}
          />
          {commentRequest.versions && (
            <div style={{ whiteSpace: 'nowrap' }}>
              <StyledDropDown
                labelText={t`Comment round`}
                id="comment request version"
                value={commentRequest.id}
                onChange={event => showCommentRequest(event.target.value)}
                showEmpty={false}
                readOnly={!!share}
                options={Object.keys(commentRequest.versions).map(key => {
                  return {
                    name: key,
                    value: commentRequest.versions
                      ? commentRequest.versions[key]
                      : commentRequest.id,
                  };
                })}
              />
            </div>
          )}
        </CommentRequestInfoWrapper>
      )}
      <PageBar padding={2} style={{ position: 'relative' }}>
        <Typography variant="h2">
          <Trans>Pages</Trans>
        </Typography>
      </PageBar>
      {Object.values(pagesById).map((page, index) => {
        const commenter: Commenter | undefined =
          page.commenters &&
          page.commenters.find((commenter: Commenter) =>
            commenter.isGuest
              ? commenter.isCurrentUser
              : commenter.userId === user?.id
          );
        return (
          page && (
            <PageItem
              key={page.index}
              thumbnailUrl={
                commentRequest
                  ? `${config.url}/api/v1/files/${
                      page.nodeId
                    }/contents/thumbnail?page=${
                      page.page
                    }${getShareKeyParameter('&')}`
                  : ''
              }
              handleEditChange={handleEditChange}
              pageNumber={(index + 1).toString()}
              selected={selectedPageId === page.index}
              onclick={() => {
                selectedPageId !== page.index && selectPage(page.index);
              }}
              approved={
                !!commenter && commenter.status === 'PAGE_STATUS_APPROVED'
              }
              disapproved={
                !!commenter && commenter.status === 'PAGE_STATUS_DISAPPROVED'
              }
            />
          )
        );
      })}
    </TabContent>
  );
};

const CommentRequestInfoWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(2)};
  padding-bottom: 0;
  > * {
    padding-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;

const DescriptionText = styled(Typography)`
  white-space: pre-line;
`;

const StyledDropDown = styled(DropDown)`
  display: block;
`;

export default CommentRequestPages;
