import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';

import Root from './Root';
import errorReporting from '~utils/reporting.setup';

errorReporting.setup();

// Where to mount on page
const appElement = document.getElementById('app');

ReactDOM.render(<Root />, appElement);
