import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import deepExtend from 'deep-extend';
import { defaultsDeep } from 'lodash';
import PropTypes from 'prop-types';

/* eslint-disable react/no-find-dom-node */
class Cropper extends Component {
  constructor(props) {
    super(props);
    const { originX, originY, width, height, fixedRatio, ratio, styles, src } =
      props;

    this.state = {
      // image and clone image src
      src,
      // background image width
      imgWidth: '100%',
      // background image height
      imgHeight: 'auto',
      // ratio of background image's natural size to actual size
      imgScale: 1,
      // cropper width, drag trigger changing
      frameWidth4Style: width,
      // cropper height, drag trigger changing
      frameHeight4Style: fixedRatio ? width / ratio : height,
      // cropper height, drag trigger changing
      toImgTop4Style: 0,
      toImgLeft4Style: 0,
      // cropper original position(x axis), accroding to image left
      originX,
      // cropper original position(y axis), accroding to image top
      originY,
      // dragging start, position's pageX and pageY
      startPageX: 0,
      startPageY: 0,
      // frame width, change only dragging stop
      frameWidth: width,
      // frame height, change only dragging stop
      frameHeight: fixedRatio ? width / ratio : height,
      dragging: false,
      maxLeft: 0,
      maxTop: 0,
      action: null,
      imgLoaded: false,
      styles: defaultsDeep({}, styles, defaultStyles),
    };
  }

  // initialize style, component did mount or component updated.
  initStyles() {
    const container = findDOMNode(this.container);
    this.setState(
      {
        imgWidth: container.offsetWidth,
      },
      () => {
        // calc frame width height
        const { disabled, allowOverflow } = this.props;
        let { originX, originY } = this.state;

        if (disabled) return;

        const { imgWidth, imgHeight } = this.state;
        const { frameWidth, frameHeight } = this.state;

        const maxLeft = imgWidth - frameWidth;
        const maxTop = imgHeight - frameHeight;

        if (!allowOverflow) {
          if (originX + frameWidth >= imgWidth) {
            originX = imgWidth - frameWidth;
            this.setState({
              originX,
            });
          }
          if (originY + frameHeight >= imgHeight) {
            originY = imgHeight - frameHeight;
            this.setState({
              originY,
            });
          }
        }

        this.setState({
          maxLeft,
          maxTop,
        });
        // calc clone position
        this.calcPosition(frameWidth, frameHeight, originX, originY, () => {
          const {
            frameWidth4Style,
            frameHeight4Style,
            toImgTop4Style,
            toImgLeft4Style,
          } = this.state;

          this.setState({
            frameWidth: frameWidth4Style,
            frameHeight: frameHeight4Style,
            originX: toImgLeft4Style,
            originY: toImgTop4Style,
          });
        });
      }
    );
  }

  componentDidMount() {
    // event
    document.addEventListener('mousemove', this.handleDrag.bind(this));
    document.addEventListener('touchmove', this.handleDrag.bind(this), {
      passive: false,
    });
    document.addEventListener('mouseup', this.handleDragStop.bind(this));
    document.addEventListener('touchend', this.handleDragStop.bind(this));
    window.addEventListener('resize', this.imgGetSizeBeforeLoad.bind(this));
    this.imgGetSizeBeforeLoad();
  }

  componentWillUnmount() {
    // remove event
    document.removeEventListener('mousemove', this.handleDrag.bind(this));
    document.removeEventListener('touchmove', this.handleDrag.bind(this));
    document.removeEventListener('mouseup', this.handleDragStop.bind(this));
    document.removeEventListener('touchend', this.handleDragStop.bind(this));
    window.removeEventListener('resize', this.imgGetSizeBeforeLoad.bind(this));
  }

  // props change to update frame
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    const { width, height, originX, originY } = this.props;
    const { imgScale } = this.state;

    // img src changed
    if (this.props.src !== newProps.src) {
      return this.setState(
        {
          src: newProps.src,
        },
        this.imgGetSizeBeforeLoad
      );
    }

    if (
      width !== newProps.width ||
      height !== newProps.height ||
      originX !== newProps.originX ||
      originY !== newProps.originY
    ) {
      // update frame
      this.setState(
        {
          frameWidth: newProps.width / imgScale,
          frameHeight: newProps.height / imgScale,
          originX: newProps.originX / imgScale,
          originY: newProps.originY / imgScale,
        },
        () => this.initStyles()
      );
    }
  }

  // image onloaded hook
  imgOnLoad() {
    if (this.props.onImgLoad) {
      this.props.onImgLoad();
    }
  }

  // adjust image height when image size scaleing change, also initialize styles
  imgGetSizeBeforeLoad() {
    // trick way to get natural width of image after component did mount
    setTimeout(() => {
      const img = findDOMNode(this.img);
      if (img && img.naturalWidth) {
        // image scaleing
        const imgHeight = parseInt(
          (img.offsetWidth / img.naturalWidth) * img.naturalHeight
        );
        const imgScale = img.naturalWidth / img.offsetWidth;
        // resize imgHeight
        this.setState(
          {
            imgScale,
            imgHeight,
            imgLoaded: true,
            originX: Math.round(this.props.originX / imgScale),
            originY: Math.round(this.props.originY / imgScale),
            frameWidth: Math.round(this.props.width / imgScale),
            frameHeight: Math.round(this.props.height / imgScale),
          },
          this.initStyles
        );
        // before image loaded hook
        if (this.props.beforeImgLoad) {
          this.props.beforeImgLoad();
        }
      } else if (img) {
        // catch if image natural width is 0
        this.imgGetSizeBeforeLoad();
      }
    }, 100);
  }

  // frame width, frame height, position left, position top
  calcPosition(width, height, left, top, callback) {
    const { imgWidth, imgHeight } = this.state;
    const { ratio, fixedRatio, allowOverflow } = this.props;
    // width < 0 or height < 0, frame invalid
    if (width < 0 || height < 0) return false;
    if (!allowOverflow) {
      // if ratio is fixed
      if (fixedRatio) {
        // adjust by width
        if (width / imgWidth > height / imgHeight) {
          if (width > imgWidth) {
            width = imgWidth;
            left = 0;
            height = width / ratio;
          }
        } else {
          // adjust by height
          if (height > imgHeight) {
            height = imgHeight;
            top = 0;
            width = height * ratio;
          }
        }
      }
      // frame width plus offset left, larger than img's width
      if (width + left > imgWidth) {
        if (fixedRatio) {
          // if fixed ratio, adjust left with width
          left = imgWidth - width;
        } else {
          // resize width with left
          width = width - (width + left - imgWidth);
        }
      }
      // frame heigth plust offset top, larger than img's height
      if (height + top > imgHeight) {
        if (fixedRatio) {
          // if fixed ratio, adjust top with height
          top = imgHeight - height;
        } else {
          // resize height with top
          height = height - (height + top - imgHeight);
        }
      }
      // left is invalid
      if (left < 0) {
        left = 0;
      }
      // top is invalid
      if (top < 0) {
        top = 0;
      }
      // if frame width larger than img width
      if (width > imgWidth) {
        width = imgWidth;
      }
      // if frame height larger than img height
      if (height > imgHeight) {
        height = imgHeight;
      }
    }
    this.setState(
      {
        toImgLeft4Style: left,
        toImgTop4Style: top,
        frameWidth4Style: width,
        frameHeight4Style: height,
      },
      () => {
        if (callback) callback();
      }
    );
  }

  // create a new frame, and drag, so frame width and height is became larger.
  createNewFrame(e) {
    if (this.state.dragging) {
      // click or touch event
      const { pageX, pageY } = e.pageX ? e : e.targetTouches[0];

      const { ratio, fixedRatio } = this.props;

      const {
        frameWidth,
        frameHeight,
        startPageX,
        startPageY,
        originX,
        originY,
      } = this.state;
      // click or touch point's offset from source image top
      const _x = pageX - startPageX;
      const _y = pageY - startPageY;

      // frame new width, height, left, top
      const _width = frameWidth + Math.abs(_x);
      const _height = fixedRatio
        ? (frameWidth + Math.abs(_x)) / ratio
        : frameHeight + Math.abs(_y);
      let _left = originX;
      let _top = originY;

      if (_y < 0) {
        // drag and resize to top, top changing
        _top = fixedRatio
          ? originY - Math.abs(_x) / ratio
          : originY - Math.abs(_y);
      }

      if (_x < 0) {
        // drag and resize, go to left, left changing
        _left = originX + _x;
      }
      // calc position
      return this.calcPosition(_width, _height, _left, _top);
    }
  }

  // frame move handler
  frameMove(e) {
    const {
      originX,
      originY,
      startPageX,
      startPageY,
      frameWidth,
      frameHeight,
      maxLeft,
      maxTop,
    } = this.state;
    const { allowOverflow } = this.props;

    const { pageX, pageY } = e.pageX ? e : e.targetTouches[0];

    let _x = pageX - startPageX + originX;
    let _y = pageY - startPageY + originY;
    if (pageX < 0 || pageY < 0) return false;

    if (!allowOverflow) {
      if (_x > maxLeft) _x = maxLeft;
      if (_y > maxTop) _y = maxTop;
    }
    // frame width, frame height not change, top and left changing
    this.calcPosition(frameWidth, frameHeight, _x, _y);
  }

  // drag dot to different direction
  frameDotMove(dir, e) {
    const { pageX, pageY } = e.pageX ? e : e.targetTouches[0];

    const { ratio, fixedRatio, allowOverflow } = this.props;

    const {
      startPageX,
      startPageY,
      originX,
      originY,
      frameWidth4Style,
      frameHeight4Style,
      frameWidth,
      frameHeight,
      imgWidth,
      imgHeight,
    } = this.state;

    if (pageY !== 0 && pageX !== 0) {
      // current drag position offset x and y to first drag start position
      const _x = pageX - startPageX;
      const _y = pageY - startPageY;

      let _width = 0;
      let _height = 0;
      let _top = 0;
      let _left = 0;
      // just calc width, height, left, top in each direction
      switch (dir) {
        case 'ne':
          _width = frameWidth + _x;
          _height = fixedRatio ? _width / ratio : frameHeight - _y;
          _left = originX;
          _top = fixedRatio ? originY - _x / ratio : originY + _y;
          break;
        case 'e':
          _width = frameWidth + _x;
          _height = fixedRatio ? _width / ratio : frameHeight;
          _left = originX;
          _top = fixedRatio ? originY - (_x / ratio) * 0.5 : originY;
          break;
        case 'se':
          _width = frameWidth + _x;
          _height = fixedRatio ? _width / ratio : frameHeight + _y;
          _left = originX;
          _top = originY;
          break;
        case 'n':
          _height = frameHeight - _y;
          _width = fixedRatio ? _height * ratio : frameWidth;
          _left = fixedRatio ? originX + _y * ratio * 0.5 : originX;
          _top = originY + _y;
          break;
        case 'nw':
          _width = frameWidth - _x;
          _height = fixedRatio ? _width / ratio : frameHeight - _y;
          _left = originX + _x;
          _top = fixedRatio ? originY + _x / ratio : originY + _y;
          break;
        case 'w':
          _width = frameWidth - _x;
          _height = fixedRatio ? _width / ratio : frameHeight;
          _left = originX + _x;
          _top = fixedRatio ? originY + (_x / ratio) * 0.5 : originY;
          break;
        case 'sw':
          _width = frameWidth - _x;
          _height = fixedRatio ? _width / ratio : frameHeight + _y;
          _left = originX + _x;
          _top = originY;
          break;
        case 's':
          _height = frameHeight + _y;
          _width = fixedRatio ? _height * ratio : frameWidth;
          _left = fixedRatio ? originX - _y * ratio * 0.5 : originX;
          _top = originY;
          break;
        default:
          break;
      }

      if (!allowOverflow) {
        if (_width > imgWidth || _height > imgHeight) {
          if (frameWidth4Style >= imgWidth || frameHeight4Style >= imgHeight) {
            return false;
          }
        }
      }

      return this.calcPosition(_width, _height, _left, _top);
    }
  }

  // judge whether to create new frame, frame or frame dot move acroding to action
  handleDrag(e) {
    if (this.state.dragging) {
      e.preventDefault();
      const { action } = this.state;

      if (!action) return this.createNewFrame(e);
      if (action === 'move') return this.frameMove(e);
      this.frameDotMove(action, e);
    }
  }

  // starting dragging
  handleDragStart(e) {
    const { allowNewSelection } = this.props;

    const action = e.target.getAttribute('data-action')
      ? e.target.getAttribute('data-action')
      : e.target.parentNode.getAttribute('data-action');

    const { pageX, pageY } = e.pageX ? e : e.targetTouches[0];

    // if drag or move or allow new selection, change startPageX, startPageY, dragging state
    if (action || allowNewSelection) {
      e.preventDefault();
      // drag start, set startPageX, startPageY for dragging start point
      this.setState({
        startPageX: pageX,
        startPageY: pageY,
        dragging: true,
        action,
      });
    }
    // if no action and allowNewSelection, then create a new frame
    if (!action && allowNewSelection) {
      const container = findDOMNode(this.container);
      const { offsetLeft, offsetTop } = container;

      this.setState(
        {
          // set offset left and top of new frame
          originX: pageX - offsetLeft,
          originY: pageY - offsetTop,
          frameWidth: 2,
          frameHeight: 2,
        },
        () => this.calcPosition(2, 2, pageX - offsetLeft, pageY - offsetTop)
      );
    }
  }

  // crop image
  crop() {
    const img = findDOMNode(this.img);
    const canvas = document.createElement('canvas');
    const { x, y, width, height } = this.values().original;

    canvas.width = width;
    canvas.height = height;
    canvas
      .getContext('2d')
      .drawImage(img, x, y, width, height, 0, 0, width, height);
    return canvas.toDataURL();
  }

  // get current values
  values() {
    const img = findDOMNode(this.img);
    const {
      frameWidth,
      frameHeight,
      originX,
      originY,
      imgWidth,
      imgHeight,
      imgScale,
    } = this.state;

    // crop accroding image's natural width
    const realFrameWidth = frameWidth * imgScale;
    const realFrameHeight = frameHeight * imgScale;
    const realOriginX = originX * imgScale;
    const realOriginY = originY * imgScale;

    return {
      display: {
        width: frameWidth,
        height: frameHeight,
        x: originX,
        y: originY,
        imgWidth,
        imgHeight,
      },
      original: {
        width: realFrameWidth,
        height: realFrameHeight,
        x: realOriginX,
        y: realOriginY,
        imgWidth: img.naturalWidth,
        imgHeight: img.naturalHeight,
      },
    };
  }

  // stop dragging
  handleDragStop(e) {
    if (this.state.dragging) {
      e.preventDefault();

      const { offsetLeft, offsetTop, offsetWidth, offsetHeight } = findDOMNode(
        this.frameNode
      );

      const { imgWidth, imgHeight } = this.state;

      this.setState(
        {
          originX: offsetLeft,
          originY: offsetTop,
          dragging: false,
          frameWidth: offsetWidth,
          frameHeight: offsetHeight,
          maxLeft: imgWidth - offsetWidth,
          maxTop: imgHeight - offsetHeight,
          action: null,
        },
        () => {
          const { onChange } = this.props;
          if (onChange) onChange(this.values());
        }
      );
    }
  }

  render() {
    const { dragging, imgHeight, imgWidth, imgLoaded, styles, src } =
      this.state;

    const { disabled, crossOrigin } = this.props;

    const imageNode = (
      <div
        style={styles.source}
        ref={ref => {
          this.sourceNode = ref;
        }}
      >
        <img
          src={src}
          ref={ref => {
            this.img = ref;
          }}
          style={deepExtend({}, styles.img, styles.sourceImg, {
            maxWidth: imgWidth,
            maxHeight: imgHeight,
          })}
          onLoad={this.imgOnLoad.bind(this)}
          crossOrigin={crossOrigin}
        />
      </div>
    );
    // disabled cropper
    if (disabled) {
      return (
        <div
          style={deepExtend({}, styles.container, {
            position: 'relative',
            height: imgHeight,
          })}
          ref={ref => {
            this.container = ref;
          }}
        >
          {imageNode}
          <div style={deepExtend({}, styles.modal, styles.modalDisabled)}></div>
        </div>
      );
    }

    return (
      <div
        onMouseDown={this.handleDragStart.bind(this)}
        onTouchStart={this.handleDragStart.bind(this)}
        style={deepExtend({}, styles.container, {
          position: 'relative',
          touchAction: 'none',
        })}
        ref={ref => {
          this.container = ref;
        }}
      >
        {imageNode}
        {imgLoaded ? (
          <div>
            <div style={styles.modal}></div>
            {/* frame container */}
            <div
              style={deepExtend(
                {},
                styles.frame,
                dragging ? styles.draggingFrame : {},
                {
                  display: 'block',
                  left: this.state.toImgLeft4Style,
                  top: this.state.toImgTop4Style,
                  width: this.state.frameWidth4Style,
                  height: this.state.frameHeight4Style,
                }
              )}
              ref={ref => {
                this.frameNode = ref;
              }}
            >
              {/* clone img */}
              <div style={styles.clone}>
                <img
                  src={src}
                  style={deepExtend({}, styles.img, {
                    marginLeft: -1 * this.state.toImgLeft4Style,
                    marginTop: -1 * this.state.toImgTop4Style,
                    maxWidth: imgWidth,
                    maxHeight: imgHeight,
                  })}
                  ref={ref => {
                    this.cloneImg = ref;
                  }}
                  crossOrigin={crossOrigin}
                />
              </div>

              {/* move element */}
              <span data-action="move" style={styles.move}></span>
              {/* move center element */}
              <span
                data-action="move"
                style={deepExtend({}, styles.dot, styles.dotCenter)}
              >
                <span
                  style={deepExtend(
                    {},
                    styles.dotInner,
                    styles.dotInnerCenterVertical
                  )}
                ></span>
                <span
                  style={deepExtend(
                    {},
                    styles.dotInner,
                    styles.dotInnerCenterHorizontal
                  )}
                ></span>
              </span>

              {/* frame dot elements */}
              <span
                data-action="ne"
                style={deepExtend({}, styles.dot, styles.dotNE)}
              >
                <span
                  style={deepExtend({}, styles.dotInner, styles.dotInnerNE)}
                ></span>
              </span>
              <span
                data-action="n"
                style={deepExtend({}, styles.dot, styles.dotN)}
              >
                <span
                  style={deepExtend({}, styles.dotInner, styles.dotInnerN)}
                ></span>
              </span>
              <span
                data-action="nw"
                style={deepExtend({}, styles.dot, styles.dotNW)}
              >
                <span
                  style={deepExtend({}, styles.dotInner, styles.dotInnerNW)}
                ></span>
              </span>
              <span
                data-action="e"
                style={deepExtend({}, styles.dot, styles.dotE)}
              >
                <span
                  style={deepExtend({}, styles.dotInner, styles.dotInnerE)}
                ></span>
              </span>
              <span
                data-action="w"
                style={deepExtend({}, styles.dot, styles.dotW)}
              >
                <span
                  style={deepExtend({}, styles.dotInner, styles.dotInnerW)}
                ></span>
              </span>
              <span
                data-action="se"
                style={deepExtend({}, styles.dot, styles.dotSE)}
              >
                <span
                  style={deepExtend({}, styles.dotInner, styles.dotInnerSE)}
                ></span>
              </span>
              <span
                data-action="s"
                style={deepExtend({}, styles.dot, styles.dotS)}
              >
                <span
                  style={deepExtend({}, styles.dotInner, styles.dotInnerS)}
                ></span>
              </span>
              <span
                data-action="sw"
                style={deepExtend({}, styles.dot, styles.dotSW)}
              >
                <span
                  style={deepExtend({}, styles.dotInner, styles.dotInnerSW)}
                ></span>
              </span>

              {/* frame line elements */}
              <span
                data-action="n"
                style={deepExtend({}, styles.line, styles.lineN)}
              ></span>
              <span
                data-action="s"
                style={deepExtend({}, styles.line, styles.lineS)}
              ></span>
              <span
                data-action="w"
                style={deepExtend({}, styles.line, styles.lineW)}
              ></span>
              <span
                data-action="e"
                style={deepExtend({}, styles.line, styles.lineE)}
              ></span>
              <span style={deepExtend({}, styles.gridLine, styles.lineMS)} />
              <span style={deepExtend({}, styles.gridLine, styles.lineMN)} />
              <span style={deepExtend({}, styles.gridLine, styles.lineME)} />
              <span style={deepExtend({}, styles.gridLine, styles.lineMW)} />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

Cropper.propTypes = {
  src: PropTypes.string.isRequired,
  originX: PropTypes.number,
  originY: PropTypes.number,
  ratio: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  fixedRatio: PropTypes.bool,
  allowNewSelection: PropTypes.bool,
  allowOverflow: PropTypes.bool,
  disabled: PropTypes.bool,
  styles: PropTypes.object,
  onImgLoad: PropTypes.func,
  beforeImgLoad: PropTypes.func,
  onChange: PropTypes.func,
  crossOrigin: PropTypes.string,
};

Cropper.defaultProps = {
  width: 200,
  height: 200,
  fixedRatio: true,
  allowNewSelection: true,
  allowOverflow: false,
  ratio: 1,
  originX: 0,
  originY: 0,
  styles: {},
  crossOrigin: null,
};

/*
default inline styles
*/
const defaultStyles = {
  container: {},
  img: {
    userDrag: 'none',
    userSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserDrag: 'none',
    WebkitUserSelect: 'none',
    WebkitTransform: 'translateZ(0)',
    WebkitPerspective: 1000,
    WebkitBackfaceVisibility: 'hidden',
    objectFit: 'contain',
  },

  clone: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'absolute',
    left: 0,
    top: 0,
  },

  frame: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    display: 'none',
  },

  draggingFrame: {
    opacity: 0.8,
  },

  source: {
    overflow: 'hidden',
  },

  sourceImg: {
    float: 'left',
  },

  modal: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    opacity: 0.4,
    backgroundColor: '#000',
  },
  modalDisabled: {
    backgroundColor: '#666',
    opacity: 0.7,
    cursor: 'not-allowed',
  },
  move: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    cursor: 'move',
    outline: '1px dashed #88f',
    backgroundColor: 'transparent',
  },

  dot: {
    zIndex: 10,
  },
  dotN: {
    cursor: 'n-resize',
  },
  dotS: {
    cursor: 's-resize',
  },
  dotE: {
    cursor: 'e-resize',
  },
  dotW: {
    cursor: 'w-resize',
  },
  dotNW: {
    cursor: 'nw-resize',
  },
  dotNE: {
    cursor: 'ne-resize',
  },
  dotSW: {
    cursor: 'sw-resize',
  },
  dotSE: {
    cursor: 'se-resize',
  },
  dotCenter: {
    backgroundColor: 'transparent',
    cursor: 'move',
  },

  dotInner: {
    border: '1px solid #88f',
    background: '#fff',
    display: 'block',
    width: 6,
    height: 6,
    padding: 0,
    margin: 0,
    position: 'absolute',
  },

  dotInnerN: {
    top: -4,
    left: '50%',
    marginLeft: -4,
  },
  dotInnerS: {
    bottom: -4,
    left: '50%',
    marginLeft: -4,
  },
  dotInnerE: {
    right: -4,
    top: '50%',
    marginTop: -4,
  },
  dotInnerW: {
    left: -4,
    top: '50%',
    marginTop: -4,
  },
  dotInnerNE: {
    top: -4,
    right: -4,
  },
  dotInnerSE: {
    bottom: -4,
    right: -4,
  },
  dotInnerNW: {
    top: -4,
    left: -4,
  },
  dotInnerSW: {
    bottom: -4,
    left: -4,
  },
  dotInnerCenterVertical: {
    position: 'absolute',
    border: 'none',
    width: 2,
    height: 8,
    backgroundColor: '#88f',
    top: '50%',
    left: '50%',
    marginLeft: -1,
    marginTop: -4,
  },
  dotInnerCenterHorizontal: {
    position: 'absolute',
    border: 'none',
    width: 8,
    height: 2,
    backgroundColor: '#88f',
    top: '50%',
    left: '50%',
    marginLeft: -4,
    marginTop: -1,
  },

  line: {
    position: 'absolute',
    display: 'block',
    zIndex: 100,
  },
  gridLine: {
    position: 'absolute',
    display: 'block',
    zIndex: 100,
    outline: '1px dashed #88f',
    backgroundColor: 'transparent',
  },
  lineS: {
    cursor: 's-resize',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 4,
    background: 'transparent',
  },
  lineN: {
    cursor: 'n-resize',
    top: 0,
    left: 0,
    width: '100%',
    height: 4,
    background: 'transparent',
  },
  lineE: {
    cursor: 'e-resize',
    right: 0,
    top: 0,
    width: 4,
    height: '100%',
    background: 'transparent',
  },
  lineW: {
    cursor: 'w-resize',
    left: 0,
    top: 0,
    width: 4,
    height: '100%',
    background: 'transparent',
  },
  lineMS: {
    left: 0,
    top: '66%',
    width: '100%',
    height: 0,
    background: 'transparent',
  },
  lineMN: {
    left: 0,
    top: '33%',
    width: '100%',
    height: 0,
    background: 'transparent',
  },
  lineME: {
    left: '66%',
    top: 0,
    width: 0,
    height: '100%',
    background: 'transparent',
  },
  lineMW: {
    left: '33%',
    top: 0,
    width: 0,
    height: '100%',
    background: 'transparent',
  },
};

export { Cropper };
