import { useState, useEffect } from 'react';
import isEmail from 'validator/lib/isEmail';
import { validateNode } from '~common/content.utils';
import { isTreeMetaField, MetaField } from '~common/content.types';

type Field = {
  name: string;
  valueType: string;
  helperText?: string;
  isMandatory: boolean;
} & (MetaField | {});

export interface FieldError {
  isMandatory?: boolean;
  name: string;
  isInvalid?: boolean;
  errorDetail?: string;
}

export const isErroneousField = (
  field: Field,
  value: any
): false | FieldError => {
  if (
    field.valueType === 'separator' ||
    field.valueType === 'VALUE_TYPE_SEPARATOR'
  )
    return false;
  if (typeof value === 'string') value = value.trim();
  if (
    field.isMandatory &&
    (value === null || value === undefined || value.length === 0)
  ) {
    return { ...field, isMandatory: true };
  } else if (!field.isMandatory && !value) return false;
  if ('valueTree' in field && isTreeMetaField(field)) {
    const nodesValid =
      Array.isArray(value) &&
      field.valueTree &&
      value.every(node => validateNode(node, { valueTree: field.valueTree }));
    return nodesValid ? false : { ...field, isInvalid: true };
  }
  switch (field.valueType) {
    case 'VALUE_TYPE_NUMERIC':
    case 'numeric':
      if (isNaN(parseFloat(value))) return { ...field, isInvalid: true };
      break;
    case 'VALUE_TYPE_EMAIL':
    case 'email':
      if (!isEmail(value)) return { ...field, isInvalid: true };
      break;
    case 'VALUE_TYPE_EMAILS':
    case 'emails':
      const emails = value.split(/;|,|\s+/).filter(x => !!x); // eslint-disable-line
      if (field.isMandatory && emails.length === 0)
        return { ...field, isMandatory: true };
      if (!emails.every(x => isEmail(x))) return { ...field, isInvalid: true };
      break;
    case 'VALUE_TYPE_TIME':
    case 'time':
    case 'VALUE_TYPE_DATE':
    case 'date':
      if (value && isNaN(value.getTime())) {
        return { ...field, isInvalid: true };
      }
  }
  return false;
};

export const useScrollToError = (
  errors: any,
  container?: React.RefObject<HTMLElement> | string
) => {
  useEffect(() => {
    const element = document.querySelector('.Mui-error');
    if (!element) return;
    const {
      top: elementTop,
      bottom: elementBottom,
      height: elementHeight,
    } = element.getBoundingClientRect();
    // Use the window if no container specified
    if (!container || (typeof container !== 'string' && !container.current)) {
      const header = document.querySelector('header');
      const { height: headerHeight } = header
        ? header.getBoundingClientRect()
        : { height: 100 };
      const { scrollY, innerHeight } = window;
      const scrollMin = scrollY + elementTop - elementHeight - headerHeight;
      const scrollMax = scrollY + elementBottom + elementHeight - innerHeight;
      if (scrollMin < scrollY) window.scroll(0, scrollMin);
      else if (scrollMax > scrollY) window.scroll(0, scrollMax);
      return;
    }
    const scrollElement =
      typeof container === 'string'
        ? document.querySelector(container as string)
        : container.current;
    if (!scrollElement) return;
    const { top: containerTop, bottom: containerBottom } =
      scrollElement.getBoundingClientRect();
    const { scrollTop } = scrollElement;
    const scrollMin = scrollTop + elementTop - elementHeight - containerTop;
    const scrollMax =
      scrollTop + elementBottom + elementHeight - containerBottom;
    if (scrollMin < scrollTop) scrollElement.scrollTop = scrollMin;
    else if (scrollMax > scrollTop) scrollElement.scrollTop = scrollMax;
  }, [errors]);
};

export const getMuiVariant = variant => {
  if (variant === 'form') return 'standard';
  if (variant === 'rounded') return 'outlined';
  return variant;
};

/**
 * In case we don't want the user to submit the form when pressing enter on
 * a basic text field, so we prevent those events from triggering submission
 */
export function preventEnter(e: React.KeyboardEvent<HTMLFormElement>) {
  if (e.target instanceof HTMLInputElement && e.key === 'Enter') {
    e.preventDefault();
  }
}

/** Utility hook for handling a MUI tab value and change callback */
export function useTabState(tab?: number) {
  const [tabValue, setTabValue] = useState(tab || 0);

  const handleTabChange = (_: unknown, newValue: number) => {
    setTabValue(newValue);
  };

  return [tabValue, handleTabChange, setTabValue] as const;
}

/** Checks that given string represents an email address */
export function validateEmailAddress(str: string) {
  const emailFormat =
    /^[_A-Za-z0-9-+]+(\.[_A-Za-z0-9-+]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/;
  return emailFormat.test(str);
}

/** Map react-hook-form's dirtyFields over the `data` received by
 * `handleSubmit` and return the changed subset of that data. */
export function dirtyValues<T extends object>(
  dirtyFields: object | boolean,
  allValues: T
) {
  // If *any* item in an array was modified, the entire array must be
  // submitted, because there's no way to indicate "placeholders" for
  // unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues;
  // Here, we have an object
  return Object.fromEntries(
    Object.keys(dirtyFields).map(key => [
      key,
      dirtyValues(dirtyFields[key], allValues[key]),
    ])
  ) as Partial<T>;
}
