import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';
import format from 'date-fns/format';

import { Comment } from '../../common/types';

import { comments } from '../../common/model';

import Bar from '~sections/Bar';
import Content from '~sections/Content';
import Typography from '~misc/Typography';
import Button from '~inputs/Button';
import { isIOS } from '~common/utils/browser.utils';

interface Props {
  commentsById: any;
  selectedCommentId: string | null;
  hoveredCommentId: string | null;
  selectComment: (id: string | null) => {};
  hoverComment?: (id: string | null) => {};
}

const Comments = ({
  commentsById,
  selectedCommentId,
  hoveredCommentId,
  selectComment,
  hoverComment,
}: Props) => {
  const comments = Object.values(commentsById).sort(
    (a: Comment, b: Comment) => {
      return new Date(a.created).getTime() - new Date(b.created).getTime();
    }
  );

  // TODO: disable for all touch-only-devices?
  const hoverEnabled = !isIOS();

  return (
    <React.Fragment>
      <Bar padding={1} paddingHoriz={2} variant="borderless">
        <Typography variant="h2">
          <Trans>Comments</Trans>
        </Typography>
      </Bar>
      <Content padding={1} paddingHoriz={2} onClick={() => selectComment(null)}>
        {comments.map((el: Comment) => (
          <CommentButton
            key={el.id}
            color="primary"
            variant={el.id === selectedCommentId ? 'contained' : 'outlined'}
            fullWidth
            onClick={e => {
              e.stopPropagation();
              selectComment(el.id);
            }}
            onMouseEnter={
              hoverEnabled && hoverComment
                ? () => hoverComment && hoverComment(el.id)
                : undefined
            }
            onMouseLeave={
              hoverEnabled && hoverComment
                ? () => hoverComment && hoverComment(null)
                : undefined
            }
            $hovered={el.id === hoveredCommentId && el.id !== selectedCommentId}
          >
            <CommentInfo>
              {el.displayName} - {format(new Date(el.created), 'dd.MM. HH:mm')}
              <br />
              {el.text}
            </CommentInfo>
          </CommentButton>
        ))}
      </Content>
    </React.Fragment>
  );
};

interface CommentButtonProps {
  theme: any;
  $hovered: boolean;
}

const CommentInfo = styled(Typography)`
  && {
    font-size: 0.8rem;
    text-align: left;
  }
`;

const CommentButton = styled(Button)<CommentButtonProps>`
  && {
    text-transform: none;
    justify-content: flex-start;
    margin-bottom: 16px;
    min-height: 36px;
    max-height: 80px;

    ${CommentInfo} {
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    .MuiButton-label {
      display: block;
      text-align: left;
    }

    ${props =>
      props.$hovered &&
      `
      background-color: ${props.theme.palette.primary.lightFade};
    `}
  }
`;

export default connect(
  (state: any) => ({
    commentsById: state.comments.commentsById,
    selectedCommentId: state.comments.selectedCommentId,
    hoveredCommentId: state.comments.hoveredCommentId,
    language: state.app.settings.language,
    defaultLanguage: state.app.customer.defaultLanguage,
  }),
  {
    selectComment: comments.actions.selectComment,
    hoverComment: comments.actions.hoverComment,
  }
)(Comments);
