import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { t } from '@lingui/macro';

import { products } from '../model';
import AddPage from './AddPage';
import PageItem from './PageItem';
import { Product } from '../common/types';

import Typography from '~misc/Typography';
import config from '~common/config';
import { getNotFoundImgSrc } from '~common/utils/img.utils';
import PopupButton from '~common/navigation/PopupButton';
import PageBar from '~sections/PageBar';
import { useActions } from '~common/utils/hooks.utils';
import { useGetMassImportQuery } from '~common/product.api';

export const showPageSelector = (
  product: Product | null,
  pagesById: Record<number, unknown>
) => allowPageOperations(product) || (Object.keys(pagesById) || []).length > 1;

interface Props {
  onSelect?: () => void;
}

export function PageSelector({ onSelect }: Props) {
  const pagesById = useSelector(state => state.products.pagesById);
  const pageTemplatesById = useSelector(
    state => state.products.pageTemplatesById
  );
  const selectedPageId = useSelector(state => state.products.selectedPageId);
  const product = useSelector(state => state.products.product);
  const refreshCountersById = useSelector(
    state => state.products.refreshCountersById
  );

  const setPagesById = useActions(products.actions.setPagesById);
  const selectPage = useActions(products.actions.selectPage);
  const addPage = useActions(products.actions.addPage);
  const deletePage = useActions(products.actions.deletePage);
  const updatePageOrder = useActions(products.actions.updatePageOrder);

  const [pageArray, setPageArray] = useState(Object.values(pagesById));
  const [pageTemplateArray, setPageTemplateArray] = useState(
    Object.values(pageTemplatesById)
  );

  const { data: massImportData } = useGetMassImportQuery({
    productId: product?.id ?? '',
  });

  const allowPageOps = allowPageOperations(product);

  useEffect(() => {
    setPageArray(
      Object.values(pagesById).sort((a, b) => a.pageNumber - b.pageNumber)
    );
  }, [pagesById]);

  useEffect(() => {
    setPageTemplateArray(Object.values(pageTemplatesById));
  }, [pageTemplatesById]);

  const handleSelect = (pageId: number) => {
    selectPage(pageId);
    onSelect?.();
  };

  const onDragEnd = result => {
    if (!result.destination || !allowPageOps) {
      return;
    }
    if (result.source.index === result.destination.index) return;
    const reorderPages = reorder(
      pageArray,
      result.source.index,
      result.destination.index
    );

    // Update locally
    setPagesById(reorderPages.reduce((a, c) => ({ ...a, [c.id]: c }), {}));

    // Update server-side (pages might have some gaps in their numbering)
    updatePageOrder(
      pageArray[result.source.index].pageNumber,
      pageArray[result.destination.index].pageNumber
    );
  };

  const handleDeletePage = (pageNumber: number) => {
    // update locally right away
    const removedPageNumber = pageArray.find(
      x => x.pageNumber === pageNumber
    )?.pageNumber;
    const filteredPages = pageArray
      .filter(x => x.pageNumber !== pageNumber)
      .map(x => {
        // The pages behind the deleted one will be moved
        const pageNumber =
          x.pageNumber - (x.pageNumber <= (removedPageNumber || 0) ? 0 : 1);
        return {
          ...x,
          pageNumber,
        };
      });
    setPagesById(filteredPages.reduce((a, c) => ({ ...a, [c.id]: c }), {}));

    // update server-side
    deletePage(pageNumber);
  };

  const handleAddPage = (id: number) => {
    // update locally
    const pageNumber = Math.max(...pageArray.map(x => x.pageNumber), 0) + 1;
    // add placeholder page with a falsy id (will be filtered out)
    const newPages = [...pageArray, { id: -pageNumber, pageNumber }];
    setPagesById(newPages.reduce((a, c) => ({ ...a, [c.id]: c }), {}));

    // update server-side
    addPage(id);
  };

  const handleAddMassImportPages = () => {
    if (product?.massImportType === 'single') {
      // update locally
      const pageNumber = Math.max(...pageArray.map(x => x.pageNumber), 0) + 1;
      // add placeholder page with a falsy id (will be filtered out)
      const newPages = [...pageArray, { id: -pageNumber, pageNumber }];
      setPagesById(newPages.reduce((a, c) => ({ ...a, [c.id]: c }), {}));

      // update server-side
      addPage(1, 0);
    } else {
      massImportData?.massImports.forEach(item => {
        // update locally
        const pageNumber = Math.max(...pageArray.map(x => x.pageNumber), 0) + 1;
        // add placeholder page with a falsy id (will be filtered out)
        const newPages = [...pageArray, { id: -pageNumber, pageNumber }];
        setPagesById(newPages.reduce((a, c) => ({ ...a, [c.id]: c }), {}));

        // update server-side
        addPage(1, Number(item.id));
      });
    }
  };

  if (!showPageSelector(product, pagesById)) return null;

  return (
    <>
      <PageBar padding={2} style={{ position: 'relative' }}>
        <Typography variant="h2">{t`Pages`}</Typography>
        {allowPageOps && (
          <PopupButton
            isIconButton
            style={{
              position: 'absolute',
              backgroundColor: 'transparent',
              right: '0',
            }}
            content={setOpen => (
              <AddPage
                isMassImport={
                  massImportData
                    ? massImportData?.massImports.length > 0
                    : false
                }
                setOpen={setOpen}
                pageTemplates={pageTemplateArray}
                onAddPage={handleAddPage}
                onAddMassImportPages={handleAddMassImportPages}
              />
            )}
          >
            <AddCircle />
          </PopupButton>
        )}
      </PageBar>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {pageArray
                .filter(page => page.id > 0)
                .map((page, index) => {
                  return (
                    <Draggable
                      key={page.id}
                      draggableId={page.id.toString()}
                      index={index}
                      /* disableInteractiveElementBlocking */
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <PageItem
                            thumbnailUrl={
                              product
                                ? `${config.url}/api/v1/products/${
                                    product.productId
                                  }/pages/${
                                    page.id
                                  }/previews/FULL?enlarge=2&counter=${
                                    refreshCountersById[page.id]
                                  }`
                                : getNotFoundImgSrc()
                            }
                            pageNumber={(index + 1).toString()}
                            pageId={page.id}
                            selected={selectedPageId === page.id}
                            onclick={() => {
                              selectedPageId !== page.id &&
                                handleSelect(page.id);
                            }}
                            allowPageOps={
                              allowPageOps &&
                              (Object.keys(pagesById) || []).length > 1
                            }
                            ondelete={() => handleDeletePage(page.pageNumber)}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

const allowPageOperations = (product: Product | null) =>
  (product && product.version !== 1) || false;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  transform: isDragging ? 'scale(1.1)' : 'none',
  ...draggableStyle,
});

// TODO: Decide id type, react-beautiful-dnd requires id as string
interface PageProps {
  id: any;
  pageNumber: number;
  thumbnailUrl: string;
  name: string;
  templatePageId: string;
}

const reorder = (list, startIndex, endIndex): PageProps[] => {
  const result: PageProps[] = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  const pageNumbers = result.map(page => page.pageNumber).sort();
  return result.map((page, i) => ({
    ...page,
    pageNumber: pageNumbers[i],
  }));
};

const AddCircle = styled(AddCircleOutline)`
  color: ${props => props.theme.palette.primary.main};
`;
