import { DefaultRootState } from 'react-redux';
import {
  Comment,
  Commenter,
  CommenterUser,
  CommentRequest,
  Page,
  UserGroup,
  CommentRequestWorkflowData,
  CommentType,
  Location,
  RequestLineData,
} from './types';
import { WorkflowProcessStep } from '~common/workflows/constants';
import {
  createActionTypes,
  createAction,
  handleActions,
} from '~utils/ducks.utils';
import { User } from '~common/app.types';
import { Criteria, EnterMode, HistoryUpdateMode } from '~common/common.types';
import { WorkflowProcess } from '~common/workflows/types';

export interface CommentState {
  commentRequests: { totalCount: number; commentings: CommentRequest[] } | null;
  commentRequest: CommentRequest | null | undefined;
  commentsById: Record<string, Comment>;
  commentersById: Record<string, Commenter>;
  pagesById: Record<string, Page>;
  selectedPageId: number;
  selectedCommentId: string | null;
  hoveredCommentId: string | null;
  loading: boolean;
  selectedCommentType: CommentType;

  // Comment request creation
  contentById: { [key: string]: RequestLineData };
  workflowProcess: WorkflowProcess;
  dataByWorkflowId: { [key: string]: CommentRequestWorkflowData } | null;
  commenters: User[] | null;
  groups: UserGroup[] | null;
  commentingReady: boolean;
}

const initialState: CommentState = {
  commentRequests: null,
  commentRequest: undefined,
  commentsById: {},
  commentersById: {},
  pagesById: {},
  selectedPageId: -1,
  selectedCommentId: null,
  hoveredCommentId: null,
  loading: false,
  selectedCommentType: '',

  // Comment request creation
  contentById: {},
  workflowProcess: {
    workflowId: null,
    step: null,
  },
  dataByWorkflowId: null,
  commenters: null,
  groups: null,
  commentingReady: false,
};

const CommentActions = createActionTypes('COMMENTS', [
  'UPDATE_CRITERIA',
  'FETCH_COMMENT_REQUESTS',

  // Read data
  'AFTER_FETCH_COMMENT_REQUESTS',
  'SHOW_COMMENT_REQUEST',
  'AFTER_READ_COMMENT_REQUEST',
  'AFTER_READ_PAGES',
  'AFTER_READ_COMMENTS',

  // Pages
  'SELECT_PAGE',
  'SELECT_NTH_PAGE',
  'APPROVE_PAGE',
  'APPROVE_ALL_PAGES',
  'DISAPPROVE_ALL_PAGES',

  // Comments
  'SELECT_COMMENT',
  'HOVER_COMMENT',
  'UPDATE_COMMENT',
  'AFTER_UPDATE_COMMENT',
  'RESET_LOADING',

  // Creating a comment
  'SELECT_COMMENT_TYPE',
  'CREATE_COMMENT',
  'AFTER_CREATE_COMMENT',

  // Deleting a comment
  'DELETE_COMMENT',
  'AFTER_DELETE_COMMENT',

  // Creating a comment request
  'START_COMMENT_ROUND',
  'SET_COMMENT_REQUEST_CONTENT',
  'SELECT_WORKFLOW',
  'SET_REQUEST_LINE_DATA',
  'INIT_WORKFLOW_DATA',
  'REMOVE_WORKFLOW_DATA',
  'SET_WORKFLOW_DATA',
  'START_PROCESS',
  'ADVANCE_PROCESS',
  'RETREAT_PROCESS',
  'UPDATE_WORKFLOW_PROCESS',
  'RESET_WORKFLOW_PROCESS',
  'SEND_COMMENT_REQUEST',
  'AFTER_SEND_COMMENT_REQUEST',
  'READ_COMMENTERS',
  'AFTER_READ_COMMENTERS',

  // Updating comment request
  'UPDATE_COMMENTERS',
  'UPDATE_CLOSING_DATE_TIME',
  'CLOSE_COMMENTING',
  'FINALIZE_COMMENTING',

  // Set commentingReadyState
  'UPDATE_COMMENTING_READY_STATE',
]);

const actions = {
  updateCriteria: createAction(
    CommentActions.UPDATE_CRITERIA,
    (
      criteria: Criteria,
      historyUpdateMode?: HistoryUpdateMode,
      enterMode?: EnterMode
    ) => ({
      criteria,
      historyUpdateMode,
      enterMode,
    })
  ),
  fetchCommentRequests: createAction(
    CommentActions.FETCH_COMMENT_REQUESTS,
    (criteria: Criteria) => ({ criteria })
  ),

  // Read data
  afterFetchCommentRequests: createAction(
    CommentActions.AFTER_FETCH_COMMENT_REQUESTS,
    (commentRequests: CommentState['commentRequests']) => ({ commentRequests })
  ),
  showCommentRequest: createAction(
    CommentActions.SHOW_COMMENT_REQUEST,
    (commentRequestId: string) => ({ commentRequestId })
  ),
  afterReadCommentRequest: createAction(
    CommentActions.AFTER_READ_COMMENT_REQUEST,
    (commentRequest: CommentRequest) => ({ commentRequest })
  ),
  afterReadPages: createAction(
    CommentActions.AFTER_READ_PAGES,
    (pagesById: CommentState['pagesById']) => ({
      pagesById,
    })
  ),
  afterReadComments: createAction(
    CommentActions.AFTER_READ_COMMENTS,
    (
      commentsById: CommentState['commentsById'],
      commentersById: CommentState['commentersById']
    ) => ({ commentsById, commentersById })
  ),

  // Pages
  selectPage: createAction(
    CommentActions.SELECT_PAGE,
    (selectedPageId: number) => ({
      selectedPageId,
    })
  ),
  selectNthPage: createAction(
    CommentActions.SELECT_NTH_PAGE,
    (selectedPageId: number) => ({ selectedPageId })
  ),
  approvePage: createAction(
    CommentActions.APPROVE_PAGE,
    (selectedPageId: number, approved: boolean, userId: string) => ({
      selectedPageId,
      approved,
      userId,
    })
  ),
  approveAllPages: createAction(CommentActions.APPROVE_ALL_PAGES, () => ({})),
  disapproveAllPages: createAction(
    CommentActions.DISAPPROVE_ALL_PAGES,
    () => ({})
  ),

  // Comments
  selectComment: createAction(
    CommentActions.SELECT_COMMENT,
    (commentId: CommentState['selectedCommentId']) => ({
      commentId,
    })
  ),
  hoverComment: createAction(
    CommentActions.HOVER_COMMENT,
    (commentId: CommentState['hoveredCommentId']) => ({
      commentId,
    })
  ),
  updateComment: createAction(
    CommentActions.UPDATE_COMMENT,
    (commentId: string, comment: Comment) => ({ commentId, comment })
  ),
  afterUpdateComment: createAction(
    CommentActions.AFTER_UPDATE_COMMENT,
    () => ({})
  ),
  resetLoading: createAction(CommentActions.RESET_LOADING, () => ({})),

  // Creating a comment
  selectCommentType: createAction(
    CommentActions.SELECT_COMMENT_TYPE,
    (type: CommentType) => ({
      type,
    })
  ),
  createComment: createAction(
    CommentActions.CREATE_COMMENT,
    (position: Location) => ({
      position,
    })
  ),
  afterCreateComment: createAction(
    CommentActions.AFTER_CREATE_COMMENT,
    (comment: Comment) => ({ comment })
  ),

  // Deleting a comment
  deleteComment: createAction(CommentActions.DELETE_COMMENT, (id: string) => ({
    id,
  })),
  afterDeleteComment: createAction(
    CommentActions.AFTER_DELETE_COMMENT,
    (id: string) => ({
      id,
    })
  ),

  // Creating a comment request
  startCommentRound: createAction(
    CommentActions.START_COMMENT_ROUND,
    (originalRound: CommentRequest) => ({
      originalRound,
    })
  ),
  setCommentRequestContent: createAction(
    CommentActions.SET_COMMENT_REQUEST_CONTENT,
    (contentIds: string[], workflowId?: string) => ({ contentIds, workflowId })
  ),
  selectWorkflow: createAction(
    CommentActions.SELECT_WORKFLOW,
    (fileId: string, workflowId: string) => ({ fileId, workflowId })
  ),
  setRequestLineData: createAction(
    CommentActions.SET_REQUEST_LINE_DATA,
    (fileId: string, data: RequestLineData) => ({ fileId, data })
  ),
  initWorkflowData: createAction(
    CommentActions.INIT_WORKFLOW_DATA,
    (dataByWorkflowId: CommentState['dataByWorkflowId'], clear?: boolean) => ({
      dataByWorkflowId,
      clear,
    })
  ),
  removeWorkflowData: createAction(
    CommentActions.REMOVE_WORKFLOW_DATA,
    (workflowId: string) => ({ workflowId })
  ),
  setWorkflowData: createAction(
    CommentActions.SET_WORKFLOW_DATA,
    (workflowId: string, fieldId: string, data: any) => ({
      workflowId,
      fieldId,
      data,
    })
  ),
  startProcess: createAction(CommentActions.START_PROCESS, () => ({})),
  advanceProcess: createAction(CommentActions.ADVANCE_PROCESS, () => ({})),
  retreatProcess: createAction(CommentActions.RETREAT_PROCESS, () => ({})),
  updateWorkflowProcess: createAction(
    CommentActions.UPDATE_WORKFLOW_PROCESS,
    (workflowProcess: CommentState['workflowProcess']) => ({ workflowProcess })
  ),
  resetWorkflowProcess: createAction(
    CommentActions.RESET_WORKFLOW_PROCESS,
    () => ({})
  ),
  sendCommentRequest: createAction(
    CommentActions.SEND_COMMENT_REQUEST,
    () => ({})
  ),
  afterSendCommentRequest: createAction(
    CommentActions.AFTER_SEND_COMMENT_REQUEST,
    (orderId: string) => ({ orderId })
  ),
  readCommenters: createAction(CommentActions.READ_COMMENTERS, () => ({})),
  afterReadCommenters: createAction(
    CommentActions.AFTER_READ_COMMENTERS,
    (
      commenters: CommentState['commenters'],
      groups: CommentState['groups']
    ) => ({ commenters, groups })
  ),

  // Updating comment request
  updateCommenters: createAction(
    CommentActions.UPDATE_COMMENTERS,
    (commenters: (CommenterUser | UserGroup)[]) => ({ commenters })
  ),
  updateClosingDateTime: createAction(
    CommentActions.UPDATE_CLOSING_DATE_TIME,
    (
      commentRequestId: string,
      expirationDateTime: string,
      reminderDateTime: string
    ) => ({
      commentRequestId,
      expirationDateTime,
      reminderDateTime,
    })
  ),
  closeCommenting: createAction(
    CommentActions.CLOSE_COMMENTING,
    (commentRequestId: string) => ({ commentRequestId })
  ),
  finalizeCommenting: createAction(
    CommentActions.FINALIZE_COMMENTING,
    (commentRequestId: string) => ({ commentRequestId })
  ),

  updateCommentingReady: createAction(
    CommentActions.UPDATE_COMMENTING_READY_STATE,
    (commentingReady: boolean) => ({ commentingReady })
  ),
};

// Reducer
export default handleActions(initialState)
  .handle(actions.afterFetchCommentRequests, (state, action) => ({
    ...state,
    commentRequests: action.payload.commentRequests,
  }))
  .handle(actions.afterReadCommentRequest, (state, action) => {
    const commentRequest = action.payload.commentRequest;
    const requestIndex = state.commentRequests
      ? state.commentRequests.commentings.findIndex(
          req => req.id === commentRequest.id
        )
      : -1;
    return {
      ...state,
      commentRequest,
      commentRequests:
        requestIndex !== -1 && state.commentRequests
          ? {
              ...state.commentRequests,
              commentings: [
                ...(state.commentRequests?.commentings || []).slice(
                  0,
                  requestIndex
                ),
                commentRequest,
                ...(state.commentRequests?.commentings || []).slice(
                  requestIndex + 1
                ),
              ],
            }
          : state.commentRequests,
    };
  })
  .handle(actions.afterReadPages, (state, action) => ({
    ...state,
    pagesById: action.payload.pagesById,
  }))
  .handle(actions.approvePage, (state, action) => ({
    ...state,
    commentersById: {
      ...state.commentersById,
      [action.payload.userId]: {
        ...state.commentersById[action.payload.userId],
        status: action.payload.approved
          ? 'PAGE_STATUS_APPROVED'
          : 'PAGE_STATUS_EDIT',
      },
    },
  }))
  .handle(actions.afterReadComments, (state, action) => ({
    ...state,
    commentsById: action.payload.commentsById,
    commentersById: action.payload.commentersById,
  }))
  .handle(actions.selectComment, (state, action) => ({
    ...state,
    selectedCommentId: action.payload.commentId,
  }))
  .handle(actions.hoverComment, (state, action) => ({
    ...state,
    hoveredCommentId: action.payload.commentId,
  }))
  .handle(actions.updateComment, (state, action) => ({
    ...state,
    commentsById: {
      ...state.commentsById,
      [action.payload.commentId]: action.payload.comment,
    },
  }))
  .handle(actions.resetLoading, state => ({
    ...state,
    loading: false,
  }))
  .handle(actions.selectPage, (state, action) => ({
    ...state,
    loading: true,
    selectedPageId: action.payload.selectedPageId,
    selectedCommentId: null,
    commentsById: {},
    commentersById: {},
  }))
  .handle(actions.selectCommentType, (state, action) => ({
    ...state,
    selectedCommentType: action.payload.type,
  }))
  .handle(actions.deleteComment, state => ({
    ...state,
    selectedCommentId: null,
    hoveredCommentId: null,
  }))
  .handle(actions.afterDeleteComment, (state, action) => {
    const id: string = action.payload.id;
    const { [id]: deleted, ...commentsById } = state.commentsById;
    return {
      ...state,
      commentsById,
    };
  })
  .handle(actions.setCommentRequestContent, (state, action) => ({
    ...state,
    contentById: action.payload.contentIds.reduce(
      (a, c) => ({
        ...a,
        [c]: { workflowId: action.payload.workflowId || null },
      }),
      {}
    ),
  }))
  .handle(actions.selectWorkflow, (state, action) => ({
    ...state,
    contentById: {
      ...state.contentById,
      [action.payload.fileId]: {
        ...state.contentById[action.payload.fileId],
        workflowId: action.payload.workflowId,
      },
    },
  }))
  .handle(actions.setRequestLineData, (state, action) => ({
    ...state,
    contentById: {
      ...state.contentById,
      [action.payload.fileId]: {
        ...state.contentById[action.payload.fileId],
        ...action.payload.data,
      },
    },
  }))
  .handle(actions.initWorkflowData, (state, action) => ({
    ...state,
    dataByWorkflowId: {
      ...action.payload.dataByWorkflowId,
      ...(action.payload.clear ? {} : state.dataByWorkflowId),
    },
  }))
  .handle(actions.removeWorkflowData, (state, action) => ({
    ...state,
    dataByWorkflowId: Object.keys(state.dataByWorkflowId || {}).reduce(
      (a, c) => {
        if (c !== action.payload.workflowId) a[c] = state.dataByWorkflowId?.[c];
        return a;
      },
      {}
    ),
    contentById: Object.keys(state.contentById)
      .filter(
        x => state.contentById[x].workflowId !== action.payload.workflowId
      )
      .reduce((a, c) => {
        a[c] = state.contentById[c];
        return a;
      }, {}),
  }))
  .handle(actions.setWorkflowData, (state, action) => ({
    ...state,
    dataByWorkflowId: {
      ...state.dataByWorkflowId,
      [action.payload.workflowId]: {
        ...(state.dataByWorkflowId?.[
          action.payload.workflowId
        ] as CommentRequestWorkflowData),
        [action.payload.fieldId]: action.payload.data,
      },
    },
  }))
  .handle(actions.updateWorkflowProcess, (state, action) => ({
    ...state,
    workflowProcess: {
      ...state.workflowProcess,
      ...action.payload.workflowProcess,
    },
  }))
  .handle(actions.resetWorkflowProcess, state => ({
    ...state,
    workflowProcess: initialState.workflowProcess,
  }))
  .handle(actions.sendCommentRequest, state => ({
    ...state,
    workflowProcess: {
      ...state.workflowProcess,
      step: WorkflowProcessStep.SENDING,
    },
  }))
  .handle(actions.afterSendCommentRequest, (state, action) => ({
    ...state,
    workflowProcess: {
      ...state.workflowProcess,
      step: WorkflowProcessStep.SENT,
      orderId: action.payload.orderId,
    },
  }))
  .handle(actions.afterReadCommenters, (state, action) => ({
    ...state,
    commenters: action.payload.commenters,
    groups: action.payload.groups,
  }))

  .handle(actions.updateCommentingReady, (state, action) => ({
    ...state,
    commentingReady: action.payload.commentingReady,
  }));

// Selectors
const selector = {
  getCurrentCommenter: (user: User | undefined, state: DefaultRootState) => {
    if (!user) {
      const guest = Object.values(state.comments.commentersById).find(
        commenter => commenter.isGuest && commenter.isCurrentUser
      );
      return guest;
    }
    return state.comments.commentersById &&
      state.comments.commentersById[user.id]
      ? state.comments.commentersById[user.id]
      : { userId: user.id };
  },

  getPagesByItem: ({ comments }: DefaultRootState) => {
    const pagesById = comments.pagesById;
    const pagesByItem = {};
    Object.values(pagesById).forEach((page: Page) => {
      if (pagesByItem[page.nodeId]) {
        pagesByItem[page.nodeId] = [...pagesByItem[page.nodeId], page];
      } else {
        pagesByItem[page.nodeId] = [page];
      }
    });
    return pagesByItem;
  },

  getWorkflowContents: ({ comments }: DefaultRootState) => {
    const { workflowId } = comments.workflowProcess;
    if (!workflowId) return comments.contentById;
    return Object.keys(comments.contentById)
      .filter(id => comments.contentById[id].workflowId === workflowId)
      .reduce((a, id) => ({ ...a, [id]: comments.contentById[id] }), {});
  },
};

// Bundle things in a model
export const comments = {
  actions,
  selector,
};
