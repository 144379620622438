import React from 'react';
import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';

import { useCenterWorkspaceId, useOpenNewWorkspace } from '../hooks';

import { Fileish, File, fileExists } from '~common/content.types';
import { ModalOption } from '~common/navigation/MenuButton';
import { DeleteIcon } from '~common/misc/icons';

export function useDeleteWorkspaceOption(
  workspace: Fileish | undefined
): ModalOption {
  const centerWorkspaceId = useCenterWorkspaceId();
  const sidebarWorkspaceId = useSelector(
    state => state.workspaces.currentWorkspaceId
  );

  const { openNewWorkspace } = useOpenNewWorkspace();

  return {
    value: 'deleteWorkspace',
    disabled:
      !fileExists(workspace) ||
      !workspace.isCart ||
      !workspace.userRights?.delete ||
      workspace?.name === '_DEFAULT_CART',
    title: t`Delete workspace`,
    icon: <DeleteIcon fontSize="small" />,
    modalType: 'CONTENT/CONFIRM_DELETE',
    modalProps: {
      items: [workspace],
      afterDelete: () => {
        // We need to reset the workspace everywhere the user has it open,
        // be it sidebar or center or both
        if ((workspace as File)?.node.id === centerWorkspaceId) {
          openNewWorkspace('center');
        }
        if ((workspace as File)?.node.id === sidebarWorkspaceId) {
          openNewWorkspace('sidebar');
        }
      },
    },
  };
}
