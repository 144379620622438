import {
  actionChannel,
  all,
  put,
  takeEvery,
  fork,
  take,
  race,
} from 'redux-saga/effects';
import { eventChannel, buffers, channel } from 'redux-saga';
import { push, replace } from 'connected-react-router';
import { t } from '@lingui/macro';

import { content } from '../../common/model';
import { products } from './model';
import { ImageElement } from './types';
import config from '~common/config';
import * as api from '~common/product.api';
import { commonContent } from '~common/content.model';
import { getCroppedElement, getProductId } from '~common/utils/product.utils';
import { userCacheSelector } from '~common/user.api';
import { call, putResolve, select } from '~common/utils/saga.utils';
import { getFile } from '~common/content.api';
const { actions: contentActions } = commonContent;

// Product

function* createProduct(
  action: ReturnType<typeof content.actions.createProduct>
) {
  try {
    const share = yield* select(state => {
      return state.app.share;
    });
    const { data: user } = yield* select(state => userCacheSelector(state));

    if (!user) {
      // Login required
      const openModalProps = {
        onConfirm: () => {},
        title: t`Log in`,
        confirmText: t`You are required to log in before you can customize a product.`,
        confirmButtonText: t`Log in`,
      };

      // See: https://decembersoft.com/posts/redux-saga-put-from-inside-a-callback/
      const channel = eventChannel(emitter => {
        openModalProps.onConfirm = () => {
          emitter({ doLogin: true });
        };

        return () => {};
      });

      yield put({
        type: 'APP/SET_OPEN_MODAL',
        payload: {
          openModalType: 'COMMON/CONFIRM_MODAL',
          openModalProps,
        },
      });

      const { doLogin } = yield take(channel);
      if (doLogin) {
        yield put({
          type: 'APP/LOGIN',
        });
      }
    } else {
      let productId;
      if (action.payload.copyProduct) {
        const response = yield* call(api.copyProdTemplate, {
          productId: action.payload.templateId,
          name: null,
          temp: true,
        });
        productId = response.data.id;
      } else {
        const product = yield* call(api.createProduct, {
          templateId: action.payload.templateId,
        });
        productId = product.id;
      }
      if (share) {
        // If customization is started from public workspace,
        // the application needs to be reloaded to the private side
        window.location.href = `${config.basePath}products/${productId}`;
      } else {
        const redirectUrl = `/products/${productId}`;
        if (action.payload.redirectInPlace) {
          yield put(replace(redirectUrl));
        } else {
          yield put(push(redirectUrl));
        }
      }
    }
  } catch (error) {
    yield put({ type: 'APP/AFTER_ERROR', payload: { error } });
  }
}

function* watchCreateProduct() {
  yield takeEvery('CONTENT/CREATE_PRODUCT', createProduct);
}

// Pages

function* readPages(action) {
  try {
    // TODO: Add support for type 'template' later
    const pagesById = yield* call(api.readPages, {
      productId: action.payload.productId,
    });
    yield put({ type: 'PRODUCTS/AFTER_READ_PAGES', payload: { pagesById } });
    // if the selected page hasn't been set, select the first one
    const selectedPageId = yield* select(
      state => state.products.selectedPageId
    );
    if (selectedPageId <= 0) {
      yield put({
        type: 'PRODUCTS/SELECT_NTH_PAGE',
        payload: {
          selectedPageIndex: 0,
        },
      });
    }
  } catch (error) {
    yield put({ type: 'PRODUCTS/AFTER_ERROR', payload: { error } });
  }
}

function* watchReadPages() {
  yield takeEvery('PRODUCTS/AFTER_PAGE_CRUD', readPages);
  yield takeEvery('PRODUCTS/SHOW_PRODUCT', readPages);
}

function* readPageTemplates() {
  try {
    const templateId = yield* select(state => {
      return state.products.product?.templateId;
    });
    if (!templateId) return;
    const pageTemplatesById = yield* call(api.readPageTemplates, {
      templateId,
    });
    yield put({
      type: 'PRODUCTS/AFTER_READ_PAGE_TEMPLATES',
      payload: { pageTemplatesById },
    });
  } catch (error) {
    yield put({ type: 'PRODUCTS/AFTER_ERROR', payload: { error } });
  }
}

function* watchReadPageTemplates() {
  yield takeEvery('PRODUCTS/AFTER_READ_PRODUCT', readPageTemplates);
}

function* addPage(action) {
  try {
    // TODO: Add support for type 'template' later
    const product = yield* select(state => {
      return state.products.product;
    });
    if (!product) return;

    const { id: selectedPageId } = yield* call(api.addPage, {
      productId: product.id,
      templatePageId: action.payload.templatePageId,
      productMassdataId: action.payload.productMassdataId,
    });
    return selectedPageId;
  } catch (error) {
    yield put({ type: 'PRODUCTS/AFTER_ERROR', payload: { error } });
  }
}

function* deletePage(action) {
  try {
    // TODO: Add support for type 'template' later
    const product = yield* select(state => {
      return state.products.product;
    });
    if (!product) return;
    const { pageId } = action.payload;

    yield* call(api.deletePage, {
      productId: product.id,
      pageId: pageId,
    });
  } catch (error) {
    yield put({ type: 'PRODUCTS/AFTER_ERROR', payload: { error } });
  }
}

function* setMetaElements(action) {
  try {
    const elements = action.payload.elementsById;
    const metaUpdaterElements = {};
    Object.values(elements).forEach((el: any) => {
      if (el.masterElement.metaId > 0) {
        const { metaMasterElementId } = el.masterElement;
        const metaUpdaterElement: any = Object.values(elements).find(
          (el: any) => el.masterElement.id === metaMasterElementId
        );
        const id = metaUpdaterElement
          ? metaUpdaterElement.id
          : metaMasterElementId;
        metaUpdaterElements[id] =
          metaUpdaterElements[id] === undefined
            ? [el.id]
            : [...metaUpdaterElements[id], el.id];
      }
    });
    yield put({
      type: 'PRODUCTS/SET_META_ELEMENTS',
      payload: { metaUpdaterElements },
    });
  } catch (error) {
    yield put({ type: 'PRODUCTS/AFTER_ERROR', payload: { error } });
  }
}

function* watchSetMetaElements() {
  yield takeEvery('PRODUCTS/AFTER_READ_ELEMENTS', setMetaElements);
}

function* readElementsOnPage(action) {
  try {
    const product = yield* select(state => {
      return state.products.product;
    });
    if (!product) return;
    const elements = yield* call(api.readElementsOnPage, {
      productId: product.id,
      pageId: action.payload.selectedPageId,
    });
    yield put({
      type: 'PRODUCTS/AFTER_READ_ELEMENTS',
      payload: { elementsById: elements },
    });
  } catch (error) {
    yield put({ type: 'PRODUCTS/AFTER_ERROR', payload: { error } });
  }
}

function* watchReadElementsOnPage() {
  yield takeEvery('PRODUCTS/READ_ELEMENTS_ON_PAGE', readElementsOnPage);
}

function* watchSelectPage() {
  yield takeEvery('PRODUCTS/SELECT_PAGE', readElementsOnPage);
}

function* selectNthPage(action) {
  const pagesById = yield* select(state => state.products.pagesById);
  const orderedPages = Object.values<any>(pagesById).sort(
    (a, b) => a.pageNumber - b.pageNumber
  );
  const index = Math.max(
    0,
    Math.min(action.payload.selectedPageIndex, orderedPages.length - 1)
  );
  yield put({
    type: 'PRODUCTS/SELECT_PAGE',
    payload: {
      selectedPageId: orderedPages[index] ? orderedPages[index].id : -1,
    },
  });
}

function* watchSelectNthPage() {
  yield takeEvery('PRODUCTS/SELECT_NTH_PAGE', selectNthPage);
}

function* updatePageOrder(action) {
  try {
    // TODO: Add support for type 'template' later
    const product = yield* select(state => {
      return state.products.product;
    });
    if (!product) return;

    yield* call(api.updatePageOrder, {
      productId: product.id,
      indexFrom: action.payload.indexFrom,
      indexTo: action.payload.indexTo,
    });
  } catch (error) {
    yield put({ type: 'PRODUCTS/AFTER_ERROR', payload: { error } });
  }
}

// Queue all page order/create/delete requests
function* watchPageUpdates() {
  const buffer = buffers.expanding<any>();
  const channel = yield actionChannel(
    ['PRODUCTS/UPDATE_PAGE_ORDER', 'PRODUCTS/DELETE_PAGE', 'PRODUCTS/ADD_PAGE'],
    buffer
  );
  while (true) {
    const action = yield take(channel);
    let newPageId = 0;
    if (action.type === 'PRODUCTS/UPDATE_PAGE_ORDER')
      yield* call(updatePageOrder, action);
    if (action.type === 'PRODUCTS/DELETE_PAGE') yield* call(deletePage, action);
    if (action.type === 'PRODUCTS/ADD_PAGE')
      newPageId = yield call(addPage, action);
    // reload pages when no other update in progress
    if (buffer.isEmpty()) {
      const product = yield* select(state => {
        return state.products.product;
      });
      const selectedPageId = yield* select(state => {
        return state.products.selectedPageId;
      });
      const pagesById = yield* select(state => {
        return state.products.pagesById;
      });
      if (!product) return;
      const pageNumber = pagesById[selectedPageId]?.pageNumber;
      yield put({
        type: 'PRODUCTS/AFTER_PAGE_CRUD',
        payload: { productId: product.id },
      });
      // Wait for the fresh pages to arrive from server
      yield take('PRODUCTS/AFTER_READ_PAGES');
      // select the newly added page
      if (newPageId) {
        yield put({
          type: 'PRODUCTS/SELECT_PAGE',
          payload: { selectedPageId: newPageId },
        });
      }
      // page has been removed, select the first one
      else if (!pageNumber) {
        yield put({
          type: 'PRODUCTS/SELECT_NTH_PAGE',
          payload: { selectedPageIndex: 0 },
        });
      }
    }
  }
}

function* setLoadingPages() {
  const selectedPageId = yield* select(state => {
    return state.products.selectedPageId;
  });
  let pagesById: Record<string, { id: number; pageNumber: number }> =
    yield* select(state => {
      return state.products.pagesById;
    });
  // Selected page has been removed, add that to the loading list
  if (selectedPageId > 0 && !pagesById[selectedPageId]) {
    pagesById = {
      ...pagesById,
      [selectedPageId]: {
        id: selectedPageId,
        pageNumber: NaN,
      },
    };
  }
  // if there exists placeholders for pages to be added, show loading on all of the pages
  const addingPage = Object.values<any>(pagesById).some(x => x.id < 0);
  yield put({
    type: 'PRODUCTS/SET_PAGES_LOADING',
    payload: {
      pagesLoading:
        addingPage ||
        Object.values<any>(pagesById)
          .filter(x => x.id !== x.pageNumber) // pages to be reloaded are those of which pageNumber has been changed
          .map(x => x.id),
    },
  });
}

function* watchLoadingPages() {
  yield takeEvery('PRODUCTS/SET_PAGES_BY_ID', setLoadingPages);
  yield takeEvery('PRODUCTS/AFTER_READ_PAGES', setLoadingPages);
}

// Elements

function* readElements(action) {
  try {
    // TODO: Add support for type 'template' later
    const elementsById = yield* call(api.readElements, {
      productId: action.payload.productId,
    });
    yield put({
      type: 'PRODUCTS/AFTER_READ_ELEMENTS',
      payload: { elementsById },
    });
  } catch (error) {
    yield put({ type: 'PRODUCTS/AFTER_ERROR', payload: { error } });
  }
}

function* watchReadElements() {
  yield takeEvery('PRODUCTS/SHOW_PRODUCT', readElements);
}

function* readElementAvailableOptions(action) {
  try {
    const filesById = yield* call(api.readAvailableOptions, {
      elementId: action.payload.elementId,
    });

    // Read text file contents
    yield all(
      Object.values(filesById)
        .filter(file => !file.removed && file.mimeGroup === 'text')
        .map(file => {
          return put({
            type: 'CONTENT/READ_FILE_CONTENT',
            payload: {
              id: !file.removed && file.node.id,
            },
          });
        })
    );

    yield put({
      type: 'CONTENT/AFTER_READ_FILES',
      payload: { filesById },
    });
  } catch (error) {
    yield put({
      type: 'CONTENT/AFTER_READ_FILE_ERROR',
      payload: { error },
    });
  }
}

function* watchReadElementAvailableOptions() {
  yield takeEvery(
    'PRODUCTS/READ_ELEMENT_AVAILABLE_OPTIONS',
    readElementAvailableOptions
  );
}

function* updateElement(action) {
  try {
    const metaUpdaterElements = yield* select(state => {
      return state.products.metaUpdaterElements;
    });
    const elementsById = yield* select(state => {
      return state.products.elementsById;
    });
    const selectedPageId = yield* select(state => {
      return state.products.selectedPageId;
    });
    const isMetaUpdater =
      Object.keys(metaUpdaterElements).indexOf(action.payload.elementId) !== -1;
    yield put({
      type: 'PRODUCTS/ADD_TO_LOADING_ELEMENTS',
      payload: {
        elementIds: isMetaUpdater
          ? [
              action.payload.elementId,
              ...metaUpdaterElements[action.payload.elementId],
            ]
          : [action.payload.elementId],
      },
    });
    const canHeightIncrease = yield* select(state => {
      return state.products.elementsById[action.payload.elementId]
        ?.masterElement.canHeightIncrease;
    });
    const response = yield* call(api.updateElement, {
      elementId: action.payload.elementId,
      // HAX: Api doesn't yet properly update the element's location so don't update it
      // UI doesn't so far even support adjusting the location so no harm done.
      // TODO: fix location updating on Api
      // (Api uses the coordinates of the top left corner while element's location should be updated as coordinates of its origo)
      element: { ...action.payload.element, location: null },
    });
    if (!elementsById[action.payload.elementId]) return;
    if (canHeightIncrease) {
      const { layers, img_height: imageHeight } = response.data;
      yield put({
        type: 'PRODUCTS/UPDATE_IMAGE_HEIGHT',
        payload: { imageHeight },
      });
      yield all(
        layers
          .filter(
            el =>
              Object.keys(elementsById).indexOf(`${el.user_element_id}`) !== -1
          )
          .map(el => {
            const { user_element_id: elementId, w, x, h, y } = el;
            return put({
              type: 'PRODUCTS/UPDATE_LOCATION',
              payload: {
                elementId,
                newLocation: { w, x, h, y },
              },
            });
          })
      );
    }
    if (isMetaUpdater) {
      yield put({
        type: 'PRODUCTS/READ_ELEMENTS_ON_PAGE',
        payload: { selectedPageId },
      });
    }
    yield put({
      type: 'PRODUCTS/AFTER_UPDATE_ELEMENT',
      payload: {
        pageIds: [
          ...new Set([selectedPageId, ...response?.data?.updated_pages]),
        ],
      },
    });
  } catch (error) {
    yield put({ type: 'PRODUCTS/AFTER_ERROR', payload: { error } });
  }
}

function* updateProductMetaLang(action) {
  try {
    const selectedPageId = yield* select(state => {
      return state.products.selectedPageId;
    });

    const metaUpdaterElements = yield* select(state => {
      return state.products.metaUpdaterElements;
    });
    const elements = yield* select(state => state.products.elementsById);

    const langUpdaterIds = Object.keys(metaUpdaterElements).filter(
      id => elements[id]?.masterElement.type === 'IMAGE'
    );

    yield put({
      type: 'PRODUCTS/ADD_TO_LOADING_ELEMENTS',
      payload: {
        elementIds: [
          ...langUpdaterIds,
          ...langUpdaterIds.flatMap(id => metaUpdaterElements[id] ?? []),
        ],
      },
    });

    yield* call(api.updateProductMetaLang, {
      productId: action.payload.productId,
      product: { ...action.payload.product },
    });

    const pagesById = yield* select(state => state.products.pagesById);

    yield put({
      type: 'PRODUCTS/READ_ELEMENTS_ON_PAGE',
      payload: { selectedPageId },
    });

    yield put({
      type: 'PRODUCTS/AFTER_UPDATE_ELEMENT',
      payload: {
        pageIds: [...new Set([selectedPageId, ...Object.keys(pagesById)])],
      },
    });
  } catch (error) {
    yield put({ type: 'PRODUCTS/AFTER_ERROR', payload: { error } });
  }
}

function* watchUpdateProductMetaLang() {
  yield takeEvery('PRODUCTS/UPDATE_PRODUCT_META_LANG', updateProductMetaLang);
}

function* generatePreviews(
  action: ReturnType<typeof products.actions.generatePreviews>
) {
  let productId: string | null;
  if ('productId' in action.payload) productId = action.payload.productId;
  else {
    const { data: file } = yield* putResolve(
      getFile.initiate({ id: action.payload.nodeId })
    );
    if (!file || file.removed) return;
    productId = getProductId(file);
  }

  if (!productId) return;

  yield* call(api.finishEditingProduct, { productId });
}

function* watchGeneratePreviews() {
  yield takeEvery(products.actions.generatePreviews.type, generatePreviews);
}

function* watchUpdateElement() {
  yield takeEvery('PRODUCTS/UPDATE_ELEMENT', updateElement);
}

function* selectElement(action) {
  try {
    if (action.payload.elementId) {
      const element = yield* select(state => {
        return state.products.elementsById[action.payload.elementId];
      });
      if (
        element.element.type === 'IMAGE' ||
        (element.masterElement.availableTextUuids &&
          element.masterElement.availableTextUuids.length)
      ) {
        yield put({
          type: 'APP/TOGGLE_RIGHT',
          payload: { open: true, content: '/productElement' },
        });
      }
    }
  } catch (error) {
    yield put({ type: 'PRODUCTS/AFTER_ERROR', payload: { error } });
  }
}

function* watchSelectElement() {
  yield takeEvery('PRODUCTS/SELECT_ELEMENT', selectElement);
}

function* cropElement(action) {
  try {
    const element = yield* select(
      state => state.products.elementsById[action.payload.elementId]
    );

    if (
      !('imageUuid' in element.element && element.element.imageUuid) ||
      element.element.imageUuid === -1
    ) {
      throw new Error('No image selected');
    }

    yield put(commonContent.actions.readFile(element.element.imageUuid));

    const { error } = yield race({
      success: take(contentActions.afterReadFile.type),
      error: take(contentActions.afterReadContentError.type),
    });

    if (error) {
      throw new Error('Could not read file');
    }

    const file =
      element.element.imageUuid &&
      (yield* select(
        state =>
          state.commonContent.filesById[
            (element.element as ImageElement).imageUuid as string
          ]
      ));

    if (!file) {
      throw new Error('Could not read file');
    }

    const { w: x, h: y } = element.element.location;
    const proportions = { x, y };

    const chan = channel<{ elementId: string; crop: any }>();

    yield put({
      type: 'APP/SET_OPEN_MODAL',
      payload: {
        openModalType: 'PRODUCT/IMAGE_CROP',
        openModalProps: {
          file: file.file,
          proportions,
          onCrop: crop =>
            chan.put({
              elementId: element.id,
              crop,
            }),
        },
      },
    });

    const { crop } = yield take(chan);

    yield put({
      type: 'PRODUCTS/UPDATE_ELEMENT',
      payload: {
        elementId: element.id,
        element: getCroppedElement(element, crop),
      },
    });
  } catch (error) {
    console.error(error);
    yield put({ type: 'PRODUCTS/AFTER_ERROR', payload: { error } });
  }
}

function* watchCropElement() {
  yield takeEvery('PRODUCTS/CROP_ELEMENT', cropElement);
}

// Combine

export default function* sagas(): any {
  yield all([
    fork(watchCreateProduct),
    fork(watchReadPages),
    fork(watchReadPageTemplates),
    fork(watchReadElements),
    fork(watchReadElementsOnPage),
    fork(watchReadElementAvailableOptions),
    fork(watchPageUpdates),
    fork(watchLoadingPages),
    fork(watchUpdateElement),
    fork(watchGeneratePreviews),
    fork(watchSelectPage),
    fork(watchSelectNthPage),
    fork(watchSetMetaElements),
    fork(watchSelectElement),
    fork(watchCropElement),
    fork(watchUpdateProductMetaLang),
  ]);
}
