import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDrag, useDrop } from 'react-dnd';
import { useSelector } from 'react-redux';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { t } from '@lingui/macro';
import Tooltip from '@mui/material/Tooltip';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';

import { useContentMode } from './utils';
import FileDownloadButton from './FileDownloadButton';
import ThumbnailDivider from './ThumbnailDivider';
import {
  useOpenWorkspace,
  useRemoveFromWorkspace,
  useWorkspaceId,
} from '../workspaces/hooks';
import { useCustomizeProductOption } from '../actionOptions/useCustomizeProductOption';
import { useLikeMaterialOption } from '../actionOptions/useLikeMaterialOption';
import { ActionOptionButton } from '~common/navigation/ActionOptionButton';

import { useWindowSize } from '~common/utils/layout.utils';
import ItemThumbnail, { ItemThumbnailProps } from '~items/ItemThumbnail';
import { getLangValue } from '~common/app.utils';
import {
  EMBED_LEVEL,
  getItemFormat,
  isEmbeded,
  updateContentCriteria,
  useCriteria,
  useCurrentFolderId,
  useUrlParams,
} from '~common/content.utils';
import { DeleteIcon, DownloadIcon } from '~misc/icons';
import IconButton from '~inputs/IconButton';
import { DragSource, File } from '~common/content.types';
import { isDesktop } from '~common/utils/styled.utils';
import { getImageSrc } from '~common/utils/img.utils';
import {
  getAcceptedDndTypes,
  getDndType,
  useOnNodeDrop,
} from '~common/misc/drag/utils';

interface Props extends Omit<ItemThumbnailProps, 'variant' | 'name'> {
  id: string;
  component?: 'div' | 'li';
  itemId: string;
  prevItem: any;
  item: File;
  childrenCount?: number | string;
  thumbnailType?: string;
  createProduct?: any;
  showActions?: boolean;
  inWorkspace?: boolean;
  // Styling
  expandable?: boolean;
  showDividers?: boolean;
  animate?: boolean;
  fullwidth?: boolean;
  /** Is the thumbnail being dragged from a specific place? */
  dragSource?: DragSource;
  childrenItems?: File[];
}

export default function Thumbnail({
  id,
  itemId,
  prevItem,
  item,
  showDividers = false,
  checked,
  thumbnailType,
  showActions = true,
  showCheckbox,
  dragSource,
  inWorkspace,
  childrenItems,
  component = 'div',
  ...thumbnailProps
}: Props) {
  const checkedContent = useSelector(
    state => state.content.checkedContent?.items
  );
  const checkedWorkspaceContent = useSelector(
    state => state.workspaces.checkedWorkspaceContent
  );

  const windowSize = useWindowSize();
  const desktop = isDesktop(windowSize.innerWidth);

  const textContent = item && item.textContent;

  const { openWorkspace } = useOpenWorkspace();
  const { removeFromWorkspace } = useRemoveFromWorkspace();

  // Monitors whether touch is active
  const [touchActive, setTouchActive] = useState(false);
  const handleTouchStart = () => {
    setTouchActive(true);
  };
  const handleTouchEnd = () => {
    setTouchActive(false);
  };

  const [menuOpen, setMenuOpen] = useState(false);

  const filesById = useSelector(state => state.commonContent.filesById);

  const { mode } = useContentMode();
  const workspaceId = useWorkspaceId();
  const currentFolderId = useCurrentFolderId();
  const folderId = inWorkspace ? workspaceId : currentFolderId || undefined;
  const isSearch = !inWorkspace && mode === 'search';

  const name = getLangValue(item.namesByLang) || item.name;
  const altText = getLangValue(item.altTextsByLang);
  const description = getLangValue(item.descriptionsByLang);
  const contentCriteria = useCriteria();

  const { embed } = useUrlParams();

  // Used to remove default drag preview. Using CustomDragLayer instead.
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);
  const file = filesById?.[item.node.id]?.file;
  let imageUrl: string | undefined = getImageSrc(file ?? item, 'thumbnail');
  // With folders, if the preview image is defined, we ask for the thumbnail of the folder node
  // if not defined, we will use the default folder image
  // This is done to check the rights to the folder rather than the preview image node
  if (item.isFolder && !item.propertiesById?.['nibo:preview-uuid'])
    imageUrl = undefined;
  // Generic thumbnail image for carts
  else if (item.isCart) imageUrl = undefined;
  // Display text content rather than the thumbnail
  else if (textContent) imageUrl = undefined;

  const format = getItemFormat(item);

  const [, drag, preview] = useDrag({
    item: {
      item,
      itemIds:
        dragSource === 'sidebar' &&
        checkedWorkspaceContent.length > 0 &&
        checkedWorkspaceContent.includes(itemId)
          ? checkedWorkspaceContent
          : dragSource !== 'shopping' &&
            checkedContent &&
            checkedContent.size > 0 &&
            checkedContent.has(itemId)
          ? [...checkedContent]
          : [itemId],
      name,
      showCustomDragLayer: true,
      type: thumbnailType || getDndType(item),
      dragSource: dragSource,
    },
    type: thumbnailType || getDndType(item),
    collect: monitor => ({
      draggedItem: monitor.getItem(),
    }),
    // Disable drag completely on touch events to prevent unwanted ghost images
    canDrag: () => !touchActive,
  });

  const onDrop = useOnNodeDrop(item);

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: getAcceptedDndTypes(item),
    drop: onDrop,
    options: {
      // Shrink on top of workspace folders on sidebar
      shrinkOnHover:
        inWorkspace && item.node.inCart && item.isFolder && !item.node.isLink,
    },
    canDrop: (droppable, monitor) =>
      item.isFolder &&
      !item.node.isLink &&
      !droppable.itemIds.includes(item.node.id) &&
      monitor.isOver({ shallow: true }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const childrenCount = childrenItems
    ? childrenItems.length
    : item.fileCountAll ?? item.fileCount;

  const customizeOption = useCustomizeProductOption(item);
  const likeOption = useLikeMaterialOption(item);

  // Action buttons hovered on top of thumbnail
  const actions = !showActions ? null : item.node.inShoppingCart ? (
    <Tooltip title={t`Remove`}>
      <IconButton
        onClick={() => removeFromWorkspace([item.node.id])}
        color="primary"
        size="small"
        aria-label={t`Remove`}
      >
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  ) : item.isFile ? (
    <>
      {!item.node.inShoppingCart && (
        <ActionOptionButton option={likeOption} type="icon" />
      )}
      <Tooltip title={t`Download`}>
        <FileDownloadButton
          color="primary"
          size="small"
          icon={DownloadIcon}
          fileId={item && item.node.id}
          folderId={folderId}
          isSearch={isSearch}
          onOpen={() => setMenuOpen(true)}
          onExit={() => setMenuOpen(false)}
        />
      </Tooltip>
    </>
  ) : (item.isMasterProduct || item.isUserProduct) && customizeOption ? (
    <ActionOptionButton option={customizeOption} type="icon" />
  ) : desktop && item.isCart ? (
    <Tooltip title={t`Open in dock`}>
      <IconButton
        onClick={() => openWorkspace && openWorkspace(item.node.id, 'sidebar')}
        color="primary"
        size="small"
        aria-label={t`Open in dock`}
      >
        <KeyboardArrowRight />
      </IconButton>
    </Tooltip>
  ) : item.isFolder && !inWorkspace ? (
    <>
      {<ActionOptionButton option={likeOption} type="icon" />}
      <Tooltip title={t`Open in detail view`}>
        <IconButton
          onClick={() =>
            updateContentCriteria(
              currentFolderId,
              {
                ...contentCriteria,
                selectedIndex: thumbnailProps.index ?? 0,
                selectedId: item.node.id,
              },
              'push'
            )
          }
          color="primary"
          size="small"
          aria-label={t`Open in detail view`}
        >
          <KeyboardArrowDown />
        </IconButton>
      </Tooltip>
    </>
  ) : null;

  return (
    <StyledWrapper
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      open={menuOpen}
      as={component}
    >
      {showDividers ? (
        <ThumbnailDivider prevItem={prevItem} nextItem={item} />
      ) : null}

      <ItemThumbnail
        {...thumbnailProps}
        name={name}
        altText={altText}
        description={description}
        imageUrl={imageUrl}
        format={format}
        childrenCount={childrenCount}
        variant={childrenItems ? 'small' : 'medium'}
        itemType={item.isFolder ? 'folder' : item.isCart ? 'cart' : 'any'}
        isLink={item.node.isLink}
        checked={checked}
        itemId={itemId}
        item={item}
        showCheckbox={
          showCheckbox && !isEmbeded(EMBED_LEVEL.JUST_CONTENT, embed)
        }
        dropHover={isOver && canDrop}
        dndRefs={{ drag, drop }}
        textContent={textContent}
        actions={actions}
        indicateSynkka={item.indicateSynkka}
        mode={mode}
      />
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div<{ open: boolean }>`
  @media print {
    /* TODO: only when executing print file action */
    display: none;
  }

  list-style: none;
  width: 100%;

  ${({ open }) =>
    open &&
    `.thumbnail-actions {
    visibility: visible !important;
  }`}
`;
