import { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '~common/utils/ducks.utils';
import { File } from '~common/content.types';

export type WorkspacesState = {
  currentWorkspaceId?: string;
  checkedWorkspaceContent: string[];
};

const initialState: WorkspacesState = {
  currentWorkspaceId: undefined,
  checkedWorkspaceContent: [],
};

type SetChecked = {
  item: File;
  checked: boolean;
  childrenItems?: File[];
};

const slice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {
    setCurrentWorkspaceId: (state, action: PayloadAction<string>) => {
      state.currentWorkspaceId = action.payload;
    },

    setCheckedWorkspaceContent: (state, action: PayloadAction<string[]>) => {
      state.checkedWorkspaceContent = action.payload;
    },

    setWorkspaceItemChecked: (state, action: PayloadAction<SetChecked>) => {
      const itemId = action.payload.item.node.id;
      const currentContent = new Set(state.checkedWorkspaceContent);

      if (action.payload.checked) {
        currentContent.add(itemId);
      } else {
        currentContent.delete(itemId);
      }

      const children = action.payload.childrenItems;
      if (children) {
        if (action.payload.checked) {
          children.forEach(item => currentContent.add(item.node.id));
        } else {
          children.forEach(item => currentContent.delete(item.node.id));
        }
      }

      state.checkedWorkspaceContent = [...currentContent];
    },
  },
});

export default slice.reducer;

export const workspaces = {
  actions: slice.actions,
  selector: {},
};
