import React from 'react';
import { t } from '@lingui/macro';

import { useIsWorkspaceSaved } from '../hooks';

import { Fileish, fileExists } from '~common/content.types';
import { ModalOption } from '~common/navigation/MenuButton';
import { SaveIcon } from '~common/misc/icons';

export function useSaveWorkspaceOption(
  workspace: Fileish | undefined
): ModalOption {
  const isSaved = useIsWorkspaceSaved(workspace);

  return {
    value: 'save',
    disabled: !fileExists(workspace) || !workspace.isCart || isSaved,
    title: t`Save workspace`,
    icon: <SaveIcon fontSize="small" />,
    modalType: 'WORKSPACE/SAVE',
    modalProps: {
      workspace: workspace,
    },
  };
}
