import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { t } from '@lingui/macro';

import { useOpenWorkspace } from './hooks';

import { afterQueryMessages } from '~utils/api.utils';
import { mapTree } from '~utils/fn.utils';
import config from '~common/config';
import { convertFilesIn, convertTextsOut } from '~common/content.utils';
import {
  File,
  Content,
  FolderTreeEntry,
  TreeResponseNode,
  FileType,
} from '~common/content.types';
import { apiBase } from '~common/api.base';
import { getFileTag } from '~common/content.api';
import { Lang } from '~common/utils/i18n';
import { TranslatedData } from '~common/common.types';
import { partition } from '~common/utils/fn.utils';

/**
 * Groups all root-level workspaces into "virtual folders" where all
 * workspaces owned by one user is in a folder with the user's name as
 * the folder's name. This is supposed to help customers's admins to manage
 * all the workspaces in an application
 */
function groupWorkspaceTree(rootNode: TreeResponseNode): TreeResponseNode {
  const [rootFolders, rootWorkspaces] = partition(
    rootNode.children,
    n => n.node.fileType === 'nt:folder'
  );

  // If (for some reason) any workspaces don't have `createdBy`, list them last
  const leftovers: TreeResponseNode[] = [];

  // Group all root workspaces accordingly
  const workspacesByUsername = rootWorkspaces.reduce((acc, item) => {
    const currentUsername = item.node.createdBy?.username;

    if (currentUsername) {
      if (currentUsername in acc) {
        acc[currentUsername] = [...acc[currentUsername], item];
      } else {
        acc[currentUsername] = [item];
      }
    } else {
      leftovers.push(item);
    }

    return acc;
  }, {} as Record<string, TreeResponseNode[]>);

  // Add grouped workspaces to "virtual folders"
  const groupedWorkspaces = Object.values(workspacesByUsername)
    .map(nodes => {
      const { username, familyName, firstName } = nodes[0].node.createdBy ?? {};

      let name = username as string; // This is known to exist because of previous grouping
      if (firstName && familyName) {
        name = `${firstName} ${familyName}`;
      } else if (firstName) {
        name = firstName;
      } else if (familyName) {
        name = familyName;
      }

      return {
        node: {
          id: `user-grouped-workspaces-${username}`,
          name,
          namesByLang: {},
          fileType: 'workspaceGroup' as FileType,
          rights: null,
          userRights: null,
          createdBy: null,
        },
        depth: 2,
        children: nodes,
      };
    })
    .sort((a, b) => a.node.name.localeCompare(b.node.name));

  return {
    ...rootNode,
    children: [...rootFolders, ...groupedWorkspaces, ...leftovers],
  };
}

/** Enforces maximum nesting depth in workspace tree */
function filterWorkspaceTree(node: TreeResponseNode, depth: number) {
  if (depth <= 0) return undefined;
  if (depth === 1)
    return {
      ...node,
      children: [],
    };

  return {
    ...node,
    children: node.children.map(child => {
      const newDepth =
        // hope this works the way it was intended
        child.node.fileType === 'nt:cart' ? depth - 1 : depth;
      return filterWorkspaceTree(child, newDepth);
    }),
  };
}

type WorkspaceMutationParams = {
  workspaceId: string;
  itemIds: string[];
  newNamesById?: Record<string, string>;
};

type SaveWorkspaceParams = {
  workspaceId: string;
  defaultLanguage: string;
  workspaceDetails?: {
    names: TranslatedData;
    descriptions: TranslatedData;
    instructions: TranslatedData;
  };
  password?: string;
  sharingForNow?: boolean;
  sharingEnd?: string;
  sharing?: string;
  basketDenyCrawlerBot?: boolean;
};

type ShareWorkspaceParams = {
  workspaceId: string;
  selectedLanguage: Lang;
  senderId?: number;
  recipients: string[];
  subject: string;
  message: string;
  validForNow: boolean;
  sharingEnd?: string;
};

type PublishWorkspaceParams = {
  workspaceId: string;
  sharingForNow: boolean;
  sharingEnd?: string;
};

type RemoveShareParams = {
  workspaceId: string;
  ids: number[];
};

const extendedApi = apiBase.injectEndpoints({
  endpoints: builder => ({
    getDefaultWorkspace: builder.query<{ id: string }, { username: string }>({
      query: ({ username }) => ({
        url: `/fileIds/${config.customer}/_users/${username}/_DEFAULT_CART`,
        method: 'get',
      }),
      providesTags: ['DefaultWorkspace'],
    }),

    getWorkspaces: builder.query<
      Content[],
      { customerId: string; limit?: number }
    >({
      query: ({ customerId, limit = 15 }) => ({
        url: `/customers/${customerId}/contents`,
        method: 'get',
        params: {
          type: 'any',
          limit,
          materialType: 'cart',
          sort: 'orderByCreated',
        },
      }),
    }),

    getPinnedWorkspaces: builder.query<
      {
        pinned: File[];
        campaign: File[];
        shopping: File[];
      },
      number | void
    >({
      queryFn: async (limit = 15, _, __, fetch) => {
        const results = await Promise.all(
          [undefined, 'campaign', 'shopping'].map(cartType =>
            fetch({
              url: '/cart/frontpage',
              method: 'get',
              params: {
                limit,
                include: 'basic,settings',
                cartType,
              },
            })
          )
        );

        if (results.some(item => item.error)) {
          return {
            error: results.find(item => item.error) as NonNullable<
              typeof results[number]['error']
            >,
          };
        } else {
          const [pinned, campaign, shopping] = results.map(item =>
            convertFilesIn((item?.data as unknown[]) ?? []).filter(
              (item): item is File => !item.removed
            )
          );
          return { data: { pinned, campaign, shopping } };
        }
      },
    }),

    getWorkspaceTree: builder.query<
      FolderTreeEntry,
      { workspacesFolderId: string; language: string }
    >({
      query: ({ workspacesFolderId, language }) => ({
        url: `/folders/${workspacesFolderId}/files/tree?lang=${language}&include=basic,users`,
        method: 'get',
      }),
      transformResponse: (data: TreeResponseNode) =>
        filterWorkspaceTree(groupWorkspaceTree(data), 2),
      providesTags: results => [
        'WorkspaceTree',
        ...(results ? mapTree(results, node => getFileTag(node.node.id)) : []),
      ],
    }),

    saveWorkspace: builder.mutation<void, SaveWorkspaceParams>({
      query: ({
        workspaceId,
        defaultLanguage,
        workspaceDetails,
        password,
        sharing,
        basketDenyCrawlerBot,
        ...sharingArgs
      }) => {
        const propertiesById = {
          ...convertTextsOut(workspaceDetails?.names ?? {}, 'nibo:name_'),
          ...convertTextsOut(
            workspaceDetails?.descriptions ?? {},
            'nibo:description_'
          ),
        };

        const data = {
          name: workspaceDetails?.names[defaultLanguage],
          propertiesById,
          infoByLang: workspaceDetails?.instructions,
          ...sharingArgs,
        };

        if (password) {
          data.propertiesById['nibo:public-password'] = true;
          data.propertiesById['nibo:public-password-value'] = password;
        } else {
          data.propertiesById['nibo:public-password'] = false;
        }

        if (sharing) {
          data.propertiesById['nibo:sharing'] = sharing;
        }

        data.propertiesById['nibo:basket-deny-crawler-bot'] =
          basketDenyCrawlerBot;

        return { url: `/files/${workspaceId}`, method: 'put', data };
      },
      onQueryStarted: (_, args) =>
        afterQueryMessages(t`Sharing saved`, t`Save failed`, args),
      invalidatesTags: (_, __, { workspaceId }) => [
        'DefaultWorkspace',
        getFileTag(workspaceId),
      ],
    }),

    shareWorkspace: builder.mutation<void, ShareWorkspaceParams>({
      query: ({ workspaceId, ...data }) => ({
        url: `/cart/${workspaceId}/send`,
        method: 'put',
        data,
      }),
      onQueryStarted: (_, args) =>
        afterQueryMessages(t`Sharing ready`, t`Share failed`, args),
      invalidatesTags: (_, __, { workspaceId }) => [getFileTag(workspaceId)],
    }),

    publishWorkspace: builder.mutation<void, PublishWorkspaceParams>({
      query: ({ workspaceId, ...data }) => ({
        url: `/files/${workspaceId}/actions/publish`,
        method: 'put',
        data,
      }),
      onQueryStarted: (_, args) =>
        afterQueryMessages(t`Published`, t`Publishing failed`, args),
      invalidatesTags: (_, __, { workspaceId }) => [getFileTag(workspaceId)],
    }),

    removeWorkspaceShare: builder.mutation<void, RemoveShareParams>({
      query: ({ workspaceId, ids }) => ({
        url: '/cart/remove_share',
        method: 'put',
        data: {
          nodeId: workspaceId,
          ids,
        },
      }),
      onQueryStarted: (_, args) =>
        afterQueryMessages(t`Share removed`, t`Remove failed`, args),
      invalidatesTags: (_, __, { workspaceId }) => [getFileTag(workspaceId)],
    }),

    addToWorkspace: builder.mutation<{ id: string[] }, WorkspaceMutationParams>(
      {
        query: ({ workspaceId, itemIds, newNamesById }) => ({
          url: `/folders/${workspaceId}/files`,
          method: 'post',
          data: {
            fileType: 'nt:linkedFile',
            sourceFileIds: itemIds,
            newNames: newNamesById,
          },
        }),
        invalidatesTags: (_, __, { workspaceId }) => [getFileTag(workspaceId)],
      }
    ),

    clearWorkspace: builder.mutation<{ success: boolean }, string>({
      queryFn: async (workspaceId, _, __, fetch) => {
        const result = await fetch({
          url: `/folders/${workspaceId}/files/`,
          method: 'get',
        });
        if (result.error) return { error: result.error };

        const itemIds =
          (result.data as { id: string }[])?.map(item => item.id) ?? [];

        if (itemIds.length > 0) {
          const deleteResult = await fetch({
            url: `/folders/${workspaceId}/files?ids=${itemIds.join(',')}`,
            method: 'delete',
          });
          if (deleteResult.error) return { error: deleteResult.error };
          return { data: { success: true } };
        }
        return { data: { success: true } };
      },
      invalidatesTags: (_, __, workspaceId) => [getFileTag(workspaceId)],
    }),
  }),
});

const {
  useGetDefaultWorkspaceQuery,
  util: { invalidateTags },
} = extendedApi;

export function useGetDefaultWorkspaceId() {
  const username = useSelector(state => state.app.user?.username) ?? '';
  const configById = useSelector(state => state.app.customer?.configById);
  const workspaceId = useSelector(state => state.workspaces.currentWorkspaceId);
  const { openWorkspace } = useOpenWorkspace();

  const queryResult = useGetDefaultWorkspaceQuery(
    username ? { username } : skipToken
  );
  useEffect(() => {
    if (!workspaceId && queryResult.data) {
      const configWorkspace = configById?.['workspaces.active.default'] as
        | number
        | undefined;
      openWorkspace(
        configWorkspace ? `${configWorkspace}` : queryResult.data.id,
        'sidebar'
      );
    }
  }, [queryResult.isSuccess, queryResult.data?.id]);

  return queryResult;
}

export const invalidateWorkspaceTree = () => invalidateTags(['WorkspaceTree']);
export const invalidateDefaultWorkspace = () =>
  invalidateTags(['DefaultWorkspace']);

export const {
  useGetWorkspacesQuery,
  useGetPinnedWorkspacesQuery,
  useGetWorkspaceTreeQuery,
  useSaveWorkspaceMutation,
  useShareWorkspaceMutation,
  usePublishWorkspaceMutation,
  useRemoveWorkspaceShareMutation,
  useAddToWorkspaceMutation,
  useClearWorkspaceMutation,
} = extendedApi;
