import styled from 'styled-components';
import Bar from './Bar';

const ButtonBar = styled(Bar)`
  border-bottom: none;
  margin-top: ${props => props.theme.spacing(4)};
  justify-content: center;
  min-height: initial;
  padding: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(2)};
  gap: ${props => props.theme.spacing(1)};
  flex-wrap: wrap;
  @media screen and (max-width: 600px) {
    button,
    .portal {
      word-break: break-word;
      flex-wrap: wrap;
      flex: 1;
      flex-basis: 0%;
      min-width: fit-content;
    }
    textarea {
      display: block;
    }
  }
`;

export default ButtonBar;
