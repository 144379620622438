import React from 'react';
import styled from 'styled-components';

import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

interface SideBarSectionProps {
  title: React.ReactElement;
}

const SideBarSection = ({
  title,
  children,
}: React.PropsWithChildren<SideBarSectionProps>) => (
  <SectionWrapper>
    <Title>{title}</Title>
    <Content>{children}</Content>
  </SectionWrapper>
);

interface SideBarSectionCollapseProps {
  title: React.ReactElement;
  open?: boolean;
  handleCollapse?: () => void;
}

const SideBarSectionCollapse = ({
  title,
  open,
  children,
  handleCollapse,
}: React.PropsWithChildren<SideBarSectionCollapseProps>) => (
  <SectionWrapper open={open}>
    <TitleButton onClick={handleCollapse}>
      <Title>
        {title}
        {!open ? <ArrowDown /> : <ArrowUp />}
      </Title>
    </TitleButton>
    {open && <Content>{children}</Content>}
  </SectionWrapper>
);

const Content = styled.div``;

const SectionWrapper = styled.div<{ open?: boolean }>`
  display: block;
  margin-bottom: ${props => (props.open ? '25px' : '15px')};
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background-color: #186d8a;
  color: white;
`;

const iconSize = '14px';
const ArrowUp = styled(KeyboardArrowUp)`
  && {
    cursor: pointer;
    font-size: ${iconSize};
  }
`;

const ArrowDown = styled(KeyboardArrowDown)`
  && {
    cursor: pointer;
    font-size: ${iconSize};
  }
`;

const TitleButton = styled.div`
  width: 100%;
  cursor: pointer;
`;

export { SideBarSection, SideBarSectionCollapse };
