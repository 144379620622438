import { createAction, handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { startOfYear, endOfYear, startOfDay, endOfDay } from 'date-fns';

/**
 * ACTIONS
 */
export const setDateRange = createAction('SET_DATE_RANGE');
export const setCustomReports = createAction('SET_CUSTOM_REPORTS');

/**
 * REDUCERS
 */
const initialState = {
  startDate: startOfYear(new Date()),
  endDate: endOfYear(new Date()),
  customReports: [],
};

export default handleActions(
  {
    SET_DATE_RANGE: (state, { payload: { startDate, endDate } }) =>
      update(state, {
        startDate: { $set: startOfDay(startDate) },
        endDate: { $set: endOfDay(endDate) }
      }),
    SET_CUSTOM_REPORTS: (state, { payload: { data } }) =>
      update(state, {
        customReports: { $set: data }
      })
  },
  initialState
);
