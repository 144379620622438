import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';

import { Criteria, GenericData } from '~common/common.types';
import { useThrottledWindowSize } from '~common/utils/layout.utils';

export function getNumOfPages(pageSize: number, totalCount: number) {
  return Math.max(1, Math.ceil(totalCount / pageSize));
}

export type SelectCallback = (
  index: number,
  eventTargetIndex: number,
  keycode?: number
) => void;

export function prevFunc(
  index: number,
  paging: Pick<Criteria, 'page' | 'pageSize'>,
  onUpdatePaging: (newPaging: Partial<Criteria>) => void,
  onSelectItem: SelectCallback
) {
  if (index > 0) {
    // There is a previous item on current page
    return () => onSelectItem(index - 1, index);
  }
  if (paging.page > 0) {
    // There is a previous page
    return () =>
      onUpdatePaging({
        page: paging.page - 1, // Load the previous page first...
        selectedIndex: paging.pageSize - 1, // ...and then select the last item
      });
  }
  return () => {};
}

export function nextFunc(
  index: number,
  paging: Pick<Criteria, 'page' | 'pageSize'>,
  onUpdatePaging: (newPaging: Partial<Criteria>) => void,
  onSelectItem: SelectCallback,
  results: { items: unknown[]; totalCount: number }
) {
  if (index < results.items.length - 1) {
    // There is a next item on current page
    return () => onSelectItem(index + 1, index);
  }
  if (paging.page < getNumOfPages(paging.pageSize, results.totalCount) - 1) {
    // There is a next page
    return () => {
      // Load the next page and select the first item on page
      onUpdatePaging({
        page: paging.page + 1,
        selectedIndex: 0,
      });
    };
  }
  return () => {};
}

export const scrollTo = (
  newItemId: string,
  oldItemId: string,
  anchorEl: HTMLElement | null,
  elementHeight: number | undefined = undefined
) => {
  // Scroll the FileOpenView component into view if selected item has changed
  if (oldItemId !== newItemId && anchorEl) {
    setTimeout(() => {
      const domEl = ReactDOM.findDOMNode(anchorEl) as HTMLElement; // eslint-disable-line
      if (!domEl) return;

      // NOTE: use domEl.offsetParent as scrollParentEl if surrounding
      // element should be scrolled instead of document body.
      const scrollParentEl = domEl.offsetParent; // document.documentElement;
      if (!scrollParentEl) return;

      const middle = scrollParentEl.clientHeight / 2;
      const half = (elementHeight || domEl.clientHeight) / 2;
      const position = middle - half;
      const scrollTop = Math.max(0, domEl.offsetTop - position);
      if (scrollParentEl.scrollTop !== scrollTop) {
        scrollParentEl.scrollTop = scrollTop;
      }
    });
  }
};

/**
 * Custom hook allowing users to navigate a "Result" grid with keyboard
 */
export function useKeyboardGridNavigation<
  WrapperElement extends HTMLElement = HTMLDivElement,
  ItemElement extends HTMLElement = HTMLDivElement
>(items: GenericData[]) {
  const containerRef = useRef<WrapperElement>(null);
  const cellRefs = useRef<ItemElement[]>([]);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const [columns, setColumns] = useState(1);

  const window = useThrottledWindowSize();

  useEffect(() => {
    setFocusedIndex(0);
  }, [
    (items || []).length,
    items.map(item => ('node' in item ? item.node.id : item.id)).join(':'),
  ]);

  useEffect(() => {
    if (containerRef.current && containerRef.current.firstElementChild) {
      const width = containerRef.current.clientWidth;
      const elemWidth =
        containerRef.current.firstElementChild.getBoundingClientRect().width;
      setColumns(Math.floor(width / elemWidth));
    }
  }, [containerRef.current, window]);

  const handleReceiveFocus = (index: number) => () => {
    setFocusedIndex(index);
  };

  const handleKeyDown = (e: React.KeyboardEvent<WrapperElement>) => {
    e.persist();
    const index = getNextGridIndex(e, items, columns, focusedIndex);

    if (index !== null && index !== focusedIndex) {
      setFocusedIndex(index);
      cellRefs.current[index] && cellRefs.current[index].focus();
    }
  };

  const getCellProps = useCallback(
    index => ({
      tabIndex: index === focusedIndex ? 0 : -1,
      onClick: handleReceiveFocus(index),
      onFocus: handleReceiveFocus(index),
      role: 'gridcell',
      ref: (instance: ItemElement) => (cellRefs.current[index] = instance),
    }),
    [focusedIndex]
  );

  return {
    getCellProps,
    wrapperProps: {
      role: 'grid',
      onKeyDown: handleKeyDown,
      ref: containerRef,
      key: items
        .map(item => ('node' in item ? item.node.id : item.id))
        .join(':'),
    },
  };
}

function getNextGridIndex<Element extends HTMLElement>(
  e: React.KeyboardEvent<Element>,
  items: GenericData[],
  columns: number,
  prevIndex: number
) {
  if (e.key.indexOf('Arrow') !== 0 && e.key !== 'Home' && e.key !== 'End')
    return null;
  if (!isChildOfGridCell(e.target)) return null;
  e.preventDefault();
  e.stopPropagation();
  const folders = items.filter(item =>
    'isFolder' in item ? item.isFolder : false
  ).length;
  const emptyCells = (columns - (folders % columns)) % columns;
  let newIndex = prevIndex;
  if (e.key === 'ArrowRight') newIndex = prevIndex + 1;
  else if (e.key === 'ArrowLeft') newIndex = prevIndex - 1;
  else if (e.key === 'ArrowDown') newIndex = prevIndex + columns;
  else if (e.key === 'ArrowUp') newIndex = prevIndex - columns;

  if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
    if (newIndex >= folders && prevIndex < folders) newIndex -= emptyCells;
    if (newIndex < folders && prevIndex >= folders) {
      newIndex += emptyCells;
      if (newIndex >= folders) newIndex = folders - 1;
    }
  }

  if (e.key === 'Home') newIndex = 0;
  if (e.key === 'End') newIndex = items.length - 1;

  return Math.max(0, Math.min(newIndex, items.length - 1));
}

function isChildOfGridCell(target: EventTarget) {
  const maxDepth = 10;

  let current: HTMLElement | null = target as unknown as HTMLElement;
  let depth = 0;
  while (depth < maxDepth) {
    if (!current) return false;
    if (current.getAttribute('role') === 'gridcell') return true;
    current = current.parentElement;
    depth++;
  }
  return false;
}
