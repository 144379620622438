import React, { useMemo } from 'react';
import { t } from '@lingui/macro';
import styled from 'styled-components';
import { IconButton, TablePagination, Tooltip } from '@mui/material';
import { Helmet } from 'react-helmet';
import { Container } from '@mui/system';

import { useGetConsentStatusHistoryQuery } from './api';
import { ConsentStatusHistory, GetConsentStatusHistoryParams } from './types';
import {
  getConsentStatusColor,
  getLocalizedConsentStatus,
} from '../common/utils';
import {
  DataTable,
  ColumnDescriptor,
  ColumnRendererProps,
} from '~common/tables/DataTable';
import { useActions } from '~common/utils/hooks.utils';

import { history } from '~common/config';
import Page from '~common/sections/Page';
import DataTableWrapper from '~common/tables/DataTableWrapper';
import { useSearchParams } from '~common/utils/browser.utils';
import { ConsentStatus } from '~common/content.constants';
import { getDateString, getTime } from '~common/utils/date.utils';
import { FileIcon } from '~common/misc/icons';
import { app } from '~common/app.model';
import { Typography } from '~common/misc/Typography';

type SearchQueryParams = {
  [key in keyof GetConsentStatusHistoryParams]: string | undefined;
};

function useQueryFilters() {
  const [searchParams, setSearchParams] = useSearchParams<SearchQueryParams>();

  const queryFilters = useMemo(() => {
    const { page, pageSize } = searchParams;
    return {
      page: parseInt(page ?? '0'),
      pageSize: parseInt(pageSize ?? '100'),
    };
  }, [searchParams]);

  return [queryFilters, setSearchParams] as const;
}

const ConsentStatusHistoryPage = () => {
  const [queryFilters, setSearchParams] = useQueryFilters();
  const { data: historyData, isLoading } =
    useGetConsentStatusHistoryQuery(queryFilters);

  const columns: ColumnDescriptor<ConsentStatusHistory>[] = [
    {
      name: t`Created`,
      dataAccessor: item =>
        (item.created &&
          item.created.length > 0 &&
          getDateString(item.created) + ' ' + getTime(item.created)) ||
        '',
      width: '10rem',
    },
    {
      name: t`File name`,
      dataAccessor: x => x.nodeName,
      renderer: function fileName({ item }) {
        return (
          <a
            onClick={() => history.push('/search?search=' + item.nodeName)}
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              color: 'blue',
            }}
          >
            {item.nodeName}
          </a>
        );
      },
    },
    {
      name: t`Old status`,
      dataAccessor: x => x.oldStatus || '',
      renderer: function oldStatus({ item }) {
        return (
          <StatusTag status={item.oldStatus}>
            {getLocalizedConsentStatus(item.oldStatus)}
          </StatusTag>
        );
      },
    },
    {
      name: t`New status`,
      dataAccessor: x => x.newStatus || '',
      renderer: function newStatus({ item }) {
        return (
          <StatusTag status={item.newStatus}>
            {getLocalizedConsentStatus(item.newStatus)}
          </StatusTag>
        );
      },
    },
    {
      name: t`Consent terms`,
      renderer: ({ item, children }) => ConsentTerms({ item, children }),
      stopRendererPropagation: true,
    },
  ];

  const handlePageChange = (event, page: number) => {
    setSearchParams(params => ({ ...params, page: `${page}` }));
  };

  const setOpenModal = useActions(app.actions.setOpenModal);

  const ConsentTerms = ({
    item,
  }: ColumnRendererProps<ConsentStatusHistory>) => {
    return (
      <Tooltip title={t`Read terms`}>
        <IconButton
          size="medium"
          disabled={!item.consentTermGroupId}
          onClick={() =>
            setOpenModal('CONSENT/READ_TERMS', {
              consentTermGroupId: item.consentTermGroupId,
            })
          }
        >
          <FileIcon />
        </IconButton>
      </Tooltip>
    );
  };

  const totalCount = historyData?.totalCount;
  return (
    <PageWrapper>
      <Helmet>
        <title>{t`Consent status history`}</title>
      </Helmet>

      <Typography variant="h1" renderAs="h1">
        {t`Consent status history`}
      </Typography>

      <ContentWrapper>
        <DataTableWrapper>
          <DataTable
            label={t`Consent status history`}
            columns={columns}
            rows={historyData?.list ?? []}
            outlined={false}
            loading={isLoading}
          />
          {totalCount !== undefined && (
            <TablePagination
              count={totalCount}
              onPageChange={handlePageChange}
              page={queryFilters.page}
              rowsPerPage={queryFilters.pageSize}
              rowsPerPageOptions={[]}
            />
          )}
        </DataTableWrapper>
      </ContentWrapper>
    </PageWrapper>
  );
};

const StatusTag = styled.div<{
  status: ConsentStatus;
}>`
  background-color: ${p =>
    p.status ? getConsentStatusColor(p.status, p.theme) : 'none'};
  border-radius: 2px;
  padding: 2px 3px 0;
  white-space: nowrap;
  display: inline-block;
  font-weight: ${p => p.theme.typography.fontWeightMedium};
  min-width: 16px;
  color: white;
  text-transform: uppercase;
`;

const PageWrapper = styled(Page)`
  align-self: center;
  width: 1200px;
  gap: ${p => p.theme.spacing(2)};
  padding: ${p => p.theme.spacing(4)};
`;

const ContentWrapper = styled(Container)`
  padding: ${p => p.theme.spacing(3)};
  gap: ${p => p.theme.spacing(4)};

  @media (max-width: 640px) {
    padding-left: ${({ theme }) => theme.spacing(1)};
    padding-right: ${({ theme }) => theme.spacing(1)};
    gap: ${p => p.theme.spacing(2)};
  }
`;

export default ConsentStatusHistoryPage;
