import React from 'react';
import styled from 'styled-components';

type OwnProps = {
  variant: 'adaptive' | 'narrow' | 'default';
  visible?: boolean;
  right?: boolean;
  minimized?: boolean;
  minimizedContent?: React.ReactNode;
  theme?: any;
};

type Props = OwnProps &
  React.HTMLAttributes<HTMLDivElement> &
  React.ComponentProps<typeof Styled>;

const Dock = React.forwardRef<HTMLDivElement, Props>(
  ({ children, ...rest }, ref) => (
    <Styled {...rest} ref={ref}>
      {rest.minimized ? rest.minimizedContent : children}
    </Styled>
  )
);

Dock.displayName = 'Dock';

const Styled = styled.section`
  display: ${(props: OwnProps) => (props.visible ? 'flex' : 'none')};
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.background.dock};
  border-left: ${({ theme, right }) =>
    right ? '3px solid ' + theme.palette.border.section : '0px'};
  border-right: ${({ theme, right }) =>
    !right ? '3px solid ' + theme.palette.border.section : '0px'};

  @media print {
    display: none !important;
  }
  > * {
    width: 100%;
  }

  ${({ minimized }) =>
    minimized &&
    `
      display: flex;
      min-width: 56px;
      width: 56px;
      max-width: 100%;
      padding: 13px 0;
      & > * {
        width: 100%;
        min-width: 0;
        max-width: 100%;
        min-height: unset;
      }
  `}

  &:focus {
    outline: none;
  }
`;
// TODO: use styled.utils for @media

export default Dock;
