import { DefaultRootState } from 'react-redux';

import { uniqueBy } from '../common/utils';
import { search as searchModel } from './model';
import { SUGGESTION_IDS } from './constants';

import { apiBase } from '~common/api.base';
import { SearchSuggestion } from '~common/content.types';
import { customerMetaFieldsCacheSelect } from '~common/content.api';

function extractMetaFieldId(categoryId: string): string | undefined {
  return (categoryId.match(/_meta_(.*?)_/) ?? [])[1];
}

type GetSearchSuggestionsParams = {
  search: string;
  language: string;
  defaultLanguage: string;
  tags: string;
  limit?: number;
  target?: 'archive';
};

const extendedApi = apiBase.injectEndpoints({
  endpoints: builder => ({
    getSearchSuggestions: builder.query<
      SearchSuggestion[],
      GetSearchSuggestionsParams
    >({
      queryFn: async (
        { search, language, defaultLanguage, tags, target, limit = 10 },
        { getState, dispatch },
        _,
        fetch
      ) => {
        const state = getState() as DefaultRootState;
        const customerId = state.app.customer?.id ?? '';
        const { data: metaFields = [] } = customerMetaFieldsCacheSelect({
          customerId,
        })(state);
        const autocompleteFieldIds = metaFields
          .filter(x => x.autocomplete)
          .map(x => x.id);

        const result = await fetch({
          url: `/customers/${customerId}/suggestions?search=${search}&lang=${language}&defaultLang=${defaultLanguage}&tags=${tags}&target=${
            target ?? 'default'
          }&limit=${limit}&isMetaSearch=on&isSidebarSearch&page=1&submitSearchBtn=true&searchSort=default`,
          method: 'get',
        });

        if (result.error) {
          return { error: result.error };
        } else {
          const filteredSuggestions = (
            (result.data as SearchSuggestion[]) ?? []
          ).filter(
            x =>
              SUGGESTION_IDS.some(id => x.id.includes(id)) ||
              autocompleteFieldIds.includes(extractMetaFieldId(x.id) ?? '')
          );

          // TODO: This might not be needed, see `./Tags:28`
          const categoryTitles = filteredSuggestions.reduce(
            (obj, cur) => ({ ...obj, [cur.id]: cur.title }),
            {} as Record<string, string>
          );
          dispatch(searchModel.actions.updateCategoryTitles(categoryTitles));

          const uniqueSuggestions = uniqueBy(filteredSuggestions, s => s.id);

          return { data: uniqueSuggestions };
        }
      },
      keepUnusedDataFor: 15, // Shorter cache duration to avoid staleness
    }),

    getSuggestionCategoryTitles: builder.query<
      Record<string, string>,
      { customerId: string; lang: string }
    >({
      query: ({ customerId, lang }) => ({
        url: `/customers/${customerId}/suggestions/titles?lang=${lang}`,
        method: 'get',
      }),
      transformResponse: (data: SearchSuggestion[]) =>
        data.reduce(
          (obj, cur) => ({
            ...obj,
            [cur.id]: cur.title,
          }),
          {} as Record<string, string>
        ),
    }),
  }),
});

export const {
  useGetSuggestionCategoryTitlesQuery,
  useGetSearchSuggestionsQuery,
} = extendedApi;
