import React, { useEffect } from 'react';
import Selecto from 'react-selecto';

interface SelectoProps {
  selectoRef: React.RefObject<Selecto>;
  targetsSelector: string[];
  scrollerElement: HTMLDivElement;
  getElementNodeId: (el: any) => string;
  setItemsChecked: (
    items: {
      node: {
        id: string;
      };
    }[],
    checked: unknown
  ) => void;
}

export function ItemsSelector({
  selectoRef,
  targetsSelector,
  scrollerElement,
  getElementNodeId,
  setItemsChecked,
}: SelectoProps) {
  useEffect(() => {
    scrollerElement?.addEventListener('onScroll', () => {
      selectoRef?.current?.checkScroll();
    });
  }, []);
  return (
    <Selecto
      ref={selectoRef}
      selectableTargets={targetsSelector}
      // Whether to select by click (default: true)
      selectByClick={false}
      // Whether to select from the target inside (default: true)
      selectFromInside={false}
      // Determines which key to continue selecting the next target via keydown and keyup.
      toggleContinueSelect={'shift'}
      scrollOptions={{
        container: scrollerElement,
        getScrollPosition: () => {
          return [scrollerElement.scrollLeft, scrollerElement.scrollTop];
        },
        throttleTime: 30,
        threshold: 40,
      }}
      // The rate at which the target overlaps the drag area to be selected. (default: 100)
      hitRate={100}
      ratio={0}
      onSelect={e => {
        const selecteditems = [] as any[];
        const removeditems = [] as any[];
        e.added.forEach(el => {
          const nodeId = getElementNodeId(el);
          if (nodeId) {
            el.classList.add('selected');
            selecteditems.push({
              node: { id: nodeId },
            });
            el.parentElement
              ?.querySelector('#opened-' + nodeId)
              ?.classList.add('selected');
          }
        });
        e.removed.forEach(el => {
          el.classList.remove('selected');
          const nodeId = getElementNodeId(el);
          if (nodeId) {
            removeditems.push({ node: { id: nodeId } });
            el.parentElement
              ?.querySelector('#opened-' + nodeId)
              ?.classList.remove('selected');
          }
        });
        setItemsChecked(selecteditems ?? [], true);
        setItemsChecked(removeditems ?? [], false);
      }}
      onScroll={e => {
        scrollerElement.scrollTo({
          top: scrollerElement.scrollTop + e.direction[1] * 10,
          left: scrollerElement.scrollLeft + e.direction[0] * 10,
        });
      }}
      onDragStart={e => {
        if (e.inputEvent.target.nodeName === 'BUTTON') {
          return false;
        }
        return true;
      }}
    />
  );
}

export default ItemsSelector;
