import { keyframes, css } from 'styled-components';

export const appear = keyframes`
  from {
    opacity 0;
    transform: translateY(-22px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const slideTop = keyframes`
  from {
    transform: translateY(-200px);
  }
  to {
    transform: translateY(0px);
  }
`;

export const slideOpen = keyframes`
  from {
    transform: scaleY(0);
  }

  to {
    transform: scaleY(1);
  }
`;

export const slideClosed = keyframes`
  from {
    transform: scaleY(1);
  }

  to {
    transform: scaleY(0);
  }
`;

export const slideOpenHorizontal = keyframes`
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
`;

export const slideClosedHorizontal = keyframes`
  from {
    transform: scaleX(1);
  }

  to {
    transform: scaleX(0);
  }
`;

export const appearForwards = css`
  animation: ${appear} 0.8s forwards;
`;
