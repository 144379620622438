import React from 'react';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro';
import { content } from '../../content/model';
import { app } from '~common/app.model';
import { useActions, useSelection } from '~common/utils/hooks.utils';
import { BasicOption, ModalOption } from '~common/navigation/MenuButton';
import { CustomerSpecificAction } from '~common/app.types';
import { FilesById } from '~common/content.types';
import { SendIcon } from '~common/misc/icons';
import { useFilesCustomerSendMutation } from '~common/content.api';
import { AllowLevel, allowLevel } from '~common/content.utils';
import { getLangValue } from '~common/app.utils';

type CustomFilesSendMutation = ReturnType<
  typeof useFilesCustomerSendMutation
>[0];

export const doCustomFunc = async (
  action: CustomerSpecificAction,
  actionLabel: string,
  ids: string[],
  filesCustomerSend: CustomFilesSendMutation | undefined,
  showInfoMessage: (message: string) => void,
  showErrorMessage: (message: string) => void,
  setItemsChecked: (
    items: {
      node: {
        id: string;
      };
    }[],
    checked: unknown
  ) => void,
  fields?: Record<string, string[]>
) => {
  const result = await filesCustomerSend?.({
    action: action.id,
    ids: ids,
    fields: fields,
  });
  const ignored = result && 'data' in result ? result.data : undefined;
  if (ignored && ignored?.length <= ids.length) {
    showInfoMessage(
      ignored.length === 0
        ? `${actionLabel} ` + t`successfully`
        : `${actionLabel} ` + t`partially successfully, ignored: ${ignored}`
    );
    setItemsChecked(
      ids.map(id => ({ node: { id } })),
      false
    );
  } else {
    showErrorMessage(t`${actionLabel} not successful`);
  }
};

const getCustomerActionButton = (
  action: CustomerSpecificAction,
  fileIds: string[],
  filesById: FilesById,
  selection: { hasFolders: boolean },
  getCustomFunc: (
    action: CustomerSpecificAction
  ) => CustomFilesSendMutation | undefined,
  showInfoMessage: (message: string) => void,
  showErrorMessage: (message: string) => void,
  setItemsChecked: (
    items: {
      node: {
        id: string;
      };
    }[],
    checked: unknown
  ) => void
): BasicOption | ModalOption => {
  const allow = allowLevel(action.right, fileIds, filesById);
  const customFunc = getCustomFunc(action);
  const actionLabel = getLangValue(action.namesByLang);
  return action.options
    ? {
        value: action.id,
        title: actionLabel,
        icon: action.isSendActionType ? <SendIcon /> : <></>,
        disabled: allow !== AllowLevel.ForAll || selection.hasFolders,
        hidden: allow !== AllowLevel.ForAll && allow !== AllowLevel.ForSome,
        modalType: 'CONTENT/CUSTOMER_SPECIFIC_ACTION',
        modalProps: {
          action,
          actionLabel,
          fileIds,
          customFunc,
          showInfoMessage,
          showErrorMessage,
          setItemsChecked,
        },
      }
    : {
        value: action.id,
        title: actionLabel,
        icon: action.isSendActionType ? <SendIcon /> : <></>,
        disabled: allow !== AllowLevel.ForAll || selection.hasFolders,
        hidden: allow !== AllowLevel.ForAll && allow !== AllowLevel.ForSome,
        onSelect: () => {
          if (fileIds?.length > 0) {
            doCustomFunc(
              action,
              actionLabel,
              fileIds,
              customFunc,
              showInfoMessage,
              showErrorMessage,
              setItemsChecked
            );
          }
        },
      };
};

const useCustomFuncGetter = () => {
  const [filesCustomerSend] = useFilesCustomerSendMutation();
  return (action: CustomerSpecificAction) => {
    if (action.isSendActionType) {
      return filesCustomerSend;
    }
    return undefined;
  };
};

export function useCustomerSpecificActionsOptions(
  fileIds: string[]
): BasicOption[] {
  const filesById = useSelector(state => state.commonContent.filesById);
  const { selection } = useSelection(fileIds, files => ({
    hasFolders: files.some(f => f?.isFolder),
  }));
  const customerSpecificActions = useSelector(
    state => state.app.customer?.customerSpecificActions
  );

  const getCustomerFunc = useCustomFuncGetter();
  const showInfoMessage = useActions(app.actions.showInfoMessage);
  const showErrorMessage = useActions(app.actions.showErrorMessage);
  const setItemsChecked = useActions(content.actions.setItemsChecked);
  const options = customerSpecificActions
    ? customerSpecificActions.map(action => {
        return getCustomerActionButton(
          action,
          fileIds,
          filesById,
          selection,
          getCustomerFunc,
          showInfoMessage,
          showErrorMessage,
          setItemsChecked
        );
      })
    : [];

  return options;
}
