import {
  all,
  call,
  put,
  takeEvery,
  fork,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { differenceInDays, format } from 'date-fns';
import { t } from '@lingui/macro';

import {
  commentRequestProcessSteps,
  commentRequestResendSteps,
} from './constants';
import {
  convertCommenterIn,
  convertCommentRequestIn,
  parseCommentersFromGroups,
} from './utils';

import { comments } from './model';
import * as api from './api';
import { initializeWorkflowData } from '~common/workflows/utils';
import { history } from '~common/config';
import { getLangValue, convertUserIn } from '~common/app.utils';
import { WorkflowProcessStep } from '~common/workflows/constants';
import { updateBrowseCriteria } from '~common/content.utils';
import { commonContent } from '~common/content.model';
import { userCacheSelector } from '~common/user.api';

// Browse

function* updateCriteria(action) {
  updateBrowseCriteria(
    '/comments',
    action.payload.enterMode,
    action.payload.historyUpdateMode,
    action.payload.criteria
  );
}

function* watchUpdateCriteria() {
  yield takeEvery('COMMENTS/UPDATE_CRITERIA', updateCriteria);
}

// Comment request

function* fetchCommentRequests(action) {
  try {
    const data = yield call(api.fetchCommentRequests, action.payload);
    yield put({
      type: 'COMMENTS/AFTER_FETCH_COMMENT_REQUESTS',
      payload: {
        commentRequests: {
          ...data,
          commentings: (data.commentings || []).map(convertCommentRequestIn),
        },
      },
    });
  } catch (error) {
    console.log(error);
  }
}

function* watchFetchCommentRequests() {
  yield takeEvery('COMMENTS/FETCH_COMMENT_REQUESTS', fetchCommentRequests);
}

function* readCommentRequest(action) {
  try {
    const commentRequest = yield call(api.readCommentRequest, {
      commentRequestId: action.payload.commentRequestId,
    });
    yield put({
      type: 'COMMENTS/AFTER_READ_COMMENT_REQUEST',
      payload: {
        commentRequest: convertCommentRequestIn(commentRequest),
      },
    });
    const pagesById = {};
    commentRequest.pages.forEach(page => {
      pagesById[page.index] = { ...page };
    });
    const selectedPageId = yield select(state => state.comments.selectedPageId);
    yield put({
      type: 'COMMENTS/AFTER_READ_PAGES',
      payload: { pagesById, selectedPageId },
    });
    // if the selected page has disappeared, select the first one
    if (!Object.values<any>(pagesById).some(x => x.index === selectedPageId))
      yield put({
        type: 'COMMENTS/SELECT_NTH_PAGE',
        payload: {
          selectedPageId: 0,
        },
      });
  } catch (error) {
    yield put({ type: 'COMMENTS/AFTER_ERROR', payload: { error } });
    yield put({
      type: 'COMMENTS/AFTER_READ_COMMENT_REQUEST',
      payload: {
        commentRequest: null,
      },
    });
  }
}

function* watchReadCommentRequest() {
  yield takeEvery('COMMENTS/SHOW_COMMENT_REQUEST', readCommentRequest);
}

function* selectNthPage(action) {
  const pagesById = yield select(state => state.comments.pagesById);
  const orderedPages = Object.values<any>(pagesById).sort(
    (a, b) => a.page - b.page
  );
  const index = Math.max(
    0,
    Math.min(action.payload.selectedPageId, orderedPages.length - 1)
  );
  const currentPageId = yield select(state => {
    return state.comments.selectedPageId;
  });

  if (index !== currentPageId) {
    yield put({
      type: 'COMMENTS/SELECT_PAGE',
      payload: { selectedPageId: index },
    });
  }
}

function* watchSelectNthPage() {
  yield takeEvery('COMMENTS/SELECT_NTH_PAGE', selectNthPage);
}

function* approvePage(action) {
  try {
    const commentRequest = yield select(state => {
      return state.comments.commentRequest;
    });
    yield call(api.approvePage, {
      commentRequestId: commentRequest.id,
      selectedPageId: action.payload.selectedPageId,
      approved: action.payload.approved,
    });
    yield put({
      type: 'COMMENTS/AFTER_UPDATE_COMMENT_REQUEST',
      payload: { commentRequestId: commentRequest.id },
    });
  } catch (error) {
    yield put({ type: 'COMMENTS/AFTER_ERROR', payload: { error } });
  }
}

function* watchApprovePage() {
  yield takeEvery('COMMENTS/APPROVE_PAGE', approvePage);
}

function* approveAllPages() {
  try {
    const commentRequest = yield select(state => {
      return state.comments.commentRequest;
    });
    yield call(api.approveAllPages, {
      commentRequestId: commentRequest.id,
    });
    yield put({
      type: 'COMMENTS/AFTER_UPDATE_COMMENT_REQUEST',
      payload: { commentRequestId: commentRequest.id },
    });
  } catch (error) {
    yield put({ type: 'COMMENTS/AFTER_ERROR', payload: { error } });
  }
}

function* disapproveAllPages() {
  try {
    const commentRequest = yield select(state => {
      return state.comments.commentRequest;
    });
    yield call(api.disapproveAllPages, {
      commentRequestId: commentRequest.id,
    });
    yield put({
      type: 'COMMENTS/AFTER_UPDATE_COMMENT_REQUEST',
      payload: { commentRequestId: commentRequest.id },
    });
  } catch (error) {
    yield put({ type: 'COMMENTS/AFTER_ERROR', payload: { error } });
  }
}

function* watchDisapproveAllPages() {
  yield takeLatest('COMMENTS/DISAPPROVE_ALL_PAGES', disapproveAllPages);
}

function* watchApproveAllPages() {
  yield takeLatest('COMMENTS/APPROVE_ALL_PAGES', approveAllPages);
}

// Comments

function* readCommentsOnPage(action) {
  try {
    const commentRequest = yield select(state => {
      return state.comments.commentRequest;
    });
    const commentData = yield call(api.readCommentsOnPage, {
      commentRequestId: commentRequest.id,
      pageId: action.payload.selectedPageId,
    });
    const commentsById = {};
    const commentersById = {};
    commentData.commenters.forEach(commenter => {
      const id = commenter.isGuest
        ? `guest${commenter.guestId}`
        : commenter.userId;
      commentersById[id] = {
        ...commenter,
      };
      commenter.comments.forEach(comment => {
        commentsById[comment.id] = {
          ...comment,
          ...commenter,
        };
      });
    });
    yield put({
      type: 'COMMENTS/AFTER_READ_COMMENTS',
      payload: { commentsById, commentersById },
    });
  } catch (error) {
    yield put({ type: 'COMMENTS/AFTER_ERROR', payload: { error } });
  }
}

function* watchReadCommentsOnPage() {
  yield takeEvery('COMMENTS/READ_COMMENTS_ON_PAGE', readCommentsOnPage);
}

function* watchSelectPage() {
  yield takeEvery('COMMENTS/SELECT_PAGE', readCommentsOnPage);
}

function* watchAfterReadPages() {
  yield takeLatest('COMMENTS/AFTER_READ_PAGES', readCommentsOnPage);
}

function* updateComment(action) {
  try {
    const commentRequest = yield select(state => {
      return state.comments.commentRequest;
    });
    const selectedPageId = yield select(state => {
      return state.comments.selectedPageId;
    });
    yield call(api.updateComment, {
      commentId: action.payload.commentId,
      comment: action.payload.comment,
      commentRequestId: commentRequest.id,
      selectedPageId,
    });
  } catch (error) {
    yield put({ type: 'COMMENTS/AFTER_ERROR', payload: { error } });
  }
}

function* watchUpdateComment() {
  yield takeEvery('COMMENTS/UPDATE_COMMENT', updateComment);
}

function* createComment(action) {
  try {
    const commentRequest = yield select(state => {
      return state.comments.commentRequest;
    });
    const selectedPageId = yield select(state => {
      return state.comments.selectedPageId;
    });
    const type = yield select(state => {
      return state.comments.selectedCommentType;
    });
    const comment = yield call(api.createComment, {
      type,
      position: action.payload.position,
      commentRequestId: commentRequest.id,
      selectedPageId,
    });
    yield put({
      type: 'COMMENTS/READ_COMMENTS_ON_PAGE',
      payload: { selectedPageId },
    });
    yield put({
      type: 'COMMENTS/SELECT_COMMENT',
      payload: { commentId: comment.id },
    });
  } catch (error) {
    yield put({ type: 'COMMENTS/AFTER_ERROR', payload: { error } });
  }
}

function* watchCreateComment() {
  yield takeEvery('COMMENTS/CREATE_COMMENT', createComment);
}

function* deleteComment(action) {
  try {
    const commentRequest = yield select(state => {
      return state.comments.commentRequest;
    });
    const selectedPageId = yield select(state => {
      return state.comments.selectedPageId;
    });
    yield call(api.deleteComment, {
      id: action.payload.id,
      commentRequestId: commentRequest.id,
      selectedPageId,
    });
    yield put({
      type: 'COMMENTS/AFTER_DELETE_COMMENT',
      payload: { id: action.payload.id },
    });
  } catch (error) {
    yield put({ type: 'COMMENTS/AFTER_ERROR', payload: { error } });
  }
}

function* watchDeleteComment() {
  yield takeEvery('COMMENTS/DELETE_COMMENT', deleteComment);
}

// Creating comment request

function* startCommentRequest(action) {
  yield put(comments.actions.resetWorkflowProcess());
  yield put(comments.actions.initWorkflowData({}, true));
  yield put({
    type: 'COMMENTS/SET_COMMENT_REQUEST_CONTENT',
    payload: { contentIds: [...action.payload.contentIds] },
  });
}

function* watchStartCommentRequest() {
  yield takeLatest('APP/START_COMMENT_REQUEST', startCommentRequest);
}

function* startCommentRound(
  action: ReturnType<typeof comments.actions.startCommentRound>
) {
  const {
    originalWorkflowId,
    attachments,
    language,
    users,
    expirationTime,
    reminderTime,
    name,
    title,
    id,
  } = action.payload.originalRound;
  const expiration = new Date(expirationTime);
  const reminder = new Date(reminderTime);
  const originalOrderId = action.payload.originalRound.originalOrderId || id;
  yield put(
    comments.actions.setCommentRequestContent(
      Object.values(attachments || {}).map(x => x.node.id),
      originalWorkflowId
    )
  );
  const approverIds = (users?.approvers || []).map(user => user.id);
  const commenterIds = (users?.commenters || []).map(user => user.id);

  yield put(
    comments.actions.initWorkflowData(
      {
        [originalWorkflowId]: {
          originalOrderId,
          commenters: (users?.commenters || []).map(user => ({
            ...user,
            isApprover: approverIds.includes(user.id),
            isCommenter: commenterIds.includes(user.id),
            isGroup: false,
          })),
          emailRecipients: '',
          fyiRecipients: '',
          expirationMode: 'date',
          expirationDate: expiration,
          expirationTime: expiration,
          reminder: differenceInDays(expiration, reminder).toString(),
          name,
          title,
          language,
          messageReply: '',
        },
      },
      true
    )
  );
  yield put(commonContent.actions.fetchWorkflow(originalWorkflowId));
  yield put(
    comments.actions.updateWorkflowProcess({
      workflowId: originalWorkflowId,
      step: WorkflowProcessStep.CONTENTS,
      originalOrderId,
    })
  );
}

function* watchStartCommentRound() {
  yield takeLatest(comments.actions.startCommentRound.type, startCommentRound);
}

function* startProcess() {
  const contentById = yield select(state => state.comments.contentById);
  const workflowsById = yield select(
    state => state.commonContent.workflowsById
  );
  const userLanguage = yield select(state => state.app.settings.language);
  const filesById = yield select(state => state.commonContent.filesById);
  const { data: currentUser } = yield select(state => userCacheSelector(state));
  yield call(readCommenters);
  const users = yield select(state => state.comments.commenters);
  const dataByWorkflowId = initializeWorkflowData(
    contentById,
    workflowsById,
    userLanguage
  );
  if (!dataByWorkflowId) return;

  // Replace all instances of %ORDER_FILES% and %ORDER_DATE%
  // Set expiration mode to date
  const orderDate = format(Date.now(), 'd.M.yyyy');
  Object.keys(dataByWorkflowId).forEach(id => {
    const data = dataByWorkflowId[id];
    Object.keys(data).forEach(key => {
      const value = data[key];
      dataByWorkflowId[id][key] =
        value &&
        typeof value === 'string' &&
        value
          .replace(
            /%ORDER_FILES%/g,
            Object.keys(contentById)
              .filter(itemId => contentById[itemId].workflowId === id)
              .map(id => {
                const file = filesById[id].file;
                return getLangValue(file.namesByLang) || file.name;
              })
              .join('\n')
          )
          .replace(/%ORDER_DATE%/g, orderDate);
    });
    dataByWorkflowId[id].expirationMode = 'date';

    const workflow = workflowsById[id].workflow;
    const defaultData =
      users &&
      users
        .map(user => {
          const [isCommenter, isApprover] = [
            workflow.defaultCommenters?.includes(user.id),
            workflow.defaultApprovers?.includes(user.id),
          ];
          if (isCommenter || isApprover) {
            return { ...user, isCommenter, isApprover };
          }
        })
        .filter(user => user !== undefined);

    if (defaultData.length !== 0) {
      dataByWorkflowId[id].commenters = defaultData;
    } else {
      dataByWorkflowId[id].commenters = [
        { ...currentUser, isApprover: true, isDisabled: { approver: true } },
      ];
    }
  });

  yield put({
    type: 'COMMENTS/INIT_WORKFLOW_DATA',
    payload: { dataByWorkflowId },
  });
  yield call(advanceProcess);
}

function* advanceProcess() {
  let process = yield select(state => state.comments.workflowProcess);
  let sentWorkflowId = null;
  const steps = process.originalOrderId
    ? commentRequestResendSteps
    : commentRequestProcessSteps;
  // There's still steps to go through in the current workflow
  if (process.step !== null && steps.indexOf(process.step) < steps.length - 3) {
    process = {
      ...process,
      step: steps[steps.indexOf(process.step) + 1],
    };
  }
  // No more steps, send the workflow and move to the next one
  else {
    const contentById = yield select(state => state.comments.contentById);
    const workflowIds = [
      ...new Set(Object.values(contentById).map(x => (x as any).workflowId)),
    ].filter(x => !!x && x !== '0' && x !== process.workflowId);
    if (process.step === WorkflowProcessStep.SENT) {
      sentWorkflowId = process.workflowId;
    }
    if (workflowIds.length > 0) {
      process = {
        workflowId: workflowIds[0],
        step: WorkflowProcessStep.DETAILS,
      };
    } else {
      // No more workflows to go through
      if (process.originalOrderId && process.orderId)
        return history.replace(`/comments/${process.orderId}`);
      return history.goBack();
    }
  }
  yield put({
    type: 'COMMENTS/UPDATE_WORKFLOW_PROCESS',
    payload: { workflowProcess: process },
  });
  if (sentWorkflowId) {
    yield put({
      type: 'COMMENTS/REMOVE_WORKFLOW_DATA',
      payload: { workflowId: sentWorkflowId },
    });
  }
}

function* retreatProcess() {
  let process = yield select(state => state.comments.workflowProcess);
  if (!process.workflowId || process.step === null) return;
  const steps = process.originalOrderId
    ? commentRequestResendSteps
    : commentRequestProcessSteps;
  // clear process if we are at the first step
  if (steps.indexOf(process.step) > 0) {
    process = {
      ...process,
      step: steps[steps.indexOf(process.step) - 1],
    };
  } else {
    process = {
      workflowId: null,
      step: null,
    };
  }
  yield put({
    type: 'COMMENTS/UPDATE_WORKFLOW_PROCESS',
    payload: { workflowProcess: process },
  });
}

function* watchProcess() {
  yield takeLatest('COMMENTS/START_PROCESS', startProcess);
  yield takeEvery('COMMENTS/ADVANCE_PROCESS', advanceProcess);
  yield takeEvery('COMMENTS/RETREAT_PROCESS', retreatProcess);
  yield takeEvery('APP/START_COMMENT_REQUEST', startCommentRequest);
}

function* sendCommentRequest() {
  try {
    const workflowId = yield select(
      state => state.comments.workflowProcess.workflowId
    );
    // TODO: infer redux store types here
    const dataByWorkflowId = yield select(
      state => state.comments.dataByWorkflowId
    );
    const contentById = yield select(state => state.comments.contentById);
    const workflowData = dataByWorkflowId[workflowId];

    const expirationTime =
      workflowData.expirationMode === 'date'
        ? `${format(workflowData.expirationDate, 'yyyy-MM-dd')}T${format(
            workflowData.expirationTime,
            'HH:mm:00.000XX'
          )}`
        : format(
            new Date(
              Date.now() + workflowData.expirationDays * 24 * 60 * 60 * 1000
            ),
            "yyyy-MM-dd'T'23:59:00.000XX"
          );
    const reminderTime = format(
      new Date(
        new Date(expirationTime).getTime() -
          workflowData.reminder * 24 * 60 * 60 * 1000
      ),
      "yyyy-MM-dd'T'HH:mm:00.000XX"
    );

    const { commenterIds, approverIds, commenterEmails } =
      parseCommentersFromGroups(workflowData.commenters, 'id');

    const { id } = yield call(api.sendCommentRequest, {
      workflowId,
      name: workflowData.name,
      title: workflowData.title,
      contentIds: Object.keys(contentById).filter(
        id => contentById[id].workflowId === workflowId
      ),
      commenterIds,
      approverIds,
      commenterEmails,
      expirationTime,
      reminderTime,
      messageReplyTo: workflowData.messageReply,
      fyiAddresses: workflowData.fyiRecipients,
      language: workflowData.language,
      originalOrderId: workflowData.originalOrderId,
    });
    yield put({
      type: 'COMMENTS/AFTER_SEND_COMMENT_REQUEST',
      payload: { orderId: id },
    });
  } catch (error) {
    yield put({
      type: 'APP/SET_OPEN_SNACKBAR',
      payload: {
        openSnackbarId: 'COMMENTS/SEND_FAIL',
        openSnackbarProps: {
          type: 'error',
          message: t`Sending comment request failed`,
        },
      },
    });
    const workflowProcess = yield select(
      state => state.comments.workflowProcess
    );
    yield put({
      type: 'COMMENTS/UPDATE_WORKFLOW_PROCESS',
      payload: {
        workflowProcess: {
          ...workflowProcess,
          step: WorkflowProcessStep.SUMMARY,
        },
      },
    });
    yield put({ type: 'COMMENTS/AFTER_ERROR', payload: { error } });
  }
}

function* watchSendCommentRequest() {
  yield takeEvery('COMMENTS/SEND_COMMENT_REQUEST', sendCommentRequest);
}

// Commenters

function* readCommenters() {
  const commenters = yield call(api.readCommenters);
  const groups = yield call(api.readCommenterGroups);
  yield put({
    type: 'COMMENTS/AFTER_READ_COMMENTERS',
    payload: {
      commenters: commenters.map(user => convertCommenterIn(user)),
      groups: groups.map(group => {
        return {
          ...group,
          members: group.members.map(user => convertUserIn(user)),
        };
      }),
    },
  });
}

function* watchReadCommenters() {
  yield takeEvery('COMMENTS/READ_COMMENTERS', readCommenters);
}

// Updating comment request

function* updateCommenters(action) {
  try {
    const commentRequest = yield select(state => {
      return state.comments.commentRequest;
    });
    const { commenterIds, approverIds, commenterEmails } =
      parseCommentersFromGroups(action.payload.commenters, 'id');
    yield call(api.updateCommentRequest, {
      commentRequestId: commentRequest.id,
      commentRequestData: {
        approverIds,
        commenterIds,
        commenterEmails,
      },
    });
    yield put({
      type: 'COMMENTS/AFTER_UPDATE_COMMENT_REQUEST',
      payload: { commentRequestId: commentRequest.id },
    });
  } catch (error) {
    yield put({ type: 'COMMENTS/AFTER_ERROR', payload: { error } });
  }
}

function* watchUpdateCommenters() {
  yield takeEvery('COMMENTS/UPDATE_COMMENTERS', updateCommenters);
}

function* updateClosingDateTime(action) {
  try {
    yield call(api.updateCommentRequest, {
      commentRequestId: action.payload.commentRequestId,
      commentRequestData: {
        expirationTime: action.payload.expirationDateTime,
        reminderTime: action.payload.reminderDateTime,
      },
    });
    yield put({
      type: 'COMMENTS/AFTER_UPDATE_COMMENT_REQUEST',
      payload: { commentRequestId: action.payload.commentRequestId },
    });
  } catch (error) {
    yield put({ type: 'COMMENTS/AFTER_ERROR', payload: { error } });
  }
}

function* watchUpdateClosingDateTime() {
  yield takeEvery('COMMENTS/UPDATE_CLOSING_DATE_TIME', updateClosingDateTime);
}

function* closeCommenting(action) {
  try {
    yield call(api.closeCommenting, {
      commentRequestId: action.payload.commentRequestId,
    });
    yield put({
      type: 'COMMENTS/AFTER_UPDATE_COMMENT_REQUEST',
      payload: { commentRequestId: action.payload.commentRequestId },
    });
  } catch (error) {
    yield put({ type: 'COMMENTS/AFTER_ERROR', payload: { error } });
  }
}

function* watchCloseCommenting() {
  yield takeLatest('COMMENTS/CLOSE_COMMENTING', closeCommenting);
}

function* finalizeCommenting(
  action: ReturnType<typeof comments.actions.finalizeCommenting>
) {
  try {
    yield call(api.finalizeCommenting, {
      commentRequestId: action.payload.commentRequestId,
    });
    yield put({
      type: 'COMMENTS/AFTER_UPDATE_COMMENT_REQUEST',
      payload: { commentRequestId: action.payload.commentRequestId },
    });
  } catch (error) {
    yield put({ type: 'COMMENTS/AFTER_ERROR', payload: { error } });
  }
}

function* watchFinalizeCommenting() {
  yield takeLatest(
    comments.actions.finalizeCommenting.type,
    finalizeCommenting
  );
}

function* watchAfterUpdateCommentRequest() {
  yield takeLatest('COMMENTS/AFTER_UPDATE_COMMENT_REQUEST', readCommentRequest);
}

// Combine

export default function* sagas(): any {
  yield all([
    fork(watchUpdateCriteria),
    fork(watchFetchCommentRequests),
    fork(watchReadCommentRequest),
    fork(watchReadCommentsOnPage),
    fork(watchUpdateComment),
    fork(watchSelectPage),
    fork(watchApprovePage),
    fork(watchApproveAllPages),
    fork(watchDisapproveAllPages),
    fork(watchSelectNthPage),
    fork(watchCreateComment),
    fork(watchDeleteComment),
    fork(watchStartCommentRequest),
    fork(watchStartCommentRound),
    fork(watchProcess),
    fork(watchSendCommentRequest),
    fork(watchReadCommenters),
    fork(watchUpdateCommenters),
    fork(watchUpdateClosingDateTime),
    fork(watchCloseCommenting),
    fork(watchFinalizeCommenting),
    fork(watchAfterUpdateCommentRequest),
    fork(watchAfterReadPages),
  ]);
}
