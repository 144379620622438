import React, { useState } from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';
import Button from '@mui/material/Button';

import Axios from 'axios';
import { UploadFile } from '~common/content.types';
import FileSlide from '~common/items/FileSlide';
import DropTarget from '~misc/drag/DropTarget';
import { UploadStatus } from '~common/content.constants';

interface Props {
  onUploadFile?: (file: any) => void;
  uploading?: boolean;
  onCancel?: () => void;
  beforeUpload?: (file: UploadFile, handleUpload: () => void) => void;
}

const FileUploader = ({
  onUploadFile,
  uploading,
  onCancel,
  beforeUpload,
}: Props) => {
  const [loadedFile, setLoadedFile] = useState<any>();
  const handleFileInsert = (files: any[]) => {
    setLoadedFile(files[0]);
  };

  const handleFileRemove = () => {
    setLoadedFile(undefined);
  };

  const handleUploadClick = () => {
    if (!onUploadFile) return;
    if (beforeUpload) {
      beforeUpload(loadedFile, handleUpload);
    } else {
      handleUpload();
    }
  };

  const handleUpload = async () => {
    if (!onUploadFile) return;
    try {
      setLoadedFile({ ...loadedFile, uploadStatus: UploadStatus.UPLOADING });
      await onUploadFile(loadedFile);
      setLoadedFile({ ...loadedFile, uploadStatus: UploadStatus.UPLOADED });
    } catch (e) {
      if (Axios.isCancel(e)) {
        setLoadedFile(undefined);
        return;
      }
      console.error(e);
      setLoadedFile({ ...loadedFile, uploadStatus: UploadStatus.ERROR });
    }
  };

  return (
    <Wrapper>
      {!loadedFile ? (
        <DropTarget onInsert={handleFileInsert} fullWidth noMargin />
      ) : (
        <CenteredFileSlide
          file={loadedFile}
          index={0}
          onRemove={handleFileRemove}
          editable={false}
          removableOnError
        />
      )}
      {uploading ? (
        <UploadButton color="primary" variant="contained" onClick={onCancel}>
          <Trans>Cancel</Trans>
        </UploadButton>
      ) : (
        <UploadButton
          color="primary"
          variant="contained"
          disabled={!loadedFile}
          onClick={handleUploadClick}
        >
          <Trans>Use</Trans>
        </UploadButton>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 5px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: ${p => p.theme.spacing(1)};
  padding: 0;
`;

const UploadButton = styled(Button)``;
const CenteredFileSlide = styled(FileSlide)`
  max-width: 20rem;
`;

export default FileUploader;
