import {
  WorkflowProcessStep,
  commentWorkflowType,
  imageCommentType,
} from '~common/workflows/constants';
import { WorkflowSettings } from '~common/workflows/types';

export const commentRequestProcessSteps = [
  WorkflowProcessStep.DETAILS,
  WorkflowProcessStep.SUMMARY,
  WorkflowProcessStep.SENDING,
  WorkflowProcessStep.SENT,
];

export const commentRequestResendSteps = [
  WorkflowProcessStep.CONTENTS,
  WorkflowProcessStep.DETAILS,
  WorkflowProcessStep.SUMMARY,
  WorkflowProcessStep.SENDING,
  WorkflowProcessStep.SENT,
];

export const imageCommentFilter = (workflow: WorkflowSettings) =>
  workflow.type === commentWorkflowType &&
  workflow.commentType === imageCommentType;
