import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { t } from '@lingui/macro';
import copy from 'copy-to-clipboard';
import { skipToken } from '@reduxjs/toolkit/query/react';

import { workspaces } from './model';
import {
  useGetDefaultWorkspaceId,
  useAddToWorkspaceMutation,
  useClearWorkspaceMutation,
} from './api';
import { content } from '../../content/model';
import { useAddFiles } from '../files/common/add.utils';

import config from '~common/config';
import { useRedirectTo } from '~common/navigation/useRedirectTo';
import { useActions } from '~common/utils/hooks.utils';
import { app } from '~common/app.model';
import { commonContent } from '~common/content.model';
import {
  AllowLevel,
  allowLevel,
  mergeIntoFilesById,
} from '~common/content.utils';
import {
  readFiles,
  useGetFolderQuery,
  useDeleteContentMutation,
  useLazyGetExistingFileQuery,
} from '~common/content.api';
import {
  File,
  fileExists,
  RemovedFile,
  UploadFile,
} from '~common/content.types';
import { filterUndefined } from '~common/utils/fn.utils';
import { blobToUploadFile } from '~common/misc/drag/utils';

/** Returns the ID of the workspace currently open in center.
 * Will return the id of the containing workspace even if we have a subfolder open. */
export function useCenterWorkspaceId() {
  const match = useRouteMatch<{ workspaceId: string }>(
    '/workspaces/:workspaceId'
  );
  let id = match?.params.workspaceId;

  const { data } = useGetFolderQuery(id ? { id } : skipToken);
  /* We might have a subfolder of the workspace open in center
  If this is the case, the workspace's id is the parentId of the folder open,
  since there's only one level of folders allowed inside workspaces */
  if (
    !data?.removed &&
    data?.node.fileType !== 'nt:cart' &&
    data?.node.inCart
  ) {
    id = data.node.parentId;
  }

  return id ?? null;
}

/**
 * Returns the current active workspace id, or if no workspace is active now,
 * returns the previously active id
 */
export function useWorkspaceId() {
  const currentWorkspaceId = useSelector(
    state => state.workspaces.currentWorkspaceId
  );
  const centerWorkspaceId = useCenterWorkspaceId();

  return centerWorkspaceId ?? currentWorkspaceId;
}

export function useIsWorkspaceSaved(
  workspace: File | RemovedFile | undefined
): boolean;
export function useIsWorkspaceSaved(workspaceId: string): boolean;
export function useIsWorkspaceSaved(
  workspaceOrId: File | RemovedFile | undefined | string
): boolean {
  const { data: defaultWorkspaceData } = useGetDefaultWorkspaceId();

  if (typeof workspaceOrId === 'string') {
    const workspaceId = workspaceOrId;
    return workspaceId !== defaultWorkspaceData?.id;
  } else {
    const workspace = workspaceOrId;
    return (
      !workspace?.removed && workspace?.node.id !== defaultWorkspaceData?.id
    );
  }
}

export function useOpenWorkspace() {
  const redirectTo = useRedirectTo();
  const { setCurrentWorkspaceId, setCheckedWorkspaceContent } = useActions(
    workspaces.actions
  );

  function openWorkspace(
    workspaceId: string,
    location: 'sidebar' | 'center',
    options?: {
      saveAsCurrentId?: boolean;
    }
  ) {
    if (location === 'sidebar' || options?.saveAsCurrentId) {
      setCurrentWorkspaceId(workspaceId);
    }
    if (location === 'center') {
      redirectTo(`/workspaces/${workspaceId}`);
    }
    setCheckedWorkspaceContent([]);
  }

  return { openWorkspace };
}

export function useOpenNewWorkspace() {
  const { data: defaultWorkspaceData, isSuccess: isDefaultWorkspaceIdSuccess } =
    useGetDefaultWorkspaceId();
  const [clearWorkspace] = useClearWorkspaceMutation();
  const { openWorkspace } = useOpenWorkspace();

  const { setCheckedWorkspaceContent, setCurrentWorkspaceId } = useActions(
    workspaces.actions
  );

  const openNewWorkspace = useCallback(
    async (location: 'sidebar' | 'center') => {
      if (defaultWorkspaceData) {
        await clearWorkspace(defaultWorkspaceData.id).unwrap();
        setCheckedWorkspaceContent([]);
        openWorkspace(defaultWorkspaceData.id, location);
        setCurrentWorkspaceId(defaultWorkspaceData.id);
      }
    },
    [defaultWorkspaceData?.id]
  );

  return useMemo(
    () => ({
      openNewWorkspace,
      isReady: isDefaultWorkspaceIdSuccess,
    }),
    [isDefaultWorkspaceIdSuccess, openNewWorkspace]
  );
}

/** Gets the collection of ids to add or remove from workspace depending on the checked items and items passed through payload */
const getIdsToMove = (
  payloadIds: string | string[] | undefined,
  checkedContent: Set<string>
) => {
  let itemIds =
    payloadIds && (Array.isArray(payloadIds) ? payloadIds : [payloadIds]);

  // Chooses single draggable over checked items if mutually exclusive
  if (!itemIds || itemIds.length === 0 || checkedContent.has(itemIds[0]))
    itemIds = Array.from(checkedContent);
  return itemIds;
};

type AddData = {
  workspaceId?: string;
  itemIds?: string[];
  /** Beware: Currently only supports renaiming user products */
  newNamesById?: Record<string, string>;
};

interface AddToWorkspaceOptions {
  customMessages?: {
    notAllowed: string;
    conflicts: string;
    added: string;
  };
}

export function useAddToWorkspace({
  customMessages,
}: AddToWorkspaceOptions = {}) {
  const checkedContent =
    useSelector(state => state.content.checkedContent?.items) ??
    new Set<string>();
  const filesById = useSelector(state => state.commonContent.filesById);
  const currentWorkspaceId = useSelector(
    state => state.workspaces.currentWorkspaceId
  );

  const [addToWorkspaceMutation] = useAddToWorkspaceMutation();
  const [getExistingFile] = useLazyGetExistingFileQuery();
  const setItemsChecked = useActions(content.actions.setItemsChecked);
  const saveFilesToState = useActions(commonContent.actions.afterReadFiles);
  const { setOpenSnackbar } = useActions(app.actions);
  const { showErrorMessage } = useActions(app.actions);

  async function addToWorkspace(data?: AddData) {
    let newIds: string[] = [];

    try {
      const id = data?.workspaceId ?? currentWorkspaceId ?? '';
      const itemIds = getIdsToMove(data?.itemIds, checkedContent);
      const newNamesById = data?.newNamesById;

      // If we are missing the rights of some files (either source or target)
      // we'll fetch them straight from the API so that we can ensure a legal
      // operation
      const missingFileIds = [...itemIds, id].filter(
        id => !filesById[id]?.file?.userRights
      );

      const missingFilesById = await readFiles({
        ids: missingFileIds,
        params: { include: 'rights' },
      });
      saveFilesToState(missingFilesById);

      const updatedFilesById = Object.values(missingFilesById)
        .filter(fileExists)
        .reduce(
          (acc, file) =>
            mergeIntoFilesById(file, acc, {
              status: null,
              timestamp: new Date(),
            }),
          filesById
        );

      const workspace = updatedFilesById[id]?.file;

      // check if any files being added have same name as the files in target workspace
      const filenameConflicts: string[] = [];
      const itemIdsWithoutNameConflicts: string[] = [];

      const workspacePath = workspace?.node.path ?? '';
      for (const itemId of itemIds) {
        const filename =
          newNamesById?.[itemId] ?? updatedFilesById[itemId]?.file?.name ?? '';
        const exists = await getExistingFile({
          filename,
          parentPath: workspacePath,
        }).unwrap();

        if (exists.id !== null) {
          filenameConflicts.push(filename);
        } else {
          itemIdsWithoutNameConflicts.push(itemId);
        }
      }

      if (
        !workspace ||
        !workspace.userRights ||
        !workspace.userRights.addFiles ||
        allowLevel('addToWorkspace', new Set(itemIds), updatedFilesById) ===
          AllowLevel.ForNone
      ) {
        showErrorMessage(
          customMessages?.notAllowed ??
            t`Adding to workspace not allowed for some of the selected files.`,
          'warning'
        );
      } else {
        if (itemIdsWithoutNameConflicts.length > 0) {
          const { id: ids } = await addToWorkspaceMutation({
            workspaceId: id as string,
            itemIds: itemIdsWithoutNameConflicts,
            newNamesById,
          }).unwrap();
          newIds = ids;
        }

        setItemsChecked(
          itemIds.map(id => ({ node: { id } })),
          false
        );

        const snackbarMessage =
          filenameConflicts.length > 0
            ? `${
                customMessages?.conflicts ??
                t`Workspace already contains file(s)`
              }: ${filenameConflicts.join(', ')}.
              ${
                filenameConflicts.length < itemIds.length
                  ? t`All other files were added.`
                  : ''
              }`
            : customMessages?.added ?? t`File(s) added to workspace`;

        setOpenSnackbar('WORKSPACE/ADDED_FILE', {
          type: filenameConflicts.length > 0 ? 'error' : 'info',
          message: snackbarMessage,
          duration: 4000,
        });
      }
    } catch {
      showErrorMessage(t`Add failed`);
    }

    return newIds;
  }

  return { addToWorkspace };
}

/** A hook that handles uploading new files directly to a workspace.
 * Returns the internal UploadFile[] state value and a setter for it.
 * Adding new files to the internal state starts the upload.
 */
export function useUploadToWorkspace(
  workspaceId: string | undefined,
  options?: { onUploadDone?: () => void }
) {
  const user = useSelector(state => state.app.user);
  const { data: workspace } = useGetFolderQuery(
    workspaceId ? { id: workspaceId } : skipToken
  );

  const [files, setFiles] = useState<
    Array<
      UploadFile & {
        /** This is used to signal awaiting promises that the upload and add are complete */
        awaiter?: { resolver: () => void; rejecter: (error: Error) => void };
      }
    >
  >([]);

  const { addToWorkspace } = useAddToWorkspace();

  const { startUpload, uploadDone, uploading, uploadedIds, reset } =
    useAddFiles({
      targetFolderId: user?.homeFolderId ?? '',
      files,
      setFiles,
      metaFields: [],
      metaData: {},
    });

  // Start uploading right away after some files have been dropped
  useEffect(() => {
    if (files.length > 0 && !uploading) startUpload();
  }, [files.map(file => file.name).join('-')]);

  // After uploading has been completed, add all newly uploaded files to workspace
  useEffect(() => {
    (async () => {
      if (!workspaceId) return;
      if (uploadDone && uploadedIds.length === files.length) {
        try {
          await addToWorkspace({ workspaceId, itemIds: uploadedIds });
        } catch (e) {
          files.forEach(file =>
            file.awaiter?.rejecter(new Error('addToWorspace failed'))
          );
        }
        reset();
        options?.onUploadDone?.();
        files.forEach(file => file.awaiter?.resolver());
      }
    })();
  }, [uploadDone, uploadedIds.join('-')]);

  const resetFiles = () => {
    setFiles([]);
  };

  /** Add multiple files to be uploaded into the workspace */
  const addFiles = (newFiles: globalThis.File[]) => {
    setFiles(files => [
      ...files,
      ...newFiles.map(blob => blobToUploadFile(blob, { timestamp: true })),
    ]);
  };

  /** Upload a single file. This function can be awaited. */
  const uploadFile = async (file: globalThis.File) => {
    let awaiter;
    const promise = new Promise<void>((resolve, reject) => {
      awaiter = {
        resolver: () => resolve(),
        rejecter: error => reject(error),
      };
    });
    setFiles(files => [
      ...files,
      {
        ...blobToUploadFile(file, { timestamp: true }),
        awaiter,
      },
    ]);
    await promise;
  };

  if (!workspace || workspace.removed || !workspace.userRights?.addFiles)
    return { allowed: false } as const;

  return {
    files: files as UploadFile[],
    addFiles,
    resetFiles,
    uploadFile,
    allowed: true,
  } as const;
}

/** Wrapper for common content deletion mutation so that
 * we can only pass workspace content IDs and not data */
export function useRemoveFromWorkspace() {
  const [deleteContentMutation] = useDeleteContentMutation();
  const { setCheckedWorkspaceContent } = useActions(workspaces.actions);
  const filesById = useSelector(state => state.commonContent.filesById);

  async function removeFromWorkspace(itemIds: string[]) {
    const files = itemIds
      .map(id => filesById[id]?.file)
      .filter(filterUndefined)
      .filter(file => file.node.inCart || file.node.inShoppingCart);
    const result = await deleteContentMutation(files);
    setCheckedWorkspaceContent([]);

    return result;
  }

  return { removeFromWorkspace };
}

/** Wraps workspace clipboard copying functionality so that we don't
 * have to always dig into the state */
export function useCopyWorkspaceToClipboard() {
  const languages = useSelector(state => state.app.customer?.languages);
  const { showInfoMessage, showErrorMessage } = useActions(app.actions);

  async function copyToClipboard(workspace?: File | RemovedFile) {
    if (workspace && !workspace.removed) {
      const publicTicket =
        workspace.propertiesById['nibo:publicly-shared-random-key'];

      if (!publicTicket) {
        showErrorMessage(t`Copy failed`);
        return;
      }

      copy(
        `${config.CURR_BASE_URL}${config.basePath}shares/${
          workspace.isCart ? 'w' : 'f'
        }${workspace.node.id}/${publicTicket}/${languages?.[0]}/`
      );

      showInfoMessage(t`The link has been copied to your clipboard`);
    } else {
      showErrorMessage(t`Copy failed`);
    }
  }

  return copyToClipboard;
}
