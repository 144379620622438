import { SearchCriteria } from './model';

export function parseCriteriaParamsOut(criteria: SearchCriteria) {
  const result = { ...criteria };
  // Combine start and end dates of the same metafield filter into a single range
  for (const key of Object.keys(criteria)) {
    const startDateMatch = key.match(/f-meta-(\d*)_dateStart/);
    if (!startDateMatch) continue;
    const id = startDateMatch[1];
    const negation = key.startsWith('-');
    const prefix = negation ? '-' : '';
    if (!criteria[key] || !criteria[`${prefix}f-meta-${id}_dateEnd`]) continue;
    // Criteria contains both the start and end date for this metafield
    result[`${prefix}f-meta-${id}_dateRange`] = `${
      result[`${prefix}f-meta-${id}_dateStart`]
    } ${result[`${prefix}f-meta-${id}_dateEnd`]}`;
    delete result[`${prefix}f-meta-${id}_dateStart`];
    delete result[`${prefix}f-meta-${id}_dateEnd`];
  }
  return result;
}

export function parseCriteriaParamsIn(criteria: SearchCriteria) {
  const result = { ...criteria };
  // split date ranges of metafield filters into start and end dates
  for (const key of Object.keys(criteria)) {
    const idMatch = key.match(/f-meta-(\d*)_dateRange/);
    if (!idMatch || typeof criteria[key] !== 'string') continue;
    const prefix = key.startsWith('-') ? '-' : '';
    const [start, end] = (criteria?.[key] as string).split(' ');
    if (criteria[`${prefix}f-meta-${idMatch[1]}_dateStart`] === undefined)
      result[`${prefix}f-meta-${idMatch[1]}_dateStart`] = start;
    if (criteria[`${prefix}f-meta-${idMatch[1]}_dateEnd`] === undefined)
      result[`${prefix}f-meta-${idMatch[1]}_dateEnd`] = end;
    delete result[`${prefix}f-meta-${idMatch[1]}_dateRange`];
  }
  return result;
}
