import { i18n } from '@lingui/core';
import { Store } from 'redux';
import { DefaultRootState } from 'react-redux';
import { isEmpty } from './utils/fn.utils';
import { TranslatedData } from './common.types';
import { UserBase } from '~common/app.types';

export interface LocalizedText {
  value: string;
  lang: string;
}

export const languageCodes = ['fi', 'en', 'se', 'de', 'fr'] as const;
export type LanguageCode = typeof languageCodes[number];

let reduxStore: Store<DefaultRootState>;

export const connectUtilsToStore = store => {
  reduxStore = store;
};

/** Returns the customer config object from redux.
 * Can be used to retrieve configs outside of hooks.
 */
export function getCustomerConfig() {
  return reduxStore.getState().app.customer?.configById;
}

/** Returns the user folder sort object from redux.
 * Can be used to retrieve configs outside of hooks.
 */
export function getUserFolderSort() {
  return reduxStore.getState().app.userFolderSort;
}

/** Returns the user search sort object from redux.
 * Can be used to retrieve configs outside of hooks.
 */
export function getUserSearchSort() {
  return reduxStore.getState().app.userSearchSort;
}

/* 
Gets correct value for language with the language code if one found.
If not found selects default language option. 
Method is connected to redux store and checks language options from there.
 */
export const getLocalizedValue = <T = any>(
  valuesByLang: TranslatedData<T> | undefined,
  lang?: string
) => {
  if (!valuesByLang) return undefined;
  const reduxState = reduxStore.getState();
  const language = (lang || reduxState.app.settings?.language) ?? 'fi';
  const defaultLanguage = reduxState.app.customer?.defaultLanguage ?? 'fi';
  return !isEmpty(valuesByLang[language])
    ? { value: valuesByLang[language] as T, lang: language }
    : !isEmpty(valuesByLang[defaultLanguage])
    ? { value: valuesByLang[defaultLanguage] as T, lang: defaultLanguage }
    : undefined;
};

export const getLangValue = <T = any>(
  valuesByLang: TranslatedData<T> | undefined,
  lang?: string
) => {
  const localizedValue = getLocalizedValue(valuesByLang, lang);
  return (localizedValue && localizedValue.value) as T;
};

export const getContentLanguage = <T = any>(
  valuesByLang: TranslatedData<T>,
  lang?: string
) => {
  const localizedValue = getLocalizedValue(valuesByLang, lang);
  return localizedValue ? localizedValue.lang : undefined;
};

export const convertLangToFull = (lang?: string): string => {
  const langNames = {
    fi: 'Suomi',
    en: 'English',
    se: 'Svenska',
    de: 'Deutsch',
    fr: 'Français',
  };
  if (!lang) return 'LANGUAGE_NOT_DEFINED';
  return langNames[lang.toLowerCase()] || i18n._(lang) || lang;
};

export const determineIfObjectOrString = (
  variable: string | LocalizedText
): variable is LocalizedText => {
  if ((variable as LocalizedText).value) {
    return true;
  }
  return false;
};

export const convertUserIn = <T extends UserBase>(user: T) => ({
  ...user,
  fullName: `${user.firstName || ''} ${user.familyName || ''}`,
});

export const getUrlParamValue = (param: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has(param) ? urlParams.get(param) : undefined;
};
