import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import fileContentApi from './fileContent.api';
import UseComponent from './Use';
import { SideBarSection } from './SideBarSection';
import { Use } from './types';
import { getLangValue } from '~common/app.utils';

interface Props {
  nodeId: string;
  uses: Use[];
  onSelectUse: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: number
  ) => void;
  availableConversions?: string[];
}

const UseSelection = ({
  nodeId,
  uses,
  onSelectUse,
  availableConversions,
}: Props) => (
  <ContentWrapper>
    <Preview
      alt={'Preview'}
      crossOrigin="use-credentials"
      src={fileContentApi.getLink({
        id: nodeId,
        type: 'preview',
        isDownload: false,
        size: undefined,
        crop: undefined,
        maskid: undefined,
      })}
    />
    <SideBar>
      <SideBarSection title={<FormattedMessage id="file.cropSelectUse" />}>
        {uses.length ? (
          <UseList component="nav">
            {uses
              .sort((a, b) =>
                getLangValue(a.namesByLang).localeCompare(
                  getLangValue(b.namesByLang)
                )
              )
              .map(
                u =>
                  availableConversions?.indexOf(u.contentType) !== -1 && (
                    <UseItem
                      key={u.id}
                      onClick={event => onSelectUse(event, u.id)}
                    >
                      <UseText>
                        <UseComponent use={u} extended />
                      </UseText>
                    </UseItem>
                  )
              )}
          </UseList>
        ) : (
          <UseItem disabled>
            <FormattedMessage id="file.noUses" />
          </UseItem>
        )}
      </SideBarSection>
    </SideBar>
  </ContentWrapper>
);

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-self: flex-start;
  max-height: 90vh;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

const Preview = styled.img`
  padding: 16px 16px 16px 0;
  width: auto;
  height: auto;
  max-width: 50%;
  max-height: 60vh;
  object-fit: contain;
  align-self: center;
`;

const SideBar = styled.div`
  flex: 1 1 auto;
  margin-top: 16px;
`;

const UseList = styled(List)`
  && {
    padding-top: 0;
    padding-bottom: 0;
  }
` as typeof List;

const UseItem = styled(ListItemButton)`
  && {
    border-bottom: 1px #dfdfdf solid;
    border-left: 1px #dfdfdf solid;
    border-right: 1px #dfdfdf solid;
  }
` as typeof ListItemButton;

const UseText = styled(ListItemText)``;

export default UseSelection;
