import React from 'react';
import { Trans } from '@lingui/macro';
import styled, { css } from 'styled-components';
import { FolderOutlined } from '@mui/icons-material';

import Typography from '~misc/Typography';
import { getLangValue } from '~common/app.utils';
import { File } from '~common/content.types';
import { getImageSrc } from '~common/utils/img.utils';
import { WorkspaceIcon as OriginalWorkspaceIcon } from '~common/misc/icons';

interface ContentDragPreviewProps extends React.HTMLAttributes<HTMLDivElement> {
  item: File & { isPreviewImage?: boolean };
  dragCount?: any;
  infoText?: string;
}

const ContentDragPreview: React.FC<ContentDragPreviewProps> = ({
  item,
  dragCount,
  ...rest
}) => {
  // TODO: load thumbnail from server
  let imageUrl;
  if (!(item.isFolder || item.isCart || item.isPreviewImage))
    imageUrl = getImageSrc(item, 'thumbnail');
  else if (item.isPreviewImage) imageUrl = getImageSrc(item, 'preview');
  return (
    <DragPreview {...rest}>
      {dragCount && dragCount > 1 ? (
        <InfoWrapper dragCount>
          <InfoText variant="h2">
            {dragCount} <Trans>pcs</Trans>
          </InfoText>
        </InfoWrapper>
      ) : item.isFolder ? (
        <FolderWrapper>
          <FolderIcon />
          <ItemTitle>
            <ItemName>{getLangValue(item.namesByLang) || item.name}</ItemName>
          </ItemTitle>
        </FolderWrapper>
      ) : item.isCart ? (
        <FolderWrapper>
          <WorkspaceIcon />
          <ItemTitle>
            <ItemName>{getLangValue(item.namesByLang) || item.name}</ItemName>
          </ItemTitle>
        </FolderWrapper>
      ) : (
        <ItemWrapper>
          <PreviewImage
            title={getLangValue(item.namesByLang) || item.name}
            src={imageUrl}
          />
          <ItemTitle>
            <ItemName>{getLangValue(item.namesByLang) || item.name}</ItemName>
          </ItemTitle>
        </ItemWrapper>
      )}
    </DragPreview>
  );
};

const DragPreview = styled.div`
  display: inline-block;
  position: relative;
`;

const ItemWrapper = styled.div`
  position: relative;
  margin: 0 12px;
  padding: 8px;
  background-color: #fff;
  border: 2px solid #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 3px;
  width: 176px;
  height: 150px;
  cursor: pointer;

  :hover {
    border-color: #186d8a;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    > div > a > img {
      opacity: 0.5;
    }
  }
`;

const InfoWrapper = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 2px solid #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 3px;
  padding: 2px;
  width: 100px;
  height: 48px;
  cursor: pointer;
`;

const FolderWrapper = styled(ItemWrapper)`
  display: flex;
  align-items: center;
  padding-bottom: 0 2px;
  gap: ${p => p.theme.spacing(2)};
  height: 48px;
`;

const InfoText = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: 24px;
  padding: 0 10px;
  text-align: center;
`;

const ItemTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex: 1;
  overflow: hidden;
  align-items: center;
`;

const ItemName = styled(Typography)`
  && {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 5px;
    max-width: 100%;
    z-index: 3;
  }
`;

const PreviewImage = styled.img<any>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 110px;
  width: auto;
  margin: 0 auto 5px;
`;

const rowIconStyles = css`
  --thumbnail-size: 2.5rem;

  display: block;
  min-width: var(--thumbnail-size);
  max-width: var(--thumbnail-size);
  height: var(--thumbnail-size);
  color: ${p => p.theme.palette.grey['500']};

  font-size: var(--thumbnail-size);
`;

const FolderIcon = styled(FolderOutlined)`
  && {
    ${rowIconStyles}
  }
`;

const WorkspaceIcon = styled(OriginalWorkspaceIcon)`
  && {
    ${rowIconStyles}
  }
`;

export default ContentDragPreview;
