import React from 'react';
import styled from 'styled-components';
import { FormControl, FormLabel as MuiFormLabel } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { format } from 'date-fns';

import { MetaData, DateRangeField } from '../content.types';
import DatePicker from '~common/inputs/DatePicker';
import StyledFormLabel from '~inputs/FormLabel';
import { getDateString } from '~common/utils/date.utils';
import { Switch } from '~common/inputs/Switch';
import { LocalizedText } from '~common/app.utils';
import TextField from '~common/inputs/TextField';

interface Props {
  field: DateRangeField;
  metaData?: Record<string, MetaData>;
  lang: string;
  onChange: (value: string | null, fieldKey?: string) => void;
  disabled?: boolean;
  label?: string | LocalizedText;
  error?: boolean;
  helperText?: string;
  showSwitch?: boolean;
  switchLabel?: string;
  readOnly?: boolean;
  valueToString?: (
    selected: boolean,
    fromDate: Date | null,
    toDate: Date | null
  ) => string;
  variant?: 'single-row' | 'two-row';
}
const FormDateRange = ({
  field,
  readOnly,
  metaData,
  lang,
  onChange,
  disabled,
  label,
  error,
  helperText,
  showSwitch,
  switchLabel,
  valueToString,
  variant = 'single-row',
  ...rest
}: Props) => {
  const { fromDateFieldId, toDateFieldId, id: fieldId } = field;

  const fromDate =
    metaData?.[fromDateFieldId] &&
    typeof metaData?.[fromDateFieldId] === 'string'
      ? new Date(metaData[fromDateFieldId] as string)
      : null;
  const toDate =
    metaData?.[toDateFieldId] && typeof metaData?.[toDateFieldId] === 'string'
      ? new Date(metaData[toDateFieldId] as string)
      : null;

  const selected = Boolean(fieldId) && metaData?.[fieldId] === 'true';
  const dateDisabled = !selected;

  const onDateChange = (fieldId: string, value: Date | null) => {
    onChange(
      value ? format(value, "yyyy-MM-dd'T'HH:mm:00.000XX") : null,
      fieldId
    );
  };
  const onSelectedChange = (value: boolean) => {
    onChange(value ? 'true' : 'false', fieldId);
  };

  const readOnlyValue = valueToString
    ? valueToString(selected, fromDate, toDate)
    : selected
    ? `${fromDate ? getDateString(fromDate) : ''} - ${
        toDate ? getDateString(toDate) : ''
      }`
    : '';

  if (readOnly)
    return (
      <FormControl component="fieldset" disabled={disabled}>
        {label && (
          <MuiFormLabel component="legend">
            <StyledFormLabel label={label} compact disabled={disabled} />
          </MuiFormLabel>
        )}
        <ReadOnlyTextField readOnly value={readOnlyValue} disabled={disabled} />
      </FormControl>
    );

  return (
    <FormControl component="fieldset" error={error} disabled={disabled}>
      {label && (
        <MuiFormLabel component="legend">
          <StyledFormLabel label={label} compact disabled={disabled} />
        </MuiFormLabel>
      )}
      <ElementsRow $twoRow={variant === 'two-row'}>
        {field?.id && showSwitch && (
          <Switch
            label={switchLabel ?? ''}
            id={field.id}
            value={selected}
            disabled={disabled}
            onChange={event => onSelectedChange(event.target.checked)}
          />
        )}
        <DatePicker
          id={fromDateFieldId}
          value={fromDate}
          onChange={(value: Date) => {
            onDateChange(fromDateFieldId, value);
          }}
          onClear={() => onDateChange(fromDateFieldId, null)}
          {...rest}
          error={error}
          labelProps={{ compact: true }}
          disabled={disabled || dateDisabled}
        />
        {'  -  '}
        <DatePicker
          id={toDateFieldId}
          value={toDate}
          onChange={(value: Date) => {
            onDateChange(toDateFieldId, value);
          }}
          onClear={() => onDateChange(toDateFieldId, null)}
          {...rest}
          error={error}
          labelProps={{ compact: true }}
          disabled={disabled || dateDisabled}
        />
      </ElementsRow>
      {helperText && (
        <FormHelperText variant="standard">{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

const ReadOnlyTextField = styled(TextField)`
  // Makes the ReadOnlyTextField as tall as the range selector
  padding-block: 4px;
`;

const ElementsRow = styled.div<{ $twoRow?: boolean }>`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  & > .MuiTextField-root {
    flex: 1;
    flex-basis: auto;
  }
  gap: ${p => p.theme.spacing(1)} ${p => p.theme.spacing(2)};

  ${p => p.$twoRow && 'flex-wrap: wrap;'}
  ${p =>
    p.$twoRow &&
    `
  & > label {
    flex-basis: calc(100% - ${p.theme.spacing(2)});
  }
  `}
`;
export default FormDateRange;
