import { t } from '@lingui/macro';

// NOTE: Defined only file section specific messages here.
// Commonly used messages should be defined in ../../common/messages.js

const translations = () => ({
  cropViewTitle: t`cropViewTitle`,
  cropImageShape: t`cropImageShape`,
  cropShape: t`cropShape`,
  cropFinalImageSize: t`cropFinalImageSize`,
  cropSelectUse: t`cropSelectUse`,
  cropPixels: t`cropPixels`,
  cropOriginalImageSize: t`cropOriginalImageSize`,
  cropSizeWarning: t`cropSizeWarning`,
  noUses: t`noUses`,
  removeCrop: t`removeCrop`,
});

/* eslint-disable max-len */
export default {
  fi: translations,
  en: translations,
  se: translations,
  de: translations,
  fr: translations,
};
