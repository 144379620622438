import React from 'react';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro';

import { content } from '../common/model';

import { useActions } from '~common/utils/hooks.utils';
import { BasicOption } from '~common/navigation/MenuButton';
import { AllowLevel, allowLevel } from '~common/content.utils';
import { SynkkaIcon } from '~common/misc/icons';

export function useSendToSynkkaOption(fileIds: string[]): BasicOption {
  const filesById = useSelector(state => state.commonContent.filesById);
  const manageSynkka = useActions(content.actions.manageSynkka);

  const sendToSynkkaAllowed = allowLevel('sendToSynkka', fileIds, filesById);

  return {
    value: 'sendToSynkka',
    title: t`Send to synkka`,
    icon: <SynkkaIcon />,
    disabled: sendToSynkkaAllowed === AllowLevel.ForSome,
    hidden: sendToSynkkaAllowed === AllowLevel.ForNone,
    onSelect: () => {
      manageSynkka(fileIds);
    },
  };
}
