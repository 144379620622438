import qs from 'qs';

import { Order } from './common/types';

import { apiBase } from '~common/api.base';
import { Criteria } from '~common/common.types';

/** Generates the tag with which queries of orders can be invalidated. */
export const getOrderTag = (orderId: string) => ({
  type: 'Order' as const,
  id: orderId,
});

type GetOrderParams = { id: string };

type ListOrdersData = {
  totalCount: number;
  orders: Order[];
};

const extendedApi = apiBase.injectEndpoints({
  endpoints: builder => ({
    getOrder: builder.query<Order, GetOrderParams>({
      query: ({ id }) => ({ url: `/orders/${id}`, method: 'get' }),
      providesTags: (_, __, { id }) => [getOrderTag(id)],
    }),

    listOrders: builder.query<ListOrdersData, Criteria>({
      query: criteria => {
        const cr = {
          ...criteria,
          startIndex: criteria.page * criteria.pageSize,
          offset: criteria.page * criteria.pageSize,
          limit: criteria.pageSize,
          orderBy: criteria.sortBy,
        };

        return { url: `/orders?${qs.stringify(cr)}`, method: 'get' };
      },
      providesTags: result =>
        result?.orders.map(order => getOrderTag(order.id)) ?? [],
    }),
  }),
});

const {
  util: { invalidateTags },
} = extendedApi;

export const invalidateOrder = (id: string) =>
  invalidateTags([getOrderTag(id)]);
export const invalidateOrders = () => invalidateTags(['Order']);
export const { useGetOrderQuery, useListOrdersQuery } = extendedApi;
