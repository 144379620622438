// TODO: Move ShareButton elsewhere
import React from 'react';
import { Trans } from '@lingui/macro';
import { ShareIcon } from '../misc/icons';

import Button from './Button';

const ShareButton = ({ hideText, ...props }: any, ref) => (
  <Button
    variant="contained"
    color="primary"
    startIcon={!hideText && <ShareIcon />}
    {...props}
    ref={ref}
  >
    {!hideText ? <Trans>Share</Trans> : <ShareIcon />}
  </Button>
);

export default React.forwardRef(ShareButton);
