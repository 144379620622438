import React from 'react';

const Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g transform="translate(-3 -3)">
        <rect
          data-name="Rectangle 7"
          width="18"
          height="18"
          rx="2"
          fill="currentColor"
          stroke="currentColor"
          transform="translate(3 3)"
        />
        <line
          data-name="Line 12"
          x2="8"
          transform="translate(8 12)"
          fill="none"
          stroke="#fff"
          strokeWidth="1"
        />
        <line
          data-name="Line 10"
          y2="8"
          transform="translate(12 8)"
          fill="none"
          stroke="#fff"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default Icon;
