import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro';

import { useLingui } from '@lingui/react';
import { Code } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import Workspaces from './content/workspaces';
import Element from './content/products/element';
import CommentList from './comments/edit/commentList';
import {
  ToggleHover as ToggleHoverBase,
  Toggle as ToggleBase,
  Resize,
} from './LeftDock';

import Dock from '~sections/Dock';
import ErrorBoundary from '~utils/ErrorBoundary';

import {
  MinimizedWorkspaces,
  MinimizedProductElement,
  MinimizedCommentList,
} from '~sections/MinimizedDockContent';
import { useActions } from '~common/utils/hooks.utils';
import { app, RightDockContent } from '~common/app.model';

const contentsById = {
  '/workspaces': {
    label: t`Workspace`,
    element: <Workspaces />,
    minimized: MinimizedWorkspaces,
  },
  '/productElement': {
    label: t`Elements`,
    element: <Element />,
    minimized: MinimizedProductElement,
  },
  '/commentList': {
    label: t`Comments`,
    element: <CommentList />,
    minimized: MinimizedCommentList,
  },
};

export const expandableDocks: Array<RightDockContent> = ['/productElement'];

function RightDock() {
  const { open, content } = useSelector(state => state.app.rightDock);
  const share = useSelector(state => state.app.share);
  const isGuestCommenter = share?.type === 'o';
  const user = useSelector(state => state.app.user);
  const visible = useSelector(
    state => !!state.app.rightDock.visible && (!!user || isGuestCommenter)
  );
  const toggleRight = useActions(app.actions.toggleRight);

  const children = (content && contentsById[content].element) ?? (
    <div id="rightDock-root" />
  );
  const MinimizedContent =
    (content && contentsById[content].minimized) ?? 'div';
  const variant = content === '/workspaces' ? 'default' : 'narrow';

  const { i18n } = useLingui();

  const { toggleRef, dockRef } = useDockResize(560, 280);
  const canResize = content && expandableDocks.includes(content);

  return (
    <Dock
      right
      aria-label={content && contentsById[content].label}
      visible={visible && open && !!content}
      minimized={visible && !open && !!content}
      minimizedContent={<MinimizedContent openDock={toggleRight} />}
      variant={variant}
      id="right-dock"
      tabIndex={-1}
      key={i18n.locale} // remount to update translated values
      ref={canResize ? dockRef : undefined}
    >
      <ToggleHover
        onClick={() => toggleRight({ open: false, content })}
        $resizable={Boolean(canResize)}
        ref={canResize ? toggleRef : undefined}
      >
        {canResize ? (
          <ResizeButtonContainer>
            <Tooltip title={t`Click to close / drag to resize dock`}>
              <StyledResizeButton>
                <StyledResizeIcon color="primary" />
              </StyledResizeButton>
            </Tooltip>
          </ResizeButtonContainer>
        ) : (
          <Toggle />
        )}
      </ToggleHover>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Dock>
  );
}

function useDockResize(maxWidth: number, minWidth: number) {
  const resizeRightDock = useActions(app.actions.resizeRightDock);

  const toggleRef = useRef<HTMLDivElement>(null);
  const dockRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    addEventListener('pointerdown', handlePointerDown, false);
    addEventListener('pointermove', handlePointerMove, false);
    addEventListener('pointerup', handlePointerUp, false);
    addEventListener('click', handleClickCapture, true);
    return () => {
      removeEventListener('pointerdown', handlePointerDown, false);
      removeEventListener('pointermove', handlePointerMove, false);
      removeEventListener('pointerup', handlePointerUp, false);
      removeEventListener('click', handleClickCapture, true);
    };
  }, []);

  /** Will keep resizing the dock while dragging */
  const resizeDock = () => {
    if (isDirty && dockRef.current && diff) {
      const el = dockRef.current;
      const x = clamp(diff.x);
      el.style.position = 'relative';
      el.style.right = '0px';
      el.style.left = `${x}px`;
      el.style.width = `calc(100% - ${x}px)`;
      isDirty = false;
    }
    if (isDrag) requestAnimationFrame(resizeDock);
  };

  let isDrag = false;
  let isDirty = false;
  let diff: { x: number } | undefined;
  let initialWidth: number | undefined;

  const clamp = (diff: number) => {
    const maxDiff = (initialWidth ?? 0) - maxWidth;
    const minDiff = (initialWidth ?? 0) - minWidth;
    return Math.min(minDiff, Math.max(maxDiff, diff));
  };

  const handlePointerDown = (e: PointerEvent) => {
    // Ensure that the toggle is being clicked
    if (
      e.target !== toggleRef.current &&
      !toggleRef.current?.contains(e.target as Node)
    )
      return;
    // On mouse accept just the left click
    if (e.pointerType === 'mouse' && e.button !== 0) return;
    e.preventDefault();
    isDrag = true;
    diff = { x: 0 };
    initialWidth = dockRef.current?.offsetWidth;
    requestAnimationFrame(resizeDock);
    return false;
  };

  const handlePointerMove = (e: PointerEvent) => {
    if (!isDrag || !diff || !dockRef.current) return;
    e.preventDefault();
    diff.x += e.movementX;
    isDirty = true;
  };

  let preventClick = false;
  const handlePointerUp = (e: PointerEvent) => {
    if (!isDrag || !diff) return;
    e.preventDefault();
    // prevent any click events if the dock was resized
    preventClick = Boolean(diff?.x);
    isDrag = false;
    requestAnimationFrame(() => {
      if (!dockRef.current || !diff) return;
      resizeRightDock(-clamp(diff.x));
      const el = dockRef.current;
      el.style.position = '';
      el.style.right = '';
      el.style.left = '';
      el.style.width = '';
    });
    return false;
  };

  const handleClickCapture = (e: PointerEvent) => {
    if (preventClick) e.stopPropagation();
    preventClick = false;
  };

  return { toggleRef, dockRef };
}

const ToggleHover = styled(ToggleHoverBase)<{ $resizable?: boolean }>`
  left: unset;
  right: 100%;
  transform: translate(50%, -50%);
  ${p => p.$resizable && 'cursor: col-resize;'}
`;

const Toggle = styled(ToggleBase)`
  left: unset;
  right: 50%;
`;

const ResizeButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledResizeButton = styled(IconButton)`
  background: rgb(211 211 211 / 30%);
  &:hover {
    background-color: rgb(211 211 211 / 80%);
  }
`;

const StyledResizeIcon = styled(Code)`
  width: 25px;
  height: 25px;
`;

export default RightDock;
