import { t } from '@lingui/macro';

export const horizontalAlign = {
  TEXT_ALIGN_LEFT: { name: 'left', value: 'flex-start', superscriptFactor: 0 },
  TEXT_ALIGN_CENTER: { name: 'center', value: 'center', superscriptFactor: 1 },
  TEXT_ALIGN_RIGHT: { name: 'right', value: 'flex-end', superscriptFactor: 2 },
  TEXT_ALIGN_JUSTIFY: { name: 'justify', value: 'space-between' },
};

export const verticalAlign = {
  TEXT_VALIGN_TOP: { name: 'top', value: 'flex-start' },
  TEXT_VALIGN_MIDDLE: { name: 'middle', value: 'center' },
  TEXT_VALIGN_BOTTOM: { name: 'bottom', value: 'flex-end' },
};

export const priceDecimalStyles = {
  0: { name: 'Superscript' },
  1: { name: 'Subscript' },
};

export const textDirection = {
  TEXT_DIRECTION_CLOCKWISE: { name: 'Clockwise' },
  TEXT_DIRECTION_ANTICLOCKWISE: { name: 'Counterclockwise' },
};

export interface ElementConstant {
  name: string;
  value?: string;
}

export const getElementTypes = () => {
  return {
    VECTOR: {
      name: t`element:VECTOR`,
    },
    IMAGE: {
      name: t`element:IMAGE`,
    },
    TEXT: {
      name: t`element:TEXT`,
    },
    TEXT_AREA: {
      name: t`element:TEXT_AREA`,
    },
    TEXT_FIELD_CURVING: {
      name: t`element:TEXT_FIELD_CURVING`,
    },
    TEXT_FLOW: {
      name: t`element:TEXT_FLOW`,
    },
    TEXT_PRICE: {
      name: t`element:TEXT_PRICE`,
    },
  };
};
