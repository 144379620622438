import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import Cookies from 'js-cookie';
import { Trans } from '@lingui/macro';

import Typography from '~common/misc/Typography';
import ButtonBar from '~common/sections/ButtonBar';
import Button from '~common/inputs/Button';

const GA_TRACKING_CONSENT_COOKIE = 'ga-tracking-consent';

interface Props {
  grediTrackingId: string;
  customerTrackingId?: string;
  lang?: string;
}

const Tracking = ({ grediTrackingId, customerTrackingId, lang }: Props) => {
  const location = useLocation();
  const customerConfig = useSelector(state => state.app.customer?.configById);
  const [gaTrackingConsentCookie, setGaCookie] = useState(
    Cookies.get(GA_TRACKING_CONSENT_COOKIE)
  );
  const [isVisible, setVisible] = useState(false);
  const bannerRef = useRef<HTMLDivElement>(null);
  const justAccepted = useRef(false);

  const gaEnabled = customerConfig?.['google.analytics.enable'] === true;

  useEffect(() => {
    if (!gaEnabled) return;
    if (!gaTrackingConsentCookie) {
      setVisible(true);
      return;
    }

    if (gaTrackingConsentCookie === 'false') return;
    // Ignore Google Analytics on localhost, newtest and TU's servers
    if (
      window.location.hostname === 'localhost' ||
      window.location.hostname === 'newtest.materialbank.net' ||
      window.location.hostname.indexOf('.taitodev.com') !== -1
    )
      return;
    // Set up Gredi's tracking
    ReactGA.initialize(grediTrackingId);
    // Set up customer's own tracking if configured
    if (customerTrackingId) {
      ReactGA.addTrackers([
        {
          trackingId: customerTrackingId,
          gaOptions: {
            name: 'CustomerTracker',
          },
        },
      ]);
    }

    if (justAccepted.current) {
      pageview();
      justAccepted.current = false;
    }
  }, [gaTrackingConsentCookie, gaEnabled]);

  // Send page views to Gredi's and customer's GA
  const pageview = () =>
    ReactGA.pageview(location.pathname + location.search, ['CustomerTracker']);

  useEffect(() => {
    if (gaTrackingConsentCookie !== 'false' && gaEnabled) {
      pageview();
    }
  }, [location]);

  const handle = (result: boolean) => {
    const cookie = result ? 'true' : 'false';
    Cookies.set(GA_TRACKING_CONSENT_COOKIE, cookie, { expires: 365 });
    setGaCookie(cookie);
    setVisible(false);
    if (result) justAccepted.current = true;
  };

  const privacyPolicyLink =
    lang === 'fi'
      ? 'https://www.gredi.fi/tietosuojaseloste/'
      : 'https://www.gredi.fi/en/privacy-policy/';

  return isVisible && gaEnabled ? (
    <Banner ref={bannerRef}>
      <Panel>
        <Header variant="h6">
          <Trans>We use cookies to provide better services.</Trans>
        </Header>
        <Typography>
          <Trans>
            Do you accept the analytics and tracking cookies used by the site?
          </Trans>
        </Typography>
      </Panel>
      <Panel>
        <PrivacyPolicy href={privacyPolicyLink} target="_blank">
          &#x24D8;{' '}
          <span>
            <Trans>Privacy policy</Trans>
          </span>
        </PrivacyPolicy>
        <StyledButtonBar>
          <Button variant="outlined" onClick={() => handle(false)}>
            <Trans>I don&apos;t accept</Trans>
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handle(true)}
          >
            <Trans>I accept</Trans>
          </Button>
        </StyledButtonBar>
      </Panel>
    </Banner>
  ) : null;
};

const Banner = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)}`};
  background: ${({ theme }) => theme.palette.common.white};
  border-top: 1px solid ${({ theme }) => theme.palette.border.card};
  box-shadow: ${({ theme }) => theme.shadows[20]};
  z-index: 999;
`;

const Panel = styled.div`
  margin: 12px;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const Header = styled(Typography)`
  && {
    font-size: 1rem;
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;

const StyledButtonBar = styled(ButtonBar)`
  margin-top: 0;
  padding: 0 !important;
`;

const PrivacyPolicy = styled.a`
  display: block;
  color: ${({ theme }) => theme.palette.primary.dark};
  cursor: pointer;
  text-decoration: none;
  margin-bottom: ${({ theme }) => theme.spacing(1)};

  & > span {
    text-decoration: underline;
  }
`;

export default Tracking;
