import { t } from '@lingui/macro';
import React from 'react';
import { useSelector } from 'react-redux';
import { useGetContentsParams } from '../common/utils';
import { useMarkMaterialAsLikedMutation } from '~common/content.api';
import { File } from '~common/content.types';
import { LikedIcon, LikeIcon } from '~common/misc/icons';

export function useLikeMaterialOption(item: File) {
  const config = useSelector(state => state.app.customer?.configById);
  const [like] = useMarkMaterialAsLikedMutation();
  const getContentsParams = useGetContentsParams();

  return {
    value: 'likeMaterial',
    title: item.isLikedByUser ? t`Dislike` : t`Like`,
    icon: item.isLikedByUser ? <LikedIcon /> : <LikeIcon />,
    hidden:
      !config?.['material.like.enabled'] ||
      (!item.isFile && !item.isFolder) ||
      item.node.inShoppingCart,
    onSelect: () => {
      like({
        id: item.node.id,
        dislike: item.isLikedByUser,
        getContentsParams,
      });
    },
  };
}
