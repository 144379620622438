import React from 'react';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro';

import { Fileish, fileExists } from '~common/content.types';
import { ModalOption } from '~common/navigation/MenuButton';
import { FolderIcon } from '~common/misc/icons';
import { useCustomerMetaFields } from '~common/content.utils';

export function useCreateFolderOption(
  workspace: Fileish | undefined
): ModalOption {
  const metaFields = useCustomerMetaFields();
  const userRights = useSelector(state => state.app.userRights);

  return {
    value: 'folder',
    title: t`Create folder`,
    icon: <FolderIcon fontSize="small" />,
    hidden:
      !fileExists(workspace) ||
      !workspace.userRights ||
      !workspace.userRights.addFolder,
    modalType: 'FOLDER/ADD',
    modalProps: {
      folder: workspace,
      metaFields,
      userRights,
    },
  };
}
