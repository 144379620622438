import React from 'react';
import styled from 'styled-components';
import { t } from '@lingui/macro';
import makeStyles from '@mui/styles/makeStyles';
import MaterialModal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import IconButton from '../inputs/IconButton';
import { CloseIcon } from '../misc/icons';
import PageBar from './PageBar';
import PageContent from './PageContent';
import CommonButtonBar from './ButtonBar';

import { Typography } from '~common/misc/Typography';
import { useActions } from '~common/utils/hooks.utils';
import { app } from '~common/app.model';
import Button from '~inputs/Button';

type BaseModalProps<T extends keyof JSX.IntrinsicElements = 'div'> =
  React.ComponentProps<typeof ModalWrapper> & {
    title: React.ReactNode;
    children: React.ReactNode;
    wrapperComponent?: T;
    /** Passing null forces confirm button to not render */
    confirmButtonProps: React.ComponentProps<typeof Button> | null;
    /** Passing null forces cancel button to not render */
    cancelButtonProps?: React.ComponentProps<typeof Button> | null;
    /** Adds additional buttons on the button bar, either to their left,
     * between them or to their right */
    extraButtons?: {
      left?: React.ReactNode;
      center?: React.ReactNode;
      right?: React.ReactNode;
    };
    /** Called when pressing the header bar "X"-button */
    onClose?: () => void;
  };

/**
 * Generic modal base to be extended for further use.
 *
 * You can edit the whole modal wrapper component with basic props, edit the
 * child container styles by wrappint the component with `styled()`, and edit
 * confirmation button with `confirmButtonProps`.
 *
 * In order to hide either of the two bottom buttons, pass `null` as their props.
 *
 * Prop typings for this component may break if styling via `styled()`, but this
 * can be mitigated with the following
 *
 * @example
 * const StyledModal = styled(BaseModal)`
 *   background-color: red;
 * ` as typeof BaseModal;
 */
export function BaseModal<T extends keyof JSX.IntrinsicElements>({
  title,
  children,
  className,
  confirmButtonProps,
  cancelButtonProps = {
    children: t`Cancel`,
  },
  extraButtons = { left: null, center: null, right: null },
  wrapperComponent,
  onClose = undefined,
  ...rest
}: BaseModalProps<T>) {
  const closeModal = useActions(app.actions.closeModal);
  const onCloseModal = onClose ?? closeModal;

  const titleComponent =
    typeof title === 'string' ? (
      <Typography variant="h3">{title}</Typography>
    ) : (
      title
    );

  return (
    <ModalWrapper
      as={
        wrapperComponent as undefined /* styled-components & typescript is a nasty combo */
      }
      className="modal-wrapper"
      {...rest}
    >
      <ModalBar>
        {titleComponent}
        <ModalCloseButton closeModal={onCloseModal} />
      </ModalBar>

      <BaseModalContent className={className}>{children}</BaseModalContent>

      <ModalButtonBar>
        {extraButtons.left}

        {cancelButtonProps !== null && (
          <Button
            variant="outlined"
            onClick={() => closeModal(true)}
            {...cancelButtonProps}
          />
        )}

        {extraButtons.center}

        {confirmButtonProps !== null && (
          <Button variant="contained" color="primary" {...confirmButtonProps} />
        )}

        {extraButtons.right}
      </ModalButtonBar>
    </ModalWrapper>
  );
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '13px',
    padding: '2vw',
  },
  paper: {
    // width: '100%',
    // margin: '128px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
}));

interface Props {
  open: boolean;
  closeModal: (goBack?: boolean) => void;
  modalProps?: object;
  children: any;
}

const Modal = ({ open, closeModal, modalProps, children, ...rest }: Props) => {
  const classes = useStyles();

  return (
    <MaterialModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => closeModal(true)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      {...rest}
    >
      <Fade in={open}>
        <StyledContent className={classes.paper} {...modalProps}>
          {children}
        </StyledContent>
      </Fade>
    </MaterialModal>
  );
};

interface CloseButtonProps {
  closeModal: (goBack?: boolean) => void;
}

/**
 * This component shouldn't be used by itself.
 * Please use `<BaseModal>` instead.
 *
 * @deprecated
 */
export const ModalCloseButton = ({ closeModal }: CloseButtonProps) => (
  <StyledCloseButton
    key="close"
    aria-label="Close"
    color="inherit"
    onClick={() => closeModal(true)}
  >
    <CloseIcon />
  </StyledCloseButton>
);

const StyledContent = styled.div`
  && {
    min-width: 560px;
    max-width: 80vw;
    border-radius: 13px;
    overflow: hidden;
    outline: none;
  }

  @media screen and (max-width: 800px) {
    && {
      min-width: 90vw;
      max-width: 90vw;
    }
  }

  @media screen and (max-width: 400px) {
    && {
      min-width: unset;
      max-width: unset;
      width: 100%;
      max-height: 100%;
      display: flex;
    }
  }

  @media screen and (max-height: 400px) {
    && {
      max-height: none;
    }
  }
`;

interface WrapperProps {
  minWidth?: string;
  maxWidth?: string;
}

/**
 * This component shouldn't be used by itself.
 * Please use `<BaseModal>` instead.
 *
 * @deprecated
 */
export const ModalWrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  min-width: ${({ minWidth }: WrapperProps) => minWidth || '480px'};
  max-width: ${({ maxWidth }: WrapperProps) => maxWidth || '1000px'};
  height: 100%;
  position: relative;

  @media screen and (max-width: 500px) {
    min-width: min(100vw, 300px);
  }
  @media screen and (max-width: 400px) {
    width: 100%;
  }
`;

/**
 * This component shouldn't be used by itself.
 * Please use `<BaseModal>` instead.
 *
 * @deprecated
 */
export const ModalBar = styled(PageBar)`
  display: flex;
  flex-direction: row;
  background-color: white;
  justify-content: space-between;
  min-height: 64px;
  white-space: break-spaces;
  padding-right: ${p => p.theme.spacing(6)};
`;

const StyledCloseButton = styled(IconButton)`
  && {
    position: absolute;
    top: 5px;
    right: 5px;
    margin: 0;
    background: transparent;
  }
`;

/*
export const ModalTabBar = styled.div`
  display: block;
  flex-direction: row;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-top: 0rem !important;
  width: 100%;
  color: black;
  border-bottom: none;
  padding: 0;
`;
*/

export const ModalTabs = styled(Tabs)<{ padded?: boolean }>`
  && {
    background-color: #f5f5f5 !important;
    ${props => props.padded && `padding: 0 ${props.theme.spacing(3)};`}
  }
`;

export const ModalTab = styled(Tab)`
  && {
    min-width: 7rem !important;
    text-transform: uppercase;
  }
`;

/**
 * This component shouldn't be used by itself.
 * Please use `<BaseModal>` instead.
 *
 * @deprecated
 */
export const ModalContent = styled(PageContent)`
  && {
    display: grid;
    flex-direction: column;
    grid-auto-rows: max-content;
    background-color: #f5f5f5;
    width: 100%;
    max-height: 70vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    /* TODO: hack */
    p.MuiTypography-body1 {
      margin-bottom: 8px;
    }
  }

  @media screen and (max-width: 400px) {
    && {
      max-height: unset;
    }
  }
`;

const BaseModalContent = styled(ModalContent)`
  gap: ${p => p.theme.spacing(2)};
  // For Safari 10 and 11
  grid-gap: ${p => p.theme.spacing(2)};
`;

/**
 * This component shouldn't be used by itself.
 * Please use `<BaseModal>` instead.
 *
 * @deprecated
 */
export const ModalButtonBar = styled(CommonButtonBar)`
  display: flex;
  margin: 0;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-top: 1px solid ${({ theme }: any) => `${theme.palette.border.section}`};
`;

export default Modal;
