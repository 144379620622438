import { useEffect, useState } from 'react';
import * as FullStory from '@fullstory/browser';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const ORG_ID = '10VMFE';

/**
 * Regex for matching regexes (:D)
 * and their flags
 */
const REGEX_REGEX = /\/(.*)\/([a-z]*)/;

function parseRegex(encoded: string) {
  const decoded = decodeURIComponent(encoded);
  const match = decoded.match(REGEX_REGEX);
  if (!match) {
    return;
  }

  // match array is of the form: [match, pattern, flags]
  return new RegExp(match[1], match[2]);
}

function useFullStory(anonymous = true) {
  const {
    location: { pathname },
  } = useHistory();

  const [enabled, setEnabled] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const user = useSelector(state => state.app.user);
  const config = useSelector(state => state.app.customer?.configById) ?? {};
  // This is an URL encoded array of regexes separated by commas
  // E.g. %2Ffolders%5C%2F(.*%3F)%5C%2Faddmultiple%2Fgi
  const fullstoryPaths = (config['fullstory.paths'] as string) ?? '';

  useEffect(() => {
    if (enabled) {
      FullStory.init({
        orgId: ORG_ID,
      });
      setInitialized(true);
    } else if (initialized) {
      FullStory.shutdown();
      setInitialized(false);
    }
  }, [enabled]);

  useEffect(() => {
    const regexes = fullstoryPaths
      .split(',')
      .map(path => parseRegex(path))
      .filter(Boolean) as RegExp[];

    const isEnabled = regexes.some(r => r.test(pathname));
    setEnabled(isEnabled);
  }, [pathname]);

  useEffect(() => {
    if (!initialized || !anonymous) {
      return;
    }
    FullStory.anonymize();
  }, [initialized, anonymous]);

  useEffect(() => {
    if (!user || !initialized || anonymous) {
      return;
    }

    FullStory.identify(user.id, {
      displayName: user.fullName,
    });
  }, [user, initialized, anonymous]);
}

export default useFullStory;
