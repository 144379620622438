import { merge } from 'lodash';
import {
  adaptV4Theme,
  Palette,
  darken,
  alpha,
  lighten,
  createTheme as createMuiTheme,
} from '@mui/material/styles';
import { amber, green, red } from '@mui/material/colors';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    commenting: Palette['primary'];
    border: Palette['primary'];
  }
  interface PaletteOptions {
    commenting: PaletteOptions['primary'];
    border: { section: string; paper: string; card: string };
  }
}

// MUI theme defaults
// https://material-ui.com/customization/default-theme/
export const defaultThemeExample = createMuiTheme();

type CustomThemePalette = ReturnType<typeof getCustomTheme>['palette'];
type CustomPalette = Palette & {
  commenting: any;
  border: any;
} & CustomThemePalette;

type Dimensions = {
  border: {
    width: string;
    radius: string;
  };
  appBar: {
    height: number;
    border: number;
  };
  extraAppBar: {
    height: number;
  };
  totalAppBarsHeight: number;
};

type Transitions = {
  duration: string;
};

type defaultThemeType = typeof defaultThemeExample;
export type Theme = defaultThemeType & { palette: CustomPalette } & {
  dimensions: Dimensions;
} & {
  custom: {
    transitions: Transitions;
  };
};
export const defaultTheme = {
  ...defaultThemeExample,
  dimensions: {
    appBar: {
      height: 56,
    },
    extraAppBar: {
      height: 0,
    },
    totalAppBarsHeight: 56,
  },
  custom: {
    transitions: {
      duration: '200ms',
    },
  },
} as Theme;

const getPalette = options => {
  return merge(options.palette, {
    primary: {
      main: options.primaryColor,
    },
    secondary: {
      main: options.secondaryColor,
    },
    commenting: {
      main: 'rgba(240, 240, 0, 1)',
      selected: options.secondaryColor,
    },
  });
};

const getCustomTheme = options => {
  const palette = getPalette(options);
  // Custom theme variables
  // https://material-ui.com/customization/theming/#custom-variables
  return {
    custom: {
      transitions: {
        duration: '150ms',
      },
    },
    dimensions: {
      appBar: {
        height: 56,
        border: options.appBar?.border ? 3 : 0,
      },
      extraAppBar: {
        height: options.extraAppBarHeight || 0,
      },
      totalAppBarsHeight:
        56 +
        (options.extraAppBarHeight || 0) +
        (options.appBar?.border ? 3 : 0),
      border: {
        width: '0.0625rem',
        radius: '0.25rem',
      },
    },
    palette: {
      primary: {
        lightest: lighten(palette.primary.main, 0.5),
        lighter: palette.primary.light || lighten(palette.primary.main, 0.1),
        light: palette.primary.light || lighten(palette.primary.main, 0.2),
        dark: palette.primary.dark || darken(palette.primary.main, 0.3),
        darker: darken(palette.primary.main, 0.05),
        lightFade: alpha(palette.primary.main, 0.09),
        darkFade: alpha(palette.primary.main, 0.16),
      },
      secondary: {
        lighter:
          palette.secondary.light || lighten(palette.secondary.main, 0.1),
        light: palette.secondary.light || lighten(palette.secondary.main, 0.2),
        dark: palette.secondary.dark || darken(palette.secondary.main, 0.3),
        darker: darken(palette.secondary.main, 0.05),
        lightFade: alpha(palette.secondary.main, 0.09),
        darkFade: alpha(palette.secondary.main, 0.16),
      },
      custom: {
        fadedTransparent: 'rgba(0, 0, 0, 0.06)',
        darkTransparent: 'rgba(0, 0, 0, 0.12)',
        darkerTransparent: 'rgba(0, 0, 0, 0.26)',
        neutralAccent: '#81dcff',
      },
    },
  };
};

const getMuiTheme = (customTheme, options) => {
  const optionsPalette = getPalette(options);

  // Default grey colors
  const grey = {
    ...defaultTheme.palette.grey,
    // Add some greys
    80: '#f7f7f7', // TODO: or just just 100 (f5f5f5)
    250: '#ebebeb',
    300: '#D2D2D2',
    450: '#C3C3C3',
    500: 'rgba(69, 69, 69, 0.87)',
  };

  // Default palette
  const palette = {
    common: defaultTheme.palette.common,
    primary: {
      main: optionsPalette.primary.main,
      light: undefined,
      dark: undefined,
    },
    secondary: {
      main: optionsPalette.secondary.main,
      light: undefined,
      dark: undefined,
      contrastText: defaultTheme.palette.common.white,
    },
    // CUSTOM: success
    success: {
      main: optionsPalette.primary.main,
    },
    // CUSTOM: warning
    warning: {
      main: amber[400],
    },
    // CUSTOM: use darker red for more contrast (a11y)
    error: {
      ...defaultTheme.palette.error,
      main: defaultTheme.palette.error.dark,
    },
    grey,
    text: defaultTheme.palette.text,
    divider: defaultTheme.palette.divider,
    background: {
      default: grey[80], // TODO: or just use 100?
      paper: defaultTheme.palette.common.white,
      card: defaultTheme.palette.common.white, // CUSTOM
      dock: defaultTheme.palette.common.white, // CUSTOM
      dark: grey[300], // CUSTOM
      thumbnail: defaultTheme.palette.common.white, // grey[200], // CUSTOM
      preview: grey[300], // grey[400], // CUSTOM
    },
    // CUSTOM: border
    border: {
      section: grey[250],
      paper: grey[250],
      card: '#C5C5C5',
    },
    action: defaultTheme.palette.action,
    commenting: {
      main: optionsPalette.commenting.main,
      selected: optionsPalette.commenting.selected,
      status: {
        success: green[400],
        waiting: amber[400],
        warning: red[400],
        empty: '#D6E3E8',
      },
    },
  };

  // Override MUI theme defaults
  const muiTheme = {
    // Palette
    // https://material-ui.com/customization/palette/
    palette,

    // Typography
    // https://material-ui.com/customization/typography/
    typography: {
      // fontFamily: 'acumin-pro, "Roboto", "Helvetica", "Arial", sans-serif',
      // Defaults:
      // htmlFontSize: 16,
      // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      // fontSize: 14,
      // fontWeightLight: 300,
      // fontWeightRegular: 400,
      // fontWeightMedium: 500,
      // fontWeightBold: 700,

      // h1: title in the main view (folder name, product name, etc)
      h1: {
        fontSize: '1rem',
        lineHeight: 1.66,
        letterSpacing: '0.00938em',
        fontWeight: 500,
      },

      // h2: subtitle in the main view (selected workspace/file name, pages, etc.)
      h2: {
        fontSize: '0.9rem',
        lineHeight: 1.66,
        letterSpacing: '0.00938em',
        fontWeight: 500,
      },

      // h3: modal main title
      h3: {
        fontSize: '1rem',
        lineHeight: 1.66,
        letterSpacing: '0.00938em',
        fontWeight: 400,
      },

      // h4 - h6: currently not used
      h4: {},
      h5: {},
      h6: {},

      // caption: file and folder name beneath the thumbnail
      caption: {
        fontSize: '0.8rem',
        fontWeight: 500,
        // Defaults:
        // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        // fontWeight: 400,
        // fontSize: '0.75rem',
        // lineHeight: 1.66,
        // letterSpacing: '0.03333em',
      },

      // subtitle1: field names in forms, etc.
      subtitle1: {
        fontSize: '0.8rem',
        fontWeight: 500,
        // Defaults:
        // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        // fontWeight: 400,
        // fontSize: '1rem',
        // lineHeight: 1.75,
        // letterSpacing: '0.00938em',
      },

      // subtitle2: text within fields in forms, etc.
      subtitle2: {
        fontSize: '0.8rem',
        fontWeight: 400,
        letterSpacing: '0.00938em',
        // Defaults:
        // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        // fontWeight: 500,
        // fontSize: '0.875rem',
        // lineHeight: 1.57,
        // letterSpacing: '0.00714em',
      },

      // body1: misc text (folder tree, autocompletion, etc)
      body1: {
        fontSize: '0.9rem',
        fontWeight: 400,
        letterSpacing: '0.00938em',
        // Defaults:
        // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        // fontWeight: 400,
        // fontSize: '1rem',
        // lineHeight: 1.5,
        // letterSpacing: '0.00938em',
      },

      // body2: misc text (folder tree, autocompletion, etc)
      body2: {
        fontSize: '0.8rem',
        fontWeight: 400,
        // Defaults:
        // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        // fontWeight: 400,
        // fontSize: '0.875rem',
        // lineHeight: 1.43,
        // letterSpacing: '0.01071em',
      },

      button: {
        // Defaults:
        // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        // fontWeight: 500,
        // fontSize: '0.875rem',
        // lineHeight: 1.75,
        // letterSpacing: '0.02857em',
        // textTransform: 'uppercase' as 'uppercase',
      },

      overline: {
        // Defaults:
        // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        // fontWeight: 400,
        // fontSize: '0.75rem',
        // lineHeight: 2.66,
        // letterSpacing: '0.08333em',
        // textTransform: 'uppercase' as 'uppercase',
      },
    },

    // Component default prop values
    // https://material-ui.com/customization/globals/#default-props
    props: {
      MuiAppBar: {
        position: 'static' as const,
        elevation: 0,
      },
      MuiCheckbox: {
        color: 'secondary',
      },
      MuiTooltip: {
        // prevent click from bubbling to containing element
        PopperProps: {
          onClick: e => e.stopPropagation(),
        },
      },
    },

    // Component CSS overrides
    // https://material-ui.com/customization/globals/#css
    overrides: {
      MuiAppBar: {
        root: {
          border: 'none' as const,
        },
      },
      MuiToolbar: {
        regular: {
          '@media (min-width: 600px)': {
            minHeight: '56px',
          },
        },
        gutters: {
          '@media (min-width: 600px)': {
            paddingLeft: '16px',
            paddingRight: '16px',
          },
        },
      },
      MuiButtonBase: {
        root: {
          whiteSpace: 'nowrap' as const,
        },
      },
      MuiButton: {
        outlined: {
          backgroundColor: palette.common.white,
        },
        textPrimary: {
          color: customTheme.palette.primary.dark,
          '&:hover': {
            backgroundColor: customTheme.palette.primary.lightFade,
          },
        },
        textSecondary: {
          color: customTheme.palette.secondary.dark,
        },
        outlinedPrimary: {
          borderColor: customTheme.palette.primary.darker,
          color: customTheme.palette.primary.dark,
          '&:hover': {
            backgroundColor: customTheme.palette.primary.lightFade,
          },
        },
        outlinedSecondary: {
          borderColor: customTheme.palette.primary.darker,
          color: customTheme.palette.secondary.dark,
        },
        outlinedSizeSmall: {
          padding: '2px 9px',
        },
      },
      MuiListItemIcon: {
        root: {
          minWidth: '32px',
        },
      },
      MuiMenuItem: {
        root: {
          whiteSpace: 'normal',
        },
      },
      MuiInputLabel: {
        root: {
          // NOTE: same style as subtitle1
          // TODO: Use input label everywhere as input label!
          color: palette.common.black,
          fontSize: '0.8rem',
          fontWeight: 500,
          lineHeight: 1.75,
          letterSpacing: '0.00938em',
        },
      },
      MuiFormLabel: {
        root: {
          color: palette.common.black,
        },
      },
      MuiInputBase: {
        root: {},
        // CUSTOM VARIANT: form
      },
      MuiPaper: {
        root: {
          // Add border because light shadows have been disabled
          border: `1px solid ${palette.border.paper}`,
        },
      },
      MuiTabs: {
        indicator: {
          height: '3px',
        },
      },
      MuiTab: {
        root: {
          textTransform: 'none' as const,
          '&$selected': {
            color: palette.primary.main,
          },
        },
      },
      /*
      // TODO: remove? (was added for search quick fix)
      MuiFormLabel: {
        root: {
          fontSize: '0.8rem',
          fontWeight: 500,
          paddingBottom: '4px',
        },
      },
      */
      MuiRadio: {
        root: {
          padding: '2px 9px',
        },
      },
      MuiTreeItem: {
        root: {
          '&.Mui-selected-item': {
            '& > .MuiTreeItem-content': {
              backgroundColor: customTheme.palette.primary.lightFade,
            },
            '& > .MuiTreeItem-content > .MuiTreeItem-iconContainer': {
              color: palette.primary.main,
              fontWeight: 500,
            },
            '& > .MuiTreeItem-content > .MuiTreeItem-label': {
              color: palette.primary.main,
              fontWeight: 500,
            },
          },
          '&.Mui-selected-item-parent': {
            '& > .MuiTreeItem-content > .MuiTreeItem-iconContainer': {
              color: palette.primary.main,
              fontWeight: 500,
            },
            '& > .MuiTreeItem-content > .MuiTreeItem-label': {
              color: palette.primary.main,
              fontWeight: 500,
            },
          },
          '&:focus > $content': {
            backgroundColor: `${customTheme.palette.primary.darkFade} !important`,
          },
          '&:focus > $content > $label': {
            backgroundColor: 'transparent',
          },
          '&.drag-hover': {
            backgroundColor: customTheme.palette.primary.darkFade,
          },
        },
        content: {
          '&:hover': {
            backgroundColor: customTheme.palette.primary.lightFade,
          },
        },
        label: {
          '& > div': {
            padding: '4px 0',
          },
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        iconContainer: {
          marginRight: '8px', // TODO: 8px ?
        },
        group: {
          marginLeft: '12px', // TODO: 8px ?
        },
      },
      MuiTooltip: {
        tooltip: {
          fontSize: '12px',
          // backgroundColor: customTheme.palette.primary.dark,
        },
      },
      MuiAutocomplete: {
        input: {
          '&.MuiAutocomplete-input': {
            padding: defaultTheme.spacing(1.5, 0.5),
          },
        },
        inputRoot: {
          padding: defaultTheme.spacing(0, 0.5),
        },
      },
    },
  };

  return muiTheme;
};

// Override MUI theme default shadows
const getShadows = (muiTheme, reduction) => {
  const shadows = muiTheme.shadows;
  return {
    // Remove slight shadows, and make all other shadows a little more flat.
    shadows: shadows.map((value, index) => {
      return index <= reduction - 1 ? 'none' : shadows[index - reduction];
    }),
  };
};

export const createTheme = options => {
  const customTheme = getCustomTheme(options);
  const muiTheme = getMuiTheme(customTheme, options);
  const shadows = getShadows(defaultTheme, options.shadowReduction || 2);
  const theme = createMuiTheme(
    adaptV4Theme(merge(muiTheme, shadows, customTheme) as any) as any
  );
  // TODO: use responsive font sizes or not?
  return theme as Theme; // responsiveFontSizes(theme);
};

export default createTheme;
