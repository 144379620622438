import React from 'react';
import styled from 'styled-components';

import { LinearProgress } from '@mui/material';
import Typography from './Typography';

interface Props {
  progress: number;
  index?: number;
  totalCount?: number;
}

const ProgressStatus = ({ progress, index, totalCount }: Props) => {
  return (
    <ProgressContainer>
      {totalCount !== undefined && index !== undefined ? (
        <ProgressLabel>
          {index} / {totalCount}
        </ProgressLabel>
      ) : null}
      <ProgressBar variant="determinate" value={progress * 100} />
      <ProgressLabel>{(progress * 100).toFixed()}%</ProgressLabel>
    </ProgressContainer>
  );
};

const ProgressContainer = styled.div`
  padding ${p => p.theme.spacing(1)} ${p => p.theme.spacing(2)};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressBar = styled(LinearProgress)`
  width: 100%;
`;

const ProgressLabel = styled(Typography)`
  margin: ${p => p.theme.spacing(1)} 0 !important;
  font-size: 1rem;
`;

export default ProgressStatus;
