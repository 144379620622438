import { snakeCase, update } from 'lodash';
import { AnyAction } from 'redux';
import {
  handleActions as createReducer,
  ReducerMap,
  ReduxCompatibleReducer,
} from 'redux-actions';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { skipToken, SkipToken } from '@reduxjs/toolkit/query/react';
import {
  createSelector,
  createSlice as rtkCreateSlice,
  CreateSliceOptions as RtkCreateSliceOptions,
  EntityState,
  Reducer,
  SliceCaseReducers,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import {
  ActionCreatorWithoutPayload,
  PayloadActionCreator,
  _ActionCreatorWithPreparedPayload,
} from '@reduxjs/toolkit/dist/createAction';
import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { QueryActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { ApiEndpointQuery } from '@reduxjs/toolkit/dist/query/core/module';

import { filterUndefined } from '~common/utils/fn.utils';
import { DeepPartial } from '~common/utils/types.utils';

/**
 * Create redux action enum from prefix and an array of
 * actions
 *
 * @example
 *
 * SearchActions = createActionTypes('SEARCH', ['UPDATE_VALUE', 'FETCH_SUGGESTIONS']);
 *
 * const updateValue = createAction(SearchActions.UPDATE_VALUE);
 *
 * console.log(updateValue('hello world'))
 * // -> { type: 'SEARCH/UPDATE_VALUE', payload: 'hello world' }
 */
// TODO: type with TS 4.1 template literal types
export function createActionTypes<A extends string>(
  prefix: string,
  actions: A[]
) {
  return actions.reduce(
    (obj, cur) => ({
      ...obj,
      [cur]: `${prefix}/${cur}`,
    }),
    {}
  ) as { [key in A]: key };
}

/**
 * Helper function for defining action creators
 *
 * @example
 * const readFile = createAction('READ_FILE', (id: string, includes?: string) => ({ id, includes }));
 * console.log(readFile('123', 'publicity'))
 * // {
 * //   type: 'READ_FILE',
 * //   payload: { id: '123', includes: 'publicity' }
 * // }
 */
export function createAction<
  Params extends Array<unknown>,
  Out,
  Type extends string
>(
  type: Type,
  creator: (...params: Params) => Out
): ActionCreator<Type, Params, Out>;
export function createAction<
  Params extends Array<unknown>,
  Out,
  Type extends string
>(type: Type): ActionCreator<Type, Params, Out>;
export function createAction<
  Params extends Array<unknown>,
  Out,
  Type extends string
>(type: Type, creator?: (...params: Params) => Out) {
  const func = (...params: Params) => {
    return {
      type,
      payload: creator ? creator(...params) : undefined,
    };
  };
  func.type = type;
  return func;
}

export interface Action<Type extends string, Payload> {
  type: Type;
  payload: Payload;
}

type ActionCreator<
  Type extends string,
  Params extends Array<unknown>,
  Payload
> = ((...params: Params) => {
  type: Type;
  payload: Payload;
}) & {
  type: Type;
};

interface ActionHandler<State> extends ReduxCompatibleReducer<State, any> {
  handle<Type extends string, Payload>(
    creator: ActionCreator<Type, unknown[], Payload> | Type,
    handler: (state: State, action: Action<Type, Payload>) => State
  ): ActionHandler<State>;
}

/**
 * Helper function for defining reducers. Mostly just a wrapper for
 * `redux-action`'s `handleActions`.
 *
 * @returns A `ReduxCompatibleReducer`, i.e. a function in the form
 * `(state, action) => state`
 *
 * @example
 * const add = createAction('ADD', (num: number) => ({ num }));
 * const subtract = createAction('SUBTRACT', (num: number) => ({ num }));
 *
 * // A redux compatible reducer is returned
 * const reducer = handleActions({ counter: 0 })
 *  // types of state and action are inferred automatically
 *  .handle(add, (state, action) => ({
 *    ...state,
 *    counter: state.counter + action.payload.num,
 *  }))
 *  .handle(subtract, (state, action) => ({
 *    ...state,
 *    counter: state.counter - action.payload.num,
 *  }))
 *
 * const nextState = reducer({ counter: 0 }, add(5));
 * // -> { counter: 5 }
 *
 * @param state Initial state
 */
export function handleActions<State>(
  state: State,
  reducerMap: ReducerMap<State, any> = {}
): ActionHandler<State> {
  const reducer = createReducer(reducerMap, state) as ActionHandler<State>;

  // We provide a chainable method, `.handle` for `handleActions`, where
  // `.handle` takes two arguments, first for the action creator and second
  // for a "standard" reducer function
  function handle<Type extends string, Payload>(
    creator: ActionCreator<Type, unknown[], Payload> | Type,
    handler: (state: State, action: Action<Type, Payload>) => State
  ): ActionHandler<State> {
    return handleActions(state, {
      ...reducerMap,
      [typeof creator === 'string' ? creator : creator.type]: handler,
    });
  }

  // attach a .handle function to the reducer function
  reducer.handle = handle;

  return reducer;
}

type CamelToSnake<T extends string> = string extends T
  ? string
  : T extends `${infer C0}${infer C1}${infer C2}${infer R}`
  ? `${C0 extends Uppercase<C0>
      ? '_'
      : ''}${Lowercase<C0>}${C1 extends Uppercase<C1>
      ? '_'
      : ''}${Lowercase<C1>}${C2 extends Uppercase<C2>
      ? '_'
      : ''}${Lowercase<C2>}${CamelToSnake<R>}`
  : T extends `${infer C0}${infer C1}${infer R}`
  ? `${C0 extends Uppercase<C0>
      ? '_'
      : ''}${Lowercase<C0>}${C1 extends Uppercase<C1>
      ? '_'
      : ''}${Lowercase<C1>}${CamelToSnake<R>}`
  : T extends `${infer C0}${infer R}`
  ? `${C0 extends Uppercase<C0> ? '_' : ''}${Lowercase<C0>}${CamelToSnake<R>}`
  : '';

type ActionType<T extends string> = Uppercase<CamelToSnake<T>>;

type ActionMap<
  T extends Record<string, (...params: unknown[]) => any>,
  Prefix
> = {
  [key in keyof T]: key extends string
    ? ActionCreator<
        Prefix extends string
          ? `${Prefix}/${ActionType<key>}`
          : ActionType<key>,
        Parameters<T[key]>,
        ReturnType<T[key]>
      >
    : never;
};

/**
 * Redux-actions -like typesafe function for defining multiple actions creators at once
 *
 * @example
 *
 * const actions = createActions({
 *   readFile: (fileId: string, include?: string) => ({
 *     fileId,
 *     include,
 *   }),
 *   readFiles: (fileIds: string[]) => ({
 *     fileIds,
 *   }),
 * });
 *
 * ...
 *
 * console.log(actions.readFiles(['12', '13']));
 * // -> results in { type: 'READ_FILES , payload: { fileIds: ['12', '13'] } }
 */
export function createActions<
  Map extends Record<string, (...params: unknown[]) => any>,
  Prefix extends string | undefined
>(actionMap: Map): ActionMap<Map, Prefix>;
export function createActions<
  Map extends Record<string, (...params: unknown[]) => any>,
  Prefix extends string | undefined
>(prefix: Prefix, actionMap: Map): ActionMap<Map, Prefix>;
export function createActions<
  Map extends Record<string, (...params: unknown[]) => any>,
  Prefix extends string | undefined
>(prefix?: Prefix, actionMap?: Map): ActionMap<Map, Prefix> {
  return Object.entries(actionMap || {}).reduce((obj, [key, value]) => {
    const actionName = snakeCase(key).toLocaleUpperCase();

    return {
      ...obj,
      [key]: createAction(
        prefix ? `${prefix}/${actionName}` : actionName,
        value
      ),
    };
  }, {} as ActionMap<Map, Prefix>);
}

// NOTE: the following types are monkey patched while we wait for
// a proper release of https://github.com/reduxjs/redux-toolkit/pull/975
//
// After that we can figure out how to type the SHOUTING_SNAKE_CASE action
// types for our needs, as they're unlikely to be supported by rtk
export type ActionsOfCaseReducersActions<
  T extends CaseReducerActions<SliceCaseReducers<any>>
> = {
  [Type in keyof T]: ReturnType<T[Type]>;
}[keyof T];

export interface Slice<
  State = any,
  CaseReducers extends SliceCaseReducers<State> = SliceCaseReducers<State>,
  Name extends string = string
> {
  name: Name;
  reducer: Reducer<
    State,
    ActionsOfCaseReducersActions<CaseReducerActions<CaseReducers, Name>>
  >;
  actions: CaseReducerActions<CaseReducers, Name>;
  caseReducers: SliceDefinedCaseReducers<CaseReducers>;
}

declare type SliceDefinedCaseReducers<
  CaseReducers extends SliceCaseReducers<any>
> = {
  [Type in keyof CaseReducers]: CaseReducers[Type] extends {
    reducer: infer Reducer;
  }
    ? Reducer
    : CaseReducers[Type];
};

type CaseReducerActions<
  CaseReducers extends SliceCaseReducers<any>,
  SliceName extends string = string
> = {
  [Type in string & keyof CaseReducers]: CaseReducers[Type] extends {
    prepare: any;
  }
    ? ActionCreatorForCaseReducerWithPrepare<
        CaseReducers[Type],
        `${SliceName}/${Uppercase<CamelToSnake<Type>>}`
      >
    : ActionCreatorForCaseReducer<
        CaseReducers[Type],
        `${SliceName}/${Uppercase<CamelToSnake<Type>>}`
      >;
};

type ActionCreatorForCaseReducerWithPrepare<
  CR extends { prepare: any },
  Type extends string = string
> = _ActionCreatorWithPreparedPayload<CR['prepare'], Type>;

type ActionCreatorForCaseReducer<
  CR,
  Type extends string = string
> = CR extends (state: any, action: infer Action) => any
  ? Action extends { payload: infer P }
    ? PayloadActionCreator<P, Type>
    : ActionCreatorWithoutPayload<Type>
  : ActionCreatorWithoutPayload<Type>;

/**
 * Custom wrapper for redux toolkit `createSlice`, so that we can
 * define action creators and namespaces with 'camelCase' as they do
 * in the official docs, but under the hood we get 'SHOUTING_SNAKE_CASE'
 * action types and namespaces.
 */
export function createSlice<
  State,
  CaseReducers extends SliceCaseReducers<State>,
  Name extends string = string
>(
  options: RtkCreateSliceOptions<State, CaseReducers, Name>
): Slice<State, CaseReducers, Uppercase<CamelToSnake<Name>>> {
  const newReducers = Object.entries(options.reducers || {}).reduce(
    (obj, [key, value]) => {
      const newName = snakeCase(key).toLocaleUpperCase();

      return {
        ...obj,
        [newName]: value,
      };
    },
    {}
  );

  const newOptions = {
    ...options,
    name: snakeCase(options.name).toLocaleUpperCase(),
    reducers: newReducers,
  };

  const slice = rtkCreateSlice(newOptions) as any;

  const newActions = Object.keys(options.reducers || {}).reduce((obj, key) => {
    const newName = snakeCase(key).toLocaleUpperCase();

    return {
      ...obj,
      [key]: slice.actions[newName],
    };
  }, {});

  return {
    ...slice,
    actions: newActions,
  };
}

/**
 * Returns a list of all entities in a normalized RTK EntityAdapter
 * ref: https://redux-toolkit.js.org/api/createEntityAdapter
 */
export function getAllEntities<T>(entityState: EntityState<T> | undefined) {
  return (
    entityState ? entityState.ids.map(id => entityState.entities[id]) : []
  ) as T[];
}

/**
 * A higher order function for creating a RTK Query hook which can take multiple
 * query keys as an argument, so that each of the keys gets fetched and cached
 * independently.
 *
 * We aggregate the separate query statuses into common booleans, which can be
 * used to inspect the status of the multi-query as a whole, e.g. if the return
 * value of this function has the property `isLoading` set to true, at least one
 * of the queries is still loading. This way the API of this function resembles
 * the standard RTK `useQuery` the most. At some point we might want to make it
 * optional, if it would be sensible to show at least some data as soon as it is
 * available.
 *
 * Adapted from example by @hornta:
 * https://github.com/reduxjs/redux-toolkit/issues/1353#issuecomment-1151162646
 *
 * We should use an official implementation if/when it becomes available:
 * https://github.com/reduxjs/redux-toolkit/issues/1353
 */
export function useQueries<QueryArgs, ReturnValue>(
  endpointQuery: ApiEndpointQuery<
    QueryDefinition<QueryArgs, any, any, ReturnValue, string>,
    any
  >
) {
  return function (options: (QueryArgs | SkipToken)[]) {
    const dispatch = useDispatch<ThunkDispatch<any, any, AnyAction>>();

    useEffect(() => {
      const subscriptions: QueryActionCreatorResult<any>[] = options
        .filter((options): options is QueryArgs => options !== skipToken)
        .map(options => dispatch(endpointQuery.initiate(options)));

      return () => {
        subscriptions.forEach(s => s.unsubscribe());
      };
    }, [dispatch, options]);

    const selectors = useMemo(
      () => options.map(options => endpointQuery.select(options)),
      [options]
    );

    const multiSelector = useMemo(
      () => createSelector(selectors, (...x) => x),
      [selectors]
    );

    const result = useSelector(multiSelector);

    return aggregateQueriesValues(result);
  };
}

/** Helper function to be used with the return value of `useQueries` */
function aggregateQueriesValues<ReturnValue>(
  results: ReturnType<
    ReturnType<
      ApiEndpointQuery<
        QueryDefinition<any, any, any, ReturnValue, string>,
        any
      >['select']
    >
  >[]
) {
  // Aggregate query states
  const hasData = results.some(item => item.isSuccess);
  const isSuccess = results.every(item => item.isSuccess);
  const isFetching = results.some((item: any) => item.isFetching);
  const isLoading = results.some(item => item.isLoading);
  const isAllLoading = results.every(item => item.isLoading);
  const isError = results.some(item => item.isError);

  return useMemo(
    () => ({
      data: hasData
        ? results.map(item => item.data).filter(filterUndefined)
        : undefined,
      error: isError
        ? results.map(item => item.error).filter(filterUndefined)
        : undefined,
      isSuccess,
      isFetching,
      isLoading,
      isAllLoading,
      isError,
    }),
    [results]
  );
}

// //////////// //
// Helper types //
// //////////// //

/** Remove all optionals / null unions from nested objects */
type DeepNonNullable<T> = {
  [P in keyof T]-?: DeepNonNullable<NonNullable<T[P]>>;
};

/** Recurser for dot notation object path */
type PathImpl<T, K extends keyof T> = K extends string
  ? T[K] extends Record<string, any>
    ? T[K] extends ArrayLike<any>
      ? never
      : K | `${K}.${PathImpl<T[K], keyof T[K]>}`
    : T[K] extends Record<string, any>
    ? K
    : never
  : never;

/** Strongly typed dot notation path for a nested object */
type Path<T> = PathImpl<T, keyof T>;

/** Value given by a dot notation path of an object */
type PathValue<T, P extends Path<T>> = P extends `${infer K}.${infer Rest}`
  ? K extends keyof T
    ? Rest extends Path<T[K]>
      ? PathValue<T[K], Rest>
      : never
    : never
  : P extends keyof T
  ? T[P]
  : never;

// ///////////////// //
// Reusable reducers //
// ///////////////// //

/**
 * A higher order function for a basic reducer that sets a single field to some
 * value, for example by reseting a counter
 *
 * @example
 * const initialState = { count: 0 };
 * const resetCount = createAction(
 *   'RESET_COUNT',
 *   () => ({}),
 * );
 *
 * handleActions(initialState)
 *   .handle(resetCount, setValueTo('count', 0));
 *
 * // Is functionally equivalent to
 *
 * handleActions(initialState)
 *   .handle(resetCount, (state) => ({
 *     ...state,
 *     count: 0,
 *   }));
 */
export const setValueTo =
  <State extends Object, Key extends keyof State, Value extends State[Key]>(
    key: Key,
    value: Value
  ) =>
  (state: State) => ({
    ...state,
    [key]: value,
  });

/**
 * A basic reducer function for appending an action's payload into the state by
 * replacing any matching key and value pairs in the state with ones given as
 * a payload.
 *
 * @example
 * const initialState = { loading: false };
 * const setLoading = createAction(
 *   'SET_LOADING',
 *   (loading: boolean) => ({ loading }),
 * );
 *
 * handleActions(initialState)
 *   .handle(setLoading, overwriteWithPayload);
 *
 * // Is functionally equivalent to
 *
 * handleActions(initialState)
 *   .handle(setLoading, (state, action) => ({
 *     ...state,
 *     loading: action.payload.loading
 *   }));
 */
export const overwriteWithPayload = <State, Type extends string, Payload>(
  state: State,
  action: Action<Type, Payload>
): State => ({
  ...state,
  ...action.payload,
});

/**
 * A basic reducer function for merging an action's payload into the state by
 * extending state's objects with ones from payload, spreading any new values
 * on top of ones already in state.
 *
 * @example
 * const initialState = {
 *   filesById: {'a1': 'cat.jpg' },
 * };
 * const updateFiles = createAction(
 *   'UPDATE_FILES',
 *   (filesById: {[key: str]: str}) => ({ filesById }),
 * );
 *
 * handleActions(initialState)
 *   .handle(updateFiles, extendWithPayload);
 *
 * // Is functionally equivalent to
 *
 * handleActions(initialState)
 *   .handle(updateFiles, (state, action) => ({
 *     ...state,
 *     filesById: {
 *       ...state.filesById,
 *       ...action.payload.filesById,
 *     },
 *   }));
 */
export const extendWithPayload = <
  State extends Object,
  Type extends string,
  Payload extends DeepPartial<State>
>(
  state: State,
  action: Action<Type, Payload>
): State => ({
  ...state,
  ...Object.keys(action.payload).reduce(
    (acc, key) => ({
      ...acc,
      [key]: { ...state[key], ...action.payload[key] },
    }),
    {} as State
  ),
});

/**
 * A generic reducer for updating a deeply nested state object with key/value
 * pairs given in an actions' payload
 *
 * @example
 * const initialState = {
 *   settings: {
 *     private: {
 *       username: 'greedy',
 *       password: 'hunter2',
 *     },
 *   },
 * };
 * const updateUsername = createAction(
 *   'UPDATE_USERNAME',
 *   (username: string) => ({ username }),
 * );
 *
 * handleActions(initialState)
 *   .handle(
 *     updateUsername,
 *     deepExtendWithPayload('settings.private')
 *   );
 *
 * // Is functionally equivalent to
 *
 * handleActions(initialState)
 *   .handle(updateUsername, (state, action) => ({
 *     ...state,
 *     settings: {
 *       ...state.settings,
 *       private: {
 *         ...state.settings.private,
 *         ...action.payload,
 *       },
 *     },
 *   }));
 */
export const deepExtendWithPayload =
  // For some reason TSC complains about generics recursion depth even though
  // the method works properly, so we'll just ignore this
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore


    <
      State extends Object,
      NonNullState extends DeepNonNullable<State>,
      P extends Path<NonNullState>,
      Payload extends PathValue<NonNullState, P>,
      Type extends string
    >(
      path: P
    ) =>
    (state: State, action: Action<Type, Payload>): State => ({
      ...update(state, path, (currentValue: Payload) => ({
        ...currentValue,
        ...action.payload,
      })),
    });
