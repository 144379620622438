import React from 'react';
import styled, { css } from 'styled-components';
import { HighlightOff } from '@mui/icons-material';

import { IconButton } from '~common/inputs/IconButton';
import { Dollarize } from '~common/utils/types.utils';

export const ChipList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: ${p => p.theme.spacing(1)};
  padding: 0;
  margin: 0;
`;

type ChipPropsBase = {
  label: React.ReactNode;
  selected?: boolean;
  small?: boolean;
  tiny?: boolean;
  disabled?: boolean;
  className?: string;
};

interface DefaultChipProps extends ChipPropsBase {
  onClick?: never;
  onDelete?: never;
}

interface ClickableChipProps extends ChipPropsBase {
  onClick: () => void;
  onDelete?: never;
}

interface DeletableChipProps extends ChipPropsBase {
  onDelete: () => void;
  onClick?: never;
  'aria-label': string;
}

type ChipProps = DefaultChipProps | ClickableChipProps | DeletableChipProps;

/**
 * A generic chip which can either
 * 1. Display a static piece of text
 * 2. Act as a toggle button or
 * 3. Act as an item that can be removed
 *
 * For the button to act as (2.), you need to provide an `onClick` callback.
 * Likewise for (3.), you need to provide an `onDelete` callback.
 */
export function Chip({
  label,
  selected,
  small,
  tiny,
  className,
  disabled,
  ...rest
}: ChipProps) {
  const size = tiny ? 'tiny' : small ? 'small' : 'default';
  const variant = selected && !disabled ? 'selected' : 'default';

  if ('onClick' in rest && !disabled)
    return (
      <ChipListItem
        $size={size}
        $variant={variant}
        onClick={rest.onClick}
        role="button"
        aria-pressed={selected}
        className={className}
      >
        <ChipText>{label}</ChipText>
      </ChipListItem>
    );
  else if ('onDelete' in rest && !disabled)
    return (
      <ChipListItem $size={size} $variant={variant} className={className}>
        <ChipText>{label}</ChipText>
        <DeleteIconButton
          aria-label={rest['aria-label']}
          onPress={rest.onDelete}
        >
          <HighlightOff />
        </DeleteIconButton>
      </ChipListItem>
    );
  else
    return (
      <ChipListItem $size={size} $variant={variant} className={className}>
        <ChipText>{label}</ChipText>
      </ChipListItem>
    );
}

type StyleProps = React.HTMLAttributes<HTMLLIElement> &
  Dollarize<{
    size: keyof typeof sizes;
    variant: keyof typeof variants;
  }>;

const ChipListItem = styled.li<StyleProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: var(--chip-height);

  font-size: var(--font-size, 0.8rem);
  list-style: none;
  color: var(--chip-text-color);
  background-color: var(--chip-background-color);
  border-radius: 999px;
  cursor: default;

  transition-property: color, background-color;
  transition-duration: ${p => p.theme.custom.transitions.duration};

  ${p => sizes[p.$size]};
  ${p => variants[p.$variant]};

  ${p =>
    p.onClick
      ? css`
          cursor: pointer;
          &:hover {
            background-color: var(--chip-hover-background-color);
          }
        `
      : ''}
`;

const sizes = {
  default: css`
    --chip-height: 2rem;
    --chip-text-padding: 0.75rem;
    --delete-icon-size: 1.375rem;
  `,
  small: css`
    --chip-height: 1.5rem;
    --chip-text-padding: 0.5rem;
    --delete-icon-size: 1rem;
  `,
  tiny: css`
    --chip-height: 1.2rem;
    --chip-text-padding: 0.4rem;
    --delete-icon-size: 1rem;
    --font-size: 0.7rem;
  `,
};

const variants = {
  default: css`
    --chip-text-color: ${p => p.theme.palette.common.black};
    --chip-background-color: ${p => p.theme.palette.grey['300']};
    --chip-hover-background-color: ${p => p.theme.palette.grey['450']};
    --delete-button-padding: 0.375rem;
  `,
  selected: css`
    --chip-text-color: ${p => p.theme.palette.common.white};
    --chip-background-color: ${p => p.theme.palette.primary.main};
    --chip-hover-background-color: ${p => p.theme.palette.primary.lighter};
    --delete-button-padding: 0.25rem;
  `,
};

const ChipText = styled.span`
  padding: 0.25rem var(--chip-text-padding);
`;

const DeleteIconButton = styled(IconButton)`
  color: var(--chip-text-color);
  padding: var(--delete-button-padding);
  margin-left: calc(-1 * var(--chip-text-padding) + 0.1rem);

  && > svg {
    width: var(--delete-icon-size);
    height: var(--delete-icon-size);
  }
` as typeof IconButton;
