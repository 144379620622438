import React, { useEffect, useRef } from 'react';
import { useCheckbox, useFocusRing, useId, VisuallyHidden } from 'react-aria';
import { useToggleState } from 'react-stately';
import styled from 'styled-components';
import MaterialCheckbox from '@mui/material/Checkbox';

import { focusRing } from '~common/utils/styled.utils';

type AriaParams = Parameters<typeof useCheckbox>[0];
type ToggleParams = NonNullable<Parameters<typeof useToggleState>[0]>;

interface Props extends ToggleParams, AriaParams {
  children: React.ReactNode;
  accessibleLabel?: string;
  hideLabel?: boolean;
  className?: string;
}

/**
 * Generic checkbox component
 *
 * TODO: Replace deprecated checkbox with this one to provide better accessibility
 *
 * One-off theming of colors is most easily done by wrapping this component
 * in a `styled-component` and overriding some of the following CSS custom
 * properties:
 *
 * ```
 * --checkbox-size
 * --checkbox-background-color-checked
 * --checkbox-background-color-unchecked
 * --checkbox-background-color-disabled
 * --checkbox-stroke-color
 * --checkbox-check-stroke-width
 * --checkbox-check-stroke-color
 * ```
 */
export function Checkbox(props: Props) {
  const state = useToggleState(props);
  const ref = useRef<HTMLInputElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const { inputProps } = useCheckbox(props, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();

  /**
   * Firefox doesn't propagate click events from label to nested input if
   * `ctrl` or `shift` keys are pressed (cmon mozzarella), so we need to
   * handle such cases manually
   *
   * https://bugzilla.mozilla.org/show_bug.cgi?id=559506
   */
  useEffect(() => {
    const input = ref.current;
    const label = labelRef.current;
    if (input && label && navigator.userAgent.includes('Firefox')) {
      const onLabelClick = (e: PointerEvent) => {
        if (e.ctrlKey || e.shiftKey) {
          props.onChange?.(!input.checked);
        }
      };

      label.addEventListener('click', onLabelClick);
      return () => label.removeEventListener('click', onLabelClick);
    }
  }, [ref.current, labelRef.current, props.onChange]);

  const inputId = useId();

  return (
    <CheckboxContainer
      className={props.className}
      disabled={!!inputProps.disabled}
      focused={isFocusVisible}
      ref={labelRef}
    >
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} id={inputId} />
        <label htmlFor={inputId}>{props.accessibleLabel}</label>
      </VisuallyHidden>

      <CheckboxSvg>
        <CheckboxWrapper
          checked={state.isSelected}
          disabled={!!inputProps.disabled}
        />

        {props.isIndeterminate && <CheckboxIndeterminate />}
        {state.isSelected && <CheckboxCheck />}
      </CheckboxSvg>

      {props.hideLabel ? (
        <VisuallyHidden>{props.children}</VisuallyHidden>
      ) : (
        props.children
      )}
    </CheckboxContainer>
  );
}

const CheckboxContainer = styled.label<{ disabled: boolean; focused: boolean }>`
  --checkbox-background-color-checked: ${p => p.theme.palette.secondary.main};
  --checkbox-background-color-unchecked: white;
  --checkbox-background-color-disabled: ${p => p.theme.palette.grey[450]};
  --checkbox-stroke-color: ${p => p.theme.palette.grey[500]};
  --checkbox-check-stroke-width: 2;
  --checkbox-check-stroke-color: white;
  --checkbox-size: 1.5em;

  display: flex;
  gap: ${p => p.theme.spacing(1)};
  align-items: center;

  cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};

  ${p => focusRing(p.focused)};

  @media print {
    display: none !important;
  }
`;

const CheckboxSvg = styled.svg.attrs({
  viewBox: '0 0 24 24',
})`
  fill: none;
  width: var(--checkbox-size);
  height: var(--checkbox-size);
`;

const CheckboxWrapper = styled.rect.attrs({
  x: 1,
  y: 1,
  width: 22,
  height: 22,
  rx: 3,
  strokeWidth: 2,
})<{ checked: boolean; disabled: boolean }>`
  fill: var(
    ${p =>
      p.disabled
        ? '--checkbox-background-color-disabled'
        : p.checked
        ? '--checkbox-background-color-checked'
        : '--checkbox-background-color-unchecked'}
  );
  stroke: var(
    ${p =>
      p.checked && !p.disabled
        ? '--checkbox-background-color-checked'
        : '--checkbox-stroke-color'}
  );
`;

const CheckboxCheck = styled.path.attrs({
  d: 'M18.6 6.9L10.2 16.8L5.6 13',
})`
  stroke: var(--checkbox-check-stroke-color);
  stroke-width: var(--checkbox-check-stroke-width);
  stroke-linecap: square;
`;

const CheckboxIndeterminate = styled.line.attrs({
  x1: 6,
  y1: 12,
  x2: 18,
  y2: 12,
})`
  stroke-width: var(--checkbox-check-stroke-width);
  stroke: var(--checkbox-stroke-color);
`;

export const CheckboxLabel = styled.span`
  font-family: ${p => p.theme.typography.fontFamily};
  margin-left: ${p => p.theme.spacing(0.5)};
  padding-top: ${p => p.theme.spacing(0.25)};
  display: flex;
  align-self: center;
`;

/**
 * This component is being deprecated in an effort of phasing out Material UI.
 * Please use the named export instead of this default one.
 *
 * @deprecated
 */
const OldCheckbox = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof MaterialCheckbox>
>((props, ref) => {
  const { 'aria-labelledby': labelledBy, ...rest } = props;
  return (
    <StyledCheckbox
      inputProps={{
        'aria-label': props.title,
        'aria-labelledby': labelledBy,
      }}
      {...rest}
      ref={ref}
    />
  );
});

OldCheckbox.displayName = 'Checkbox';

const StyledCheckbox = styled(MaterialCheckbox)`
  & svg {
    background-color: ${p => p.theme.palette.common.white};
    width: 0.8334em; // scale down to 20px * 20px
    height: 0.8334em;
    & > path {
      transform: translate(-4px, -4px) scale(1.333);
    }
  }
  && {
    min-width: 16px;
    margin-right: 4px;
    padding: 6px;
    height: 24px;
    .MuiIconButton-label {
      overflow: hidden;
    }
    @media print {
      display: none !important;
    }
  }
`;

export default OldCheckbox;
