import React from 'react';
import { t } from '@lingui/macro';

import { useIsWorkspaceSaved } from '../hooks';

import { Fileish, fileExists } from '~common/content.types';
import { ModalOption } from '~common/navigation/MenuButton';
import { LinkIcon } from '~common/misc/icons';

export function useCopyWorkspaceOption(
  workspace: Fileish | undefined
): ModalOption {
  const isSaved = useIsWorkspaceSaved(workspace);

  return {
    value: 'copy',
    disabled:
      !fileExists(workspace) ||
      !workspace.isCart ||
      !workspace.userRights ||
      !workspace.userRights.share,
    title: t`Copy workspace link to clipboard`,
    icon: <LinkIcon fontSize="small" />,
    modalType: 'WORKSPACE/COPY',
    modalProps: { isWorkspaceSaved: isSaved, workspace },
  };
}
