import React from 'react';
import styled, { css } from 'styled-components';
import { t } from '@lingui/macro';
import { VisuallyHidden } from 'react-aria';
import { AutocompleteGroupedOption } from '@mui/material';
import Typography from '~misc/Typography';
import { ArrowForwardIcon } from '~misc/icons';

interface Props<T> {
  getListboxProps: () => React.HTMLAttributes<HTMLUListElement>;
  groupedOptions: AutocompleteGroupedOption<string | T>[];
  getOptionProps: ({
    option,
    index,
  }: {
    option: string | T;
    index: number;
  }) => React.HTMLAttributes<HTMLLIElement> & { key: React.Key };
  value: string;
  isOpen: boolean;
}

function SuggestionBox<T extends { value: string }>({
  getListboxProps,
  groupedOptions,
  getOptionProps,
  value,
  isOpen,
}: Props<T>) {
  let itemIndex = 0;
  const { 'aria-labelledby': ariaLabelledBy, ...rest } = getListboxProps();
  return (
    <Visibility visible={isOpen}>
      <Box>
        <ResultList
          {...rest}
          aria-labelledby={ariaLabelledBy ?? 'seach-autocomplete-label'}
          role="listbox"
          style={{ display: isOpen ? undefined : 'none' }}
        >
          {groupedOptions.map(group => {
            if (group.options.length !== 0) {
              return (
                <Category key={group.key}>
                  {group.group && (
                    <CategoryTitle variant="body2">{group.group}</CategoryTitle>
                  )}
                  {group.options.map(option => {
                    if (typeof option === 'string')
                      return (
                        <Item
                          variant="highlight"
                          {...getOptionProps({
                            option,
                            index: itemIndex++,
                          })}
                        >
                          <SearchButton>
                            <Typography variant="body2">
                              {t`Show all results for "${option}"`}
                            </Typography>
                            <StyledArrowForwardIcon />
                          </SearchButton>
                        </Item>
                      );

                    const pos = option.value
                      .toLowerCase()
                      .search(value.toLowerCase());
                    let suggestion: JSX.Element;
                    if (pos > -1) {
                      suggestion = (
                        <Typography variant="body2">
                          {option.value.substring(0, pos)}
                          <Highlighted>
                            {option.value.substring(pos, pos + value.length)}
                          </Highlighted>
                          {option.value.substring(pos + value.length)}
                        </Typography>
                      );
                    } else {
                      suggestion = (
                        <Typography variant="body2">{option.value}</Typography>
                      );
                    }
                    const optionProps = getOptionProps({
                      option: { ...option, title: group.group },
                      index: itemIndex++,
                    });
                    return (
                      <Item {...optionProps} key={optionProps.key}>
                        {suggestion}
                      </Item>
                    );
                  })}
                </Category>
              );
            }
          })}
        </ResultList>
      </Box>
    </Visibility>
  );
}

function Visibility({
  children,
  visible,
}: {
  children: React.ReactNode;
  visible: boolean;
}) {
  return visible ? (
    <>{children}</>
  ) : (
    <VisuallyHidden>{children}</VisuallyHidden>
  );
}

const Highlighted = styled.span`
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const SuggestionBoxStyle = css`
  background-color: white;
  border-style: solid;
  border-color: #d3d3d3;
  border-width: 1px;
  border-radius: 6px;
  box-shadow: 3px 3px 10px #00000029;
  z-index: 8;
`;

const Box = styled.div`
  ${SuggestionBoxStyle}
  position: absolute;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
  max-height: 300px;
  display: flex;
  flex-direction: column;
`;

const ResultList = styled.ul`
  position: relative;
  overflow: auto;
  padding: 0;
  margin: 0;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const CategoryTitle = styled(Typography)`
  padding: 4px 4px;
  && {
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  }
`;

const Category = styled.div`
  border-bottom: 1px solid
    ${({ theme }) => (theme.palette as any).border.section};
  padding: 8px 8px 8px 8px;
`;

interface ItemProps {
  variant?: string;
  theme?: any;
}

export const Item = styled.li<ItemProps>`
  list-style: none;
  padding: 4px 4px;
  cursor: pointer;
  border-radius: 4px;

  color: ${({ theme, variant }: any) =>
    variant === 'highlight' ? theme.palette.primary.main : 'inherit'};
  background-color: ${({ theme, variant }: any) =>
    variant === 'highlight' ? theme.palette.primary.lightFade : 'inherit'};

  &.Mui-focused {
    background-color: ${({ theme, variant }: any) =>
      variant === 'highlight'
        ? theme.palette.primary.darkFade
        : theme.palette.primary.lightFade};
    outline: 1px solid ${p => p.theme.palette.primary.main};
  }
`;

const SearchButton = styled.div`
  position: relative;
  padding-right: 24px;
`;

const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
`;

export default SuggestionBox;
