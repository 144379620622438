import { t } from '@lingui/macro';

// NOTE: These have been copied from an old implementation and will be
// replaced with an lingui based implementation later.

const translations = () => ({
  clear: t`clear`,
  edit: t`edit`,
  save: t`save`,
  cancel: t`cancel`,
  close: t`close`,
  crop: t`crop`,
  add: t`add`,
  remove: t`remove`,
  share: t`share`,
  link: t`link`,
  name: t`name`,
  description: t`description`,
  download: t`download`,
  isDownloading: t`isDownloading`,
  height: t`height`,
  width: t`width`,
  public: t`public`,
  private: t`private`,
  processing: t`processing`,

  // TODO move to these feeds/search directory
  'feeds.feeds': 'Feeds',
});

// eslint-disable-next-line
export const messages = {
  en: translations,
  fi: translations,
  se: translations,
  de: translations,
};

export default messages;
