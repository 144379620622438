import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import useAutocomplete, {
  AutocompleteGroupedOption,
} from '@mui/material/useAutocomplete';
import { t } from '@lingui/macro';

import {
  encodeTags,
  getSearchSuggestionType,
  SearchSuggestionType,
  useSearchTarget,
} from 'src/content/common/utils';
import SuggestionBox from 'src/content/search/SuggestionBox';
import { useGetSearchSuggestionsQuery } from 'src/content/search/api';
import { VisuallyHidden } from 'react-aria';
import { Chip } from '@mui/material';
import { search } from '../../content/search/model';

import { TextField } from '~inputs/TextField';
import { SearchIcon } from '~misc/icons';
import { useActions } from '~common/utils/hooks.utils';
import { SearchTag } from '~common/content.types';
import { Criteria } from '~common/common.types';
import { ChipList } from '~common/items/Chips';
import { IconButton } from '~common/inputs/IconButton';

interface Props {
  selfcontrolled?: boolean;
  onSearch: (searchCriteria: Partial<Criteria>) => any;
}

const SEARCH_OPERATORS = [
  'JA',
  'TAI',
  'EI',
  'AND',
  'OR',
  'NOT',
  'UND',
  'ODER',
  'NICHT',
];
const SEARCH_OPERATOR_REGEX = new RegExp(
  `(.*)((?:${SEARCH_OPERATORS.join('|')})(\\s+))(.*?)$`,
  'g'
);

type Option = {
  value: string;
  fieldId: string;
  title: string;
};

/**
 * FIXME: This component breaks the rule: [Element with role attribute has required states and properties](https://act-rules.github.io/rules/4e8ab6)
 */
export default function SearchField({
  selfcontrolled,
  onSearch,
  ...inputFieldProps
}: Props) {
  const language = useSelector(state => state.app.settings?.language) ?? 'fi';
  const defaultLanguage =
    useSelector(state => state.app.customer?.defaultLanguage) ?? 'fi';
  const criteria = useSelector(state => state.search.criteria);
  const searchTags = useSelector(state => state.search.tags);
  const searchTarget = useSearchTarget();

  const { updateCriteria, updateTags } = useActions(search.actions);

  // Holds selfcontrolled search string
  const [selfValue, setSelfValue] = useState('');
  const [selfTags, setSelfTags] = useState<SearchTag[]>([]);

  const value = selfcontrolled ? selfValue : criteria.search ?? '';
  const setValue = selfcontrolled
    ? setSelfValue
    : (value: string) =>
        updateCriteria({ search: value, target: searchTarget });

  const tags = selfcontrolled ? selfTags : searchTags;
  const setTags = selfcontrolled ? setSelfTags : updateTags;

  const { data: suggestions = [], refetch: refetchSuggestions } =
    useGetSearchSuggestionsQuery({
      search: value,
      language,
      defaultLanguage,
      tags: encodeTags(tags),
      target: searchTarget,
    });

  // Mui Autocomplete functionality
  const {
    groupedOptions,
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getTagProps,
    setAnchorEl,
    popupOpen,
  } = useAutocomplete<Option | string, true, false, true>({
    id: 'search-autocomplete',
    value: tags.map(x => ({
      fieldId: x.id,
      value: x.value ?? '',
      title: x.title ?? '',
    })),
    inputValue: value ?? '',
    freeSolo: true,
    options: suggestions.reduce(
      (a, c) => [...a, ...c.items.map(x => ({ ...x, title: c.title }))],
      []
    ),
    getOptionLabel: option =>
      typeof option === 'string' ? option : option?.value ?? '',
    // insert an element for the "Show search" -option
    filterOptions: options => (value && suggestions ? [...options, value] : []),
    isOptionEqualToValue: (option, value) => {
      if (typeof option !== 'string' && typeof value !== 'string')
        return option.value === value.value && option.fieldId === value.fieldId;
      return option === value;
    },
    includeInputInList: true,
    filterSelectedOptions: true,
    groupBy: option =>
      option && typeof option !== 'string' ? option.title : '',
    multiple: true,
    onChange: (event, data, reason, details) => {
      setTags(
        data
          .filter(
            (x): x is Option =>
              Boolean(x) && getOptionType(x) === SearchSuggestionType.TAG
          )
          .map(x => ({ ...x, id: x.fieldId }))
      );

      if (reason === 'selectOption' && details) {
        if (typeof details.option === 'string') {
          setChange(true);
        } else if (getOptionType(details.option) !== SearchSuggestionType.TAG) {
          const newValue = value.replace(
            SEARCH_OPERATOR_REGEX,
            `$1$2${getOptionValue(details.option)}`
          );

          setValue(
            // The regex doesn't handle the case when there's no AND/OR/NOT operator
            SEARCH_OPERATORS.some(op => value.includes(op))
              ? newValue
              : getOptionValue(details.option)
          );

          setChange(true);
        } else {
          setValue('');
          setChange(true);
        }
      }
      if (reason === 'createOption' && details) {
        setChange(true);
      }
      if (reason === 'clear') {
        setValue('');
      }
    },
  });
  const { onChange, value: _value, ...restInputProps } = getInputProps() as any;

  // Activates other field change monitoring
  const [watchChange, setChange] = useState(false);

  // Sync search criteria to value if not selfcontrolled but don't overwrite value
  useEffect(() => {
    if (!selfcontrolled && !value) setValue(criteria.search ?? '');
  }, [criteria.search]);

  // Monitor watchChange value
  useEffect(() => {
    if (!watchChange) return;
    setChange(false);
    updateCriteria({
      search: value || undefined,
      target: searchTarget,
      tags: tags.length ? tags : undefined,
    });
    onSearch({
      search: value || undefined,
      tags: tags.length ? tags : undefined,
    });
  }, [watchChange]);

  const { 'aria-labelledby': ariaLabelledBy, id: listBoxId } =
    getListboxProps();

  return (
    <>
      <div {...getRootProps()} ref={setAnchorEl}>
        <VisuallyHidden>
          <label
            htmlFor={restInputProps.id ?? 'search-autocomplete'}
            id={ariaLabelledBy ?? 'seach-autocomplete-label'}
          >
            {t`Search for content`}
          </label>
        </VisuallyHidden>
        <RoundedTextField
          placeholder={t`Search content`}
          margin="dense"
          color="primary"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <IconButton
                aria-label={t`Show all results for ${value}`}
                onPress={() => {
                  setChange(true);
                }}
              >
                <SearchIcon color="primary" fontSize="small" />
              </IconButton>
            ),
            inputProps: {
              inputMode: 'search',
              ...restInputProps,
              'aria-controls': listBoxId ?? 'search-autocomplete-listbox',
            },
          }}
          fullWidth
          value={value || ''}
          onChange={e => {
            setValue(e.target.value);
            onChange(e);
          }}
          onFocus={refetchSuggestions}
          onKeyDown={e => {
            if (e.key === 'Enter' && !value) {
              // Launch search with enter even without value
              setChange(true);
            }
          }}
          {...inputFieldProps}
        />
        {selfcontrolled && (
          <StyledChipList>
            {tags.map((tag, index) => (
              <Chip
                {...getTagProps({ index })}
                key={`${tag.id}-${tag.value}`}
                label={`${tag.title}: ${tag.value}`}
                color="primary"
              />
            ))}
          </StyledChipList>
        )}
      </div>
      <SuggestionBox
        isOpen={popupOpen}
        getListboxProps={getListboxProps}
        getOptionProps={({ option, ...args }) => ({
          ...getOptionProps({ option, ...args }),
          ...(typeof option === 'string'
            ? { key: 'option' }
            : {
                key: `${option.fieldId} ${option.value}`,
                'aria-label': t`Search for ${option.title}: ${option.value}`,
              }),
        })}
        groupedOptions={
          groupedOptions as AutocompleteGroupedOption<string | Option>[]
        }
        value={value}
      />
    </>
  );
}

function getOptionType(option: string | Option) {
  return typeof option === 'string'
    ? SearchSuggestionType.LITERAL
    : getSearchSuggestionType(option.fieldId);
}

function getOptionValue(option: string | Option) {
  return typeof option === 'string' ? option : option.value;
}

const RoundedTextField = styled(TextField)`
  && > * {
    border-radius: ${p => p.theme.spacing(3)};
    background-color: rgba(64, 139, 168, 0.05);
    font-size: 0.9rem;
  }

  fieldset {
    top: 0;
  }
`;

const StyledChipList = styled(ChipList)`
  position: absolute;
  margin-top: 4px;
`;
