import ExcelJS from 'exceljs';

export const downloadExcel = async (
  workbook: ExcelJS.Workbook,
  filename: string,
  type: 'csv' | 'xlsx'
) => {
  const buffer =
    type === 'csv'
      ? await workbook.csv.writeBuffer()
      : await workbook.xlsx.writeBuffer();

  // Adding some bits to csv file fixes the scandinavian lettering
  const BOM = new Uint8Array(type === 'csv' ? [0xef, 0xbb, 0xbf] : []);
  // https://stackoverflow.com/questions/19327749/javascript-blob-filename-without-link
  const url = URL.createObjectURL(new Blob([BOM, buffer]));
  const a = document.createElement('a');
  a.href = url;
  a.download = `${filename}.${type}`;

  a.click();
  URL.revokeObjectURL(url);
};

export interface BatchedExecutionCallbacks<T> {
  /** Fired when all of the operations have returned, resolve or reject */
  onAllReady?: (results: (T | { error: any })[]) => void;
  /** Fired after each operation returns, resolve or reject */
  onReady?: (result: T | { error: any }, index: number, ready: number) => void;
  /** Fired after an operation resolves */
  onResolve?: (result: T, index: number) => void;
  /** Fired after an operation rejects */
  onReject?: ({ error: any }, index: number) => void;
}

/**
 * Executes async operations in a rolling window.
 *
 * We don't want to congest the network by firing tens of API requests
 * simultaneously, so this function allows us to "batch" any array of
 * functions that return a promise for execution in a rolling window.
 *
 * For example, instead of using `Promise.all()` and firing 100 requests
 * at once, we can use this function to ensure only an amount equal to
 * `windowSize` queries is running at once.
 *
 * @example
 * const idsToUpdate = [1, ..., 100];
 *
 * executeBatchedOperations({
 *   operations: idsToUpdate.map(
 *     id => () => expensiveApiCall(id)
 *   ),
 *   windowSize: 10
 * });
 */
export function executeBatchedOperations<T>(
  batch: {
    operations: (() => Promise<T>)[];
    /** Maximum number of operations being executed simultaneously */
    windowSize: number;
  },
  callbacks?: BatchedExecutionCallbacks<T>
) {
  // Attach indeces to operations in order to preserve the order for results
  const operations = batch.operations.map((oper, i) => [i, oper] as const);
  const results: (T | { error: any })[] = [];
  let inProgress = 0;

  const next = () => {
    // Only start execution if there's room in the window
    if (!operations.length || inProgress >= batch.windowSize) return;
    // Take the next operation out of the queue and update counters
    const [operIndex, nextOperation] = operations[0];
    operations.splice(0, 1);
    inProgress += 1;

    nextOperation()
      .then(res => {
        results[operIndex] = res;
        callbacks?.onResolve?.(res, operIndex);
      })
      .catch(e => {
        results[operIndex] = { error: e };
        callbacks?.onReject?.({ error: e }, operIndex);
      })
      .finally(() => {
        inProgress -= 1;
        callbacks?.onReady?.(results[operIndex], operIndex, results.length);
        if (!operations.length && inProgress === 0) {
          callbacks?.onAllReady?.(results);
        }
        // Previous operation finished so there's more room in the window, try to execute next operation
        next();
      });
    // Might still have room in the window :shrug:, try to execute next operation
    next();
  };

  // Take the first operation into execution
  next();
}

/** Parses a HTTP header of form "value;key1=value1;key2=value2"
 * and returns the values mapped to their keys.
 * The values without keys are mapped to indeces.
 */
export function parseResponseHeader(
  header: string
): Record<number | string, string> {
  const parts = header
    .split(';')
    .map(x => x.trim())
    .filter(x => Boolean(x));
  const defaultValues: Record<number, string> = parts.filter(
    x => !x.includes('=')
  );
  const keyedValues = parts
    .filter(x => x.includes('='))
    .map(x => x.split('=').map(y => y.trim()));

  return keyedValues.reduce(
    (acc, [key, value]) => ({ ...acc, [key]: value }),
    defaultValues
  );
}
