import React from 'react';

import Row from '~layout/Row';

interface Props {
  children: any;
}

const FormRow = ({ children }: Props) => {
  return (
    <>
      {children}
      <Row padding={1} />
    </>
  );
};

export default FormRow;
