import styled from 'styled-components';

interface Props {
  top?: string;
}

const TabContent = styled.div<Props>`
  /* TODO: remove height quick fix */
  height: ${({ top }: any) => (top ? `calc(100vh - ${top})` : '100%')};
  position: relative;
  overflow-y: auto;
`;

export default TabContent;
