import React, { CSSProperties } from 'react';
import styled, { useTheme } from 'styled-components';
import MaterialTypography from '@mui/material/Typography';
import { styleToString } from '~common/utils/fn.utils';

// This way we don't get forwardRef errors but typescript magic
// works with MaterialUI
export default MaterialTypography as unknown as (props: any) => JSX.Element;

type Variant = keyof Omit<
  ReturnType<typeof useTheme>['typography'],
  | 'pxToRem'
  | 'fontFamily'
  | 'fontSize'
  | 'fontWeightRegular'
  | 'fontWeightBold'
  | 'fontWeightLight'
  | 'fontWeightMedium'
>;

type Color = keyof Omit<ReturnType<typeof useTheme>['palette'], 'custom'>;

type Props<Element extends React.ElementType> = {
  children: React.ReactNode;
  variant?: Variant;
  color?: Color;
  renderAs?: Element;
};

/**
 * Allow polymorphism based on the `renderAs` prop, i.e. we can infer proper
 * HTML element props for the chosen rendered element
 *
 * Ref: https://www.benmvp.com/blog/polymorphic-react-components-typescript/
 */
type TypographyProps<Element extends React.ElementType> = Props<Element> &
  Omit<React.ComponentPropsWithoutRef<Element>, keyof Props<Element>>;

export function Typography<Element extends React.ElementType = 'p'>({
  variant,
  color,
  renderAs,
  ...rest
}: TypographyProps<Element>) {
  const theme = useTheme();

  const styles = variant ? theme.typography[variant] : {};
  const colorValue = color ? theme.palette[color].dark : 'inherit';

  return (
    <StyledText
      as={renderAs as undefined}
      variantStyles={styleToString(styles as CSSProperties)}
      color={colorValue}
      {...rest}
    />
  );
}

const StyledText = styled.p<{ variantStyles: string; color: string }>`
  margin: 0;
  color: ${p => p.color};
  ${p => p.variantStyles}
`;
