import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { t } from '@lingui/macro';
import styled from 'styled-components';
import DropDown from '~common/inputs/DropDown';

interface Props {
  withLabel?: boolean;
  handleChange?: (any) => void;
  selectedValue: number;
}

const FileDownloadNameModelSelector = ({
  withLabel = false,
  language,
  handleChange,
  selectedValue,
  customer,
}: Props & ConnectedProps<typeof connector>) => {
  const [value, setValue] = useState<number>(selectedValue);
  const downloadConfig = customer?.downloadConfig;
  const nameModels = language ? downloadConfig?.fileNameModels?.[language] : [];

  let fileNameOptions = [{ value: 0, name: t`Default name model` }];
  if (nameModels && nameModels.length > 0) {
    fileNameOptions = nameModels.map(obj => {
      return { value: obj.configId, name: obj.label };
    });
  }

  return (
    <DropdownWrapper withLabel={withLabel}>
      <DropDown
        id="nameModel"
        labelText={withLabel ? t`Name model used at download` : undefined}
        labelProps={{ noWrap: true }}
        options={fileNameOptions}
        value={value}
        showEmpty={false}
        onChange={e => {
          setValue(e.target.value);
          handleChange && handleChange(e.target.value);
        }}
      />
    </DropdownWrapper>
  );
};

const DropdownWrapper = styled.div<{ withLabel: boolean }>`
  float: left;
  display: inline;
  width: 100%;
  ${p => p.withLabel && `margin-top: ${p.theme.spacing(2)}; `}

  & > div {
    width: 100%;
    align-items: flex-start;
  }
`;

const connector = connect(
  state => ({
    language: state.app.settings?.language,
    customer: state.app.customer,
  }),
  {}
);
export default connector(FileDownloadNameModelSelector);
