import { useCallback } from 'react';
import { useHistory } from 'react-router';

/**
 * Returns a function that can be used to navigate the user to another page
 */
export function useRedirectTo() {
  const { push, replace } = useHistory();

  return useCallback(
    (url: string, redirectMode: 'push' | 'replace' = 'push') => {
      if (redirectMode === 'push') {
        push(url);
      } else {
        replace(url);
      }
    },
    [push, replace]
  );
}
