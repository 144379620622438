import React from 'react';
import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import {
  AccordionListItem,
  NavigationListItem,
} from '~common/navigation/DrawerListItem';

export function LeftDrawerContent() {
  const language = useSelector(state => state.app.settings?.language) ?? 'fi';
  const links = {
    designInstructions:
      language === 'en'
        ? 'https://www.jcdecaux.fi/en/planning-instructions/design-instructions-and-templates'
        : 'https://www.jcdecaux.fi/aineisto-ohjeet/klassisten-ulkomainosten-aineisto-ohjeet/painoaineiston-suunnitteluohjeet-ja-pohjat',
    mockPoster:
      language === 'en'
        ? 'https://www.jcdecaux.fi/en/planning-guide/classic-outdoor-advertisement-material-guidelines/mockup-templates'
        : 'https://jcd-mockup.mediamodifier.com',
    materialInstructions:
      language === 'en'
        ? 'https://www.jcdecaux.fi/en/planning-guide/general-instructions-for-an-open-programme-document'
        : 'https://www.jcdecaux.fi/aineisto-ohjeet/klassisten-ulkomainosten-aineisto-ohjeet/yleisohje-avoimelle-ohjelmadokumentille',
    colorManaged:
      language === 'en'
        ? 'https://www.jcdecaux.fi/en/planning-instructions/print-ready-file'
        : 'https://www.jcdecaux.fi/aineisto-ohjeet/klassisten-ulkomainosten-aineisto-ohjeet/painovalmis-tiedosto',
  };
  return (
    <>
      <NavigationListItem
        href="/"
        text={t`Home`}
        data-test="navigate-to-home"
      />
      <NavigationListItem
        href="/upload-print"
        text={t`Upload for printing`}
        data-test="navigate-to-upload-print"
      />
      <NavigationListItem
        href="/upload-preparation"
        text={t`Upload for preparation`}
        data-test="navigate-to-upload-preparation"
      />
      <AccordionListItem
        childItems={[
          {
            href: links.designInstructions,
            text: t`Design instructions and templates`,
            'data-test': 'navigate-to-design-instructions',
            external: true,
          },
          {
            href: links.mockPoster,
            text: t`Test your poster / Flash image test`,
            'data-test': 'navigate-to-mock-poster',
            external: true,
          },
        ]}
        text={t`Design support`}
      />
      <AccordionListItem
        childItems={[
          {
            href: links.materialInstructions,
            text: t`Material instructions for an open programme document`,
            'data-test': 'navigate-to-material-instructions',
            external: true,
          },
          {
            href: links.colorManaged,
            text: t`Print-ready colour-managed PDF`,
            'data-test': 'navigate-to-color-managed',
            external: true,
          },
        ]}
        text={t`Producing files`}
      />
    </>
  );
}
