/*
 * Helper for Use API
 */

import { camelCase } from 'lodash';
import api from '../../../common/utils/api.utils';
import config from '~common/config';

async function fetch({ customerId }) {
  const res = await api.http.get(
    `${config.url}/api/node-api/uses?customerId=${customerId}`
  );
  const uses = res.data;
  const newUses = [];
  uses.forEach((use, index) => {
    newUses.push({});
    Object.keys(use).forEach(key => {
      // Transform database forms into objects
      if (key === 'proportions_x' || key === 'proportions_y') {
        if (use.proportions_x && use.proportions_y) {
          newUses[index].proportions = {
            x: use.proportions_x,
            y: use.proportions_y,
          };
        }
        return;
      }
      if (key === 'size_x' || key === 'size_y') {
        if (use.size_x && use.size_y) {
          newUses[index].size = {
            x: use.size_x,
            y: use.size_y,
          };
        }
        return;
      }
      // Change every key into camelcase
      newUses[index][camelCase(key)] = uses[index][key];
    });
    // Special cases
    newUses[index].namesByLang = JSON.parse(use.names_by_lang);
    newUses[index].descriptionsByLang = JSON.parse(use.descriptions_by_lang);
    // Add customerConvertProfileId to content type
    if (newUses[index].customerConvertProfileId) {
      newUses[
        index
      ].contentType += `.p${newUses[index].customerConvertProfileId}`;
    }
  });
  return newUses;
}

export default { fetch };
